var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import FactoryFileUpload from '@/common/Uplaod/FactoryFileUpload';
import useDownloadStorageFile from '@/contexts/useDownloadStorageFile';
const UploadAccountLogo = ({ onChange, data }) => {
    const [logoUrl, setLogoUrl] = useState('');
    const [path, setPath] = useState('');
    const [curFile, setCurFile] = useState(null);
    const { downloadFile } = useDownloadStorageFile();
    useEffect(() => {
        const getFileUrl = () => __awaiter(void 0, void 0, void 0, function* () {
            if (data.str_id && data.exists_in_storage && !curFile) {
                const file = yield downloadFile({
                    endpoint_str_id: data.str_id,
                    file_preview_type: 'logo',
                    endpoint: 'accounts',
                });
                if (!file)
                    return;
                const url = URL.createObjectURL(file);
                setLogoUrl(url);
            }
        });
        getFileUrl();
    }, [data]);
    const handleFileChange = (files) => __awaiter(void 0, void 0, void 0, function* () {
        const file = files[0];
        if (!file)
            return;
        setCurFile(file);
        setPath(file.name);
    });
    useEffect(() => {
        if (path && curFile) {
            onChange(path, curFile);
        }
    }, [onChange, path, curFile]);
    useEffect(() => {
        if (!curFile)
            return;
        const url = URL.createObjectURL(curFile);
        setLogoUrl(url);
    }, [curFile]);
    return (_jsxs(Box, { sx: {
            display: 'flex',
            flexDirection: 'row',
            ml: 4,
        }, children: [logoUrl && (_jsx(Box, { sx: { mr: 4 }, children: _jsx("img", { src: logoUrl, alt: "logo", style: { maxHeight: '100px' } }) })), _jsx(Box, { children: _jsx(FactoryFileUpload, { onChange: handleFileChange, imageOnly: true }) })] }, "accountLogo"));
};
export default UploadAccountLogo;
