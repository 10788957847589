import { create } from 'zustand';

import { RowMappingModel } from '../process';

type CommissionStoreType = {
  rowMapping: RowMappingModel;
};

const defaultState: CommissionStoreType = {
  rowMapping: {},
};

const useCommissionStore = create<
  CommissionStoreType & {
    setState: (state: CommissionStoreType) => void;
    setRowMapping: (rowMapping: RowMappingModel, reset?: boolean) => void;
  }
>((set, get) => ({
  ...defaultState,
  setState: (state) => set(state),
  setRowMapping: (rowMapping, reset) => {
    const currentMapping = reset ? {} : { ...get().rowMapping };
    for (const [key, value] of Object.entries(rowMapping)) {
      if (typeof value === 'number') {
        currentMapping[key] = value;
      } else if (value === 'MANUAL_ENTRY') {
        currentMapping[key] = '';
      } else if (value === 'NULL') {
        delete currentMapping[key];
      } else {
        currentMapping[key] = value;
      }
    }

    set({
      rowMapping: currentMapping,
    });
  },
}));

export default useCommissionStore;
