var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useMemo, useEffect } from 'react';
import { Box, TextField, CircularProgress, Autocomplete, autocompleteClasses, } from '@mui/material';
const DataUpdateConfigGroups = ({ field, row, setter, dynamicSelects, }) => {
    const [selectedGroup, setSelectedGroup] = useState(row[field.id] || '');
    const [loading, setLoading] = useState(false);
    const options = useMemo(() => {
        const opts = (dynamicSelects === null || dynamicSelects === void 0 ? void 0 : dynamicSelects.map((group) => ({ group }))) || [];
        if (selectedGroup && !(dynamicSelects === null || dynamicSelects === void 0 ? void 0 : dynamicSelects.includes(selectedGroup))) {
            opts.push({ group: selectedGroup });
        }
        return opts;
    }, [dynamicSelects, selectedGroup]);
    useEffect(() => {
        setLoading(dynamicSelects &&
            Array.isArray(dynamicSelects) &&
            dynamicSelects.length === 0);
    }, [dynamicSelects]);
    return (_jsx(Box, { sx: { width: '50%', mb: 1 }, children: _jsx(Autocomplete, { value: { group: selectedGroup }, options: options, getOptionLabel: (option) => option.group, isOptionEqualToValue: (option, value) => option.group === value.group, onChange: (event, newValue) => {
                const newGroup = (newValue === null || newValue === void 0 ? void 0 : newValue.group) || '';
                if (newGroup && !dynamicSelects.includes(newGroup)) {
                    dynamicSelects.push(newGroup);
                }
                setSelectedGroup(newGroup);
                setter(Object.assign(Object.assign({}, row), { [field.id]: newGroup }));
            }, filterOptions: (options, params) => {
                const filtered = options.filter((option) => option.group.toLowerCase().includes(params.inputValue.toLowerCase()));
                if (params.inputValue !== '' &&
                    !dynamicSelects.includes(params.inputValue)) {
                    filtered.push({ group: params.inputValue });
                }
                return filtered;
            }, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Group", InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(React.Fragment, { children: [loading ? _jsx(CircularProgress, { size: 20 }) : null, params.InputProps.endAdornment] })) }) }))), renderOption: (props, option, state, ownerState) => {
                const { key } = props, optionProps = __rest(props, ["key"]);
                const renderText = () => {
                    if (!dynamicSelects.includes(option.group))
                        return `"${ownerState.getOptionLabel(option)}"`;
                    return ownerState.getOptionLabel(option);
                };
                return (_jsx(Box, Object.assign({ sx: {
                        borderRadius: '8px',
                        margin: '5px',
                        [`&.${autocompleteClasses.option}`]: {
                            padding: 1,
                            paddingLeft: 2,
                        },
                    }, component: "li" }, optionProps, { children: renderText() }), key));
            } }) }));
};
export default DataUpdateConfigGroups;
