import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { memo, useRef, useState } from 'react';
import { areEqual, VariableSizeList } from 'react-window';
import { Box, Checkbox, colors, IconButton, Popper, Typography, } from '@mui/material';
import GpsFixedOutlined from '@mui/icons-material/GpsFixedOutlined';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { AllValue, ContainerWidth } from '../constants';
// Divide the container width by the average character width (9) to estimate characters per line.
const ROW_HEIGHT = 40;
const Row = memo(({ index, style, data, }) => {
    const refSubParentDiv = useRef();
    const item = data.options[index];
    const subList = item.subList || [];
    const isAll = item.value === AllValue;
    let isSelected = !!(item.value === AllValue
        ? data.isAllSelected
        : data.selectedValues[item.value]);
    const renderLabel = data.renderLabel && !isAll ? data.renderLabel : null;
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleCloseSub = () => {
        setAnchorEl(null);
    };
    const handleOpenSub = () => {
        if (refSubParentDiv.current)
            setAnchorEl(refSubParentDiv.current);
    };
    if ((subList === null || subList === void 0 ? void 0 : subList.length) && !data.multiple) {
        const itemData = {
            options: subList,
            onChange: (v) => {
                data.onChange(v);
            },
            selectedValues: data.selectedValues,
            onTargetSelect: () => { },
        };
        isSelected = subList.some((subItem) => data.selectedValues[subItem.value]);
        return (_jsxs(_Fragment, { children: [_jsxs(Box, { ref: refSubParentDiv, onMouseEnter: handleOpenSub, onMouseLeave: handleCloseSub, style: style, sx: {
                        background: isSelected ? 'rgba(33, 150, 243, 0.12)' : 'white',
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        '&:hover': {
                            background: 'rgba(33, 150, 243, 0.12)',
                        },
                    }, children: [_jsx(Typography, { sx: {
                                flexGrow: 1,
                                paddingLeft: !data.multiple ? 2 : 0,
                                lineHeight: 1.2,
                            }, children: item.label }), _jsx(ArrowForwardIosIcon, { sx: { fontSize: 18, mr: 1 } })] }), _jsx(Popper, { open: open, anchorEl: anchorEl, onMouseLeave: handleCloseSub, onMouseEnter: handleOpenSub, placement: "left-start", sx: { zIndex: 9999 }, children: _jsx(Box, { boxShadow: 4, sx: {
                            borderRadius: 1,
                            background: 'white',
                            width: data.containerWidth || ContainerWidth,
                        }, children: _jsx(List, { itemData: itemData }) }) })] }));
    }
    return (_jsxs(Box, { onClick: () => data.onChange(item), style: style, sx: {
            background: isSelected ? 'rgba(33, 150, 243, 0.12)' : 'white',
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            '&:hover': {
                background: 'rgba(33, 150, 243, 0.12)',
                '& .target-select-btn': {
                    visibility: 'visible',
                },
            },
        }, children: [data.multiple && _jsx(Checkbox, { checked: isSelected }), renderLabel ? (renderLabel({
                isSelected,
                key: item.value,
                sx: { flexGrow: 1 },
            })) : (_jsx(Typography, { sx: {
                    flexGrow: 1,
                    paddingLeft: !data.multiple ? 2 : 0,
                    lineHeight: 1.2,
                }, children: item.label })), data.multiple && item.value !== AllValue && (_jsx(IconButton, { className: "target-select-btn", sx: {
                    marginRight: 1,
                    opacity: 0.3,
                    visibility: 'hidden',
                    color: colors.blue['600'],
                    '&:hover': { opacity: 1 },
                }, onClick: (evt) => {
                    evt.stopPropagation();
                    data.onTargetSelect(item);
                }, children: _jsx(GpsFixedOutlined, { sx: { width: 16, height: 16 } }) }))] }));
}, areEqual);
export default function List({ itemData }) {
    const containerWidth = itemData.containerWidth || ContainerWidth;
    const TOTAL_CHARS_INLINE = Math.floor(containerWidth / 9);
    return (_jsx(VariableSizeList, { itemData: itemData, height: ROW_HEIGHT * Math.min(itemData.options.length, 10), itemCount: itemData.options.length, width: '100%', itemSize: (index) => {
            var _a;
            const totalChars = ((_a = itemData.options[index].label) === null || _a === void 0 ? void 0 : _a.length) || 0;
            const totalRow = totalChars / TOTAL_CHARS_INLINE;
            return Math.max(ROW_HEIGHT, totalRow * ROW_HEIGHT);
        }, itemKey: (index, _data) => _data.options[index].value, children: Row }));
}
