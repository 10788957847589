const convertMapToArray = (objects) => {
    if (!objects || !objects.length) {
        return { keys: [], valuesArray: [] };
    }
    const keys = Object.keys(objects[0]);
    const valuesArray = objects.map((obj) => keys.map((key) => obj[key]));
    return { keys, values: valuesArray };
};
export const desc_convertMapToArray = {
    name: 'libs.tools.convertMapToArray(objects)',
    description: 'Converts an array of objects into an object containing keys and values arrays.',
    arguments: {
        objects: '[Array<Object>] Array of objects to be converted',
    },
    returns: '[Object] Object containing keys array and corresponding values array',
    examples: 'convertMapToArray([{a: 1, b: 2}, {a: 3, b: 4}])\n// → {keys: ["a", "b"], values: [[1, 2], [3, 4]]}\n',
};
export default convertMapToArray;
