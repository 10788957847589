import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Divider, FormControl, InputLabel, MenuItem, Select, TextField, } from '@mui/material';
import { useMemo, useState } from 'react';
import { Edit } from '@mui/icons-material';
import Spreadsheet from '@/services/Spreadsheet';
import { DocumentProcessActionTypes } from '@/types';
import useCommissionStore from './stores/useCommissionStore';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
const ProcessMappingForm = ({ selectedDataRange, rangeData, shareData, setSelectedDataRange, processForm, processedData, addActionCount, setProcessedData, }) => {
    const rowMapping = useCommissionStore((state) => state.rowMapping);
    const setRowMapping = useCommissionStore((state) => state.setRowMapping);
    const [selectedFields, setSelectedFields] = useState([]);
    const options = useMemo(() => {
        return Object.keys(shareData.fields)
            .sort((a, b) => {
            const aValue = shareData.fields[a];
            const bValue = shareData.fields[b];
            if (aValue.required && !bValue.required)
                return -1;
            else if (!aValue.required && bValue.required)
                return 1;
            else
                return aValue.label.localeCompare(bValue.label);
        })
            .map((k) => ({
            id: k,
            label: shareData.fields[k].label,
        }));
    }, [shareData.fields]);
    const mergedSelectedFields = useMemo(() => {
        const hasValueKeys = Object.keys(rowMapping);
        const mergedKeys = new Set([...selectedFields, ...hasValueKeys]);
        return options.filter((o) => mergedKeys.has(o.id));
    }, [options, rowMapping, selectedFields]);
    return (_jsxs(Box, { sx: {
            width: 288,
            flexShrink: 0,
            mr: 1,
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        }, children: [_jsxs(FormControl, { sx: { mt: 1 }, children: [_jsx(InputLabel, { id: "select-row", children: "Spreadsheet header row" }), _jsxs(Select, { id: "select-row", label: "Spreadsheet header row", value: selectedDataRange === null || selectedDataRange === void 0 ? void 0 : selectedDataRange.index, onChange: (e) => {
                            addActionCount(DocumentProcessActionTypes.EDIT_MAPPING);
                            const currentData = rangeData.filter((a) => { var _a; return a.index === Number.parseInt(((_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.value) || 0, 10); })[0];
                            setSelectedDataRange(currentData);
                            setProcessedData({
                                data: currentData.data,
                                fields: currentData.fields,
                                version: processedData.version,
                            });
                        }, children: [rangeData.length === 0 && _jsx(MenuItem, { value: 0, children: "\u00A0" }), Object.values(rangeData).map((row) => (_jsx(MenuItem, { value: row.index, children: _jsx("span", { style: { width: 800, overflow: 'auto' }, children: `Row ${row.index + 1}: ${row.fields
                                        .filter((f) => f !== null && f !== undefined)
                                        .join(', ')}` }) }, row.index)))] })] }), _jsx(Divider, { sx: { mt: 2 } }), _jsxs(Box, { sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    mt: 2,
                    flexGrow: 1,
                }, children: [_jsx(EnhancedSelect, { options: options, label: "Select mapping fields", sx: { width: '100%' }, value: mergedSelectedFields, labelKey: "label", valueKey: "id", onChange: (v) => {
                            setSelectedFields(v.map((o) => o.id));
                        }, multiple: true, enableSearch: true }), _jsx(Box, { sx: { position: 'relative', flexGrow: 1, mt: 1.5 }, children: _jsx(Box, { sx: {
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                overflow: 'auto',
                            }, children: mergedSelectedFields.map(({ id: k }) => {
                                var _a, _b, _c;
                                const v = shareData.fields[k];
                                const options = (_a = selectedDataRange === null || selectedDataRange === void 0 ? void 0 : selectedDataRange.fields) === null || _a === void 0 ? void 0 : _a.filter(Boolean).map((userField, i) => ({
                                    id: i,
                                    name: `Col ${Spreadsheet.indexToCol(i)}: ${userField}`,
                                }));
                                options.unshift({ id: 'NULL', name: '(None)' });
                                options.unshift({ id: 'MANUAL_ENTRY', name: '[Manual entry]' });
                                let idValue = rowMapping === null || rowMapping === void 0 ? void 0 : rowMapping[k];
                                if (typeof (rowMapping === null || rowMapping === void 0 ? void 0 : rowMapping[k]) === 'string') {
                                    idValue = 'MANUAL_ENTRY';
                                }
                                else if (typeof (rowMapping === null || rowMapping === void 0 ? void 0 : rowMapping[k]) === 'object') {
                                    idValue = rowMapping === null || rowMapping === void 0 ? void 0 : rowMapping[k].colIndex;
                                }
                                else {
                                    idValue = (rowMapping === null || rowMapping === void 0 ? void 0 : rowMapping[k]) != null ? rowMapping === null || rowMapping === void 0 ? void 0 : rowMapping[k] : '';
                                }
                                const value = options.find((o) => o.id === idValue);
                                return (_jsxs(Box, { sx: {
                                        mt: 1.5,
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%%',
                                    }, children: [_jsx(EnhancedSelect, { enableSearch: true, label: `${v.label}${v.required ? ' *' : ''}`, options: options, value: value, onChange: (v) => {
                                                addActionCount(DocumentProcessActionTypes.EDIT_MAPPING);
                                                setRowMapping({ [k]: v.id });
                                            }, disabled: !!processForm.mapping, sx: { width: '100%' } }), typeof (rowMapping === null || rowMapping === void 0 ? void 0 : rowMapping[k]) === 'string' && (_jsx(Box, { sx: { width: '100%', display: 'flex', mt: 1, mb: 1 }, children: !((_b = v.options) === null || _b === void 0 ? void 0 : _b.length) ? (_jsx(TextField, { id: `${v.key}-manual-entry`, label: `${v.label} (manual entry)`, variant: "outlined", sx: {
                                                    width: '100%',
                                                    border: '1px dashed #ccc',
                                                    borderRadius: 50,
                                                }, value: rowMapping[k] || '', onBlur: (e) => {
                                                    addActionCount(DocumentProcessActionTypes.EDIT_MAPPING);
                                                }, onChange: (e) => {
                                                    setRowMapping({
                                                        [k]: e.target.value,
                                                    });
                                                }, InputProps: {
                                                    endAdornment: _jsx(Edit, {}),
                                                } })) : (_jsxs(FormControl, { sx: { width: '100%' }, children: [_jsx(InputLabel, { children: `${v.label} (manual entry)` }), _jsxs(Select, { id: `${v.key}-manual-entry`, label: `${v.label} (manual entry)`, variant: "outlined", value: rowMapping[k] || '', sx: { border: '1px dashed #ccc' }, onChange: (e) => {
                                                            addActionCount(DocumentProcessActionTypes.EDIT_MAPPING);
                                                            setRowMapping({
                                                                [k]: e.target.value,
                                                            });
                                                        }, children: [_jsx(MenuItem, { value: 'NULL', sx: { color: 'gray' }, children: "(None)" }), (_c = v.options) === null || _c === void 0 ? void 0 : _c.map((userField, i) => (_jsx(MenuItem, { value: userField, children: userField }, userField)))] })] })) }))] }, k));
                            }) }) })] })] }));
};
export default ProcessMappingForm;
