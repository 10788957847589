var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Autocomplete, TextField, CircularProgress, Typography, Checkbox, FormControlLabel, } from '@mui/material';
import { useEffect, useState } from 'react';
import API from '@/services/API';
import useSnackbar from '@/contexts/useSnackbar';
import PolicyCard from './PolicyCard';
export default function ReconcileEdit({ open, statementIds, onClose, }) {
    const { showSnackbar } = useSnackbar();
    const [selected, setSelected] = useState(null);
    const [options, setOptions] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const { data, abort } = API.getBasicQuery(`report_data`, `q=${searchQuery}&incl_linked=true&limit=100`);
    const [loading, setLoading] = useState(false);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const reconcile = () => __awaiter(this, void 0, void 0, function* () {
        setConfirmOpen(false);
        try {
            setLoading(true);
            const res = yield fetch(`${process.env.REACT_APP_API}/api/reconciliation/reconcile`, {
                method: 'POST',
                headers: yield API.getHeaders(),
                body: JSON.stringify({
                    policyId: selected === null || selected === void 0 ? void 0 : selected.id,
                    isSync: true,
                    isManual: true,
                    statementIds: statementIds,
                    assignOneAgent: (selected === null || selected === void 0 ? void 0 : selected.assign_one_agent) || false,
                }),
            });
            const data = yield res.json();
            if ((res.status === 200 && data.statusText) || res.status >= 400) {
                showSnackbar(data.message || data.statusText, 'error');
            }
            handleClose();
            console.log('processReconciliation: ', data);
        }
        catch (_a) {
            showSnackbar('Error reconciling', 'error');
        }
        finally {
            setLoading(false);
        }
    });
    const handleClose = () => {
        onClose();
        setSelected(null);
        setSearchQuery('');
        setConfirmOpen(false);
    };
    useEffect(() => {
        var _a, _b;
        setOptions((_b = (_a = data === null || data === void 0 ? void 0 : data.data) === null || _a === void 0 ? void 0 : _a.map((r) => (Object.assign({ id: r.id, label: `PolicyNumber: ${r.policy_id} - EffDate: ${r.effective_date}`, str_id: r.str_id }, r)))) !== null && _b !== void 0 ? _b : []);
    }, [data]);
    const handleInputChange = (e, v, _reason) => {
        setSearchQuery((prev) => {
            if (prev !== v) {
                abort();
            }
            return v;
        });
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Dialog, { open: open, onClose: onClose, children: [_jsx(DialogTitle, { children: "Manual reconcile" }), _jsx("div", {}), _jsxs(DialogContent, { sx: {
                            width: '400px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                            alignItems: 'stretch',
                        }, children: [_jsx(Autocomplete, { onChange: (_e, v) => setSelected(v), options: options, onInputChange: handleInputChange, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Search for policy" }))), renderOption: (props, option) => {
                                    const { key: _key } = props, optionProps = __rest(props, ["key"]);
                                    return (_jsx(PolicyCard, Object.assign({ policy: option }, optionProps), option.id));
                                } }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: (selected === null || selected === void 0 ? void 0 : selected.assign_one_agent) || false, onChange: (e) => {
                                        if (selected) {
                                            setSelected(Object.assign(Object.assign({}, selected), { assign_one_agent: e.target.checked }));
                                        }
                                    } }), label: "Only assign one agent (best match)" })] }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: handleClose, children: "Cancel" }), _jsx(Button, { onClick: () => setConfirmOpen(true), disabled: loading || !selected, variant: "contained", children: loading ? _jsx(CircularProgress, { size: 24 }) : 'Reconcile' })] })] }), _jsxs(Dialog, { open: confirmOpen, onClose: () => setConfirmOpen(false), children: [_jsx(DialogTitle, { children: "Confirm reconciliation" }), _jsxs(DialogContent, { children: [_jsxs(Typography, { children: ["Are you sure you want to manually reconcile this statement with policy number: ", selected === null || selected === void 0 ? void 0 : selected.policy_id, "?"] }), selected && _jsx(PolicyCard, { policy: selected })] }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: () => setConfirmOpen(false), children: "Cancel" }), _jsx(Button, { onClick: reconcile, color: "primary", variant: "contained", children: "Confirm" })] })] })] }));
}
