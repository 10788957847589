var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useContext, useEffect, useState } from 'react';
import { captureException } from '@sentry/react';
import { useOriginalFile, useOverrideFile } from 'store/excelStore';
import Spreadsheet from '@/services/Spreadsheet';
import { LoadingContext } from '@/contexts/LoadingContext';
import API from '@/services/API';
import { ProcessMethodE } from '../process';
import { XLS_CSV_TYPES } from '@/common/preview/model';
const useFileToData = (rowData, processForm) => {
    const originalFile = useOriginalFile();
    const overrideFile = useOverrideFile();
    const [file, setFile] = useState(null);
    const [fileData, setFileData] = useState();
    const [curExtractionId, setCurExtractionId] = useState();
    const { setLoadingConfig, loadingConfig } = useContext(LoadingContext);
    const documentAIPoster = API.getMutation('documents/googleDocumentAI', 'POST');
    const documentsExtractDataPoster = API.getMutation('documents/extractData', 'POST');
    const adobeExtractDataPoster = API.getMutation('documents/adobeExtract', 'POST');
    const nanonetsDataPoster = API.getMutation('documents/nanonets', 'POST');
    const htmlExtractPoster = API.getMutation('documents/htmlExtract', 'POST');
    const vertexPoster = API.getMutation('gpt/doc', 'POST');
    const { data: curExtractionData, isLoading: curExtractionLoading } = API.getBasicQuery(`extractions/${curExtractionId}`, '', !!curExtractionId);
    useEffect(() => {
        setFile(overrideFile || originalFile);
    }, [overrideFile, originalFile]);
    useEffect(() => {
        if (rowData.method) {
            const latestGeminiExtract = rowData.extractions.find((item) => item.method === ProcessMethodE.Gemini);
            // Check rowdata method is gemini and prompt text is exist
            const [method, str_id] = rowData.method.split('::');
            if (file && processForm.method === ProcessMethodE.Gemini) {
                if (method === ProcessMethodE.Gemini) {
                    handleFileWithGPT(file, str_id);
                }
                else if (latestGeminiExtract) {
                    handleFileWithGPT(file, latestGeminiExtract.str_id);
                }
                else {
                    handleFileWithGPT(file, '');
                }
            }
        }
    }, [processForm.promptText, file, processForm.method]);
    useEffect(() => {
        if (file && XLS_CSV_TYPES.includes(file.type)) {
            saveSpreadsheet(file);
        }
    }, [file]);
    useEffect(() => {
        if (rowData.method) {
            const [_method, _extractionStrId, extractionId] = rowData.method.split('::');
            if (extractionId) {
                setCurExtractionId(extractionId);
            }
        }
    }, [rowData.method]);
    useEffect(() => {
        if (rowData.override_file_path || curExtractionLoading)
            return;
        const [method, extractionStrId] = rowData.method.split('::');
        if (file) {
            switch (method) {
                case 'documentAI':
                    extractDataByGoogleDocumentAIService(file, extractionStrId);
                    break;
                case 'extractTable':
                    extractDataByExtractTableService(file, extractionStrId);
                    break;
                case 'adobeExtract':
                    extractDataByAdobePDFExtractService();
                    break;
                case 'nanonets':
                    extractDataByNanonetsService();
                    break;
                case 'htmlExtract':
                    extractDataByHtmlExtractService();
                    break;
                default:
                    break;
            }
        }
    }, [curExtractionData, curExtractionLoading, file]);
    const fixInvalidJSONString = (str) => {
        let jsonString = str.trim();
        const regx = /:\s*"{2,}?$/; // Match 'some text: ""', 'some text:   ""' pattern
        if (regx.test(jsonString)) {
            jsonString += '}]';
        }
        else if (jsonString.endsWith('"')) {
            jsonString += '"}]';
        }
        else if (jsonString.endsWith('"}')) {
            jsonString += ']';
        }
        else if (jsonString.endsWith('```')) {
            jsonString = jsonString.replace(/`+$/, '');
        }
        return jsonString;
    };
    const jsonParse = (str) => {
        if (!str)
            return null;
        try {
            return JSON.parse(str);
        }
        catch (error) {
            console.error('Error parsing JSON', error);
            return null;
        }
    };
    const handleFileWithGPT = (file, extractionStrId) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        if (loadingConfig.loading)
            return;
        setLoadingConfig({
            loading: true,
            message: 'Gemini is processing...',
        });
        const resp = yield vertexPoster.mutateAsync({
            type: file.type,
            document_id: rowData.id,
            prompt: processForm.promptText,
            force_run: !extractionStrId,
            extract_str_id: extractionStrId,
        });
        try {
            let res = [];
            resp.data.forEach((objStr) => {
                const target = jsonParse(objStr);
                if (!target)
                    return;
                const list = target.parts.map((item) => {
                    const fixedText = fixInvalidJSONString(item.text.trim());
                    return jsonParse(fixedText);
                });
                res = res.concat(...list);
            });
            setLoadingConfig({
                loading: false,
            });
            setFileData({
                type: ProcessMethodE.Gemini,
                data: res,
                extraction: resp.extraction,
                error: '',
            });
        }
        catch (_c) {
            setLoadingConfig({
                loading: false,
            });
            const target = jsonParse(((_a = resp.data) === null || _a === void 0 ? void 0 : _a.length) ? resp.data[0] : '');
            if (target && target.parts && target.parts.length) {
                setFileData({
                    type: ProcessMethodE.InvalidJSON,
                    data: (_b = target.parts[0]) === null || _b === void 0 ? void 0 : _b.text,
                    extraction: resp === null || resp === void 0 ? void 0 : resp.extraction,
                    error: '',
                });
            }
        }
    });
    const saveSpreadsheet = (file) => __awaiter(void 0, void 0, void 0, function* () {
        const res = yield Spreadsheet.loadSpreadsheet(file);
        if (res) {
            setFileData({
                type: 'spreadsheet',
                data: res,
                error: '',
            });
        }
    });
    const extractDataByGoogleDocumentAIService = (_file, extractionStrId) => __awaiter(void 0, void 0, void 0, function* () {
        let tableData;
        if (!curExtractionData && !extractionStrId) {
            const data = {
                document_id: rowData.id,
                document_type: 'original',
            };
            setLoadingConfig({
                loading: true,
                message: 'Processing document...',
                allowClose: true,
            });
            try {
                const resJson = yield documentAIPoster.mutateAsync(data);
                setLoadingConfig({
                    loading: false,
                    message: '',
                });
                if (resJson.error) {
                    setFileData({
                        type: 'documentAI',
                        data: null,
                        error: resJson.error,
                    });
                    captureException(resJson.error);
                    return;
                }
                tableData = resJson.data;
            }
            catch (error) {
                setLoadingConfig({
                    loading: false,
                    message: '',
                });
                setFileData({
                    type: 'documentAI',
                    data: null,
                    error: `${error}`,
                });
                captureException(error);
            }
        }
        else if (curExtractionData) {
            const outputData = jsonParse(curExtractionData.output);
            tableData = outputData;
        }
        if (tableData) {
            setFileData({
                type: 'documentAI',
                data: tableData,
                error: '',
            });
        }
    });
    const extractDataByAdobePDFExtractService = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const [_method, _extractionStrId, extractionId] = rowData.method.split('::');
            if (extractionId) {
                const res = [jsonParse(curExtractionData.output)];
                setFileData({
                    type: 'adobeExtract',
                    data: res,
                    error: '',
                });
                return;
            }
            else {
                const param = {
                    document_id: rowData.id,
                };
                setLoadingConfig({
                    loading: true,
                    message: 'Processing document...',
                    allowClose: true,
                });
                const resJson = yield adobeExtractDataPoster.mutateAsync(param);
                setLoadingConfig({
                    loading: false,
                });
                if (resJson.error) {
                    setFileData({
                        type: 'adobeExtract',
                        data: null,
                        error: resJson.error,
                    });
                    captureException(resJson.error);
                    return;
                }
                setFileData({
                    type: 'adobeExtract',
                    data: [resJson.data],
                    error: '',
                });
            }
        }
        catch (error) {
            setFileData({
                type: 'adobeExtract',
                data: null,
                error: `${error}`,
            });
            captureException(error);
        }
        finally {
            setLoadingConfig({
                loading: false,
            });
        }
    });
    const extractDataByExtractTableService = (_file, extractionStrId) => __awaiter(void 0, void 0, void 0, function* () {
        const reextract = !extractionStrId;
        try {
            let res;
            if (rowData.extractions.length > 0 && !reextract) {
                res = curExtractionData.output;
            }
            else {
                const extractionOption = rowData.method.split('::');
                const extractionId = extractionOption.length > 1 ? extractionOption.pop() : '';
                const param = {
                    document_id: rowData.id, // Str_id?
                    extract_job_id: rowData.extract_job_id,
                    update: reextract,
                    extractionId,
                };
                setLoadingConfig({
                    loading: true,
                    message: 'Processing document...',
                    allowClose: true,
                });
                const resJson = yield documentsExtractDataPoster.mutateAsync(param);
                setLoadingConfig({
                    loading: false,
                });
                if (resJson.error) {
                    setFileData({
                        type: 'extractTable',
                        data: null,
                        error: resJson.error,
                    });
                    captureException(resJson.error);
                    return;
                }
                res = JSON.stringify(resJson);
            }
            setFileData({
                type: 'extractTable',
                data: res,
                error: '',
            });
        }
        catch (error) {
            setFileData({
                type: 'extractTable',
                data: null,
                error: `${error}`,
            });
            captureException(error);
        }
        finally {
            setLoadingConfig({
                loading: false,
            });
        }
    });
    const extractDataByNanonetsService = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const [_method, _extractionStrId, extractionId] = rowData.method.split('::');
            if (extractionId) {
                const res = [jsonParse(curExtractionData.output)];
                setFileData({
                    type: 'nanonets',
                    data: res,
                    error: '',
                });
                return;
            }
            else {
                const param = {
                    document_id: rowData.id,
                };
                setLoadingConfig({
                    loading: true,
                    message: 'Processing document...',
                    allowClose: true,
                });
                const resJson = yield nanonetsDataPoster.mutateAsync(param);
                setLoadingConfig({
                    loading: false,
                });
                if (resJson.error) {
                    setFileData({
                        type: 'nanonets',
                        data: null,
                        error: resJson.error,
                    });
                    captureException(resJson.error);
                    return;
                }
                setFileData({
                    type: 'nanonets',
                    data: [resJson.result],
                    error: '',
                });
            }
        }
        catch (error) {
            setFileData({
                type: 'nanonets',
                data: null,
                error: `${error}`,
            });
            captureException(error);
        }
        finally {
            setLoadingConfig({
                loading: false,
            });
        }
    });
    const extractDataByHtmlExtractService = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const [_method, _extractionStrId, extractionId] = rowData.method.split('::');
            if (extractionId) {
                const res = [jsonParse(curExtractionData.output)];
                setFileData({
                    type: 'htmlExtract',
                    data: res,
                    error: '',
                });
                return;
            }
            else {
                const param = {
                    document_id: rowData.id,
                };
                setLoadingConfig({
                    loading: true,
                    message: 'Processing document...',
                    allowClose: true,
                });
                const resJson = yield htmlExtractPoster.mutateAsync(param);
                setLoadingConfig({
                    loading: false,
                });
                if (resJson.error) {
                    setFileData({
                        type: 'htmlExtract',
                        data: null,
                        error: resJson.error,
                    });
                    captureException(resJson.error);
                    return;
                }
                setFileData({
                    type: 'htmlExtract',
                    data: [resJson.result],
                    error: '',
                });
            }
        }
        catch (error) {
            setFileData({
                type: 'htmlExtract',
                data: null,
                error: `${error}`,
            });
            captureException(error);
        }
        finally {
            setLoadingConfig({
                loading: false,
            });
        }
    });
    return {
        setFileData,
        fileData,
        file,
        setFile,
    };
};
export default useFileToData;
