import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Chip, Skeleton, Tooltip } from '@mui/material';
import * as chrono from 'chrono-node';
import { AccountIds } from 'common/constants';
import currency from 'currency.js';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { checkType } from './helpers';
import { convertExcelDateToJSDate, isNill } from './tools';
const isTransGlobalAccount = (accountID) => accountID === AccountIds.TRANSGLOBAL;
class Formatter {
}
/**
 * @deprecated Use formatCurrency from common/helpers/formatCurrency.ts
 * @param {*} s
 * @returns
 */
Formatter.currency = (s) => {
    if (s === undefined || s === '')
        return '';
    let s1 = s;
    if (typeof s1 === 'string') {
        s1 = s.trim();
    }
    if (typeof s1 === 'string') {
        s1 = s.replaceAll(',', '');
        s1 = s1.replaceAll('$', '');
    }
    if (typeof s1 === 'string' && s1.split('\n').length > 1) {
        [s1] = s.split('\n');
    }
    if (typeof s1 === 'string' && s1.split(' ').length > 1) {
        [s1] = s1.split(' ');
    }
    if (typeof s1 === 'string' && s1.endsWith('-')) {
        s1 = `-${s1.slice(0, -1)}`;
    }
    if (typeof s1 === 'string' && s1.startsWith('(') && s1.endsWith(')')) {
        s1 = `-${s1.slice(1, -1)}`;
    }
    s1 = parseFloat(s1);
    if (Number.isNaN(s1)) {
        s1 = '';
    }
    else {
        s1 = currency(s1).format();
        // +s1.toFixed(2) >= 0
        //   ? `$${s1.toLocaleString('en-US', {
        //       minimumFractionDigits: 2,
        //       maximumFractionDigits: 2,
        //     })}`
        //   : `-$${Math.abs(s1).toLocaleString('en-US', {
        //       minimumFractionDigits: 2,
        //       maximumFractionDigits: 2,
        //     })}`;
    }
    return s1;
};
// TODO: should make sure this is a number
Formatter.commissionRate = (s) => {
    var _a;
    return s !== undefined && s !== null && s !== '' && s !== '%'
        ? ((_a = s === null || s === void 0 ? void 0 : s.toString()) === null || _a === void 0 ? void 0 : _a.endsWith('%'))
            ? s
            : `${s}%`
        : '';
};
Formatter.pct = (val) => `${+(+val * 100).toFixed(2)}%`;
// Takes in a numerical representation of a percentage (e.g. 0.8) and formats
// it as a percentage (e.g. 80%)
Formatter.percentage = (val) => {
    if (typeof val === 'string' || isNill(val)) {
        return val;
    }
    const percentageVal = val * 100;
    return Math.abs(percentageVal) < 100
        ? `${percentageVal.toPrecision(3)}%`
        : `${Math.round(percentageVal)}%`;
};
Formatter.percentageNotDecimal = (val) => {
    if (typeof val === 'string' || isNill(val)) {
        return val;
    }
    return Math.abs(val) < 100
        ? `${val.toPrecision(3)}%`
        : `${Math.round(val)}%`;
};
Formatter.date = (s, options = {
    format: 'MM/DD/YYYY',
}) => {
    var _a;
    let result = '';
    if (!s) {
        return '';
    }
    // Check the value is a number or string number
    if (/^\d+$/.test(s)) {
        let mils = s;
        if (s.toString().length === 5) {
            mils = convertExcelDateToJSDate(+s);
        }
        const time = dayjs(mils).format(options.format);
        return time;
    }
    if (checkType(s, 'Date')) {
        result = dayjs(new Date(s.getTime() + s.getTimezoneOffset() * 60 * 1000)).format(options.format);
    }
    else if (checkType(s, 'Number')) {
        console.error('Unsupported date format', s);
        let bom = dayjs('1/1/1900').add(s - 2, 'days');
        if (options.startOfMonth) {
            bom = bom.startOf('month');
        }
        result = bom.format(options.format);
    }
    else if (checkType(s, 'String')) {
        const parsedDate = chrono.parseDate(s);
        if (parsedDate) {
            // Hack for time zone offset
            let bom = dayjs(new Date(parsedDate.getTime() + parsedDate.getTimezoneOffset() * 60 * 1000));
            if (options.startOfMonth) {
                bom = bom.startOf('month');
            }
            result = bom.format((_a = options.format) !== null && _a !== void 0 ? _a : 'MM/DD/YYYY');
        }
        else {
            console.error(`Couldn't parse '${s}' as a date.`);
            result = '';
        }
    }
    return result;
};
Formatter.dateTime = (date) => new Date(date).toLocaleString();
Formatter.contact = (contact, opts) => {
    var _a, _b;
    const isTransGlobal = (opts === null || opts === void 0 ? void 0 : opts.account_id) === AccountIds.TRANSGLOBAL;
    const lastFirst = (_a = (isTransGlobal || (opts === null || opts === void 0 ? void 0 : opts.last_first))) !== null && _a !== void 0 ? _a : false;
    const inclNickname = (_b = (isTransGlobal || (opts === null || opts === void 0 ? void 0 : opts.incl_nickname))) !== null && _b !== void 0 ? _b : false;
    if (!contact) {
        return '';
    }
    const arr = [];
    const nameArr = [];
    if (contact.first_name)
        nameArr.push(contact.first_name.trim());
    if (contact.last_name)
        nameArr.push(contact.last_name.trim());
    if (lastFirst) {
        nameArr.reverse();
        arr.push(nameArr.join(', '));
    }
    else {
        arr.push(nameArr.join(' '));
    }
    if (inclNickname && contact.nickname)
        arr.push(`(${contact.nickname.trim()})`);
    if ((opts === null || opts === void 0 ? void 0 : opts.incl_email) && contact.email)
        arr.push(`<${contact.email.trim()}>`);
    const str = arr.join(' ');
    return str;
};
Formatter.contactInitials = (contact) => {
    if (!contact) {
        return '';
    }
    let initials = '';
    if (contact.first_name)
        initials += contact.first_name.trim()[0];
    if (contact.last_name)
        initials += contact.last_name.trim()[0];
    return initials.toUpperCase();
};
Formatter.boolean = (val) => {
    if (['TRUE', 'True', 'true', true].includes(val))
        return 'Yes';
    if (['FALSE', 'False', 'false', false].includes(val))
        return 'No';
    return val;
};
Formatter.fieldMatcher = (criteria) => `"${criteria === null || criteria === void 0 ? void 0 : criteria.field}" ${criteria === null || criteria === void 0 ? void 0 : criteria.op} "${criteria === null || criteria === void 0 ? void 0 : criteria.value}"`;
// Formatters returning html elements should generally only be used as tableFormatters or custom components
Formatter.status = (val, _opts) => {
    const statusMap = {
        completed: '✅',
        processing: '🔄',
        error: '⛔️',
        pending: '⏳',
        imported: '📥',
    };
    return (_jsx(Tooltip, { title: val, children: _jsx("span", { children: statusMap[val] || val }) }));
};
Formatter.actionByUser = (row, collection, action = 'created_by', _opts) => {
    const actionProxyField = action.replace('_by', '_proxied_by');
    return collection ? (_jsxs(_Fragment, { children: [_jsx("span", { style: { whiteSpace: 'nowrap' }, children: Formatter.contact(collection === null || collection === void 0 ? void 0 : collection.find((item) => item.uid === row[action])) }), row[actionProxyField] && (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsxs("span", { style: { whiteSpace: 'nowrap' }, children: [' ', "(via", ' ', Formatter.contact(collection === null || collection === void 0 ? void 0 : collection.find((item) => item.uid === row.created_proxied_by)), ")"] })] }))] })) : (_jsx(Skeleton, {}));
};
Formatter.jsonToggleFormatted = (val) => {
    // TODO: Figure out why this results in a different number of hooks error
    // const [isFormatted, setIsFormatted] = useState(false);
    // return (
    //   <Box onClick={() => setIsFormatted(!isFormatted)}>
    //     {isFormatted ? (
    //       <pre style={{ lineHeight: 1 }}>
    //         <span style={{ fontSize: 12 }}>{JSON.stringify(val, null, 2)}</span>
    //       </pre>
    //     ) : (
    //       JSON.stringify(val)
    //     )}
    //   </Box>
    // );
    return JSON.stringify(val);
};
Formatter.policyNumber = (val, opts) => {
    if (isTransGlobalAccount(opts === null || opts === void 0 ? void 0 : opts.account_id)) {
        return val.toUpperCase();
    }
    return val;
};
Formatter.getDynamicSelectFormatter = (stringFn = (o) => o === null || o === void 0 ? void 0 : o.name, matchKey = 'id', defaultString = '') => (val, collection) => {
    if (!Array.isArray(collection))
        return _jsx(Skeleton, {});
    const match = Array.isArray(collection) &&
        (collection === null || collection === void 0 ? void 0 : collection.find((e) => e[matchKey] === val));
    const str = stringFn(match);
    return str || defaultString;
};
Formatter.numberRange = (start, end) => {
    if (![null, undefined].includes(start) && start === end) {
        return start;
    }
    else if (start && end) {
        return `${start}-${end}`;
    }
    else if (start) {
        return `${start}+`;
    }
    else if (end) {
        return `< ${end}`;
    }
    else {
        return 'any';
    }
};
Formatter.compGridCriterion = (val) => {
    var _a;
    return val
        ? `${val.comp_grid_product.type || '(No product type)'} • ${(_a = val.comp_grid_product.name) !== null && _a !== void 0 ? _a : 'n/a'} • Years (${Formatter.numberRange(val.policy_year_start, val.policy_year_end)}) • Ages (${Formatter.numberRange(val.issue_age_start, val.issue_age_end)})${val.compensation_type ? ` • ${val.compensation_type}` : ''}${val.transaction_type ? ` • ${val.transaction_type}` : ''}`
        : '';
};
Formatter.getLinkChipFormatter = (labelKey, paramKey, href, target = '_blank') => (val, _row) => {
    if (val && val[paramKey] && val[labelKey]) {
        return (_jsx(Chip, { component: Link, to: `${href}${val[paramKey]}`, label: val[labelKey], clickable: true, target: target }));
    }
    return null;
};
Formatter.statusChip = (status, opts) => {
    var _a, _b, _c, _d, _e, _f;
    if (!status)
        return null;
    const colorMap = {
        green: '#edf7ed',
        greenDark: '#1e4620',
        blue: '#e5f6fd',
        blueDark: '#014361',
        yellow: '#fff4e5',
        yellowDark: '#663c00',
        red: '#fdeded',
        redDark: '#5f2120',
    };
    const _color = (_e = (_c = (_a = opts === null || opts === void 0 ? void 0 : opts.color) !== null && _a !== void 0 ? _a : colorMap[(_b = opts === null || opts === void 0 ? void 0 : opts.mapping) === null || _b === void 0 ? void 0 : _b[status]]) !== null && _c !== void 0 ? _c : (_d = opts === null || opts === void 0 ? void 0 : opts.mapping) === null || _d === void 0 ? void 0 : _d[status]) !== null && _e !== void 0 ? _e : '#ebebeb';
    const textColor = colorMap[`${(_f = opts === null || opts === void 0 ? void 0 : opts.mapping) === null || _f === void 0 ? void 0 : _f[status]}Dark`];
    return (_jsx(Chip, { sx: {
            color: textColor,
            backgroundColor: _color,
            borderRadius: 1,
            m: 0.25,
        }, size: "small", label: status }));
};
Formatter.highlightChip = (s, min, max) => {
    const colorMap = {
        green: '#4CAF50',
        red: '#F44336',
    };
    const color = s >= max ? colorMap.red : s <= min ? colorMap.green : 'inherit';
    return _jsx("span", { style: { color }, children: s });
};
export default Formatter;
