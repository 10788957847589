import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import { useEffect, useRef, useState } from 'react';
import { utils } from 'xlsx';
const ExcelPreview = ({ spreadsheet, setSpreadsheet, sheet = '', }) => {
    const [maxRowLen, setMaxRowLen] = useState(0);
    const [dataList, setDataList] = useState([]);
    const [rowDataDef, setRowDataDef] = useState([]);
    const [columnDefs, setColumnDefs] = useState([]);
    const containerRef = useRef(null);
    const [sheets, setSheets] = useState([]);
    const [curSheet, setCurSheet] = useState('');
    useEffect(() => {
        if (spreadsheet) {
            const sheets = spreadsheet.getSheets();
            setSheets(sheets);
            const sheetName = sheets.length === 1 || !sheet ? sheets[0] : sheet;
            setCurSheet(sheetName);
        }
    }, [spreadsheet, setSpreadsheet, sheet]);
    useEffect(() => {
        var _a;
        if (!spreadsheet || !curSheet)
            return;
        const _sheetData = (_a = spreadsheet.getJson(curSheet)) !== null && _a !== void 0 ? _a : [];
        const sheetData = Array.isArray(_sheetData) ? _sheetData : [];
        const maxRowlen = sheetData.reduce((max, row) => Math.max(max, row.length), 0);
        const _data = sheetData.map((row) => {
            const _rowLen = row.length;
            if (_rowLen < maxRowlen) {
                return [...row, ...Array(maxRowlen - _rowLen).fill('')];
            }
            return row;
        });
        console.log('maxRowLen', maxRowlen);
        setMaxRowLen(maxRowlen);
        setDataList(_data);
    }, [curSheet, spreadsheet]);
    // Convert the data to object of arrays
    useEffect(() => {
        if (dataList.length) {
            const mappedArr = dataList.map((item) => {
                return item.reduce((acc, currentVal, index) => {
                    const _key = utils.encode_col(index);
                    acc[_key] = currentVal;
                    return acc;
                }, {});
            });
            setRowDataDef(mappedArr);
        }
    }, [dataList]);
    // Set column defs by the max row length
    useEffect(() => {
        if (maxRowLen) {
            console.log('maxRowLen2', maxRowLen);
            const _columnDefs = Array.from(new Array(maxRowLen), (el, idx) => {
                const _key = utils.encode_col(idx);
                return {
                    headerName: _key,
                    field: _key,
                };
            });
            setColumnDefs(_columnDefs);
        }
    }, [maxRowLen]);
    return maxRowLen ? (_jsxs(Box, { ref: containerRef, sx: {
            width: '100%',
            height: '100%',
            display: 'flex',
            border: '1px solid #cecece',
            overflow: 'auto',
            flexDirection: 'column',
            gap: 1,
            maxWidth: '100%',
        }, children: [sheets.length > 1 && (_jsx(Box, { sx: { p: 2 }, children: _jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { children: "Sheet" }), _jsx(Select, { id: "select-sheet", value: curSheet, label: "Sheet", onChange: (e) => {
                                setCurSheet(e.target.value);
                            }, children: sheets.map((s) => (_jsx(MenuItem, { value: s, children: s }, s))) })] }) })), _jsx(Box, { style: { width: '100%', height: '100%' }, className: "ag-theme-material", children: _jsx(AgGridReact, { headerHeight: 40, rowData: rowDataDef, columnDefs: columnDefs, alwaysShowHorizontalScroll: true, defaultColDef: { flex: 1, minWidth: 160, resizable: true } }) })] })) : (_jsx("div", {}));
};
export default ExcelPreview;
