const mappingLibrary = {
    policy_id: {
        keywords: [
            { word: 'policy', score: 2 },
            { word: 'id', score: 1 },
            { word: 'number', score: 1 },
            { word: '#', score: 1 },
            { word: 'no', score: 1 },
        ],
        required: true,
        minScore: 3,
    },
    customer_name: {
        keywords: [
            { word: 'customer', score: 2 },
            { word: 'insured', score: 2 },
            { word: 'client', score: 2 },
            { word: 'name', score: 1 },
        ],
        required: true,
        minScore: 2,
    },
    premium_amount: {
        keywords: [
            { word: 'premium', score: 2 },
            { word: 'amount', score: 1 },
            { word: 'value', score: 1 },
        ],
        required: true,
        minScore: 3,
    },
    commission_rate: {
        keywords: [
            { word: 'rate', score: 2 },
            { word: 'commission', score: 1 },
            { word: 'comm', score: 1 },
            { word: 'comp', score: 1 },
            { word: '%', score: 1 },
        ],
        required: true,
        minScore: 2,
    },
    commission_amount: {
        keywords: [
            { word: 'commission', score: 2 },
            { word: 'comm', score: 2 },
            { word: 'comp', score: 2 },
            { word: 'net', score: 2 },
            { word: 'amount', score: 1 },
            { word: 'gross', score: 1 },
        ],
        required: true,
        minScore: 3,
    },
    product_name: {
        keywords: [
            { word: 'product', score: 2 },
            { word: 'plan', score: 2 },
            { word: 'name', score: 1 },
            { word: 'code', score: 1 },
        ],
        required: false,
        minScore: 2,
    },
    transaction_type: {
        keywords: [
            { word: 'transaction', score: 2 },
            { word: 'type', score: 2 },
            { word: 'trans', score: 2 },
        ],
        required: false,
        minScore: 2,
    },
    agent_id: {
        keywords: [
            { word: 'agent', score: 2 },
            { word: 'id', score: 1 },
            { word: 'number', score: 1 },
        ],
        required: false,
        minScore: 3,
    },
    agent_name: {
        keywords: [
            { word: 'agent', score: 2 },
            { word: 'name', score: 1 },
        ],
        required: false,
        minScore: 2,
    },
    effective_date: {
        keywords: [
            { word: 'effective', score: 2 },
            { word: 'issue', score: 2 },
            { word: 'eff', score: 2 },
            { word: 'date', score: 1 },
        ],
        required: false,
        minScore: 3,
    },
    split_percentage: {
        keywords: [
            { word: 'split', score: 2 },
            { word: 'share', score: 2 },
            { word: 'percentage', score: 1 },
            { word: '%', score: 1 },
        ],
        required: false,
        minScore: 2,
    },
};
const autoMapping = (arr, onlyMatched = true) => {
    const [headerConf, ...mainData] = arr;
    const matchedIndexes = new Set();
    const newHeaders = [...headerConf];
    const missingRequiredKeys = [];
    const cleanString = (str) => str
        .toLowerCase()
        .replace(/[\p{P}\p{S}]/gu, '')
        .trim();
    const headerScores = headerConf.map((header) => {
        const cleanedHeader = cleanString(header);
        const scores = new Map();
        Object.entries(mappingLibrary).forEach(([mappedKey, pattern]) => {
            let totalScore = 0;
            pattern.keywords.forEach(({ word, score }) => {
                if (cleanedHeader.includes(cleanString(word))) {
                    totalScore += score;
                }
            });
            if (totalScore >= pattern.minScore) {
                scores.set(mappedKey, totalScore);
            }
        });
        return scores;
    });
    const maxIterations = headerConf.length;
    for (let iteration = 0; iteration < maxIterations; iteration++) {
        let bestScore = 0;
        let bestHeader = -1;
        let bestMapping = '';
        headerConf.forEach((_, headerIndex) => {
            if (matchedIndexes.has(headerIndex))
                return;
            headerScores[headerIndex].forEach((score, mappedKey) => {
                if (score > bestScore && !newHeaders.includes(mappedKey)) {
                    bestScore = score;
                    bestHeader = headerIndex;
                    bestMapping = mappedKey;
                }
            });
        });
        if (bestScore === 0 || bestHeader === -1)
            break;
        newHeaders[bestHeader] = bestMapping;
        matchedIndexes.add(bestHeader);
    }
    Object.entries(mappingLibrary).forEach(([mappedKey, { required }]) => {
        if (required && !newHeaders.includes(mappedKey)) {
            missingRequiredKeys.push(mappedKey);
            newHeaders.push(mappedKey);
        }
    });
    if (onlyMatched) {
        const filteredHeaders = newHeaders
            .map((header, index) => {
            if (matchedIndexes.has(index) || missingRequiredKeys.includes(header)) {
                return header;
            }
            return undefined;
        })
            .filter((header) => header !== undefined);
        const filteredData = mainData.map((row) => {
            const newRow = row.filter((_, index) => matchedIndexes.has(index));
            missingRequiredKeys.forEach(() => {
                newRow.push('');
            });
            return newRow;
        });
        return [filteredHeaders, ...filteredData];
    }
    return [newHeaders, ...mainData];
};
export const desc_autoMapping = {
    name: 'libs.tools.autoMapping(arr, onlyMatched)',
    description: 'Automatically maps input headers to predefined field names based on keyword matching and scoring.',
    arguments: {
        arr: '[Array] Two-dimensional array where first array contains headers and subsequent arrays contain data',
        onlyMatched: '[boolean] Optional. If true (default), returns only matched columns and required fields. If false, returns all columns.',
    },
    returns: '[Array] First array contains mapped headers, followed by arrays of corresponding data',
    examples: `// Input:
 const data = [
  ["POLICY NUMBER", "PRODUCT CODE", "PREMIUM", "COMM RATE"],
  ["POL-123", "TERM-100", "1500.00", "25%"]
 ];
 
 // With onlyMatched = true
 autoMapping(data) 
 // → [
 //   ["policy_id", "product_name", "premium_amount", "commission_rate"],
 //   ["POL-123", "TERM-100", "1500.00", "25%"]
 // ]
 
 // With onlyMatched = false (keeps unmapped columns)
 autoMapping(data, false)
 // → [
 //   ["policy_id", "product_name", "premium_amount", "commission_rate"],
 //   ["POL-123", "TERM-100", "1500.00", "25%"]
 // ]
 `,
};
export default autoMapping;
