var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { javascript } from '@codemirror/lang-javascript';
import { EditNote, History, Launch, MenuBook, PlayArrow, ContentCopy, Search, } from '@mui/icons-material';
import { Box, Button, Chip, FormControl, IconButton, MenuItem, Popover, TextField, Tooltip, Typography, ToggleButtonGroup, ToggleButton, InputAdornment, } from '@mui/material';
import CodeMirror from '@uiw/react-codemirror';
import { EditorView, keymap } from '@codemirror/view';
import { search as CodeMirrorSearch, openSearchPanel, } from '@codemirror/search';
import { defaultKeymap } from '@codemirror/commands';
import { Allotment } from 'allotment';
import 'allotment/dist/style.css';
import Formatter from 'common/Formatter';
import localforage from 'localforage';
import { forwardRef, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useState, } from 'react';
import { useInterval } from 'react-use';
import { getFilenameFromPath } from 'common/helpers';
import { debounce } from 'lodash';
import { AccessTypes } from 'common/globalTypes';
import { tool, toolDesc } from 'common/tools';
import { BasicDialog, CommentDrawer, FileDialogPreview, FilterSelect, } from '@/common';
import useCommonData from '@/components/UploadModal/processFlow/hoc/useCommonData';
import { LoadingContext } from '@/contexts/LoadingContext';
import usePreviewParams from '@/contexts/usePreviewParams';
import { auth } from '@/firebase';
import API from '@/services/API';
import Spreadsheet from '@/services/Spreadsheet';
import HistoryDialog from '@/views/ProcessorPlayground/HistoryDialog';
import ResultTable from '@/views/ProcessorPlayground/ResultTable';
import ReviewerSelector from '@/views/ProcessorPlayground/ReviewerSelector';
import { codeTheme } from '@/views/ProcessorPlayground/config';
import { DocumentPreviewKeys, NotesEntityTypes } from '@/types';
import { documentAICode, extractTableCode, spreadSheetCode, nanonetsCode, htmlExtractCode, } from '@/views/ProcessorPlayground/defaultCode';
import useDownloadStorageFile from '@/contexts/useDownloadStorageFile';
import useSnackbar from '@/contexts/useSnackbar';
import ToolDesc from '@/views/ProcessorPlayground/ToolDesc';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
function ProcessorPlayground({ rowData, setShowSavingMsg, documentList }, ref) {
    var _a, _b, _c, _d, _e, _f, _g, _j, _k, _m;
    const [currentPrview, setCurrentPrview] = useState();
    const [selectExtraction, setSelectExtraction] = useState('');
    const [currentCode, setCurrentCode] = useState('');
    const [currentResult, setCurrentResult] = useState('');
    const [showChangeCodeDialog, setShowChangeCodeDialog] = useState(false);
    const { setLoadingConfig } = useContext(LoadingContext);
    const [historyList, setHistoryList] = useState([]);
    const [showHistoryList, setShowHistoryList] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [reviewer, setReviewer] = useState({
        uid: null,
        first_name: null,
        last_name: null,
        email: null,
    });
    const [openNotes, setOpenNotes] = useState(false);
    const [sheetList, setSheetList] = useState([]);
    const [spreadsheetData, setSpreadsheetData] = useState();
    const [search, setSearch] = useState('');
    const { showSnackbar } = useSnackbar();
    const [processorTemplateList] = useState([
        {
            label: 'Document AI',
            name: 'documentAI',
            value: documentAICode,
        },
        {
            label: 'Extract Table',
            name: 'extractTable',
            value: extractTableCode,
        },
        {
            label: 'Spreadsheet',
            name: 'spreadsheet',
            value: spreadSheetCode,
        },
        {
            label: 'Nanonets',
            name: 'nanonets',
            value: nanonetsCode,
        },
        {
            label: 'HTML Extract',
            name: 'htmlExtract',
            value: htmlExtractCode,
        },
    ]);
    const [formData, setFormData] = useState({
        name: '',
        type: '',
        method: '',
        company_ids: [],
        company_id: '',
        access: '',
        status: '',
        reviewer_str_id: '',
        notes: '',
        document: '',
        sheetName: '',
        inner_name: '',
        owner: '',
    });
    const [disableOption, setDisableOption] = useState({
        excelDocument: false,
        extractData: false,
    });
    const [showJson, setShowJson] = useState(false);
    const [preview, setPreview] = useState('raw');
    const [previewDisable, setPreviewDisable] = useState(false);
    const [resultTableSearch, setResultTableSearch] = useState('');
    const { showPreview, setShowPreview, previewId, setPreviewPath } = usePreviewParams();
    const [anchorEl, setAnchorEl] = useState();
    // Processors
    const extractPutter = API.getMutation('extractions', 'PUT');
    const processorPoster = API.getMutation('processors', 'POST');
    const processorPatcher = API.getMutation('processors', 'PATCH');
    const { data: documentProfiles = [] } = API.getBasicQuery('document_profiles');
    const { data: owners = [] } = API.getBasicQuery('users/get_fintary_admins');
    const documentProfilePutter = API.getMutation('document_profiles', 'PUT');
    const { data: curProcessorData, isLoading: isProcessorLoading } = API.getBasicQuery(`processors/${rowData === null || rowData === void 0 ? void 0 : rowData.id}`, '', !!(rowData === null || rowData === void 0 ? void 0 : rowData.id));
    const { data: curExtractionData, isLoading: isCurExtractionLoading } = API.getBasicQuery(`extractions/${selectExtraction}`, '', !!selectExtraction);
    const [{ isLoading: isCompaniesLoading, data: extractionsLists = [] }, { isLoading: isExtractionLoading, data: companiesList = [] },] = API.getBasicQueryAll(['extractions', 'companies'], '');
    const { data: _libProcessorList } = API.getBasicQuery('processors', `snapshot=${(rowData === null || rowData === void 0 ? void 0 : rowData.processor_status.includes('::'))
        ? rowData === null || rowData === void 0 ? void 0 : rowData.processor_status.split('::')[0]
        : rowData === null || rowData === void 0 ? void 0 : rowData.str_id}`);
    const { downloadFile } = useDownloadStorageFile();
    const { fields } = useCommonData(formData.type, null);
    const extractionsList = extractionsLists || [];
    const spreadsheetDocuments = useMemo(() => {
        if (!documentList || !documentList.count) {
            return [];
        }
        return documentList.data
            .filter((doc) => (doc === null || doc === void 0 ? void 0 : doc.file_type) === 'spreadsheet')
            .map((doc) => {
            // Load a new field to show the label in the select
            const { override_file_path, filename } = doc;
            if (!override_file_path) {
                doc.label = filename;
            }
            else {
                const _filename = override_file_path.split('/').pop();
                const [_h, ...s] = (_filename === null || _filename === void 0 ? void 0 : _filename.split('-')) || [];
                const file = s.join('-');
                doc.label = file;
            }
            return doc;
        });
    }, [documentList]);
    const handleFabClick = () => {
        setOpenNotes(true);
    };
    const delExtractFields = (res) => {
        var _a, _b;
        if (!res)
            return '';
        const tables = (_a = res.Tables) === null || _a === void 0 ? void 0 : _a.map((t) => {
            delete t.TableConfidence;
            delete t.TableCoordinates;
            return t;
        });
        const lines = (_b = res.Lines) === null || _b === void 0 ? void 0 : _b.map((l) => {
            const _l = l.LinesArray.map((item) => {
                delete item.WordsArray;
                return item;
            });
            l.LinesArray = _l;
            return l;
        });
        res.Tables = tables;
        res.Lines = lines;
        return res;
    };
    useEffect(() => {
        if (reviewer.uid) {
            setFormData((prev) => {
                return Object.assign(Object.assign({}, prev), { reviewer_str_id: reviewer.uid });
            });
        }
    }, [reviewer]);
    useEffect(() => {
        if (rowData) {
            setSelectExtraction(rowData.extractionsid);
        }
        else {
            setSelectExtraction('');
        }
    }, [rowData]);
    // When edit
    useEffect(() => {
        const doAction = () => __awaiter(this, void 0, void 0, function* () {
            var _a, _b, _c, _d;
            if (curProcessorData && curExtractionData) {
                const companyIds = (curProcessorData.companies || []).map((company) => company.str_id);
                const effectiveCompanyIds = companyIds.length > 0
                    ? companyIds
                    : curProcessorData.company_id
                        ? [curProcessorData.company_id]
                        : [];
                setFormData({
                    company_id: effectiveCompanyIds[0] || '',
                    company_ids: effectiveCompanyIds,
                    type: formData.type || curProcessorData.type,
                    name: formData.name || curProcessorData.name,
                    access: curProcessorData.access,
                    method: curProcessorData.method,
                    status: curProcessorData.status,
                    reviewer_str_id: curProcessorData.reviewer_str_id,
                    notes: curProcessorData.notes,
                    document: curProcessorData.document_str_id,
                    sheetName: curProcessorData.suggest_for,
                    inner_name: formData.inner_name,
                    owner: curProcessorData.owner,
                });
                setCurrentCode(curProcessorData === null || curProcessorData === void 0 ? void 0 : curProcessorData.processor);
                // Extraction data
                if (curExtractionData) {
                    setCurrentPrview(JSON.parse((curExtractionData === null || curExtractionData === void 0 ? void 0 : curExtractionData.output) || ''));
                    // SetSelectExtraction(rowData.extractionsid);
                    setCurrentResult('');
                }
                else if (curProcessorData.document_str_id) {
                    spreadsheetChange(curProcessorData.document_str_id);
                }
                // If rowData.extractions has value, disable the excel document select, else disable the extract data select
                setDisableOption({
                    excelDocument: !!curExtractionData,
                    extractData: !curExtractionData,
                });
            }
            else {
                if (!curProcessorData && curExtractionData) {
                    setCurrentPrview(JSON.parse(curExtractionData.output));
                    setCurrentResult('');
                    setFormData(Object.assign(Object.assign({}, formData), { company_ids: ((_a = curExtractionData.documents) === null || _a === void 0 ? void 0 : _a.company_str_id)
                            ? [(_b = curExtractionData.documents) === null || _b === void 0 ? void 0 : _b.company_str_id]
                            : [], company_id: ((_c = curExtractionData.documents) === null || _c === void 0 ? void 0 : _c.company_str_id) || '', type: curExtractionData.documents.type || '', name: '', access: 'account', method: curExtractionData.method || '', notes: '', document: '' }));
                    setDisableOption((prev) => {
                        return Object.assign(Object.assign({}, prev), { excelDocument: true });
                    });
                }
                else if (curProcessorData && !curExtractionData) {
                    const companyIds = ((_d = curProcessorData.companies) === null || _d === void 0 ? void 0 : _d.map((company) => company.str_id)) || [];
                    spreadsheetChange(curProcessorData.document_str_id);
                    setCurrentCode(curProcessorData.processor);
                    setFormData({
                        company_ids: companyIds,
                        company_id: curProcessorData.company_id,
                        type: curProcessorData.type,
                        name: curProcessorData.name,
                        access: curProcessorData.access,
                        method: curProcessorData.method,
                        status: curProcessorData.status,
                        reviewer_str_id: curProcessorData.reviewer_str_id,
                        notes: curProcessorData.notes,
                        document: curProcessorData.document_str_id,
                        sheetName: curProcessorData.suggest_for,
                        inner_name: curProcessorData.inner_name,
                        owner: curProcessorData.owner,
                    });
                }
                else if (!selectExtraction &&
                    !formData.document &&
                    !curProcessorData) {
                    setFormData(Object.assign(Object.assign({}, formData), { company_id: '', type: '', name: '', access: 'account', method: '', status: '', reviewer_str_id: '', notes: '', document: formData.document, sheetName: '', inner_name: '', owner: '' }));
                }
            }
        });
        doAction();
    }, [
        curProcessorData,
        isProcessorLoading,
        curExtractionData,
        isCurExtractionLoading,
        rowData,
        selectExtraction,
    ]);
    useEffect(() => {
        if (!rowData && (selectExtraction || formData.document)) {
            let code;
            switch (formData.method) {
                case 'extractTable':
                    code = extractTableCode;
                    break;
                case 'documentAI':
                    code = documentAICode;
                    break;
                case 'nanonets':
                    code = nanonetsCode;
                    break;
                case 'htmlExtract':
                    code = htmlExtractCode;
                    break;
                case 'spreadsheet':
                    code = spreadSheetCode;
                    break;
                default:
                    throw new Error(`Unsupported extraction method: ${formData.method}`);
            }
            if (!currentCode || !rowData) {
                setCurrentCode(code);
            }
            else {
                // Show confirm dialog
                setShowChangeCodeDialog(true);
            }
        }
    }, [formData.method]);
    useEffect(() => {
        var _a;
        if (formData.company_id) {
            const targetCompany = (_a = companiesList === null || companiesList === void 0 ? void 0 : companiesList.data) === null || _a === void 0 ? void 0 : _a.find((c) => c.str_id === formData.company_id);
            let processorName = '';
            if (targetCompany) {
                processorName = `${targetCompany.company_name}_${formData.type}_${formData === null || formData === void 0 ? void 0 : formData.method}`;
            }
            const _inner = targetCompany ? processorName : (rowData === null || rowData === void 0 ? void 0 : rowData.inner_name) || '';
            setFormData((prev) => {
                return Object.assign(Object.assign({}, prev), { name: (rowData === null || rowData === void 0 ? void 0 : rowData.name) || processorName, inner_name: _inner });
            });
        }
    }, [formData.company_id, formData.method, formData.type]);
    useInterval(() => {
        const localFn = () => __awaiter(this, void 0, void 0, function* () {
            const preCodeList = (yield localforage.getItem('seedData'));
            const seedData = Object.assign(Object.assign({}, formData), { selectExtraction, processor: currentCode, created_at: new Date().toISOString() });
            const dbKey = `${selectExtraction}-${seedData.type}-${seedData.method}`;
            if (preCodeList && selectExtraction) {
                let preList = JSON.parse(preCodeList);
                // Temp remove old data structure
                if (Array.isArray(preList)) {
                    localforage.removeItem('seedData');
                    preList = {};
                }
                const target = preList[dbKey];
                if (target) {
                    // Compare the lastest one, if same, do nothing
                    const _a = target[0], { created_at: _c1 } = _a, rest = __rest(_a, ["created_at"]);
                    const { created_at: _c2 } = seedData, rest2 = __rest(seedData, ["created_at"]);
                    if (JSON.stringify(rest) === JSON.stringify(rest2) ||
                        !seedData.processor) {
                        return;
                    }
                    preList[dbKey].unshift(seedData);
                    // If the target length is more than 50, remove the last one
                    if (preList[dbKey].length > 50) {
                        preList[dbKey].pop();
                    }
                }
                else {
                    // Add it
                    preList[dbKey] = [seedData];
                }
                setShowSavingMsg(true);
                localforage.setItem('seedData', JSON.stringify(preList));
                setTimeout(() => {
                    setShowSavingMsg(false);
                }, 1000);
            }
            else {
                // Create it
                localforage.setItem('seedData', JSON.stringify({
                    [dbKey]: [seedData],
                }));
            }
        });
        localFn();
    }, 30000);
    const onClickShowPreview = () => {
        let fileStrId = '';
        if (selectExtraction) {
            const target = extractionsList.find((item) => item.id === selectExtraction);
            if (target) {
                fileStrId = target.documents.str_id;
            }
        }
        else if (formData.document) {
            const target = spreadsheetDocuments.find((item) => item.str_id === formData.document);
            if (target) {
                fileStrId = target.str_id;
            }
        }
        if (fileStrId) {
            setPreviewPath(fileStrId, DocumentPreviewKeys.ORIGINAL);
            setShowPreview(true);
        }
    };
    const spreadsheetChange = (value) => {
        const readFile = (_path) => __awaiter(this, void 0, void 0, function* () {
            setLoadingConfig({
                loading: true,
            });
            if (typeof value === 'string')
                return;
            const file = yield downloadFile({
                endpoint_str_id: value.str_id,
                file_preview_type: 'original',
                endpoint: 'documents',
            });
            setLoadingConfig({
                loading: false,
            });
            const res = yield Spreadsheet.loadSpreadsheet(file);
            setSpreadsheetData(res);
            const sheets = res === null || res === void 0 ? void 0 : res.getSheets();
            if (sheets && sheets.length) {
                const _sheet = (rowData === null || rowData === void 0 ? void 0 : rowData.suggest_for) || sheets[0];
                const data = res.getJson(_sheet);
                setSheetList(sheets);
                setFormDataValue('sheetName', _sheet);
                return data;
            }
            return null;
        });
        // if value is a string, we need to find the document object
        let docObj = value;
        if (typeof value === 'string') {
            docObj = (spreadsheetDocuments || []).find((item) => item.str_id === value);
        }
        if (docObj) {
            readFile(docObj.file_path).then((fileData) => {
                setCurrentPrview(fileData);
            });
            setFormData((prev) => {
                var _a;
                return Object.assign(Object.assign({}, prev), { company_id: (_a = docObj.companies) === null || _a === void 0 ? void 0 : _a.str_id, type: docObj.type, method: 'spreadsheet', document: docObj.str_id });
            });
            // Disable extract data select
            setDisableOption((prev) => {
                return Object.assign(Object.assign({}, prev), { extractData: true });
            });
            setCurrentResult('');
        }
        else {
            console.log('not found');
        }
    };
    const setFormDataValue = useCallback((key, value) => {
        setFormData((prev) => {
            return Object.assign(Object.assign({}, prev), { [key]: value });
        });
    }, []);
    const onCodeChange = useCallback(debounce((value) => {
        setCurrentCode(value);
    }, 500), []);
    const onChangeSheetName = (e) => {
        const sheetName = e.target.value;
        const data = spreadsheetData.getJson(sheetName);
        setCurrentPrview(data);
        setFormDataValue('sheetName', e.target.value);
    };
    const execCode = useCallback(() => {
        try {
            if (currentCode) {
                const resultFn = eval(`${currentCode}`);
                const currentDocument = !formData.document
                    ? documentList.data.find((item) => {
                        return (item.extractions &&
                            item.extractions.length &&
                            item.extractions.some((extraction) => extraction.id === selectExtraction));
                    })
                    : documentList.data.find((item) => {
                        return item.str_id === formData.document;
                    });
                const libs = {
                    document: currentDocument,
                    tools: tool,
                };
                setCurrentResult(resultFn(currentPrview, libs));
            }
        }
        catch (error) {
            setCurrentResult(`eval error: ${error}`);
        }
    }, [currentCode, currentPrview, currentResult]);
    useEffect(() => {
        if (!isCompaniesLoading &&
            !isExtractionLoading &&
            currentPrview &&
            typeof currentResult !== 'string') {
            checkValid();
        }
    }, [currentResult]);
    const checkValid = useCallback(() => {
        const isValid = currentCode && typeof currentResult !== 'string';
        // Valid for multiple spreadsheet (Medical Mutual)
        if (isValid && (currentResult === null || currentResult === void 0 ? void 0 : currentResult.version) === 'Multiple-Spreadsheet') {
            return true;
        }
        if (!isValid) {
            showSnackbar('Error: Please run the code first or check the form data', 'error');
            return false;
        }
        // Check the result is valid
        if (typeof currentResult === 'string' || !currentResult) {
            return true;
        }
        const resKeys = Object.keys(currentResult);
        const validKeys = ['fields', 'data', 'version', 'sheet'];
        const notValidKeys = resKeys.filter((key) => !validKeys.includes(key));
        if (notValidKeys.length) {
            showSnackbar(`Error: Please check the result key: [${notValidKeys.toString()}] is valid`, 'error');
            return false;
        }
        const resHeader = currentResult.fields;
        const validHeaders = Object.keys(fields);
        const requiredHeaders = Object.keys(fields).filter((key) => fields[key].required && fields[key].enabled);
        const notValidHeader = resHeader.filter((item) => !validHeaders.includes(item));
        if (notValidHeader.length) {
            showSnackbar(`Error: Invalid headers: ${notValidHeader.join(', ')}`, 'error');
            return false;
        }
        const missingRequiredHeaders = requiredHeaders.filter((item) => !resHeader.includes(item));
        if (missingRequiredHeaders.length) {
            showSnackbar(`Error: Missing required headers: ${missingRequiredHeaders.join(', ')}`, 'error');
        }
        return true;
    }, [currentCode, currentResult, selectExtraction, formData]);
    // Clear data
    const clearData = useCallback(() => {
        setCurrentCode('');
        setCurrentResult('');
        setCurrentPrview(undefined);
        setSelectExtraction('');
        setFormData({
            company_id: '',
            company_ids: [],
            type: '',
            name: '',
            access: '',
            method: '',
            status: '',
            reviewer_str_id: '',
            notes: '',
            document: '',
            sheetName: '',
            inner_name: '',
            owner: '',
        });
        setDisableOption({
            excelDocument: false,
            extractData: false,
        });
    }, []);
    /**
     * Create processor snapshot
     * @param isComfirm is ok or cancel
     */
    const onCloseCreateTip = (isComfirm) => __awaiter(this, void 0, void 0, function* () {
        var _a;
        const isExcelDocument = formData.method === 'spreadsheet';
        const processorParam = Object.assign(Object.assign({}, formData), { processor: currentCode, extractionsid: !isExcelDocument ? selectExtraction : '', company_id: formData.company_id, document_str_id: formData.document, suggest_for: formData.sheetName });
        // Reflect.deleteProperty(processorParam, 'method');
        Reflect.deleteProperty(processorParam, 'document');
        if (!processorParam.extractionsid) {
            Reflect.deleteProperty(processorParam, 'extractionsid');
        }
        if (isComfirm) {
            // 1. create a new processor with snapshot tag
            const snapshotTag = (rowData === null || rowData === void 0 ? void 0 : rowData.processor_status) === 'processed'
                ? `${rowData === null || rowData === void 0 ? void 0 : rowData.str_id}::${new Date().toISOString()}`
                : `${rowData === null || rowData === void 0 ? void 0 : rowData.processor_status}::${new Date().toISOString()}`;
            const createRes = yield processorPoster.mutateAsync({
                processor_status: snapshotTag,
                type: rowData === null || rowData === void 0 ? void 0 : rowData.type,
                status: rowData === null || rowData === void 0 ? void 0 : rowData.status,
                reviewer_str_id: rowData === null || rowData === void 0 ? void 0 : rowData.reviewer_str_id,
                processor: rowData === null || rowData === void 0 ? void 0 : rowData.processor,
                notes: rowData === null || rowData === void 0 ? void 0 : rowData.notes,
                name: rowData === null || rowData === void 0 ? void 0 : rowData.name,
                inner_name: rowData === null || rowData === void 0 ? void 0 : rowData.inner_name,
                file_type: rowData === null || rowData === void 0 ? void 0 : rowData.file_type,
                extractionsid: rowData === null || rowData === void 0 ? void 0 : rowData.extractionsid,
                extract_str_ids: rowData === null || rowData === void 0 ? void 0 : rowData.extract_str_ids,
                extract_ids: rowData === null || rowData === void 0 ? void 0 : rowData.extract_ids,
                document_str_id: rowData === null || rowData === void 0 ? void 0 : rowData.document_str_id,
                company_id: rowData === null || rowData === void 0 ? void 0 : rowData.company_id,
                access: rowData === null || rowData === void 0 ? void 0 : rowData.access,
                method: rowData === null || rowData === void 0 ? void 0 : rowData.method,
                owner: rowData === null || rowData === void 0 ? void 0 : rowData.owner,
                suggest_for: formData.sheetName,
                profile_str_id: rowData === null || rowData === void 0 ? void 0 : rowData.profile_str_id,
            });
            if (createRes.error) {
                showSnackbar(createRes.error, 'error');
                return false;
            }
            // 2. update the old processor with new code
            const res = yield processorPatcher.mutateAsync(Object.assign(Object.assign({}, processorParam), { id: rowData === null || rowData === void 0 ? void 0 : rowData.id, updated_by: (_a = auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid }));
            if (res.error) {
                showSnackbar(res.error, 'error');
                return false;
            }
        }
        else {
            clearData();
        }
        return true;
    });
    const submit = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        var _a, _b;
        const isValid = checkValid();
        if (!isValid ||
            !formData.company_ids ||
            formData.company_ids.length === 0) {
            return false;
        }
        try {
            setLoadingConfig({
                loading: true,
            });
            const isExcelDocument = formData.method === 'spreadsheet';
            const primaryCompanyId = formData.company_ids[0];
            if (primaryCompanyId) {
                const processorParam = Object.assign(Object.assign({}, formData), { processor: currentCode, extractionsid: !isExcelDocument ? selectExtraction : '', company_id: primaryCompanyId, company_ids: formData.company_ids, document_str_id: formData.document, suggest_for: formData.sheetName });
                Reflect.deleteProperty(processorParam, 'sheetName');
                Reflect.deleteProperty(processorParam, 'document');
                if (!processorParam.extractionsid) {
                    Reflect.deleteProperty(processorParam, 'extractionsid');
                }
                if (rowData && rowData.processor_status === 'new') {
                    if (formData.status === 'approved' &&
                        ((_a = auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid) !== (rowData === null || rowData === void 0 ? void 0 : rowData.reviewer_str_id)) {
                        showSnackbar('Error: The processor is approved, if you want to edit it please change the status to "draft" or "in review".', 'error');
                        return false;
                    }
                    else {
                        // Get the processor str_id and push it to document_profiles table's processor_str_ids field.
                        const docProfile = documentProfiles.data.find((item) => item.carrier_name === formData.company_id);
                        const res = yield processorPatcher.mutateAsync(Object.assign(Object.assign({}, processorParam), { id: rowData.id, updated_by: (_b = auth.currentUser) === null || _b === void 0 ? void 0 : _b.uid, profile_str_id: docProfile === null || docProfile === void 0 ? void 0 : docProfile.str_id }));
                        if (res.error) {
                            showSnackbar(res.error, 'error');
                            return false;
                        }
                        if (docProfile) {
                            const strIds = [
                                ...new Set([res.str_id, ...docProfile.processor_str_ids]),
                            ];
                            yield documentProfilePutter.mutateAsync({
                                id: docProfile.id,
                                processor_str_ids: strIds,
                                status: formData.status,
                            });
                        }
                    }
                }
                else {
                    if (rowData && rowData.processor_status !== 'new') {
                        // SetShowCreateTip(true);
                        const res = yield onCloseCreateTip(true);
                        setLoadingConfig({
                            loading: false,
                            delayToClose: 0,
                        });
                        return res;
                    }
                    let fileType = '';
                    if (isExcelDocument) {
                        fileType = 'spreadsheet';
                    }
                    else {
                        fileType = (curExtractionData === null || curExtractionData === void 0 ? void 0 : curExtractionData.documents.file_type) || '';
                    }
                    const res = yield processorPoster.mutateAsync(Object.assign(Object.assign({}, processorParam), { file_type: fileType }));
                    if (res.error) {
                        showSnackbar(res.error, 'error');
                        setLoadingConfig({
                            loading: false,
                            delayToClose: 1000,
                        });
                        return false;
                    }
                    const docProfile = documentProfiles.data.find((item) => item.carrier_name === res.company_id);
                    if (docProfile) {
                        const strIds = [
                            ...new Set([res.str_id, ...docProfile.processor_str_ids]),
                        ];
                        yield documentProfilePutter.mutateAsync({
                            id: docProfile.id,
                            processor_str_ids: strIds,
                            status: formData.status,
                        });
                    }
                }
            }
            if (!isExcelDocument) {
                const extractionParam = {
                    output_format: 'Success',
                    id: selectExtraction,
                    method: formData.method,
                };
                // TODO: This action requires make authenticated user
                yield extractPutter.mutateAsync(extractionParam);
            }
            setLoadingConfig({
                loading: false,
                delayToClose: 1000,
            });
            // Clear data
            clearData();
            return true;
        }
        catch (error) {
            setLoadingConfig({
                loading: false,
                delayToClose: 1000,
            });
            showSnackbar(`Error: ${error}`, 'error');
            return false;
        }
    }), [currentCode, currentResult, selectExtraction, formData]);
    const showHistory = useCallback(() => {
        const localFn = () => __awaiter(this, void 0, void 0, function* () {
            const preCodeList = (yield localforage.getItem('seedData'));
            if (preCodeList) {
                const preList = JSON.parse(preCodeList);
                const dbKey = `${selectExtraction}-${formData.type}-${formData.method}`;
                const targetList = preList[dbKey];
                if (targetList && targetList.length) {
                    setHistoryList(targetList.sort((a, b) => a.created_at - b.created_at));
                    setShowHistoryList(true);
                }
                else {
                    showSnackbar('Error: No history data for this extraction', 'error');
                }
            }
        });
        localFn();
    }, [formData.company_id, selectExtraction, formData.name]);
    useImperativeHandle(ref, () => {
        return {
            submit,
        };
    });
    useEffect(() => {
        if (disableOption.extractData ||
            (curExtractionData && (curExtractionData === null || curExtractionData === void 0 ? void 0 : curExtractionData.method) !== 'extractTable')) {
            setPreview('raw');
            setPreviewDisable(true);
        }
        else {
            setPreviewDisable(false);
        }
    }, [disableOption.extractData, curExtractionData]);
    const handlePreview = (event, newPreview) => {
        setPreview(newPreview);
    };
    let jsonPreview = '';
    if (!currentPrview) {
        jsonPreview = 'No data available';
    }
    else {
        if (preview === 'raw') {
            jsonPreview = JSON.stringify(delExtractFields(currentPrview), null, 2);
        }
        else if (preview === 'line') {
            jsonPreview = JSON.stringify(delExtractFields(currentPrview).Lines.reduce((result, eachLine, pageIndex) => {
                const pageKey = `Page ${pageIndex + 1}`;
                result[pageKey] = eachLine.LinesArray.reduce((lines, each, lineIndex) => {
                    lines[lineIndex] = each.Line;
                    return lines;
                }, {});
                return result;
            }, {}), null, 2);
        }
        else if (preview === 'table') {
            let count = 1;
            let prePage = 0;
            jsonPreview = JSON.stringify(delExtractFields(currentPrview).Tables.reduce((result, eachLine) => {
                if (prePage === eachLine.Page) {
                    count += 1;
                }
                else {
                    prePage = eachLine.Page;
                    count = 1;
                }
                const pageKey = `${eachLine.Page}-${count}`;
                result[pageKey] = eachLine.TableJson;
                return result;
            }, {}), null, 2);
        }
    }
    const handleCopy = () => {
        navigator.clipboard.writeText(jsonPreview).catch((err) => {
            console.error('Error copying text: ', err);
        });
    };
    const extractionOptions = [
        ...(extractionsList || [])
            .sort((ia, ib) => +new Date(ib.created_at) - +new Date(ia.created_at))
            .map((item) => ({
            id: item.id,
            label: item,
        }))
            .filter((item) => {
            var _a, _b, _c, _d, _e, _f;
            if (search) {
                if (((_c = (_b = (_a = item.label) === null || _a === void 0 ? void 0 : _a.documents) === null || _b === void 0 ? void 0 : _b.file_path) === null || _c === void 0 ? void 0 : _c.toLowerCase().includes(search.toLowerCase())) ||
                    ((_f = (_e = (_d = item.label) === null || _d === void 0 ? void 0 : _d.documents) === null || _e === void 0 ? void 0 : _e.override_file_path) === null || _f === void 0 ? void 0 : _f.toLowerCase().includes(search.toLowerCase())))
                    return item;
            }
            else {
                return item;
            }
        }),
    ];
    const enhancedSelectorStyle = {
        minWidth: 125,
        width: 'auto',
        '& .MuiBox-root': {
            maxWidth: 'calc(100% - 24px)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    };
    return (_jsxs(Box, { className: "flex flex-col w-full h-full p-4 overflow-hidden", children: [_jsxs(Box, { className: "h-15 flex mb-2 gap-1", children: [!disableOption.excelDocument && (_jsxs(_Fragment, { children: [_jsx(FilterSelect, { id: "id-document", sx: {
                                    '& .MuiInputBase-root': {
                                        height: '35px',
                                    },
                                    '& .MuiOutlinedInput-input': {
                                        padding: '0 14px',
                                        height: '35px',
                                    },
                                    '& .MuiInputLabel-root': {
                                        transform: 'translate(14px, -6px) scale(0.75)',
                                        backgroundColor: 'white',
                                        paddingLeft: '4px',
                                        paddingRight: '4px',
                                    },
                                    '& .MuiInputLabel-shrink': {
                                        transform: 'translate(14px, -6px) scale(0.75)',
                                    },
                                }, options: spreadsheetDocuments || [], fullWidth: true, valueKey: "str_id", value: formData.document, onChange: (data) => {
                                    spreadsheetChange(data === null || data === void 0 ? void 0 : data.data);
                                }, label: "Excel/CSV document", InputLabelProps: { shrink: true }, getOptionLabel: (value) => {
                                    let docObj = value;
                                    if (typeof value === 'string') {
                                        docObj = spreadsheetDocuments === null || spreadsheetDocuments === void 0 ? void 0 : spreadsheetDocuments.find((item) => item.str_id === value);
                                    }
                                    return getFilenameFromPath(docObj === null || docObj === void 0 ? void 0 : docObj.label);
                                }, renderOptionItem: (item) => (_jsxs(Box, { display: "flex", alignItems: "left", flexDirection: "column", justifyContent: "space-between", children: [_jsx("div", { className: "flex-1 mr-2", children: getFilenameFromPath(item === null || item === void 0 ? void 0 : item.label) }), _jsx("div", { className: "mx-1 text-sm text-gray-400", children: `${item.status} - ${Formatter.date(item.created_at, true, 'MM/DD/YYYY hh:mm:A')}` })] })) }), sheetList && sheetList.length ? (_jsx(FormControl, { fullWidth: true, children: _jsx(EnhancedSelect, { label: "Sheet name", sx: enhancedSelectorStyle, options: (sheetList === null || sheetList === void 0 ? void 0 : sheetList.map((item) => ({
                                        id: item,
                                        label: item,
                                    }))) || [], value: {
                                        id: formData.sheetName || '',
                                        label: formData.sheetName || '',
                                    }, onChange: (value) => onChangeSheetName({
                                        target: {
                                            value: value.id,
                                        },
                                    }) }) })) : ('')] })), !disableOption.extractData && extractionsList && (_jsx(FormControl, { fullWidth: true, children: _jsx(EnhancedSelect, { enableSearch: true, label: "Extraction", sx: enhancedSelectorStyle, listContainerSx: {
                                minWidth: 800,
                            }, value: {
                                id: selectExtraction || '',
                                label: getFilenameFromPath((_c = (_b = (_a = extractionOptions.find((opt) => opt.id === selectExtraction)) === null || _a === void 0 ? void 0 : _a.label) === null || _b === void 0 ? void 0 : _b.documents) === null || _c === void 0 ? void 0 : _c.file_path) || '',
                            }, onChange: (value) => setSelectExtraction(value.id), onSearch: setSearch, searchKeyword: search, options: extractionOptions, renderLabel: ({ key }) => {
                                var _a, _b, _c, _d, _e, _f;
                                const item = extractionOptions.find((opt) => opt.id === key);
                                if (!(item === null || item === void 0 ? void 0 : item.label))
                                    return null;
                                const filename = getFilenameFromPath(((_b = (_a = item === null || item === void 0 ? void 0 : item.label) === null || _a === void 0 ? void 0 : _a.documents) === null || _b === void 0 ? void 0 : _b.override_file_path) ||
                                    ((_d = (_c = item === null || item === void 0 ? void 0 : item.label) === null || _c === void 0 ? void 0 : _c.documents) === null || _d === void 0 ? void 0 : _d.file_path));
                                return (_jsx(MenuItem, { value: item.id, children: _jsxs(Box, { display: "flex", alignItems: "center", justifyContent: "space-between", sx: {
                                            width: '100%',
                                            minWidth: '500px',
                                            p: 1,
                                        }, children: [_jsx("div", { className: "flex-1 mr-2 truncate", children: filename }), _jsx("div", { className: "mx-1 text-sm text-gray-400 whitespace-nowrap", children: `${(_e = item === null || item === void 0 ? void 0 : item.label) === null || _e === void 0 ? void 0 : _e.method} - ${Formatter.date((_f = item.label) === null || _f === void 0 ? void 0 : _f.created_at, true, 'MM/DD/YYYY hh:mm:A')}` })] }) }, item.id));
                            } }) })), _jsx(FormControl, { fullWidth: true, children: _jsx(EnhancedSelect, { multiple: true, enableSearch: true, label: "Companies", sx: enhancedSelectorStyle, options: ((_d = companiesList === null || companiesList === void 0 ? void 0 : companiesList.data) === null || _d === void 0 ? void 0 : _d.filter(Boolean).map((item) => ({
                                id: item.str_id,
                                label: item.access === AccessTypes.GLOBAL
                                    ? `${item.company_name} (Fintary)`
                                    : item.company_name,
                                type: 'item',
                            }))) || [], value: ((_e = formData.company_ids) === null || _e === void 0 ? void 0 : _e.map((id) => {
                                var _a;
                                const company = (_a = companiesList === null || companiesList === void 0 ? void 0 : companiesList.data) === null || _a === void 0 ? void 0 : _a.find((item) => item.str_id === id);
                                return {
                                    id,
                                    label: (company === null || company === void 0 ? void 0 : company.access) === AccessTypes.GLOBAL
                                        ? `${company.company_name} (Fintary)`
                                        : (company === null || company === void 0 ? void 0 : company.company_name) || '',
                                    type: 'item',
                                };
                            })) || [], onChange: (values) => {
                                const ids = values.map((v) => v.id);
                                setFormData((prev) => (Object.assign(Object.assign({}, prev), { company_ids: ids, company_id: ids[0] || '' })));
                            } }) }), _jsx(FormControl, { fullWidth: true, children: _jsx(EnhancedSelect, { label: "Document type", sx: enhancedSelectorStyle, options: [
                                { id: 'statement', label: 'Statement' },
                                { id: 'report', label: 'Report' },
                            ], value: {
                                id: formData.type || '',
                                label: formData.type === 'statement'
                                    ? 'Statement'
                                    : formData.type === 'report'
                                        ? 'Report'
                                        : '',
                            }, onChange: (value) => setFormDataValue('type', value.id) }) }), _jsx(FormControl, { fullWidth: true, children: _jsx(EnhancedSelect, { label: "Extraction method", sx: enhancedSelectorStyle, options: processorTemplateList.map((item) => ({
                                id: item.name,
                                label: item.label,
                            })), value: {
                                id: formData.method || '',
                                label: ((_f = processorTemplateList.find((item) => item.name === formData.method)) === null || _f === void 0 ? void 0 : _f.label) || '',
                            }, onChange: (value) => setFormDataValue('method', value.id) }) }), _jsx(TextField, { label: "Name", sx: {
                            '& .MuiInputBase-root': {
                                height: '35px',
                            },
                            '& .MuiOutlinedInput-input': {
                                padding: '0 14px',
                                height: '35px',
                            },
                        }, fullWidth: true, variant: "outlined", value: formData.name, onChange: (e) => setFormDataValue('name', e.target.value), InputLabelProps: { shrink: true } }), _jsx(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 2 }, children: _jsx(EnhancedSelect, { label: "Owner", sx: enhancedSelectorStyle, listContainerSx: {
                                p: '10px',
                                minWidth: 500,
                            }, options: (owners || []).map((c) => ({
                                id: c.uid,
                                label: Formatter.contact(c),
                                email: c.email,
                            })), value: {
                                id: formData.owner || '',
                                label: formData.owner
                                    ? Formatter.contact(owners === null || owners === void 0 ? void 0 : owners.find((c) => c.uid === formData.owner))
                                    : '',
                            }, renderLabel: ({ key }) => {
                                const owner = owners.find((c) => c.uid === key);
                                if (!owner)
                                    return null;
                                return (_jsxs(Box, { sx: {
                                        display: 'flex',
                                        width: 480,
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        gap: 1,
                                    }, children: [_jsx(Box, { sx: { flex: 1 }, children: Formatter.contact(owner) }), _jsx(Box, { sx: { color: '#666' }, children: owner.email })] }));
                            }, onChange: (value) => setFormDataValue('owner', value.id) }) }), _jsx(FormControl, { fullWidth: true, children: _jsx(EnhancedSelect, { label: "Access", sx: enhancedSelectorStyle, options: [
                                { id: 'account', label: 'Account' },
                                { id: 'global', label: 'Global' },
                            ], value: {
                                id: formData.access || '',
                                label: formData.access === 'account'
                                    ? 'Account'
                                    : formData.access === AccessTypes.GLOBAL
                                        ? 'Global'
                                        : '',
                            }, onChange: (value) => setFormDataValue('access', value.id) }) }), _jsx(FormControl, { fullWidth: true, children: _jsx(EnhancedSelect, { label: "Status", sx: enhancedSelectorStyle, options: ((_g = auth.currentUser) === null || _g === void 0 ? void 0 : _g.uid) === (rowData === null || rowData === void 0 ? void 0 : rowData.reviewer_str_id)
                                ? [
                                    { id: 'draft', label: 'Draft' },
                                    { id: 'in_review', label: 'In review' },
                                    { id: 'approved', label: 'Approved' },
                                    { id: 'needs_update', label: 'Needs update' },
                                ]
                                : [
                                    { id: 'approved', label: 'Approved', disabled: true },
                                    {
                                        id: 'needs_update',
                                        label: 'Needs update',
                                        disabled: true,
                                    },
                                    { id: 'draft', label: 'Draft' },
                                    { id: 'in_review', label: 'In review' },
                                ], value: {
                                id: formData.status || '',
                                label: formData.status
                                    ? formData.status.charAt(0).toUpperCase() +
                                        formData.status.replace(/_/g, ' ').slice(1)
                                    : '',
                            }, onChange: (value) => {
                                var _a;
                                setFormDataValue('status', value.id);
                                if (((_a = auth.currentUser) === null || _a === void 0 ? void 0 : _a.uid) !== (rowData === null || rowData === void 0 ? void 0 : rowData.reviewer_str_id) &&
                                    value.id === 'in_review') {
                                    setOpenModal(true);
                                }
                            } }) })] }), _jsx(Box, { className: "flex-1 flex", children: _jsxs(Allotment, { defaultSizes: [25, 75], children: [_jsx(Allotment.Pane, { children: _jsx(Box, { className: "w-full h-full overflow-auto bg-sky-50/50 border border-blue-400 border-solid text-sm relative", children: _jsxs(Box, { sx: {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        width: '100%',
                                        height: '100%',
                                        overflow: 'hidden',
                                    }, children: [_jsxs(Box, { sx: {
                                                display: 'flex',
                                                width: '100%',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                padding: '0 8px',
                                                borderBottom: '1px solid #60a5fa',
                                            }, children: [_jsxs(ToggleButtonGroup, { color: "primary", value: preview, exclusive: true, onChange: handlePreview, "aria-label": "Preview options", children: [_jsx(ToggleButton, { value: "raw", sx: {
                                                                width: '40px',
                                                                height: '20px',
                                                                borderRadius: 1,
                                                            }, children: "Raw" }), _jsx(ToggleButton, { value: "table", disabled: previewDisable, sx: {
                                                                width: '40px',
                                                                height: '20px',
                                                                borderRadius: 1,
                                                            }, children: "Table" }), _jsx(ToggleButton, { value: "line", disabled: previewDisable, sx: {
                                                                width: '40px',
                                                                height: '20px',
                                                                borderRadius: 1,
                                                            }, children: "Line" })] }), _jsxs(Box, { children: [_jsx(IconButton, { onClick: handleCopy, sx: {
                                                                opacity: 0.8,
                                                                '&:hover': { opacity: 1 },
                                                                backgroundColor: 'white',
                                                            }, children: _jsx(ContentCopy, { sx: { width: '22px', height: '22px' } }) }), _jsx(IconButton, { onClick: onClickShowPreview, sx: {
                                                                opacity: 0.8,
                                                                '&:hover': { opacity: 1 },
                                                                backgroundColor: 'white',
                                                                cursor: 'pointer',
                                                            }, children: _jsx(Launch, { sx: { width: '24px', height: '24px' } }) })] })] }), _jsx(Box, { sx: { flex: 1, overflow: 'auto' }, children: _jsx(CodeMirror, { height: "50%", value: jsonPreview, width: "100%", readOnly: true, basicSetup: {
                                                    lineNumbers: true,
                                                }, extensions: [
                                                    javascript({ jsx: true }),
                                                    EditorView.lineWrapping,
                                                    CodeMirrorSearch({
                                                        top: true,
                                                    }),
                                                    keymap.of([
                                                        {
                                                            key: 'Ctrl-f',
                                                            run: openSearchPanel,
                                                            preventDefault: true,
                                                        },
                                                    ]),
                                                    keymap.of(defaultKeymap),
                                                ] }) })] }) }) }), _jsx(Allotment.Pane, { children: _jsxs(Box, { className: "flex-1 flex flex-col relative overflow-hidden w-full h-full bg-sky-50/5 border border-blue-400 ml-3", children: [_jsxs(Box, { className: "absolute top-2 right-4 z-10 flex gap-1", children: [_jsx(Tooltip, { title: "Tool library", children: _jsx(IconButton, { "aria-label": "tool library", color: "primary", onClick: (e) => setAnchorEl(e.currentTarget), children: _jsx(MenuBook, {}) }) }), _jsx(Popover, { id: "p", open: !!anchorEl, anchorEl: anchorEl, onClose: () => setAnchorEl(null), anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }, children: _jsx(ToolDesc, { toolDesc: toolDesc }) }), _jsx(Tooltip, { title: "Show history", children: _jsx(IconButton, { "aria-label": "show history", color: "primary", onClick: showHistory, children: _jsx(History, {}) }) }), _jsx(IconButton, { "aria-label": "run code", color: "primary", onClick: execCode, children: _jsx(PlayArrow, {}) }), _jsx(FormControl, { children: _jsx(EnhancedSelect, { placeholder: "Template", sx: enhancedSelectorStyle, options: processorTemplateList.map((item) => ({
                                                        id: item.value,
                                                        label: item.label,
                                                    })), value: {
                                                        id: currentCode || '',
                                                        label: ((_j = processorTemplateList.find((item) => item.value === currentCode)) === null || _j === void 0 ? void 0 : _j.label) || '',
                                                    }, onChange: (value) => setCurrentCode(value.id) }) }), _jsx(Button, { variant: "outlined", onClick: handleFabClick, startIcon: _jsx(EditNote, {}), children: "Notes" })] }), _jsxs(Allotment, { defaultSizes: [75, 25], vertical: true, children: [_jsx(Allotment.Pane, { children: _jsx(Box, { className: "w-full h-full overflow-auto bg-sky-50/5 border border-blue-400 border-solid text-sm", children: _jsx(CodeMirror, { theme: codeTheme, height: "100%", value: currentCode, width: "100%", extensions: [javascript({ jsx: true })], onChange: onCodeChange }) }) }), _jsx(Allotment.Pane, { children: _jsxs(Box, { className: "w-full overflow-auto bg-sky-50/50 border border-blue-400 border-solid mt-3 text-sm", sx: { height: 'calc(100% - 12.5px)' }, children: [currentResult && typeof currentResult !== 'string' && (_jsxs(Box, { sx: {
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                                justifyContent: 'space-between',
                                                                height: '100%',
                                                                width: '100%',
                                                                px: 1,
                                                            }, children: [_jsx(Box, { sx: {
                                                                        display: 'flex',
                                                                        width: '100%',
                                                                        mt: 1,
                                                                        mb: 1,
                                                                    }, children: _jsxs(Box, { sx: {
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            flex: 1,
                                                                            gap: 1,
                                                                        }, children: [_jsx(Button, { variant: "outlined", onClick: () => setShowJson(!showJson), children: showJson ? 'Show Table' : 'Show JSON' }), _jsx(Chip, { label: `Rows: ${(_m = (_k = currentResult === null || currentResult === void 0 ? void 0 : currentResult.data) === null || _k === void 0 ? void 0 : _k.length) !== null && _m !== void 0 ? _m : 'n/a'}` }), _jsx(TextField, { size: "small", placeholder: "Search...", onChange: (e) => setResultTableSearch(e.target.value), sx: {
                                                                                    width: '200px',
                                                                                    '& .MuiOutlinedInput-root': {
                                                                                        borderRadius: '20px',
                                                                                        height: '32px',
                                                                                    },
                                                                                    '& .MuiInputLabel-root': {
                                                                                        lineHeight: '1em',
                                                                                    },
                                                                                }, InputProps: {
                                                                                    startAdornment: (_jsx(InputAdornment, { position: "start", children: _jsx(Search, {}) })),
                                                                                } })] }) }), _jsx(Box, { sx: { flex: 1, height: '100%', width: '100%' }, children: !showJson ? (_jsx(ResultTable, { headers: currentResult.fields, rows: currentResult.data, resultTableSearch: resultTableSearch })) : (_jsx(Box, { sx: {
                                                                            flex: 1,
                                                                            height: '100%',
                                                                            width: '100%',
                                                                            overflow: 'auto',
                                                                        }, children: _jsx(CodeMirror, { value: typeof currentResult !== 'string'
                                                                                ? JSON.stringify(currentResult, null, 2)
                                                                                : currentResult, width: "100%", height: "100%", readOnly: true, editable: false, basicSetup: {
                                                                                lineNumbers: false,
                                                                                foldGutter: false,
                                                                            }, extensions: [javascript({ jsx: true })] }) })) })] })), typeof currentResult === 'string' && (_jsx(Box, { children: currentResult }))] }) })] })] }) })] }) }), _jsx(HistoryDialog, { setShowHistoryList: setShowHistoryList, showHistoryList: showHistoryList, historyList: historyList, setCurrentCode: setCurrentCode }), _jsx(ReviewerSelector, { setOpen: setOpenModal, open: openModal, setReviewer: setReviewer }), openNotes && (_jsx(CommentDrawer, { open: openNotes, setOpen: setOpenNotes, type: NotesEntityTypes.PROCESSOR, rowData: rowData })), showPreview && (_jsx(FileDialogPreview, { showPreview: showPreview, setShowPreview: setShowPreview, fileId: previewId })), showChangeCodeDialog && (_jsx(BasicDialog, { title: "Change processor code", bodyComponent: _jsxs(Box, { className: "flex flex-col gap-2", children: [_jsx(Typography, { variant: "body2", children: "After the method is changed, we will match you with a more accurate parsing template. Are you sure you want to change the current code?" }), _jsx(Typography, { variant: "body2", className: "text-sky-700", children: "You can also retrieve the previous code in the history." })] }), open: showChangeCodeDialog, onClose: (isComfirm) => {
                    var _a;
                    if (isComfirm) {
                        const code = ((_a = processorTemplateList.find((item) => item.name === formData.method)) === null || _a === void 0 ? void 0 : _a.value) || spreadSheetCode;
                        setCurrentCode(code);
                    }
                    setShowChangeCodeDialog(false);
                } }))] }));
}
export default forwardRef(ProcessorPlayground);
