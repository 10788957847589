import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useMemo } from 'react';
import { GROUP_BY_VALUES } from 'common/documents/documents.constants';
import { Box, Chip, colors, IconButton, Link, Tooltip, Typography, } from '@mui/material';
import BusinessIcon from '@mui/icons-material/Business';
import CalendarIcon from '@mui/icons-material/CalendarMonth';
import { Link as RouterLink } from 'react-router-dom';
import LaunchOutlined from '@mui/icons-material/LaunchOutlined';
import { getStartAndEndDates } from '../utils';
import { ROUTES } from '../../constants';
import DataTransformation from '@/services/DataTransformation';
import { useFilterParams } from './useFilterParams';
export const useColumns = ({ groupBy, totalData, }) => {
    const { companies, start_date, end_date } = useFilterParams();
    const dateColumn = useMemo(() => {
        return {
            accessorKey: 'display_name',
            header: 'Date',
            Footer: () => _jsx(Typography, { children: "Total" }),
            Cell: ({ renderedCellValue, row }) => {
                if (!row.original.date) {
                    return 'Empty date';
                }
                if (row.original.date === 'total') {
                    return 'Total';
                }
                const { startDate, endDate } = getStartAndEndDates(row.original.date, groupBy);
                return (_jsxs(Box, { sx: {
                        display: 'flex',
                        alignItems: 'center',
                        cursor: 'pointer',
                        ':hover': {
                            '& .action': {
                                visibility: 'visible',
                            },
                        },
                    }, children: [renderedCellValue, _jsx(Box, { className: "action", sx: {
                                display: 'flex',
                                alignItems: 'center',
                                '& a': {
                                    cursor: 'pointer',
                                    color: colors.grey[800],
                                    display: 'inline-flex',
                                },
                            }, children: _jsx(Tooltip, { title: "View companies in this period", children: _jsx(RouterLink, { to: {
                                        pathname: ROUTES.documentsGroupView,
                                        search: new URLSearchParams({
                                            start_date: startDate,
                                            end_date: endDate,
                                            group_by: GROUP_BY_VALUES.COMPANY,
                                        }).toString(),
                                    }, children: _jsx(BusinessIcon, { sx: { ml: 1, fontSize: 18 } }) }) }) })] }));
            },
        };
    }, [groupBy]);
    const companyColumn = useMemo(() => {
        return {
            accessorKey: 'company_name',
            header: 'Company',
            Footer: () => _jsx(Typography, { children: "Total" }),
            Cell: ({ renderedCellValue, row }) => {
                if (row.original.company_str_id === 'total') {
                    return 'Total';
                }
                return (_jsxs(Box, { sx: {
                        display: 'flex',
                        alignItems: 'center',
                        ':hover': {
                            '& .action': {
                                visibility: 'visible',
                            },
                        },
                    }, children: [_jsx(Chip, { label: renderedCellValue, clickable: true, component: "a", href: `/companies?id=${row.original.company_str_id}`, target: "_blank" }), _jsx(Box, { className: "action", sx: {
                                display: 'flex',
                                alignItems: 'center',
                                '& a': {
                                    cursor: 'pointer',
                                    color: colors.grey[700],
                                    display: 'inline-flex',
                                },
                            }, children: _jsx(Tooltip, { title: "View group by month", children: _jsx(RouterLink, { to: {
                                        pathname: ROUTES.documentsGroupView,
                                        search: new URLSearchParams({
                                            companies: row.original.company_str_id || '',
                                        }).toString(),
                                    }, children: _jsx(CalendarIcon, { sx: { ml: 1, fontSize: 18 } }) }) }) })] }));
            },
        };
    }, []);
    const columns = useMemo(() => {
        return [
            groupBy === GROUP_BY_VALUES.COMPANY ? companyColumn : dateColumn,
            {
                accessorKey: 'file_count',
                header: 'Documents',
                size: 150,
                Footer() {
                    return (_jsx(Typography, { sx: { textAlign: 'right' }, children: totalData.file_count }));
                },
                muiTableHeadCellProps: {
                    sx: {
                        '&>.Mui-TableHeadCell-Content': {
                            justifyContent: 'flex-end',
                        },
                    },
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                Cell: ({ renderedCellValue, row }) => {
                    const urlParams = new URLSearchParams({});
                    if (groupBy === GROUP_BY_VALUES.COMPANY) {
                        if (start_date) {
                            urlParams.append('start_date', start_date);
                        }
                        if (end_date) {
                            urlParams.append('end_date', end_date);
                        }
                        urlParams.append('companies', row.original.company_str_id || '');
                    }
                    else {
                        // Group by month
                        companies === null || companies === void 0 ? void 0 : companies.forEach((company) => {
                            urlParams.append('companies', company);
                        });
                        const { startDate, endDate } = getStartAndEndDates(row.original.date, groupBy);
                        urlParams.append('start_date', startDate);
                        urlParams.append('end_date', endDate);
                    }
                    const link = `${ROUTES.documents}?${urlParams.toString()}`;
                    return (_jsxs(Box, { children: [renderedCellValue, row.original.date && (_jsx(IconButton, { component: Link, sx: {
                                    ml: 1,
                                    opacity: 0.5,
                                    '&:hover': { opacity: 1 },
                                    color: '#2196f3',
                                }, href: link, target: "_blank", children: _jsx(LaunchOutlined, {}) }))] }));
                },
            },
            {
                accessorKey: 'total_commission_amount',
                header: 'Commission amount',
                size: 150,
                Footer() {
                    return (_jsx(Typography, { sx: { textAlign: 'right' }, children: DataTransformation.formatCurrency(totalData.total_commission_amount) }));
                },
                muiTableHeadCellProps: {
                    sx: {
                        '&>.Mui-TableHeadCell-Content': {
                            justifyContent: 'flex-end',
                        },
                    },
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                Cell: ({ renderedCellValue }) => {
                    if (typeof renderedCellValue === 'number') {
                        return DataTransformation.formatCurrency(renderedCellValue);
                    }
                    return renderedCellValue;
                },
            },
            {
                accessorKey: 'total_statement_amount',
                header: 'Statement amount',
                size: 150,
                Footer() {
                    return (_jsx(Typography, { sx: { textAlign: 'right' }, children: DataTransformation.formatCurrency(totalData.total_statement_amount) }));
                },
                muiTableHeadCellProps: {
                    sx: {
                        '&>.Mui-TableHeadCell-Content': {
                            justifyContent: 'flex-end',
                        },
                    },
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                Cell: ({ renderedCellValue }) => DataTransformation.formatCurrency((renderedCellValue || 0)),
            },
            {
                accessorKey: 'total_bank_amount',
                header: 'Bank amount',
                size: 150,
                Footer() {
                    return (_jsx(Typography, { sx: { textAlign: 'right' }, children: DataTransformation.formatCurrency(totalData.total_bank_amount) }));
                },
                muiTableHeadCellProps: {
                    sx: {
                        '&>.Mui-TableHeadCell-Content': {
                            justifyContent: 'flex-end',
                        },
                    },
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                Cell: ({ renderedCellValue }) => DataTransformation.formatCurrency((renderedCellValue || 0)),
            },
        ];
    }, [
        companies,
        companyColumn,
        dateColumn,
        end_date,
        groupBy,
        start_date,
        totalData,
    ]);
    return { columns };
};
