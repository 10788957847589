var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { captureException } from '@sentry/react';
import { removeLeadingTrailingChar } from 'common/helpers';
import { useCallback, useEffect, useState } from 'react';
import useSnackbar from '@/contexts/useSnackbar';
import API from '@/services/API';
const useDownloadStorageFile = () => {
    const [file, setFile] = useState(null);
    const [blobFile, setBlobFile] = useState(null);
    const [downloadFileParams, setDownloadFileParams] = useState({});
    const { showSnackbar } = useSnackbar();
    const downloader = API.getMutation('storage/download', 'POST', {
        rawData: true,
    });
    const downloadFile = useCallback((downloadFileParams) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const { endpoint_str_id, file_preview_type, endpoint } = downloadFileParams;
        if (!file_preview_type || !endpoint_str_id)
            return;
        try {
            const res = yield downloader.mutateAsync({
                endpoint_str_id,
                file_preview_type,
                endpoint,
            });
            if (res.status !== 200) {
                showSnackbar(`Error: ${res.statusText}`, 'error');
                return null;
            }
            const filename = removeLeadingTrailingChar(((_a = res.headers['content-disposition']) === null || _a === void 0 ? void 0 : _a.split('=')[1]) || 'documents', '"');
            const blob = res.data;
            const file = new File([blob], filename, { type: blob.type });
            setFile(file);
            setBlobFile(blob);
            return file;
        }
        catch (error) {
            console.error(typeof error === 'object' ? JSON.stringify(error) : error);
            captureException(error);
            showSnackbar(typeof error === 'object' ? JSON.stringify(error) : error, 'error');
            return null;
        }
    }), [downloadFileParams]);
    useEffect(() => {
        const { endpoint_str_id, file_preview_type } = downloadFileParams;
        if (endpoint_str_id && file_preview_type) {
            downloadFile(downloadFileParams);
        }
    }, [downloadFileParams]);
    return { file, blobFile, downloadFile, setDownloadFileParams };
};
export default useDownloadStorageFile;
