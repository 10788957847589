var _a;
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Button from '@mui/material/Button';
import StepButton from '@mui/material/StepButton';
import Typography from '@mui/material/Typography';
import validator from 'validator';
import DataView from '@/components/DataView';
import { auth } from '@/firebase';
import useAccountStore from '@/store/accountStore';
import API from '@/services/API';
const userData = {
    label: 'User information',
    table: 'users/onboarding_update',
    editable: false,
    validateData: (data) => validator.isEmail(data === null || data === void 0 ? void 0 : data.email),
    fields: [
        {
            id: 'email',
            label: 'Email',
            default: (_a = auth === null || auth === void 0 ? void 0 : auth.currentUser) === null || _a === void 0 ? void 0 : _a.email, // Null on load, set below
            readOnly: true,
            validator: (val) => validator.isEmail(val || ''),
        },
        {
            id: 'first_name',
            label: 'First name',
            required: true,
        },
        {
            id: 'last_name',
            label: 'Last name',
            required: true,
        },
        {
            id: 'phone',
            label: 'Phone',
            validator: (val) => validator.isMobilePhone(val || '', 'en-US') || val === '',
        },
    ],
};
const accountData = {
    label: 'Account information',
    table: 'accounts/onboarding_update',
    editable: false,
    fields: [
        {
            id: 'name',
            label: 'Account name',
            required: true,
        },
        {
            id: 'description',
            label: 'Account description',
        },
        {
            id: 'short_name',
            label: 'Account identifier (unique short name)',
            required: true,
        },
    ],
};
const Onboarding = ({ userState }) => {
    var _a, _b, _c;
    const storedUser = localStorage.getItem('customLoginUser');
    const impersonatedUser = storedUser ? JSON.parse(storedUser) : null;
    userData.fields.filter((field) => field.id === 'email')[0].default =
        (_c = (_a = impersonatedUser === null || impersonatedUser === void 0 ? void 0 : impersonatedUser.email) !== null && _a !== void 0 ? _a : (_b = auth === null || auth === void 0 ? void 0 : auth.currentUser) === null || _b === void 0 ? void 0 : _b.email) !== null && _c !== void 0 ? _c : userState === null || userState === void 0 ? void 0 : userState.userEmail;
    const [activeStep, setActiveStep] = useState(0);
    const [account, setAccount] = useState({ state: null });
    const [steps, setSteps] = useState(['User info', 'Account info']);
    const [completed, setCompleted] = useState({});
    const { data } = API.getBasicQuery('accounts/get_onboarding_accounts', '');
    const { selectedAccount } = useAccountStore();
    useEffect(() => {
        var _a;
        if (data && !data.error) {
            // TODO: handle multiple accounts
            setAccount(data[0]);
            if (((_a = data[0]) === null || _a === void 0 ? void 0 : _a.state) === 'active') {
                setSteps(['User info']);
            }
            else {
                setSteps(['User info', 'Account info']);
            }
        }
    }, [data, selectedAccount]);
    const savedUser = (userResponse) => {
        if (userResponse && userResponse.state === 'active') {
            handleComplete();
        }
    };
    const savedAccount = (accountResponse) => {
        if (accountResponse && accountResponse.state === 'active') {
            handleComplete();
        }
    };
    const totalSteps = () => {
        return steps.length;
    };
    const completedSteps = () => {
        return Object.keys(completed).length;
    };
    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };
    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };
    const handleNext = () => {
        const newActiveStep = isLastStep() && !allStepsCompleted()
            ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
            : activeStep + 1;
        setActiveStep(newActiveStep);
    };
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
    const handleStep = (step) => () => {
        setActiveStep(step);
    };
    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };
    const welcomeMessage = (account === null || account === void 0 ? void 0 : account.state) === 'active'
        ? 'Tell us about yourself'
        : 'Tell us about yourself and your agency';
    return (_jsx(_Fragment, { children: _jsxs(Box, { sx: { width: '100%', mt: 4, px: 2 }, children: [_jsx(Typography, { variant: "h5", children: "Let's get started" }), _jsx(Typography, { sx: { mt: 3 }, children: welcomeMessage }), Array.isArray(steps) && steps.length > 1 && (_jsx(Stepper, { sx: { mt: 3 }, nonLinear: true, activeStep: activeStep, children: steps.map((label, index) => (_jsx(Step, { completed: completed[index], children: _jsx(StepButton, { color: "inherit", onClick: handleStep(index), children: label }) }, label))) })), _jsx("div", { children: allStepsCompleted() ? (_jsxs(Box, { sx: { mt: 5 }, children: [_jsx(Box, { sx: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }, children: _jsx(Typography, { variant: "h6", children: "Congratulations! You're ready for start using Fintary." }) }), _jsx(Box, { sx: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: 2,
                                }, children: _jsx(Button, { onClick: () => {
                                        window.location.href = '/';
                                    }, variant: "outlined", sx: { mr: 1 }, children: "Go to home" }) })] })) : (_jsxs(_Fragment, { children: [activeStep === 0 ? (_jsx(Box, { sx: {
                                    height: '300px',
                                    mt: 2,
                                }, children: _jsx(DataView, { dataDesc: userData, dataCallback: savedUser, formModeOnly: true, embed: true }) })) : null, activeStep === 1 ? (_jsx(Box, { sx: {
                                    height: '300px',
                                    mt: 2,
                                }, children: _jsx(DataView, { dataDesc: accountData, dataCallback: savedAccount, formModeOnly: true, embed: true }) })) : null, _jsx(Box, { sx: { display: 'flex', flexDirection: 'row', pt: 5 }, children: activeStep > 0 && (_jsx(Button, { onClick: handleBack, sx: { mr: 1 }, variant: "outlined", children: "Back" })) })] })) })] }) }));
};
export default Onboarding;
