import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState, useMemo, useCallback } from 'react';
import { DataEntities } from 'common/globalTypes';
import { useQueryClient } from '@tanstack/react-query';
import { DataUpdateCopyTypes, } from '@/components/ToolsPage/DataUpdateTools/types';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import { FieldTypes } from '@/types';
import CriteriaActionsViewer from '@/components/ToolsPage/DataUpdateTools/CriteriaActionsViewer';
import API from '@/services/API';
import DataUpdateCopyModal from '@/components/ToolsPage/DataUpdateTools/DataUpdateCopyModal';
const DataUpdateCriteria = ({ fields }) => {
    const [showGlobal, setShowGlobal] = useState(false);
    const [selectedData, setSelectedData] = useState([]);
    const [openCopy, setOpenCopy] = useState(false);
    const [queryKey, setQueryKey] = useState(null);
    const queryClient = useQueryClient();
    const poster = API.getMutation(`data-update/criteria?copy=true`, 'POST');
    const handleClose = useCallback(() => {
        setOpenCopy(false);
    }, []);
    const handleRefetch = useCallback(() => {
        if (queryKey) {
            queryClient.refetchQueries({
                queryKey: queryKey,
                type: 'active',
                exact: true,
            });
        }
    }, [queryKey, queryClient]);
    const extraActions = useMemo(() => [
        {
            label: 'Toggle access',
            onClick: () => setShowGlobal(!showGlobal),
            type: 'button',
        },
        {
            label: 'Copy selected',
            onClick: () => setOpenCopy(true),
            disabled: selectedData.length === 0,
            type: 'button',
        },
    ], [showGlobal, selectedData.length]);
    const dataSpec = useMemo(() => ({
        label: 'Data update criteria',
        table: `data-update/criteria?global=${showGlobal}&`,
        fields: {
            data_entity: {
                label: 'Data entity',
                type: FieldTypes.SELECT,
                options: [DataEntities.COMMISSIONS, DataEntities.POLICIES],
                enabled: true,
            },
            name: {
                label: 'Name',
                enabled: true,
            },
            data_update_criteria: {
                label: 'Data update criteria',
                type: FieldTypes.FIELD_MATCHER,
                fieldGetter: (row) => {
                    if (!row.data_entity)
                        return [];
                    return fields[row.data_entity];
                },
                usePolicyData: (row) => {
                    if (!row.data_entity)
                        return [];
                    return row.data_entity === DataEntities.COMMISSIONS;
                },
                fields: Object.values(fields),
                tableFormatter: (field, row) => {
                    return (_jsx(CriteriaActionsViewer, { fieldData: field, rowData: row, fields: fields }));
                },
                enabled: true,
            },
            notes: {
                label: 'Notes',
                enabled: true,
            },
            access: {
                label: 'Access',
                readOnly: true,
                enabled: true,
            },
        },
    }), [showGlobal, fields]);
    return (_jsxs(_Fragment, { children: [_jsx(EnhancedDataView, { dataSpec: dataSpec, hideSelectedCount: true, hideExport: true, setSelectedData: setSelectedData, 
                // TODO: Fix this type in the EnhancedDataView component
                // @ts-ignore
                extraActions: extraActions, onQueryKeyChange: setQueryKey }), _jsx(DataUpdateCopyModal, { handleClose: handleClose, open: openCopy, type: DataUpdateCopyTypes.CRITERIA, selectedData: selectedData, poster: poster, refetch: handleRefetch })] }));
};
export default DataUpdateCriteria;
