import { jsx as _jsx } from "react/jsx-runtime";
import BasicTable from '../molecules/BasicTable';
const TableWidget = (widget) => {
    var _a;
    try {
        return (_jsx(BasicTable, { headers: widget.data ? widget.data[0] : [], rows: (_a = widget.data) === null || _a === void 0 ? void 0 : _a.slice(1), defaultShow: true, formatters: widget.formatters }));
    }
    catch (error) {
        console.error('Error rendering BasicTable:', error);
        return null;
    }
};
export default TableWidget;
