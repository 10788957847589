var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState, useCallback, useImperativeHandle, forwardRef } from 'react';
import { Box } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
const GridTable = forwardRef((_a, ref) => {
    var { rowData, colDefs, onRowClick, onRowChanged, onCellChanged } = _a, otherProps = __rest(_a, ["rowData", "colDefs", "onRowClick", "onRowChanged", "onCellChanged"]);
    const [gridApi, setGridApi] = useState(null);
    const [columnDefs, setColumnDefs] = useState(colDefs);
    const [rowDataState, setRowDataState] = useState(rowData);
    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
    }, []);
    const handleRowClicked = useCallback((event) => {
        onRowClick === null || onRowClick === void 0 ? void 0 : onRowClick(event.data);
    }, [onRowClick]);
    const handleRowDataChanged = useCallback(() => {
        const rowData = gridApi.getRowData();
        setRowDataState(rowData);
        onRowChanged === null || onRowChanged === void 0 ? void 0 : onRowChanged(rowData);
    }, [gridApi, onRowChanged]);
    const handleCellValueChanged = useCallback((params) => {
        onCellChanged === null || onCellChanged === void 0 ? void 0 : onCellChanged(params.data, params.column.getColId());
    }, [onCellChanged]);
    const addRow = (newRow) => {
        const updatedRowData = [...rowDataState, newRow];
        setRowDataState(updatedRowData);
        gridApi.setRowData(updatedRowData);
        onRowChanged === null || onRowChanged === void 0 ? void 0 : onRowChanged(updatedRowData);
    };
    const updateRow = (updatedRow, rowIndex) => {
        const updatedRowData = [...rowDataState];
        updatedRowData[rowIndex] = Object.assign(Object.assign({}, updatedRowData[rowIndex]), updatedRow);
        setRowDataState(updatedRowData);
        gridApi.setRowData(updatedRowData);
        onRowChanged === null || onRowChanged === void 0 ? void 0 : onRowChanged(updatedRowData);
    };
    const addColumn = (newColDef) => {
        const updatedColDefs = [...columnDefs, newColDef];
        setColumnDefs(updatedColDefs);
    };
    const removeColumn = (colId) => {
        const updatedColDefs = columnDefs.filter((colDef) => colDef.field !== colId);
        setColumnDefs(updatedColDefs);
    };
    useImperativeHandle(ref, () => ({
        addRow,
        updateRow,
        addColumn,
        removeColumn,
    }));
    return (_jsx(Box, { sx: {
            width: '100%',
            height: '100%',
            display: 'flex',
            border: '1px solid #cecece',
        }, children: _jsx("div", { style: { width: '100%', height: '100%' }, className: "ag-theme-material", children: _jsx(AgGridReact, Object.assign({ rowData: rowDataState, columnDefs: columnDefs, onGridReady: onGridReady, onRowClicked: handleRowClicked, onRowValueChanged: handleRowDataChanged, onCellValueChanged: handleCellValueChanged }, otherProps)) }) }));
});
export default GridTable;
