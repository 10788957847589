var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AttachFileOutlined, Close, ExpandLess, ExpandMore, ReceiptOutlined, RequestQuoteOutlined, TableChartOutlined, } from '@mui/icons-material';
import { Box, Button, Collapse, FormControl, FormControlLabel, IconButton, InputAdornment, Switch, TextField, Tooltip, CircularProgress, } from '@mui/material';
import useBeforeUnloadPage from 'contexts/useBeforeunloadPage';
import { cloneDeep, isEqual } from 'lodash-es';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState, } from 'react';
import { useBeforeUnload } from 'react-use';
import { useOriginalFile, useSetOriginFile } from 'store/excelStore';
import validator from 'validator';
import { DocumentTypes } from 'common/constants/documents';
import { ProcessorSelectorStatuses } from 'common/globalTypes';
import FactoryFileUpload from '@/common/Uplaod/FactoryFileUpload';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import useSnackbar from '@/contexts/useSnackbar';
import API from '@/services/API';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
const initialFormState = {
    companies: { value: '', error: false, required: true },
    documentType: { value: '', error: false },
    originalFile: { value: [], error: false },
};
const FileUploads = ({ setCurrent, setFormModel, formModel }, ref) => {
    var _a, _b, _c, _d;
    const [loading, setLoading] = useState(false);
    const { showSnackbar } = useSnackbar();
    const [formState, setFormState] = useState(initialFormState);
    const [error, setError] = useState(false);
    const [newCompany, setNewCompany] = useState({
        value: '',
        error: false,
    });
    const [eachCompany, setEachCompany] = useState({});
    const [companyError, setCompanyError] = useState([]);
    const [eachDocType, setEachDocType] = useState({});
    const [docTypeError, setDocTypeError] = useState([]);
    const [bankTotalAmounts, setBankTotalAmounts] = useState({});
    const [checkDates, setCheckDates] = useState({});
    const [depositDates, setDepositDates] = useState({});
    const [multiCompanyMode, setMultiCompanyMode] = useState(false);
    const [multiDocTypeMode, setMultiDocTypeMode] = useState(false);
    const [fileCollapse, setFileCollapse] = useState([]);
    const [predictedResults, setPredictedResults] = useState({});
    const [originalPredictedResults, setOriginalPredictedResults] = useState({});
    const [isAutoSelecting, setIsAutoSelecting] = useState(false);
    const documentTypesList = [
        {
            label: 'Commissions',
            value: DocumentTypes.STATEMENT,
            icon: _jsx(RequestQuoteOutlined, { sx: { color: '#666', mr: 1 } }),
        },
        {
            label: 'Policies',
            value: DocumentTypes.REPORT,
            icon: _jsx(ReceiptOutlined, { sx: { color: '#666', mr: 1 } }),
        },
        {
            label: 'Comp grid',
            value: DocumentTypes.COMPGRID,
            icon: _jsx(TableChartOutlined, { sx: { color: '#666', mr: 1 } }),
        },
    ];
    const [isAddingCompany, setIsAddingCompany] = useState(false);
    const { data: companies, refetch } = API.getBasicQuery('companies');
    const companiesPoster = API.getMutation('companies', 'POST');
    const classificationPoster = API.getMutation('documents/document-classification', 'POST');
    const companiesOptions = useMemo(() => {
        var _a, _b;
        if (!companies) {
            return [];
        }
        return (_b = (_a = companies === null || companies === void 0 ? void 0 : companies.data) === null || _a === void 0 ? void 0 : _a.map((company) => ({
            label: company.company_name,
            value: company.str_id,
        }))) === null || _b === void 0 ? void 0 : _b.sort((a, b) => (b.label > a.label ? -1 : 1));
    }, [companies]);
    const [formDirty, setFormDirty] = useState(false);
    const dirtyFn = useCallback(() => {
        const isChange = !isEqual(formState, initialFormState);
        setFormDirty(isChange);
        return isChange;
    }, [formState]);
    useBeforeUnload(dirtyFn, 'You have unsaved changes, are you sure?');
    useBeforeUnloadPage(formDirty, 'You have unsaved changes, are you sure?');
    const originFile = useOriginalFile();
    const setOriginFile = useSetOriginFile();
    /**
     * Handle form item change
     */
    const handleChange = (field) => (event) => {
        const val = event.target.value;
        setFormState((pre) => {
            const preState = cloneDeep(pre);
            preState[field].value = val;
            preState[field].error = false;
            return preState;
        });
    };
    useEffect(() => {
        if (formModel) {
            if (formModel.form) {
                setFormState(formModel.form);
            }
            if (formModel.companies) {
                if (typeof formModel.companies === 'object' &&
                    !Array.isArray(formModel.companies)) {
                    setEachCompany(formModel.companies);
                    setMultiCompanyMode(true);
                }
                else {
                    setFormState((prev) => (Object.assign(Object.assign({}, prev), { companies: { value: formModel.companies, error: false } })));
                }
            }
            if (formModel.document_type) {
                if (typeof formModel.document_type === 'object') {
                    setEachDocType(formModel.document_type);
                    setMultiDocTypeMode(true);
                }
                else {
                    setFormState((prev) => (Object.assign(Object.assign({}, prev), { documentType: { value: formModel.document_type, error: false } })));
                }
            }
            if (formModel.bank_total_amounts) {
                setBankTotalAmounts(formModel.bank_total_amounts);
            }
            if (formModel.check_dates) {
                setCheckDates(formModel.check_dates);
            }
            if (formModel.deposit_dates) {
                setDepositDates(formModel.deposit_dates);
            }
            if (formModel.predictedResults) {
                setPredictedResults(formModel.predictedResults);
            }
            if (formModel.originalPredictedResults) {
                setOriginalPredictedResults(formModel.originalPredictedResults);
            }
        }
    }, [formModel]);
    useEffect(() => {
        if (!Object.keys(predictedResults).length)
            return;
        const results = Object.entries(predictedResults).map(([path, result]) => ({
            path: path.startsWith('./') ? path.slice(2) : path,
            company: (result === null || result === void 0 ? void 0 : result.result)
                ? {
                    value: result.result.str_id,
                    label: result.result.company_name,
                }
                : null,
        }));
        const firstCompany = results[0].company;
        const allSame = results.every((r) => (!r.company && !firstCompany) ||
            (r.company && firstCompany && r.company.value === firstCompany.value));
        if (allSame && (firstCompany || results.length === 1)) {
            setFormState((prev) => (Object.assign(Object.assign({}, prev), { companies: Object.assign(Object.assign({}, prev.companies), { value: formState.companies.value || firstCompany }) })));
            const newEachCompany = {};
            results.forEach(({ path }) => {
                newEachCompany[path] = formState.companies.value || firstCompany;
            });
            setEachCompany(newEachCompany);
        }
        else {
            setFormState((prev) => (Object.assign(Object.assign({}, prev), { companies: Object.assign(Object.assign({}, prev.companies), { value: null }) })));
            setMultiCompanyMode(true);
            const newEachCompany = {};
            results.forEach(({ path, company }) => {
                newEachCompany[path] = eachCompany[path] || company;
            });
            setEachCompany(newEachCompany);
        }
    }, [predictedResults]);
    /**
     * Handle file delete
     */
    const onFileDelete = (f, index) => {
        const deletePathKey = (state, pathKey) => {
            const newState = Object.assign({}, state);
            delete newState[pathKey];
            return newState;
        };
        setFormState((pre) => {
            const preState = Object.assign({}, pre);
            preState['originalFile'].value = preState['originalFile'].value.filter((each) => each !== f);
            return preState;
        });
        setEachCompany((pre) => deletePathKey(pre, f.path));
        setEachDocType((pre) => deletePathKey(pre, f.path));
        setBankTotalAmounts((pre) => deletePathKey(pre, f.path));
        setCheckDates((pre) => deletePathKey(pre, f.path));
        setDepositDates((pre) => deletePathKey(pre, f.path));
        setCompanyError((pre) => pre.filter((_, i) => i !== index));
        setDocTypeError((pre) => pre.filter((_, i) => i !== index));
        setFileCollapse((pre) => pre.filter((_, i) => i !== index));
        setPredictedResults((pre) => {
            const newResults = Object.assign({}, pre);
            delete newResults[f.path];
            return newResults;
        });
        setOriginalPredictedResults((pre) => {
            const newResults = Object.assign({}, pre);
            delete newResults[f.path];
            return newResults;
        });
    };
    // Convert file to base64 for uploading to filename detection API
    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64 = typeof reader.result === 'string' ? reader.result.split(',')[1] : '';
                resolve(base64);
            };
            reader.onerror = (error) => reject(error);
        });
    };
    const companyClassification = (file) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const base64Content = yield fileToBase64(file);
            const result = yield classificationPoster.mutateAsync({
                file: {
                    name: file.name,
                    type: file.name.split('.').pop().toLowerCase(),
                    content: base64Content,
                },
            });
            return result;
        }
        catch (error) {
            console.warn(`Detection failed for ${file.name}:`, error);
            return null;
        }
    });
    const mutiFilesCompanyClassification = (files) => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        setIsAutoSelecting(true);
        try {
            for (const file of files) {
                const result = yield companyClassification(file);
                if (result) {
                    setPredictedResults((prev) => (Object.assign(Object.assign({}, prev), { [file.path]: result })));
                    if ((_b = (_a = result === null || result === void 0 ? void 0 : result.classificationResult) === null || _a === void 0 ? void 0 : _a.canonical_predictions) === null || _b === void 0 ? void 0 : _b[0]) {
                        const { canonical_id, confidence } = result.classificationResult.canonical_predictions[0];
                        setOriginalPredictedResults((prev) => {
                            var _a, _b;
                            return (Object.assign(Object.assign({}, prev), { [file.path]: {
                                    instance_company_name: ((_a = result.result) === null || _a === void 0 ? void 0 : _a.company_name) || null,
                                    instance_company_id: ((_b = result.result) === null || _b === void 0 ? void 0 : _b.str_id) || null,
                                    canonical_id: canonical_id || null,
                                    confidence: confidence || null,
                                } }));
                        });
                    }
                    else {
                        setOriginalPredictedResults((prev) => (Object.assign(Object.assign({}, prev), { [file.path]: { error: true } })));
                    }
                }
            }
        }
        finally {
            setIsAutoSelecting(false);
        }
    });
    /**
     * Handle file add
     */
    const addMoreFile = (field) => (files) => __awaiter(void 0, void 0, void 0, function* () {
        if (field === 'originalFile') {
            setLoading(true);
            try {
                setFormState((pre) => {
                    const preState = cloneDeep(pre);
                    files = files.filter((file) => {
                        return !preState[field].value.some((each) => each.path === file.path);
                    });
                    preState[field].value.push(...files);
                    return preState;
                });
                const newAmountsMap = {};
                const newDocTypeMap = {};
                files.forEach((f) => {
                    newAmountsMap[f.path] = '';
                    newDocTypeMap[f.path] = '';
                });
                setBankTotalAmounts((prev) => (Object.assign(Object.assign({}, prev), newAmountsMap)));
                setEachDocType((prev) => (Object.assign(Object.assign({}, prev), newDocTypeMap)));
                setCompanyError((prev) => [
                    ...prev,
                    ...Array(files.length).fill(false),
                ]);
                setDocTypeError((prev) => [
                    ...prev,
                    ...Array(files.length).fill(false),
                ]);
                setFileCollapse((prev) => [
                    ...prev,
                    ...Array(files.length).fill(false),
                ]);
                yield mutiFilesCompanyClassification(files);
            }
            catch (error) {
                console.error('Error in processing files:', error);
                showSnackbar('Error in processing files:', 'error');
            }
            finally {
                setLoading(false);
            }
        }
    });
    /**
     * Handle form submit
     */
    const handleSubmit = () => {
        var _a, _b;
        let isValid = true;
        if (!multiCompanyMode && !formState.companies.value) {
            setFormState((pre) => {
                const preState = cloneDeep(pre);
                preState.companies.error = true;
                return preState;
            });
            isValid = false;
        }
        if (multiCompanyMode) {
            const updatedCompanyError = [...companyError];
            Object.entries(eachCompany).forEach(([key, company], index) => {
                if (!company || !company.value) {
                    updatedCompanyError[index] = true;
                    isValid = false;
                }
            });
            setCompanyError(updatedCompanyError);
        }
        if (!isValid) {
            showSnackbar('Company is required', 'error');
            return;
        }
        const _originalFiles = formState.originalFile.value;
        if (_originalFiles.length) {
            setOriginFile(_originalFiles);
        }
        // Store current data to formModel
        setFormModel({
            companies: multiCompanyMode ? eachCompany : (_a = formState.companies) === null || _a === void 0 ? void 0 : _a.value,
            document_type: multiDocTypeMode
                ? eachDocType
                : (_b = formState.documentType) === null || _b === void 0 ? void 0 : _b.value,
            form: cloneDeep(formState),
            bank_total_amounts: bankTotalAmounts,
            check_dates: checkDates,
            deposit_dates: depositDates,
            predictedResults,
            originalPredictedResults,
        });
        // Go to next step
        setCurrent((pre) => pre + 1);
        // }
    };
    const onAddCompany = (evt) => {
        evt.preventDefault();
        const companyName = newCompany.value;
        if (!companyName) {
            setNewCompany((pre) => (Object.assign(Object.assign({}, pre), { error: true })));
            return;
        }
        const companyItem = {
            company_name: companyName,
        };
        setLoading(true);
        companiesPoster.mutate(companyItem, {
            onSuccess: (res) => {
                refetch(); // Refresh companies list
                setLoading(false);
                setNewCompany({ value: '', error: false });
                showSnackbar(`Added new company ${companyName}`, 'success');
                // Auto select the new company
                setFormState((pre) => {
                    const preState = cloneDeep(pre);
                    preState.companies.value = { label: companyName, value: res.str_id };
                    preState.companies.error = false;
                    return preState;
                });
                if (!multiCompanyMode) {
                    const tempEachCompany = eachCompany;
                    Object.keys(tempEachCompany).forEach((key) => {
                        tempEachCompany[key] = { label: companyName, value: res.str_id };
                    });
                    setEachCompany(tempEachCompany);
                }
                setIsAddingCompany(false);
            },
            onError: (err) => {
                showSnackbar(err.message, 'error');
                setLoading(false);
            },
        });
    };
    const clearFile = () => {
        setOriginFile(null);
        setFormState(initialFormState);
    };
    useImperativeHandle(ref, () => ({
        submit: handleSubmit,
        clearFile,
    }));
    const enhancedSelectorStyle = {
        minWidth: 200,
        width: '100%',
        '& .MuiBox-root': {
            maxWidth: 'calc(100% - 24px)',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    };
    const commonInputStyle = {
        '& .MuiInputBase-root': {
            height: '35px',
        },
    };
    return (_jsx("div", { style: { minWidth: 640, maxWidth: 1200 }, children: _jsxs("form", { onSubmit: handleSubmit, style: { width: '100%' }, children: [_jsxs(Box, { sx: {
                        display: 'flex',
                        alignItems: 'center',
                    }, children: [!isAddingCompany && (_jsx(FormControl, { required: true, fullWidth: true, sx: {
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                            }, error: formState.companies.error, children: _jsxs(Box, { sx: {
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'row',
                                    width: '100%',
                                    alignItems: 'center',
                                }, children: [_jsx(EnhancedSelect, { label: "Company", enableSearch: true, options: companiesOptions, sx: enhancedSelectorStyle, listContainerSx: {
                                            minWidth: 450,
                                            p: 1,
                                        }, value: {
                                            id: ((_a = formState.companies.value) === null || _a === void 0 ? void 0 : _a.value) || '',
                                            label: ((_b = formState.companies.value) === null || _b === void 0 ? void 0 : _b.label) || '',
                                        }, onChange: (selectedOption) => {
                                            const newValue = {
                                                value: selectedOption.id,
                                                label: selectedOption.name,
                                            };
                                            handleChange('companies')({
                                                target: {
                                                    value: newValue,
                                                },
                                            });
                                            setPredictedResults((prev) => {
                                                const newResults = Object.assign({}, prev);
                                                Object.keys(newResults).forEach((path) => {
                                                    newResults[path] = {
                                                        status: ProcessorSelectorStatuses.SUCCESS,
                                                        result: {
                                                            str_id: selectedOption.id,
                                                            company_name: selectedOption.name,
                                                        },
                                                    };
                                                });
                                                return newResults;
                                            });
                                        } }), _jsx(Box, { sx: {
                                            width: 23,
                                            display: 'flex',
                                            justifyContent: 'center',
                                        }, children: isAutoSelecting && (_jsx(Tooltip, { title: "Auto-detecting company from files...", children: _jsx(CircularProgress, { size: 15, sx: {
                                                    ml: 1,
                                                    color: 'primary.main',
                                                } }) })) }), _jsx(Button, { variant: "outlined", sx: { ml: 1, minWidth: 155 }, onClick: () => setIsAddingCompany(true), children: "Add new company" })] }) })), isAddingCompany && (_jsxs(Box, { sx: {
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                                flex: 1,
                            }, onKeyDown: (e) => e.stopPropagation(), children: [_jsx(TextField, { sx: Object.assign({ flex: 1 }, commonInputStyle), label: "New company", error: newCompany.error, value: newCompany.value, onChange: (evt) => setNewCompany({ value: evt.target.value, error: false }) }), _jsx(Button, { sx: { ml: 1 }, onClick: onAddCompany, variant: newCompany.value ? 'contained' : 'outlined', disabled: !newCompany.value, children: loading ? 'Adding...' : 'Add' }), _jsx(Button, { variant: "outlined", sx: { ml: 1 }, onClick: () => setIsAddingCompany(false), children: "Cancel" })] })), _jsx(Tooltip, { title: "Select if documents are from multiple carriers", children: _jsx(FormControlLabel, { control: _jsx(Switch, { onChange: () => setMultiCompanyMode((prev) => !prev), checked: multiCompanyMode }), label: "Various", sx: { ml: 0.5, mr: 0 } }) })] }), _jsxs(Box, { sx: {
                        display: 'flex',
                        alignItems: 'center',
                    }, children: [_jsx(FormControl, { sx: { flex: 1, my: 1, width: '100%' }, error: formState.documentType.error, children: _jsx(EnhancedSelect, { label: "Document type", options: documentTypesList.map((item) => ({
                                    id: item.value,
                                    label: item.label,
                                    icon: item.icon,
                                })), sx: enhancedSelectorStyle, listContainerSx: {
                                    minWidth: 300,
                                    p: 1,
                                }, renderLabel: ({ key }) => {
                                    const option = documentTypesList.find((item) => item.value === key);
                                    return (_jsxs(Box, { sx: { display: 'flex', alignItems: 'center' }, children: [option === null || option === void 0 ? void 0 : option.icon, option === null || option === void 0 ? void 0 : option.label] }));
                                }, value: {
                                    id: formState.documentType.value || '',
                                    label: ((_c = documentTypesList.find((item) => item.value === formState.documentType.value)) === null || _c === void 0 ? void 0 : _c.label) || '',
                                    icon: ((_d = documentTypesList.find((item) => item.value === formState.documentType.value)) === null || _d === void 0 ? void 0 : _d.icon) || null,
                                }, onChange: (selectedOption) => {
                                    handleChange('documentType')({
                                        target: { value: selectedOption.id },
                                    });
                                    if (!multiDocTypeMode) {
                                        const updatedEachDocType = Object.assign({}, eachDocType);
                                        Object.keys(updatedEachDocType).forEach((key) => {
                                            updatedEachDocType[key] = selectedOption.id;
                                        });
                                        setEachDocType(updatedEachDocType);
                                    }
                                } }) }), _jsx(Tooltip, { title: "Select if documents are of different types", children: _jsx(FormControlLabel, { control: _jsx(Switch, { onChange: () => setMultiDocTypeMode((prev) => !prev), checked: multiDocTypeMode }), label: "Various", sx: { ml: 0.5, mr: 0 } }) })] }), _jsx(FactoryFileUpload, { required: true, error: formState.originalFile.error, curFile: originFile, onChange: addMoreFile('originalFile'), multiple: true, showFileInfo: false }), _jsx(Box, { sx: { mt: 1 }, children: !!formState.originalFile.value.length &&
                        formState.originalFile.value.map((f, index) => {
                            var _a, _b, _c, _d;
                            return (_jsxs(Box, { sx: {
                                    position: 'relative',
                                    display: 'flex',
                                    fontSize: '0.875rem',
                                    '& .MuiInputLabel-root': {
                                        fontSize: '0.875rem',
                                    },
                                    '& .MuiInputBase-root': {
                                        fontSize: '0.875rem',
                                    },
                                }, children: [_jsx(IconButton, { className: "group absolute p-0 -top-1 -right-2 cursor-pointer hover:text-blue-600", onClick: () => onFileDelete(f, index), children: _jsx(Close, { className: "text-sm group-hover:rotate-180 transition-all origin-center rounded-full border border-gray-400 p-1 bg-white hover:bg-gray-100", style: { fontSize: '1.35rem' } }) }), _jsxs(Box, { sx: {
                                            flex: 1,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            margin: '4px 0',
                                            p: 1,
                                            pt: 1.5,
                                            border: '1px dashed #ccc',
                                            gap: 0.5,
                                        }, children: [_jsxs(Box, { sx: {
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    color: 'text.primary',
                                                    justifyContent: 'space-between',
                                                }, children: [_jsxs(Box, { sx: {
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            overflow: 'hidden',
                                                            whiteSpace: 'nowrap',
                                                            overflowX: 'auto',
                                                            mr: 2,
                                                            '&::-webkit-scrollbar': {
                                                                display: 'none',
                                                            },
                                                        }, children: [_jsx(AttachFileOutlined, { sx: { pr: 1 } }), f.path, " - ", (f.size / 1024).toFixed(2), " KB"] }), _jsxs(Box, { sx: { display: 'flex', alignItems: 'center' }, children: [multiCompanyMode && (_jsx(EnhancedSelect, { label: "Company", enableSearch: true, options: companiesOptions, value: {
                                                                    id: ((_a = eachCompany[f.name]) === null || _a === void 0 ? void 0 : _a.value) || '',
                                                                    label: ((_b = eachCompany[f.name]) === null || _b === void 0 ? void 0 : _b.label) || '',
                                                                }, onChange: (selectedOption) => {
                                                                    if (!multiCompanyMode) {
                                                                        setMultiCompanyMode(true);
                                                                    }
                                                                    setEachCompany((pre) => {
                                                                        const preState = cloneDeep(pre);
                                                                        preState[f.name] = {
                                                                            value: selectedOption.id,
                                                                            label: selectedOption.name,
                                                                        };
                                                                        return preState;
                                                                    });
                                                                    setPredictedResults((prev) => {
                                                                        const newResults = Object.assign({}, prev);
                                                                        newResults[f.path] = {
                                                                            status: ProcessorSelectorStatuses.SUCCESS,
                                                                            result: {
                                                                                str_id: selectedOption.id,
                                                                                company_name: selectedOption.name,
                                                                            },
                                                                        };
                                                                        return newResults;
                                                                    });
                                                                } })), multiDocTypeMode && (_jsx(FormControl, { error: docTypeError[index], sx: { mr: 1 }, children: _jsx(EnhancedSelect, { label: "Document type", options: documentTypesList.map((item) => ({
                                                                        id: item.value,
                                                                        label: item.label,
                                                                        icon: item.icon,
                                                                    })), listContainerSx: {
                                                                        minWidth: 300,
                                                                        p: 1,
                                                                    }, renderLabel: ({ key }) => {
                                                                        const option = documentTypesList.find((item) => item.value === key);
                                                                        return (_jsxs(Box, { sx: { display: 'flex', alignItems: 'center' }, children: [option === null || option === void 0 ? void 0 : option.icon, option === null || option === void 0 ? void 0 : option.label] }));
                                                                    }, value: {
                                                                        id: eachDocType[f.path] || '',
                                                                        label: ((_c = documentTypesList.find((item) => item.value === eachDocType[f.path])) === null || _c === void 0 ? void 0 : _c.label) || '',
                                                                        icon: ((_d = documentTypesList.find((item) => item.value === eachDocType[f.path])) === null || _d === void 0 ? void 0 : _d.icon) || null,
                                                                    }, onChange: (selectedOption) => {
                                                                        if (!multiDocTypeMode &&
                                                                            selectedOption.id !== eachDocType[f.path]) {
                                                                            setMultiDocTypeMode(true);
                                                                        }
                                                                        setEachDocType((pre) => {
                                                                            const preState = cloneDeep(pre);
                                                                            preState[f.path] = selectedOption.id;
                                                                            return preState;
                                                                        });
                                                                        setDocTypeError((prev) => {
                                                                            const updated = [...prev];
                                                                            updated[index] = false;
                                                                            return updated;
                                                                        });
                                                                    } }) })), _jsx(Tooltip, { title: "Amount received in your bank for this statement. We'll cross reference with records from this document.", children: _jsx(TextField, { label: "Bank total", sx: Object.assign({ mr: 1, width: 150 }, commonInputStyle), InputProps: {
                                                                        startAdornment: (_jsx(InputAdornment, { position: "start", children: "$" })),
                                                                    }, value: bankTotalAmounts[f.path] || '', error: error, helperText: error ? 'Invalid amount' : '', onChange: (evt) => {
                                                                        const val = evt.target.value;
                                                                        const isValidCurrency = validator.isCurrency(val, {
                                                                            allow_negatives: true,
                                                                            thousands_separator: ',',
                                                                            decimal_separator: '.',
                                                                            allow_decimal: true,
                                                                            digits_after_decimal: [1, 2],
                                                                        });
                                                                        if (val !== '' &&
                                                                            (!isValidCurrency ||
                                                                                parseFloat(val) >= 1e12 ||
                                                                                parseFloat(val) <= -1e12)) {
                                                                            setError(true);
                                                                        }
                                                                        else {
                                                                            setError(false);
                                                                        }
                                                                        setBankTotalAmounts((pre) => {
                                                                            const preState = cloneDeep(pre);
                                                                            preState[f.path] = val;
                                                                            return preState;
                                                                        });
                                                                    } }) }), fileCollapse[index] ? (_jsx(ExpandLess, { sx: {
                                                                    cursor: 'pointer',
                                                                    transition: 'all 0.3s ease',
                                                                    color: '#888',
                                                                    '&:hover': {
                                                                        color: '#555',
                                                                        transform: 'scale(1.1)',
                                                                    },
                                                                    '&:active': {
                                                                        transform: 'scale(0.95)',
                                                                    },
                                                                }, onClick: () => setFileCollapse((pre) => {
                                                                    const newState = [...pre];
                                                                    newState[index] = false;
                                                                    return newState;
                                                                }) })) : (_jsx(ExpandMore, { sx: {
                                                                    cursor: 'pointer',
                                                                    transition: 'all 0.3s ease',
                                                                    color: '#888',
                                                                    '&:hover': {
                                                                        color: '#555',
                                                                        transform: 'scale(1.1)',
                                                                    },
                                                                    '&:active': {
                                                                        transform: 'scale(0.95)',
                                                                    },
                                                                }, onClick: () => setFileCollapse((pre) => {
                                                                    const newState = [...pre];
                                                                    newState[index] = true;
                                                                    return newState;
                                                                }) }))] })] }), _jsx(Collapse, { in: fileCollapse[index], children: _jsxs(Box, { sx: {
                                                        display: 'flex',
                                                        justifyContent: 'flex-end',
                                                        gap: 2,
                                                        mt: 1,
                                                        pr: 4,
                                                        '& .MuiFormControl-root': Object.assign({ width: 150 }, commonInputStyle),
                                                    }, children: [_jsx(BasicDatePicker, { label: "Check date", value: checkDates[f.path], setValue: (e) => setCheckDates((pre) => {
                                                                const preState = cloneDeep(pre);
                                                                preState[f.path] = e;
                                                                return preState;
                                                            }) }), _jsx(BasicDatePicker, { label: "Deposit date", value: depositDates[f.path], setValue: (e) => setDepositDates((pre) => {
                                                                const preState = cloneDeep(pre);
                                                                preState[f.path] = e;
                                                                return preState;
                                                            }) })] }) })] })] }, index));
                        }) })] }) }));
};
export default forwardRef(FileUploads);
