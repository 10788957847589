const findAllTextLineByIndex = (json, indicesArray) => {
    const resultLines = [];
    for (const indices of indicesArray) {
        if (indices.length !== 2) {
            resultLines.push(null);
            continue;
        }
        const [lineIndex, arrayIndex] = indices;
        if (json.Lines[lineIndex] && json.Lines[lineIndex].LinesArray[arrayIndex]) {
            resultLines.push(json.Lines[lineIndex].LinesArray[arrayIndex].Line);
        }
        else {
            resultLines.push(null);
        }
    }
    return resultLines;
};
export const desc_findAllTextLineByIndex = {
    name: 'libs.tools.findAllTextLineByIndex(json, indicesArray)',
    description: 'Retrieves text lines from a JSON object using arrays of line and array indices.',
    arguments: {
        json: '[Object] JSON object containing Lines array with text content',
        indicesArray: '[Array<Array>] Array of index pairs [lineIndex, arrayIndex] specifying positions to retrieve',
    },
    returns: '[Array<string|null>] Array of found text lines or null for invalid indices',
    examples: 'findAllTextLineByIndex(json, [[0, 1], [1, 2]])\n// → ["text1", "text2"]\n\nfindAllTextLineByIndex(json, [[0, 1], [999, 999]])\n// → ["text1", null]\n',
};
export default findAllTextLineByIndex;
