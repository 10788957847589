var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingButton } from '@mui/lab';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography, } from '@mui/material';
import { isValidJsonString } from 'common/helpers';
import { useEffect, useMemo, useState } from 'react';
import * as XLSX from 'xlsx';
import useSnackbar from '@/contexts/useSnackbar';
import API from '@/services/API';
const transformObjectVal = (json, fieldsMap) => json.map((item) => Object.fromEntries(Object.entries(item).map(([k, v]) => {
    var _a;
    if (typeof ((_a = fieldsMap[k]) === null || _a === void 0 ? void 0 : _a.strToValue) === 'function') {
        const fn = fieldsMap[k].strToValue;
        return [k, fn(typeof v === 'string' ? v.trim() : v)];
    }
    return [k, typeof v === 'string' ? v.trim() : v];
})));
const DataBulkAdd = ({ fields, params, handleUpdateParams, onCancel, onSave, btnLabel = 'Bulk add', isLoading = false, }) => {
    const [selectOptions, setSelectOptions] = useState({});
    const [selectedVals, setSelectedVals] = useState({});
    const [csv, setCsv] = useState('');
    const [json, setJSON] = useState('[]');
    const { showSnackbar } = useSnackbar();
    const fieldsMap = useMemo(() => fields.reduce((acc, field) => {
        if (['created_at', 'updated_at'].includes(field.id))
            return acc;
        acc[field.id] = field;
        return acc;
    }, {}), [fields]);
    const requiredFields = fields.filter((field) => field.required);
    const selectFields = fields.filter((field) => field.bulkAddSelect);
    selectFields.forEach((field) => __awaiter(void 0, void 0, void 0, function* () {
        const { data } = API.getBasicQuery(field.table);
        if (JSON.stringify(selectOptions[field.table]) !== JSON.stringify(data)) {
            setSelectOptions((prev) => (Object.assign(Object.assign({}, prev), { [field.table]: data })));
        }
    }));
    useEffect(() => {
        setCsv(`${fields
            .map((field) => field.id)
            .filter((f) => !['created_at', 'updated_at'].includes(f))
            .join(',')}\n`);
    }, [fields]);
    useEffect(() => {
        try {
            const csvSheet = XLSX.read(csv, {
                type: 'string',
                raw: true,
            });
            const jsonRes = XLSX.utils.sheet_to_json(csvSheet.Sheets[csvSheet.SheetNames[0]], { raw: true });
            setJSON(JSON.stringify(transformObjectVal(jsonRes, fieldsMap), null, 2));
        }
        catch (e) {
            console.error(e);
        }
    }, [csv, fieldsMap]);
    return (_jsxs(Box, { sx: { width: '100%', px: 1 }, children: [_jsx(Typography, { children: "Bulk add entities in csv/tsv format" }), _jsxs(Typography, { variant: "caption", children: ["Standard CSV/TSV format, you may remove columns that you don't need, but these are required: (", `${requiredFields.map((field) => field.id).join(', ')}`, ")"] }), selectFields.length > 0 && (_jsxs(Box, { children: [_jsx(Typography, { variant: "caption", children: "IDs for reference" }), selectFields.map((field) => {
                        var _a, _b, _c;
                        return (_jsxs(FormControl, { sx: { mb: 1 }, fullWidth: true, children: [_jsx(InputLabel, { id: `${field.id}-label`, children: field.label }), _jsx(Select, { label: field.label, value: selectedVals === null || selectedVals === void 0 ? void 0 : selectedVals[field.id], onChange: (event) => setSelectedVals(Object.assign(Object.assign({}, selectedVals), { [field.id]: event.target.value })), children: (_c = (Array.isArray((_a = selectOptions === null || selectOptions === void 0 ? void 0 : selectOptions[field.table]) === null || _a === void 0 ? void 0 : _a.data)
                                        ? (_b = selectOptions === null || selectOptions === void 0 ? void 0 : selectOptions[field.table]) === null || _b === void 0 ? void 0 : _b.data
                                        : Array.isArray(selectOptions === null || selectOptions === void 0 ? void 0 : selectOptions[field.table])
                                            ? selectOptions === null || selectOptions === void 0 ? void 0 : selectOptions[field.table]
                                            : [])) === null || _c === void 0 ? void 0 : _c.map((option) => (_jsxs(MenuItem, { value: option.id, children: [option.id, " ", (option === null || option === void 0 ? void 0 : option.access) ? `(${option.access})` : '', ":", ' ', field.optionFormatter(option)] }, option.id))) })] }, field.id));
                    })] })), _jsx(TextField, { label: "CSV/TSV", multiline: true, rows: 8, value: csv, onChange: (event) => {
                    setCsv(event.target.value);
                }, sx: { width: '100%', mt: 1 }, fullWidth: true }), params && (_jsx(TextField, { label: "Params", value: params, onChange: handleUpdateParams, sx: { width: '100%', mt: 1 }, fullWidth: true, error: !isValidJsonString(params), helperText: !isValidJsonString(params) && 'Invalid JSON string' })), _jsx(TextField, { label: "Entities", multiline: true, maxRows: 20, value: JSON.parse(json).map(JSON.stringify).join('\n'), sx: { width: '100%', mt: 1 }, fullWidth: true, disabled: true }), _jsxs(Box, { sx: { mt: 1, display: 'flex', justifyContent: 'flex-end' }, children: [_jsx(Button, { sx: { mr: 1 }, onClick: onCancel, children: "Cancel" }), _jsx(LoadingButton, { variant: "contained", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            var _a;
                            try {
                                yield onSave(Object.assign({ data: JSON.parse(json) }, (params ? { params: JSON.parse(params) } : {})));
                            }
                            catch (e) {
                                console.error(e, e.error);
                                showSnackbar((_a = e.message) !== null && _a !== void 0 ? _a : e.error, 'error');
                            }
                        }), loading: isLoading, disabled: (params && !isValidJsonString(params)) || isLoading, children: btnLabel })] })] }));
};
export default DataBulkAdd;
