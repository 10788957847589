const convertToNumber = (str, setNaNToZero = false) => {
    if (!str)
        return 0;
    const numberString = str.replace(/[^0-9.]+/g, '').trim();
    let res = numberString;
    if (str.includes('-') || str.includes('(') || str.includes(')')) {
        res = `-${numberString}`;
    }
    const numberValue = parseFloat(res);
    if (Number.isNaN(numberValue) && setNaNToZero)
        return 0;
    return numberValue;
};
export const desc_convertToNumber = {
    name: 'libs.tools.convertToNumber(str, setNaNToZero)',
    description: 'Converts a string to a number, handling various formats including currency, negative numbers, and decimal values.',
    arguments: {
        str: '[String] String to be converted to number',
        setNaNToZero: '[Boolean] Optional. If true, returns 0 instead of NaN for invalid conversions',
    },
    returns: '[Number] Converted number value, returns 0 for empty string',
    examples: 'convertToNumber("123") // → 123\nconvertToNumber("123.45") // → 123.45\nconvertToNumber("($123)") // → -123\nconvertToNumber("123-") // → -123\nconvertToNumber("($123,34.66)") // → -12334.66\n',
};
export default convertToNumber;
