var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { DeleteOutlined, Launch } from '@mui/icons-material';
import { Button } from '@mui/material';
import validator from 'validator';
import { Navigate } from 'react-router-dom';
import { SavedReportStatuses, SavedReportStatusesLabels, } from 'common/globalTypes';
import DataView from '@/components/DataView';
import API from '@/services/API';
import { useAccountStore } from '@/store';
import Formatter from '@/services/Formatter';
const ReportsView = () => {
    var _a;
    const { data: accountSettings, isFetched: isFetchedAccountSettings } = API.getBasicQuery(`accounts/settings`);
    const viewSettings = (_a = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _a === void 0 ? void 0 : _a.reports;
    if (isFetchedAccountSettings && (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.show_page) === false) {
        return (
        // TODO: Remove navigate after figuring out how to handle this in router
        _jsx(Navigate, { to: "/settings" }));
    }
    const { selectedAccount } = useAccountStore();
    const deleter = API.getMutation('saved_reports', 'DELETE');
    const dataDesc = {
        label: 'Saved reports',
        table: 'saved_reports?include_shared_groups=true',
        editable: false,
        validateData: (data) => validator.isEmail((data === null || data === void 0 ? void 0 : data.email) || '') ||
            validator.isURL((data === null || data === void 0 ? void 0 : data.website) || ''),
        fields: [
            {
                id: 'name',
                label: 'Name',
                formatter: (row, collectionVals) => {
                    return (_jsx(Button, { onClick: () => {
                            window.open(`/reports/${collectionVals.str_id}`, '_blank');
                        }, endIcon: _jsx(Launch, {}), children: row }));
                },
            },
            {
                id: 'page',
                label: 'Page',
            },
            {
                id: 'notes',
                label: 'Notes',
            },
            {
                id: 'access',
                label: 'Access',
            },
            {
                id: 'users_white_list',
                label: 'Users with access',
                type: 'dynamic-select',
                table: `users/get_account_users?state=active`,
                queryParamTable: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                queryParamNameTable: 'accId',
                queryParamValue: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                queryParamName: 'accId',
                formatter: (val, collectionVals = []) => {
                    var _a;
                    if (Array.isArray(collectionVals) && collectionVals.length > 0) {
                        const datum = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.filter((datum) => datum.str_id === val)) === null || _a === void 0 ? void 0 : _a[0];
                        return datum ? `${datum.email}` : 'Not found';
                    }
                    return val; // Not formatting when collectionVals is not available
                },
            },
            {
                id: 'status',
                label: 'Status',
                formatter: (val) => Formatter.statusChip(SavedReportStatusesLabels[val], {
                    mapping: {
                        [SavedReportStatusesLabels[SavedReportStatuses.APPROVED]]: 'blue',
                        [SavedReportStatusesLabels[SavedReportStatuses.PAID]]: 'green',
                        [SavedReportStatusesLabels[SavedReportStatuses.DRAFT]]: '',
                    },
                }),
            },
            {
                id: 'reviewed_by',
                label: 'Approver',
                formatter: (s, row) => { var _a; return s ? `${(_a = row.users_reports_reviewed_byTousers) === null || _a === void 0 ? void 0 : _a.email}` : ''; },
            },
            {
                id: 'saved_report_group_id',
                label: 'Report group',
                type: 'dynamic-select',
                table: `saved_reports/groups`,
                field: 'id',
                formatter: (val, collectionVals = []) => {
                    var _a;
                    if (Array.isArray(collectionVals) && collectionVals.length > 0) {
                        const datum = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.filter((datum) => datum.id === val)) === null || _a === void 0 ? void 0 : _a[0];
                        return datum ? `${datum.name}` : 'Not found';
                    }
                    return val; // Not formatting when collectionVals is not available
                },
            },
            {
                id: 'created_at',
                label: 'Created at',
                formatter: (row) => new Date(row).toLocaleString(),
            },
        ],
        actions: [
            {
                label: _jsx(DeleteOutlined, { sx: { color: '#777' } }),
                type: 'button',
                onClick: (data) => __awaiter(void 0, void 0, void 0, function* () {
                    if (data.isOwner) {
                        if (confirm('Are you sure you want to delete this report?')) {
                            // @ts-ignore
                            yield deleter.mutateAsync({ ids: [data.id] });
                        }
                    }
                    else {
                        confirm('Only the owner can delete this report.');
                    }
                }),
            },
        ],
        queryChips: {
            all: {
                id: 'all',
                label: 'All',
                query: {},
            },
            reconciliation: {
                id: 'reconciliation',
                label: 'Reconciliation',
                query: {
                    pageFilter: 'reconciliation',
                },
            },
            policies: {
                id: 'policies',
                label: 'Policies',
                query: {
                    pageFilter: 'policies',
                },
            },
            commissions: {
                id: 'commissions',
                label: 'Commissions',
                query: {
                    pageFilter: 'commissions',
                },
            },
        },
    };
    if (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label) {
        dataDesc.label = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label;
    }
    return (_jsx(_Fragment, { children: _jsx(DataView, { headingOffset: 105, dataDesc: dataDesc, viewOnly: true, hideExport: true, enablePagination: true }) }));
};
export default ReportsView;
