import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

const Prefix = `${process.env.REACT_APP_API}/api`;

export const API_PATHS = {
  acceptTos: `${Prefix}/user/accept-tos`,
};

export const useAcceptTos = () => {
  return useMutation({
    mutationKey: [API_PATHS.acceptTos],
    mutationFn: async () => {
      return axios.get(API_PATHS.acceptTos);
    },
  });
};
