var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { BookmarkBorder, Save } from '@mui/icons-material';
import { Box, IconButton, Popover, TextField, Tooltip, Typography, } from '@mui/material';
import { useEffect, useState } from 'react';
import API from '@/services/API';
import useSnackbar from '@/contexts/useSnackbar';
const SaveReport = ({ sx = {}, queryData, getDataUrl, reportId = null, table, setSavingReport, }) => {
    const { showSnackbar } = useSnackbar();
    const [anchorElView, setAnchorElView] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [reportName, setReportName] = useState('');
    const [viewName, setViewName] = useState('');
    const [page, setPage] = useState('');
    const [params, setParams] = useState({
        params: '',
        queryData: '',
    });
    const openView = Boolean(anchorElView);
    const idView = openView ? 'simple-popover' : undefined;
    const handleClickView = (event) => {
        setAnchorElView(event.currentTarget);
    };
    const handleCloseView = () => {
        setAnchorElView(null);
    };
    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const poster = API.getMutation(`saved_reports`, 'POST');
    useEffect(() => {
        setParams({
            params: window.location.search,
            queryData: JSON.stringify(queryData),
        });
        if (table === 'statement_data') {
            setPage('commissions');
        }
        else if (table === 'report_data') {
            setPage('policies');
        }
        else if (table === 'reconciliation_data') {
            setPage('reconciliation');
        }
    }, [window.location.search, window.location.pathname, queryData]);
    const onSave = (type) => __awaiter(void 0, void 0, void 0, function* () {
        if (type === 'report') {
            setSavingReport(true);
            showSnackbar('Saving report...');
            const queryDataSend = params.queryData;
            const res = yield fetch(`${process.env.REACT_APP_API}/api/saved_reports?${params.params.split('?')[1]}`, {
                method: 'POST',
                headers: yield API.getHeaders(),
                body: JSON.stringify({
                    reportId: reportId,
                    getDataUrl,
                    page,
                    queryData: queryDataSend,
                    name: reportName,
                    type: 'report',
                }),
            });
            setSavingReport(false);
            if (res.ok) {
                const data = yield res.json();
                if (data.updatedReport) {
                    showSnackbar('Report data updated', 'success');
                    window.location.pathname = `/reports/${reportId}`;
                }
                else {
                    showSnackbar('Report saved', 'success');
                }
            }
            else {
                showSnackbar('An error occurred', 'error');
            }
            handleClose();
        }
        else if (type === 'view') {
            setSavingReport(true);
            showSnackbar('Saving view...');
            const paramsSend = params.params;
            const res = yield poster.mutateAsync({
                page,
                params: paramsSend,
                name: viewName,
                type: 'view',
            });
            setSavingReport(false);
            if (res.error) {
                showSnackbar(res.error, 'error');
            }
            else {
                showSnackbar('View saved', 'success');
            }
            handleClose();
        }
    });
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: sx, children: [_jsx(Popover, { id: idView, open: openView, anchorEl: anchorElView, onClose: handleCloseView, anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        }, children: _jsxs(Box, { sx: { p: 1 }, children: [_jsx(TextField, { InputProps: {
                                        style: { height: '34px' },
                                    }, sx: { width: 200 }, value: viewName, onChange: (e) => setViewName(e.target.value) }), _jsx(IconButton, { color: "primary", onClick: () => onSave('view'), children: _jsx(BookmarkBorder, {}) })] }) }), _jsx(Tooltip, { title: params.params === ''
                            ? 'Customize filters to save view'
                            : 'Save current view - this saves the current configuration of filters.', children: _jsx("span", { children: _jsx(IconButton, { onClick: handleClickView, color: "primary", children: _jsx(BookmarkBorder, {}) }) }) })] }), _jsxs(Box, { sx: sx, children: [_jsx(Popover, { id: id, open: open, anchorEl: anchorEl, onClose: handleClose, anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left',
                        }, children: _jsxs(Box, { sx: { p: 1, display: 'flex', alignItems: 'center' }, children: [reportId === null ? (_jsx(TextField, { InputProps: {
                                        style: { height: '34px' },
                                    }, sx: { width: 200 }, value: reportName, onChange: (e) => setReportName(e.target.value) })) : (_jsx(Typography, { children: "Update report" })), _jsx(IconButton, { color: "primary", onClick: () => onSave('report'), children: _jsx(Save, {}) })] }) }), _jsx(Tooltip, { title: 'Save a snapshot of this data', children: _jsx("span", { children: _jsx(IconButton, { onClick: handleClick, color: "primary", children: _jsx(Save, {}) }) }) })] })] }));
};
export default SaveReport;
