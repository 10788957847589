import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { LaunchOutlined } from '@mui/icons-material';
import { Box, Chip, IconButton } from '@mui/material';
import CommonFormatter from 'common/Formatter';
import Formatter from '@/services/Formatter';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
const ImportsView = () => {
    const dataDesc = useMemo(() => ({
        label: 'Imports',
        table: 'data_imports',
        fields: {
            summed_total_amount: {
                label: 'Summed total',
                enabled: true,
                formatter: Formatter.currency,
            },
            statement_total_amount: {
                label: 'Statement total',
                enabled: true,
                formatter: Formatter.currency,
            },
            count: {
                label: 'Count',
                enabled: true,
            },
            process_count: {
                label: 'Manual actions',
                enabled: true,
            },
            process_action_records: {
                label: 'Action records',
                enabled: true,
                formatter: (actionMap) => {
                    if (!actionMap) {
                        return '';
                    }
                    const actionRecords = Object.entries(actionMap).map(([key, value]) => {
                        return (_jsx(Box, { sx: { display: 'flex', gap: 1 }, children: _jsx(Chip, { label: `${key}: ${value}`, size: "small", sx: { mb: '4px' } }) }, key));
                    });
                    return _jsx(Box, { children: actionRecords });
                },
            },
            process_duration: {
                label: 'Duration',
                enabled: true,
                formatter: CommonFormatter.duration,
            },
            status: {
                label: 'Status',
                enabled: true,
                formatter: (val) => Formatter.statusChip(val, {
                    mapping: {
                        Success: 'green',
                        Failed: 'red',
                    },
                }),
            },
            str_id: {
                label: 'Import ID',
                enabled: true,
                formatter: (val, row) => {
                    // Link to the documents vieew
                    return (_jsx(_Fragment, { children: _jsxs(Box, { sx: { display: 'flex', alignItems: 'center' }, children: [_jsx(Chip, { size: "small", label: val }), _jsx(IconButton, { component: Link, to: `/${row.type === 'statement' ? 'commissions' : 'policies'}?q=${val}`, target: "_blank", sx: {
                                        opacity: 0.5,
                                        '&:hover': { opacity: 1 },
                                        color: '#2196f3',
                                    }, children: _jsx(LaunchOutlined, {}) })] }) }));
                },
            },
            type: {
                label: 'Type',
                enabled: true,
            },
            company: {
                label: 'Company',
                enabled: true,
                formatter: (val) => val === null || val === void 0 ? void 0 : val.company_name,
            },
            feedback: {
                label: 'Feedback',
                enabled: true,
                readOnly: true,
                formatter: (val) => {
                    return (_jsx(Box, { sx: {
                            width: '300px',
                            maxHeight: '60px',
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            whiteSpace: 'normal',
                            wordWrap: 'break-word',
                            lineHeight: '20px',
                        }, children: val }));
                },
            },
            created_at: {
                label: 'Uploaded at',
                enabled: true,
                formatter: (s) => Formatter.dateTime(s),
                readOnly: true,
            },
        },
    }), []);
    return (_jsx(_Fragment, { children: _jsx(EnhancedDataView, { dataSpec: dataDesc, hideAdd: true, hideSelectedCount: true, enableMultiSelect: false, enableEdit: false, hideExport: true }) }));
};
export default ImportsView;
