import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, TextField, Typography, } from '@mui/material';
import React, { useMemo } from 'react';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';
import API from '@/services/API';
import { SyncEndAdornment } from '@/common/SyncEndAdornment';
import { FieldTypes } from '@/types';
import BasicDateRangePicker from '@/common/BasicDateRangePicker';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
const ContactsLevelsAdd = ({ data, field, syncedFields, isSyncedField, setter, }) => {
    var _a;
    const contactLevels = (_a = data === null || data === void 0 ? void 0 : data.contact_level) !== null && _a !== void 0 ? _a : [];
    const { data: _companies } = API.getBasicQuery('companies');
    const isReadonly = (data, fieldId) => {
        if (syncedFields === null || syncedFields === void 0 ? void 0 : syncedFields.includes(fieldId)) {
            return isSyncedField(data, syncedFields, fieldId, data.config);
        }
        return false;
    };
    const companies = useMemo(() => {
        var _a;
        const companyData = (_a = _companies === null || _companies === void 0 ? void 0 : _companies.data) !== null && _a !== void 0 ? _a : [];
        return [
            { value: null, label: 'None' },
            ...companyData
                .map((company) => ({
                value: company.id,
                label: company.company_name,
            }))
                .sort((a, b) => { var _a; return (_a = a.label) === null || _a === void 0 ? void 0 : _a.localeCompare(b.label); }),
        ];
    }, [_companies]);
    return (_jsxs(Box, { sx: { width: '100%' }, children: [_jsx(Typography, { variant: "subtitle2", children: field.label }), _jsx(Box, { sx: {
                    width: '100%',
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                }, children: _jsx(Box, { sx: {
                        mt: 0.5,
                        pl: 1,
                        pt: 0.5,
                        pb: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        borderStyle: 'solid',
                        borderColor: 'silver',
                        borderWidth: 1,
                        borderRadius: 4,
                        width: '100%',
                        backgroundColor: '#2196f308',
                    }, children: _jsxs(Box, { sx: { my: 0.5, display: 'flex', flexWrap: 'wrap' }, children: [contactLevels === null || contactLevels === void 0 ? void 0 : contactLevels.map((contactLevel, index) => {
                                var _a, _b;
                                return (_jsxs(Box, { sx: {
                                        m: 0.5,
                                        px: 1,
                                        py: 0.5,
                                        borderStyle: 'solid',
                                        borderColor: 'silver',
                                        borderWidth: 1,
                                        borderRadius: 4,
                                        display: 'inline-block',
                                    }, children: [_jsxs(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", position: "relative", sx: { ml: 0.5 }, children: [_jsx(Typography, { variant: "body2", children: "Agent level" }), _jsx(IconButton, { onClick: () => {
                                                        setter(Object.assign(Object.assign({}, data), { contact_level: contactLevels.filter((cl) => cl.id !== contactLevel.id) }));
                                                    }, children: _jsx(CloseIcon, {}) })] }), _jsxs(Box, { children: [_jsx(Box, { children: _jsx(TextField, { label: "Level", variant: "outlined", value: (_a = contactLevel.level_label) !== null && _a !== void 0 ? _a : '', disabled: isReadonly(contactLevel, 'level_label'), onChange: (e) => {
                                                            setter(Object.assign(Object.assign({}, data), { contact_level: data.contact_level.map((item) => item.id === contactLevel.id
                                                                    ? Object.assign(Object.assign({}, item), { level_label: e.target.value }) : item) }));
                                                        }, sx: { mt: 0.5, mb: 1, width: '100%' }, InputProps: {
                                                            endAdornment: (_jsx(SyncEndAdornment, { syncedFields: syncedFields, syncId: contactLevel.sync_id, fieldId: "level_label", data: contactLevel, onChange: (newOverrideFields) => {
                                                                    setter(Object.assign(Object.assign({}, data), { contact_level: data.contact_level.map((item) => item.id === contactLevel.id
                                                                            ? Object.assign(Object.assign({}, item), { config: Object.assign(Object.assign({}, (item.config || {})), { overrideFields: newOverrideFields }) }) : item) }));
                                                                } })),
                                                        } }) }), _jsx(Box, { sx: { mt: 0.5 }, children: _jsx(BasicDateRangePicker, { range: {
                                                            startDate: contactLevel.start_date
                                                                ? dayjs.utc(contactLevel.start_date)
                                                                : null,
                                                            startDateLabel: 'Start date',
                                                            endDate: contactLevel.end_date
                                                                ? dayjs.utc(contactLevel.end_date)
                                                                : null,
                                                            endDateLabel: 'End date',
                                                            endAdornmentStartDate: (_jsx(SyncEndAdornment, { syncedFields: syncedFields, syncId: contactLevel.sync_id, fieldId: "start_date", fieldType: FieldTypes.DATE, data: contactLevel, onChange: (newOverrideFields) => {
                                                                    setter(Object.assign(Object.assign({}, data), { contact_level: data.contact_level.map((item) => item.id === contactLevel.id
                                                                            ? Object.assign(Object.assign({}, item), { config: Object.assign(Object.assign({}, (item.config || {})), { overrideFields: newOverrideFields }) }) : item) }));
                                                                } })),
                                                            endAdornmentEndDate: (_jsx(SyncEndAdornment, { syncedFields: syncedFields, syncId: contactLevel.sync_id, fieldId: "end_date", fieldType: FieldTypes.DATE, data: contactLevel, onChange: (newOverrideFields) => {
                                                                    setter(Object.assign(Object.assign({}, data), { contact_level: data.contact_level.map((item) => item.id === contactLevel.id
                                                                            ? Object.assign(Object.assign({}, item), { config: Object.assign(Object.assign({}, (item.config || {})), { overrideFields: newOverrideFields }) }) : item) }));
                                                                } })),
                                                        }, onChange: ({ startDate, endDate }) => {
                                                            setter(Object.assign(Object.assign({}, data), { contact_level: data.contact_level.map((item) => item.id === contactLevel.id
                                                                    ? Object.assign(Object.assign({}, item), { start_date: startDate
                                                                            ? dayjs.isDayjs(startDate)
                                                                                ? startDate.toDate()
                                                                                : new Date(startDate)
                                                                            : null, end_date: endDate
                                                                            ? dayjs.isDayjs(endDate)
                                                                                ? endDate.toDate()
                                                                                : new Date(endDate)
                                                                            : null }) : item) }));
                                                        }, width: contactLevel.sync_id ? 168 : 146 }) })] }), _jsx(Box, { sx: { mt: 0.5 }, children: _jsx(EnhancedSelect, { value: companies === null || companies === void 0 ? void 0 : companies.find((company) => company.value === contactLevel.company_id), disabled: !!data.sync_id, sx: { mt: 1, width: '100%' }, options: companies !== null && companies !== void 0 ? companies : [], label: "Company", disableAllOption: !data.sync_id, onChange: (e) => {
                                                    setter(Object.assign(Object.assign({}, data), { contact_level: data.contact_level.map((item) => item.id === contactLevel.id
                                                            ? Object.assign(Object.assign({}, item), { company_id: e === null || e === void 0 ? void 0 : e.id }) : item) }));
                                                }, valueKey: "id", labelKey: "company_name" }) }), _jsx(TextField, { label: "Product type", variant: "outlined", value: (_b = contactLevel.product_type) !== null && _b !== void 0 ? _b : '', disabled: isReadonly(contactLevel, 'product_type'), onChange: (e) => {
                                                setter(Object.assign(Object.assign({}, data), { contact_level: data.contact_level.map((item) => item.id === contactLevel.id
                                                        ? Object.assign(Object.assign({}, item), { product_type: e.target.value }) : item) }));
                                            }, sx: { mt: 1.5, width: '100%' }, InputProps: {
                                                endAdornment: (_jsx(SyncEndAdornment, { syncedFields: syncedFields, syncId: contactLevel.sync_id, fieldId: "product_type_label", data: contactLevel, onChange: (newOverrideFields) => {
                                                        setter(Object.assign(Object.assign({}, data), { contact_level: data.contact_level.map((item) => item.id === contactLevel.id
                                                                ? Object.assign(Object.assign({}, item), { config: Object.assign(Object.assign({}, (item.config || {})), { overrideFields: newOverrideFields }) }) : item) }));
                                                    } })),
                                            } }), _jsx(Box, { sx: { mt: 0.5 }, children: _jsx(FormControlLabel, { sx: { ml: 0 }, control: _jsxs(_Fragment, { children: [_jsx(Checkbox, { checked: contactLevel.loa, disabled: isReadonly(contactLevel, 'loa'), onChange: (e) => {
                                                                setter(Object.assign(Object.assign({}, data), { contact_level: data.contact_level.map((item) => item.id === contactLevel.id
                                                                        ? Object.assign(Object.assign({}, item), { loa: e.target.checked }) : item) }));
                                                            } }), _jsx(Box, { style: { marginLeft: 10 }, children: _jsx(SyncEndAdornment, { syncedFields: syncedFields, syncId: contactLevel.sync_id, fieldId: "loa", 
                                                                // FieldType={FieldTypes.BOOLEAN}
                                                                data: contactLevel, onChange: (newOverrideFields) => {
                                                                    setter(Object.assign(Object.assign({}, data), { contact_level: data.contact_level.map((item) => item.id === contactLevel.id
                                                                            ? Object.assign(Object.assign({}, item), { config: Object.assign(Object.assign({}, (item.config || {})), { overrideFields: newOverrideFields }) }) : item) }));
                                                                } }) })] }), label: "LOA" }) })] }, `${contactLevel.id}-${index}`));
                            }), _jsx(FormControl, { sx: { m: 0.5, width: 30 }, children: _jsx(Button, { onClick: (e) => {
                                        var _a;
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setter(Object.assign(Object.assign({}, data), { contact_level: [
                                                ...((_a = data.contact_level) !== null && _a !== void 0 ? _a : []),
                                                {
                                                    loa: false,
                                                    level_label: '',
                                                    id: nanoid(),
                                                    start_date: null,
                                                    end_date: null,
                                                },
                                            ] }));
                                    }, children: "Add" }) }, field.id)] }) }) }, `${field.id}-box`)] }));
};
export default ContactsLevelsAdd;
