import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { DocumentGroupItem } from 'common/documents/documents.types';

import { FilterFormType } from './validator';

const Prefix = `${process.env.REACT_APP_API}/api`;

export const API_PATHS = {
  group: `${Prefix}/documents/groups`,
};

export const useGroupData = (
  params?: Omit<FilterFormType, 'companies'> & { companies: string }
) => {
  return useQuery<DocumentGroupItem[]>({
    queryKey: [API_PATHS.group, params],
    queryFn: async () => {
      return axios
        .get(API_PATHS.group, { params, paramsSerializer: { indexes: null } })
        .then((res) => res.data);
    },
    enabled: !!params,
  });
};
