import { jsx as _jsx } from "react/jsx-runtime";
import { Navigate } from 'react-router-dom';
import DataView from '@/components//DataView';
import Formatter from '@/services/Formatter';
import DataTransformation from '@/services/DataTransformation';
import { FieldTypes } from '@/types';
import API from '@/services/API';
const Normalizer = DataTransformation;
const dataDesc = {
    label: 'Agent incentive tiers',
    table: 'schedules/agents/incentive_tiers',
    editable: true,
    copyable: true,
    fields: [
        { id: 'name', label: 'Name' },
        {
            id: 'companies',
            label: 'Carriers',
            type: 'dynamic-select',
            table: 'companies',
            field: 'id',
            multiple: true,
            formatter: (val, collectionVals = []) => {
                var _a;
                const _val = typeof val === 'object' ? val === null || val === void 0 ? void 0 : val.id : val;
                if (_val &&
                    Array.isArray(collectionVals) &&
                    collectionVals.length > 0) {
                    return (((_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.find((company) => company.id === _val)) === null || _a === void 0 ? void 0 : _a.company_name) || 'Not found');
                }
                return val;
            },
            optionFormatter: (option) => option.company_name,
            optionValuer: (option) => option.id,
        },
        { id: 'group', label: 'Group' },
        [
            {
                id: 'threshold_min',
                label: 'Threshold min',
                normalizer: Normalizer.normalizeCurrency,
                formatter: Formatter.currency,
                type: FieldTypes.CURRENCY,
            },
            {
                id: 'threshold_max',
                label: 'Threshold max',
                normalizer: Normalizer.normalizeCurrency,
                formatter: Formatter.currency,
                type: FieldTypes.CURRENCY,
            },
        ],
        {
            id: 'calculation_basis',
            label: 'Calculation basis',
            type: 'select',
            options: [
                { id: 'policy_annual_premium', label: 'Policy: Annual premium' },
                { id: 'policy_target_premium', label: 'Policy: Target premium' },
                {
                    id: 'commission_annual_premium',
                    label: 'Commission: Annual premium',
                },
                {
                    id: 'commission_target_premium',
                    label: 'Commission: Target premium',
                },
                { id: 'commission_amount', label: 'Commission amount' },
            ],
        },
        {
            id: 'lookback_units',
            label: 'Qualification period',
            type: 'select',
            sort: false,
            options: ['Annually', 'Quarterly', 'Monthly'],
        },
        [
            {
                startDateId: 'start_date',
                startDateLabel: 'Start date',
                endDateId: 'end_date',
                endDateLabel: 'End date',
                type: 'date_range',
                formatter: Formatter.date,
            },
        ],
        { id: 'notes', label: 'Notes' },
        { id: 'divider', type: 'divider' },
        [
            {
                id: 'created_at',
                label: 'Created',
                formatter: (val) => new Date(val).toLocaleString(),
                readOnly: true,
            },
            {
                id: 'updated_at',
                label: 'Updated',
                formatter: (val) => new Date(val).toLocaleString(),
                readOnly: true,
            },
        ],
    ],
};
const IncentivesTiersView = () => {
    var _a, _b;
    const { data: accountSettings, isFetched: isFetchedAccountSettings } = API.getBasicQuery(`accounts/settings`);
    const viewSettings = (_a = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _a === void 0 ? void 0 : _a.incentives_schedules;
    const viewOnly = (_b = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.read_only) !== null && _b !== void 0 ? _b : false;
    if (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label) {
        dataDesc.label = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label;
    }
    if (isFetchedAccountSettings && (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.show_page) === false) {
        return (
        // TODO: Remove navigate after figuring out how to handle this in router
        _jsx(Navigate, { to: "/settings" }));
    }
    return (_jsx(DataView, { dataDesc: dataDesc, viewOnly: viewOnly, readOnly: viewOnly, hideExport: true }));
};
export default IncentivesTiersView;
