import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { AttachMoney, CalendarTodayOutlined, CheckBoxOutlined, DataArray, DataObject, Numbers, Percent, PlusOne, TextFieldsOutlined, } from '@mui/icons-material';
import { Box, Tooltip } from '@mui/material';
import DataView from '@/components/DataView';
const types = {
    text: {
        label: 'Text',
        type: 'text',
        icon: _jsx(TextFieldsOutlined, {}),
    },
    integer: {
        label: 'Integer',
        type: 'integer',
        icon: _jsx(PlusOne, {}),
    },
    decimal: {
        label: 'Decimal',
        type: 'decimal',
        icon: _jsx(Numbers, {}),
    },
    currency: {
        label: 'Currency',
        type: 'currency',
        icon: _jsx(AttachMoney, {}),
    },
    date: {
        label: 'Date',
        type: 'date',
        icon: _jsx(CalendarTodayOutlined, {}),
    },
    percentage: {
        label: 'Percentage',
        type: 'percentage',
        icon: _jsx(Percent, {}),
    },
    boolean: {
        label: 'Boolean',
        type: 'boolean',
        icon: _jsx(CheckBoxOutlined, {}),
    },
    array: {
        label: 'Array',
        type: 'array',
        icon: _jsx(DataArray, {}),
    },
    json: {
        label: 'JSON',
        type: 'text',
        icon: _jsx(DataObject, {}),
    },
};
const normalizeJson = (val) => {
    if (val && typeof val === 'string')
        return JSON.parse(val);
    return val;
};
const formatJson = (s) => (typeof s === 'string' ? s : JSON.stringify(s));
const formatJsonAsKeyVal = (json) => {
    const _json = typeof json === 'string' ? JSON.parse(json) : json;
    return (_jsx(Box, { children: Object.entries(_json).map(([k, v]) => (_jsx(Box, { sx: { whiteSpace: 'nowrap' }, children: _jsxs("span", { style: { color: v === false ? 'darkgray' : 'inherit' }, children: [k, ": ", String(v)] }) }, k))) }));
};
const formatType = (val) => types[val] ? (_jsx(Tooltip, { title: types[val].label, children: types[val].icon })) : null;
const dataDesc = {
    label: 'Fields',
    table: 'fields',
    editable: true,
    fields: [
        {
            id: 'model',
            label: 'Model',
            type: 'select',
            options: [
                {
                    label: 'Policies',
                    id: 'reports',
                },
                {
                    label: 'Commissions',
                    id: 'statements',
                },
            ],
            // TableFormatter: formatModel,
        },
        { id: 'label', label: 'Label', tableFormatter: (val) => _jsx("b", { children: val }) },
        { id: 'key', label: 'Key' },
        {
            id: 'options',
            label: 'Options',
            type: 'text-multiline',
            normalizer: normalizeJson,
            formatter: formatJson,
        },
        {
            id: 'matches',
            label: 'Matches',
            type: 'text-multiline',
            normalizer: normalizeJson,
            formatter: formatJson,
        },
        {
            id: 'enabled_for',
            label: 'Enabled?',
            normalizer: normalizeJson,
            formatter: formatJson,
            tableFormatter: formatJsonAsKeyVal,
            default: { default: true, insurance: true },
        },
        {
            id: 'required_for',
            label: 'Required?',
            normalizer: normalizeJson,
            formatter: formatJson,
            tableFormatter: formatJsonAsKeyVal,
            default: { default: false, insurance: false },
        },
        {
            id: 'type',
            label: 'Type',
            type: 'select',
            options: Object.keys(types),
            tableFormatter: formatType,
        },
        {
            id: 'copyable',
            label: 'Copyable?',
            type: 'boolean',
        },
        { id: 'description', label: 'Description', type: 'text-multiline' },
        { id: 'notes', label: 'Notes', type: 'text-multiline' },
    ],
    queryChips: {
        all: {
            id: 'all',
            label: 'All',
            query: {},
        },
        commissions: {
            id: 'commissions',
            label: 'Commissions',
            query: {
                model: 'statements',
            },
        },
        policies: {
            id: 'policies',
            label: 'Policies',
            query: {
                model: 'reports',
            },
        },
    },
};
const FieldsView = () => _jsx(DataView, { dataDesc: dataDesc, hideExport: true });
export default FieldsView;
