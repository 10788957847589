var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, FormHelperText, IconButton, TextField, } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import validator from 'validator';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import useSnackbar from '@/contexts/useSnackbar';
import API from '@/services/API';
import { useUserInfo } from '@/hooks/useUserInfo';
import { useCompanies } from '@/api/companies';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
const ActionDialog = ({ open, setOpen, rowData, documentProfiles }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const { data: { data: companies } = { data: [] } } = useCompanies();
    const { showSnackbar } = useSnackbar();
    const { data: { fintaryAdmin } = {} } = useUserInfo();
    const [search, setSearch] = useState('');
    const schema = z.object({
        bank_total_amount: z
            .string()
            .transform((val) => (val === '' ? null : val))
            .nullable()
            .refine((val) => val === null ||
            validator.isCurrency(val, {
                allow_negatives: true,
                thousands_separator: ',',
                decimal_separator: '.',
                allow_decimal: true,
                digits_after_decimal: [1, 2],
            }), { message: 'Invalid currency format' }),
        statement_amount: z
            .string()
            .transform((val) => (val === '' ? null : val))
            .nullable()
            .refine((val) => val === null ||
            validator.isCurrency(val, {
                allow_negatives: true,
                thousands_separator: ',',
                decimal_separator: '.',
                allow_decimal: true,
                digits_after_decimal: [1, 2],
            }), { message: 'Invalid currency format' }),
        check_date: z.string().nullable(),
        company_str_id: z.string({ message: 'Required' }),
        profile_str_id: z.string().nullable().optional(),
        deposit_date: z.string().nullable(),
        notes: z.string().optional(),
        status: z.string({ message: 'Required' }),
        type: z.string(),
    });
    const { handleSubmit, setValue, watch, formState: { errors }, register, reset, } = useForm({
        resolver: zodResolver(schema),
    });
    const type = watch('type');
    const company_str_id = watch('company_str_id');
    const profile_str_id = watch('profile_str_id');
    const status = watch('status');
    const deposit_date = watch('deposit_date');
    const check_date = watch('check_date');
    const statusList = useMemo(() => [
        {
            id: 'new',
            label: 'New',
        },
        {
            id: 'processed',
            label: 'Processed',
        },
    ], []);
    const typeList = useMemo(() => [
        {
            id: 'statement',
            label: 'Statement',
        },
        {
            id: 'report',
            label: 'Report',
        },
    ], []);
    const documentProfileOptions = useMemo(() => {
        return (documentProfiles || [])
            .map((profile) => {
            const profileName = profile.name ? profile.name : '(Blank)';
            const carrierName = profile.company_name
                ? profile.company_name
                : '(Blank)';
            const displayName = `${carrierName} - ${profileName}`;
            return {
                id: profile.str_id,
                label: displayName,
            };
        })
            .filter((option) => {
            if (search) {
                const searchLower = search.toLowerCase();
                return option.label.toLowerCase().includes(searchLower);
            }
            return true;
        });
    }, [documentProfiles, search]);
    const apiPutter = API.getMutation('documents', 'PUT');
    useEffect(() => {
        if (rowData) {
            reset(rowData);
        }
    }, [reset, rowData]);
    const onSubmit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        const { id, str_id } = rowData;
        const body = Object.assign(Object.assign({}, data), { str_id,
            id });
        try {
            const result = yield apiPutter.mutateAsync(body);
            if (result.error) {
                showSnackbar(result.error, 'error');
                return;
            }
            if (result) {
                setOpen(false, 'save');
            }
        }
        catch (error) {
            showSnackbar((error === null || error === void 0 ? void 0 : error.message) || error, 'error');
        }
    });
    return (_jsxs(Dialog, { open: open, maxWidth: "md", fullWidth: true, sx: { background: 'transparent', p: 1 }, onClose: () => setOpen(false), disableEscapeKeyDown: true, children: [_jsx(DialogTitle, { children: "Edit document" }), _jsx(IconButton, { className: "group cursor-pointer hover:text-blue-600", onClick: () => setOpen(false), sx: { position: 'absolute', right: 8, top: 8 }, children: _jsx(Close, { className: "group-hover:rotate-180 transition-all origin-center" }) }), _jsx(Divider, {}), _jsx(DialogContent, { sx: {
                    p: 0,
                    backgroundColor: '#fff',
                    borderRadius: '4px',
                }, children: _jsxs(Box, { sx: { width: '100%', maxHeight: '700px', p: 2 }, children: [_jsxs(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 1.5 }, children: [_jsx(EnhancedSelect, { label: "Type", sx: { width: '100%' }, value: typeList.find((item) => item.id === type), options: typeList, onChange: (value) => {
                                        setValue('type', value.id, { shouldValidate: true });
                                    } }), _jsx(FormHelperText, { error: !!((_a = errors.type) === null || _a === void 0 ? void 0 : _a.message), children: (_b = errors.type) === null || _b === void 0 ? void 0 : _b.message })] }), _jsxs(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 1.5 }, children: [_jsx(EnhancedSelect, { label: "Company", sx: { width: '100%' }, value: companies.find((company) => company.str_id === company_str_id), labelKey: "company_name", valueKey: "str_id", options: companies, onChange: (value) => {
                                        setValue('company_str_id', value.str_id, {
                                            shouldValidate: true,
                                        });
                                    } }), _jsx(FormHelperText, { error: !!((_c = errors.company_name) === null || _c === void 0 ? void 0 : _c.message), children: (_d = errors.company_name) === null || _d === void 0 ? void 0 : _d.message })] }), fintaryAdmin && (_jsxs(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 1.5 }, children: [_jsx(EnhancedSelect, { enableSearch: true, label: "Document profiles \uD83D\uDD12", sx: { width: '100%' }, listContainerSx: {
                                        width: 800,
                                    }, value: documentProfileOptions.find((profile) => profile.id === profile_str_id), options: documentProfileOptions, onChange: (value) => {
                                        setValue('profile_str_id', value.id, {
                                            shouldValidate: true,
                                        });
                                    }, onSearch: setSearch, searchKeyword: search }), _jsx(FormHelperText, { error: !!((_e = errors.profile_str_id) === null || _e === void 0 ? void 0 : _e.message), children: (_f = errors.profile_str_id) === null || _f === void 0 ? void 0 : _f.message })] })), _jsxs(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 1.5 }, children: [_jsx(EnhancedSelect, { label: "Status", sx: { width: '100%' }, value: statusList.find((item) => item.id === status), options: statusList, onChange: (value) => {
                                        setValue('status', value.id, { shouldValidate: true });
                                    } }), _jsx(FormHelperText, { error: !!((_g = errors.status) === null || _g === void 0 ? void 0 : _g.message), children: (_h = errors.status) === null || _h === void 0 ? void 0 : _h.message })] }), _jsxs(Box, { sx: {
                                display: 'flex',
                                alignItems: 'center',
                                gap: 2,
                            }, children: [_jsx(FormControl, { fullWidth: true, sx: { maxWidth: 250, mb: 1.5 }, children: _jsx(TextField, Object.assign({ label: "Bank total", variant: "outlined" }, register('bank_total_amount'), { error: !!((_j = errors === null || errors === void 0 ? void 0 : errors.bank_total_amount) === null || _j === void 0 ? void 0 : _j.message), helperText: (((_k = errors === null || errors === void 0 ? void 0 : errors.bank_total_amount) === null || _k === void 0 ? void 0 : _k.message) || '') })) }), _jsx(BasicDatePicker, { label: "Deposit date", value: deposit_date, setValue: (e) => setValue('deposit_date', e, { shouldValidate: true }), sx: { mb: 1.5, width: 300 } })] }), _jsx(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 1.5 }, children: _jsx(TextField, Object.assign({ label: "Statement total", variant: "outlined" }, register('statement_amount'), { error: !!((_l = errors === null || errors === void 0 ? void 0 : errors.statement_amount) === null || _l === void 0 ? void 0 : _l.message), helperText: (((_m = errors === null || errors === void 0 ? void 0 : errors.statement_amount) === null || _m === void 0 ? void 0 : _m.message) || '') })) }), _jsx(Box, { children: _jsx(BasicDatePicker, { label: "Check date", value: check_date, setValue: (e) => setValue('check_date', e, { shouldValidate: true }), sx: { width: 250, mb: 2 } }) }), _jsx(FormControl, { fullWidth: true, sx: { minWidth: 100, mb: 1.5 }, children: _jsx(TextField, Object.assign({ label: "Notes", variant: "outlined", multiline: true, rows: 4 }, register('notes'))) })] }) }), _jsxs(DialogActions, { sx: { pt: 0, pb: 2, px: 2 }, children: [_jsx(Button, { onClick: () => setOpen(false), children: "Cancel" }), _jsx(LoadingButton, { onClick: handleSubmit(onSubmit), loading: apiPutter.isPending, variant: "contained", sx: { width: '100px' }, children: "Save" })] })] }));
};
export default ActionDialog;
