import { jsx as _jsx } from "react/jsx-runtime";
import { TransactionStatusesOptions } from 'common/globalTypes';
import DataView from '@/components/DataView';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { useAccountStore, useRoleStore } from '@/store';
import { FieldTypes, Roles } from '@/types';
const View = () => {
    var _a;
    const { userRole } = useRoleStore();
    const { selectedAccount } = useAccountStore();
    const { data: contactsData } = API.getBasicQuery('contacts', 'is_dynamic_select=true');
    const contacts = (_a = contactsData === null || contactsData === void 0 ? void 0 : contactsData.data) !== null && _a !== void 0 ? _a : [];
    const dataDesc = {
        label: 'Transactions',
        table: 'accounting/transactions',
        editable: true,
        copyable: true,
        bulkAdd: true,
        fields: [
            {
                id: 'str_id',
                label: 'ID',
                readOnly: true,
                condition: (val) => val === null || val === void 0 ? void 0 : val.str_id,
                visible: false,
            },
            {
                id: 'date',
                label: 'Date',
                type: FieldTypes.DATE,
                formatter: Formatter.date,
            },
            {
                id: 'type',
                label: 'Type',
                type: 'select',
                options: [
                    { label: 'Cash receipt', id: 'cash_received' },
                    { label: 'Receivable', id: 'receivable' },
                    { label: 'Payable', id: 'payable' },
                ],
            },
            {
                id: 'report_id',
                label: 'Policy',
                type: 'dynamic-select',
                table: 'report_data',
                nullable: true,
                formatter: (val, collectionVals = []) => {
                    var _a;
                    if (val === '')
                        return '';
                    if (Array.isArray(collectionVals) && collectionVals.length > 0) {
                        const record = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.filter((datum) => datum.id === val)) === null || _a === void 0 ? void 0 : _a[0];
                        return record
                            ? `${record.policy_id} (${Formatter.date(record.effective_date)})`
                            : '';
                    }
                    return val;
                },
                optionFormatter: (option) => `${option.policy_id} (${Formatter.date(option.effective_date)})`,
                optionValuer: (option) => option === null || option === void 0 ? void 0 : option.id,
            },
            {
                id: 'contact_id',
                label: 'Agent name',
                type: 'dynamic-select',
                table: 'contacts',
                formatter: (val, collectionVals = []) => {
                    var _a;
                    if (val === '')
                        return '';
                    if (Array.isArray(collectionVals) && collectionVals.length > 0) {
                        const record = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.filter((datum) => datum.id === val)) === null || _a === void 0 ? void 0 : _a[0];
                        return record ? `${record.first_name} ${record.last_name}` : '';
                    }
                    return val;
                },
                optionFormatter: (option) => `${option.first_name} ${option.last_name}`,
                optionValuer: (option) => option === null || option === void 0 ? void 0 : option.id,
            },
            {
                id: 'report_data',
                label: 'Product name',
                formatter: (val) => val === null || val === void 0 ? void 0 : val.product_name,
                readOnly: true,
                condition: (val) => val === null || val === void 0 ? void 0 : val.product_name,
            },
            { id: 'amount', label: 'Amount', type: FieldTypes.CURRENCY },
            {
                id: 'created_by', // Random field since a valid one is required
                label: 'Reconciled amount',
                type: 'computed',
                formatter: (val, row) => {
                    var _a;
                    return row.type === 'receivable'
                        ? (_a = row === null || row === void 0 ? void 0 : row.accounting_transaction_details) === null || _a === void 0 ? void 0 : _a.reduce((acc, cur) => {
                            return acc + +cur.amount;
                        }, 0)
                        : 'n/a';
                },
                readOnly: true,
            },
            {
                id: 'updated_by', // Random field since a valid one is required
                label: 'Balance',
                formatter: (val, row) => {
                    var _a;
                    return row.type === 'receivable'
                        ? +row.amount +
                            ((_a = row === null || row === void 0 ? void 0 : row.accounting_transaction_details) === null || _a === void 0 ? void 0 : _a.reduce((acc, cur) => {
                                return acc + +cur.amount;
                            }, 0))
                        : 'n/a';
                },
                readOnly: true,
            },
            { id: 'notes', label: 'Notes' },
            {
                id: 'status',
                label: 'Status',
                type: FieldTypes.SELECT,
                options: TransactionStatusesOptions.map((status) => ({
                    id: status.value,
                    label: status.label,
                })),
            },
            {
                id: 'created_at',
                label: 'Created',
                formatter: Formatter.dateTime,
                condition: (val) => val.id,
                readOnly: true,
            },
            {
                id: 'updated_at',
                label: 'Last updated',
                formatter: Formatter.dateTime,
                condition: (val) => val.id,
                readOnly: true,
            },
        ],
        queryChips: {},
        queryChipsType: 'select',
    };
    if (Array.isArray(contacts) && contacts.length > 0) {
        contacts.forEach((contact, i) => {
            dataDesc.queryChips[contact.id] = {
                id: contact.id,
                label: Formatter.contact(contact, {
                    account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                }),
                query: { contact_id: contact.id },
            };
        });
    }
    const rolesWithEditAccess = [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST];
    return (_jsx(DataView, { dataDesc: dataDesc, readOnly: !userRole || !rolesWithEditAccess.includes(userRole), viewOnly: !userRole || !rolesWithEditAccess.includes(userRole), hideExport: true }));
};
export default View;
