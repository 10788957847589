import { create } from 'zustand';

interface EnhancedTableToolbarState {
  isBulkEdit: boolean;
  setIsBulkEdit: (value: boolean) => void;
}

const useEnhancedTableToolbarStore = create<EnhancedTableToolbarState>(
  (set) => ({
    isBulkEdit: false,
    setIsBulkEdit: (value: boolean) => set({ isBulkEdit: value }),
  })
);

export default useEnhancedTableToolbarStore;
