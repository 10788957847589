import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { MoreVert } from '@mui/icons-material';
import { Card, CardContent, Grid, IconButton, InputLabel, FormControl, Select, Menu, MenuItem, Typography, } from '@mui/material';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import React, { useState, useEffect } from 'react';
import BasicTable from '@/components/molecules/BasicTable';
import { exportCsv } from '@/services/helpers';
import Formatter from '@/services/Formatter';
import ChartWidget from '@/components/DashboardView/ChartWidget';
dayjs.extend(utc);
const ChartWrapper = ({ title, data: _data, dataSet: _dataSet, timeRange, setTimeRange, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const [anchorEl, setAnchorEl] = useState(null);
    const [view, setView] = useState('chart');
    const [selectVal, setSelectVal] = useState({});
    const [currTitle, setCurrTitle] = useState('');
    const [options, setOptions] = useState([]);
    const [filterType, setFilterType] = useState('all');
    const [filterCount, setFilterCount] = useState('20');
    useEffect(() => {
        var _a;
        if (_dataSet) {
            const newOptions = [];
            _dataSet.forEach((eachData) => {
                const _title = eachData === null || eachData === void 0 ? void 0 : eachData.title;
                if (_title) {
                    newOptions.push(_title);
                }
            });
            setOptions(newOptions);
            if (newOptions.length > 0) {
                const curr = !currTitle ? newOptions[0] : currTitle;
                setSelectVal(((_a = _dataSet.find((eachData) => eachData.title === curr)) === null || _a === void 0 ? void 0 : _a.value) || []);
                setCurrTitle(curr);
            }
        }
        else if (_data && title) {
            setSelectVal(_data);
            setCurrTitle(title);
        }
    }, [_data, _dataSet, title]);
    const handleDataSet = (e) => {
        const selectedTitle = e.target.value;
        const selectedData = _dataSet === null || _dataSet === void 0 ? void 0 : _dataSet.find((eachData) => eachData.title === selectedTitle);
        if (selectedData) {
            setSelectVal(selectedData.value);
            setCurrTitle(selectedTitle);
        }
    };
    const handleMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = () => {
        setAnchorEl(null);
    };
    const handleTimeRangeChange = (event) => {
        setTimeRange(event.target.value);
    };
    const showFilter = (currTitle === null || currTitle === void 0 ? void 0 : currTitle.includes('processors')) || (currTitle === null || currTitle === void 0 ? void 0 : currTitle.includes('profiles'));
    // Get data
    let processedData = Object.assign({}, selectVal);
    if (showFilter && filterType !== 'all' && ((_b = (_a = selectVal === null || selectVal === void 0 ? void 0 : selectVal.series) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.data)) {
        const sortedData = [...selectVal.series[0].data].sort((a, b) => b.val - a.val);
        const count = parseInt(filterCount);
        processedData = Object.assign(Object.assign({}, selectVal), { series: [
                Object.assign(Object.assign({}, selectVal.series[0]), { data: filterType === 'top'
                        ? sortedData.slice(0, count)
                        : sortedData.slice(-count) }),
            ] });
    }
    const headers = Object.keys(((_e = (_d = (_c = processedData === null || processedData === void 0 ? void 0 : processedData.series) === null || _c === void 0 ? void 0 : _c[0]) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e[0]) || {});
    headers[0] = ((_f = selectVal === null || selectVal === void 0 ? void 0 : selectVal.xAxis) === null || _f === void 0 ? void 0 : _f.type) || headers[0];
    if (currTitle === 'Document types' && (selectVal === null || selectVal === void 0 ? void 0 : selectVal.series)) {
        headers.splice(1, 1);
        selectVal.series.forEach((series) => {
            headers.push(series.name);
        });
    }
    else {
        headers[1] = ((_h = (_g = selectVal === null || selectVal === void 0 ? void 0 : selectVal.series) === null || _g === void 0 ? void 0 : _g[0]) === null || _h === void 0 ? void 0 : _h.name) || headers[1];
    }
    let data = (_l = (_k = (_j = processedData === null || processedData === void 0 ? void 0 : processedData.series) === null || _j === void 0 ? void 0 : _j[0]) === null || _k === void 0 ? void 0 : _k.data) === null || _l === void 0 ? void 0 : _l.map((row) => {
        return Object.values(row);
    });
    let exportData = data;
    if (((_m = selectVal === null || selectVal === void 0 ? void 0 : selectVal.xAxis) === null || _m === void 0 ? void 0 : _m.type) === 'time') {
        exportData = [];
        if (currTitle === 'Document types') {
            const timeMap = new Map();
            processedData.series.forEach((series, index) => {
                var _a;
                (_a = series.data) === null || _a === void 0 ? void 0 : _a.forEach(([time, value]) => {
                    if (!timeMap.has(time)) {
                        timeMap.set(time, new Array(processedData.series.length).fill(0));
                    }
                    timeMap.get(time)[index] = value;
                });
            });
            data = Array.from(timeMap.entries())
                .map(([time, values]) => {
                const date = dayjs.utc(time).format('YYYY-MM-DD');
                exportData.push([date, ...values]);
                return [date, ...values];
            })
                .sort((a, b) => dayjs(a[0]).valueOf() - dayjs(b[0]).valueOf());
        }
        else {
            data = data.map(([k, v]) => {
                let date = dayjs.utc(k).format('YYYY-MM-DD');
                let value;
                date = dayjs(date).format('YYYY-MM-DD');
                exportData.push([date, v]);
                switch (currTitle) {
                    case 'Time to import':
                        value = Formatter.highlightChip(v, 10, 100);
                        break;
                    case 'Processing time':
                        value = Formatter.highlightChip(v, 1, 5);
                        break;
                    case 'Records':
                        value = Formatter.highlightChip(v, 30, 300);
                        break;
                    case 'Records per minutes':
                        value = Formatter.highlightChip(v, 0, 5);
                        break;
                    case 'Total actions':
                        value = Formatter.highlightChip(v, 5, 30);
                        break;
                    case 'Add data':
                        value = Formatter.highlightChip(v, 1, 5);
                        break;
                    case 'Edit data':
                        value = Formatter.highlightChip(v, 2, 20);
                        break;
                    case 'Delete data':
                        value = Formatter.highlightChip(v, 2, 20);
                        break;
                    case 'Edit mapping':
                        value = Formatter.highlightChip(v, 2, 20);
                        break;
                    case 'Select method':
                        value = Formatter.highlightChip(v, 1, 2);
                        break;
                    case 'Select mapping':
                        value = Formatter.highlightChip(v, 1, 2);
                        break;
                    case 'Select processor':
                        value = Formatter.highlightChip(v, 1, 2);
                        break;
                    default:
                        value = v;
                        break;
                }
                return [date, value];
            });
        }
    }
    return (_jsx(Card, { children: _jsxs(CardContent, { sx: { pb: 0 }, children: [_jsxs(Grid, { container: true, justifyContent: "space-between", alignItems: "center", children: [_jsx(Grid, { item: true, style: { flex: 1 }, children: _jsx(Typography, { gutterBottom: true, variant: "body1", children: currTitle }) }), _jsxs(Grid, { item: true, style: {
                                display: 'flex',
                                alignItems: 'center',
                                gap: '16px',
                            }, children: [(currTitle === 'Document import trends' ||
                                    currTitle === 'Rows import trends' ||
                                    currTitle === 'Document types import trends') && (_jsx(FormControl, { children: _jsxs(Select, { value: timeRange, onChange: handleTimeRangeChange, variant: "outlined", sx: {
                                            width: 120,
                                            height: 32,
                                            fontSize: '0.9rem',
                                            '& .MuiSelect-icon': {
                                                color: 'primary.main',
                                            },
                                            '& .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'primary.main',
                                                borderRadius: 0.5,
                                            },
                                            '&:hover .MuiOutlinedInput-notchedOutline': {
                                                borderColor: 'primary.dark',
                                            },
                                        }, children: [_jsx(MenuItem, { value: "day", children: "Daily" }), _jsx(MenuItem, { value: "week", children: "Weekly" }), _jsx(MenuItem, { value: "month", children: "Monthly" })] }) })), showFilter && (_jsxs(_Fragment, { children: [_jsx(FormControl, { children: _jsxs(Select, { value: filterType, onChange: (e) => setFilterType(e.target.value), variant: "outlined", sx: {
                                                    ml: 3,
                                                    width: 120,
                                                    height: 32,
                                                    fontSize: '0.9rem',
                                                    '& .MuiSelect-icon': {
                                                        color: 'primary.main',
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'primary.main',
                                                        borderRadius: 0.5,
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'primary.dark',
                                                    },
                                                }, children: [_jsx(MenuItem, { value: "all", children: "All items" }), _jsx(MenuItem, { value: "top", children: "Top items" }), _jsx(MenuItem, { value: "bottom", children: "Bottom items" })] }) }), filterType !== 'all' && (_jsx(FormControl, { children: _jsx(Select, { value: filterCount, onChange: (e) => setFilterCount(e.target.value), variant: "outlined", sx: {
                                                    width: 100,
                                                    height: 32,
                                                    fontSize: '0.9rem',
                                                    '& .MuiSelect-icon': {
                                                        color: 'primary.main',
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'primary.main',
                                                        borderRadius: 0.5,
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'primary.dark',
                                                    },
                                                }, children: [5, 10, 20, 50].map((num) => (_jsx(MenuItem, { value: num.toString(), children: filterType === 'top'
                                                        ? `Top ${num}`
                                                        : `Bottom ${num}` }, num))) }) }))] })), _jsx("div", { style: { flexGrow: 1 } }), options.length > 0 && (_jsxs(FormControl, { children: [_jsx(InputLabel, { id: "select-data-label", children: "Select data" }), _jsx(Select, { value: currTitle, onChange: handleDataSet, label: "Select data", variant: "outlined", sx: {
                                                width: 190,
                                                height: 32,
                                                fontSize: '0.9rem',
                                                '& .MuiSelect-icon': {
                                                    color: 'primary.main',
                                                },
                                                '& .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'primary.main',
                                                    borderRadius: 0.5,
                                                },
                                                '&:hover .MuiOutlinedInput-notchedOutline': {
                                                    borderColor: 'primary.dark',
                                                },
                                            }, children: options.map((option) => (_jsx(MenuItem, { value: option, children: option }, option))) })] })), _jsx(IconButton, { onClick: handleMenuClick, children: _jsx(MoreVert, {}) }), _jsxs(Menu, { anchorEl: anchorEl, open: Boolean(anchorEl), onClose: handleMenuClose, children: [_jsx(MenuItem, { onClick: () => {
                                                setView(view === 'table' ? 'chart' : 'table');
                                            }, children: view === 'table' ? 'Chart view' : 'Table view' }, "view-toggle"), _jsx(MenuItem, { onClick: () => {
                                                exportCsv(headers, exportData, 'Fintary-Metrics-Export.csv');
                                            }, children: "Export" }, "export")] })] })] }), view === 'chart' && _jsx(ChartWidget, { data: processedData }), view === 'table' && (_jsx(BasicTable, { headers: headers, rows: data, formatters: {} }))] }) }));
};
export default ChartWrapper;
