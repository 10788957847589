import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FormControl, TextField } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { DataProcessingAction } from 'common/globalTypes';
import CodeMirror from '@uiw/react-codemirror';
import { debounce } from 'lodash';
import { javascript } from '@codemirror/lang-javascript';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
import { codeTheme } from '@/views/ProcessorPlayground/config';
const defaultCode = `
  /**
   * @param {object} record - The record to process
   * @returns {object} - The processed record
   */

    const fieldIDToSet = 'commission_rate'

    console.log(fieldIDToSet);
    console.log(record[fieldIDToSet]);

    if (record[fieldIDToSet])
      record[fieldIDToSet] = record[fieldIDToSet] * 100;
    else 
      record[fieldIDToSet] = 0;

    return record[fieldIDToSet];
`;
export const fieldMatcherActions = [
    {
        id: DataProcessingAction.SET_FIELD_VALUE,
        label: 'Set field value',
    },
    {
        id: DataProcessingAction.SET_VALUE_FROM_FIELD,
        label: `Set field to another field's value`,
    },
    {
        id: DataProcessingAction.SET_BY_CUSTOM_RULE,
        label: 'Set field based on custom rule',
    },
];
const ActionPicker = ({ value: actionObj = { field: '', action: '', value: '' }, row, fieldGetter, setValue, sx = {}, }) => {
    const [currentCode, setCurrentCode] = useState((actionObj === null || actionObj === void 0 ? void 0 : actionObj.code_to_use) || defaultCode);
    const [selectedActionField, setSelectedActionField] = useState(actionObj === null || actionObj === void 0 ? void 0 : actionObj.field);
    const selectedFields = fieldGetter(row);
    const setActionObject = useCallback((value, key) => {
        const updatedActionObj = Object.assign(Object.assign({}, actionObj), { [key]: value });
        if (key === 'action' && value === DataProcessingAction.SET_BY_CUSTOM_RULE)
            updatedActionObj.code_to_use = currentCode;
        setValue((prevData) => {
            return Object.assign(Object.assign({}, prevData), { data_update_actions: updatedActionObj });
        });
    }, [actionObj, currentCode, setValue]);
    const valueOptions = useMemo(() => {
        var _a;
        return (_a = selectedFields === null || selectedFields === void 0 ? void 0 : selectedFields.find((field) => field.id === selectedActionField)) === null || _a === void 0 ? void 0 : _a.options;
    }, [selectedFields, selectedActionField]);
    const onCodeChange = useCallback(debounce((value) => {
        setCurrentCode(value);
        setActionObject(value, 'code_to_use');
    }, 500), [setActionObject]);
    return (_jsxs(Box, { sx: Object.assign({ mt: 1, display: 'flex', flexWrap: 'wrap', width: '100%' }, sx), children: [_jsx(EnhancedSelect, { label: "Action", value: fieldMatcherActions.find((item) => item.id === (actionObj === null || actionObj === void 0 ? void 0 : actionObj.action)), sortLabel: false, options: fieldMatcherActions, onChange: (v) => {
                    setActionObject(v === null || v === void 0 ? void 0 : v.id, 'action');
                }, sx: { width: 200, mr: 1 } }), selectedFields && (_jsxs(_Fragment, { children: [_jsx(EnhancedSelect, { enableSearch: true, label: (actionObj === null || actionObj === void 0 ? void 0 : actionObj.action) === DataProcessingAction.SET_VALUE_FROM_FIELD
                            ? 'Field to set'
                            : 'Field', options: selectedFields, value: selectedFields.find((item) => item.id === (actionObj === null || actionObj === void 0 ? void 0 : actionObj.field)), onChange: (v) => {
                            setActionObject(v === null || v === void 0 ? void 0 : v.id, 'field');
                            setSelectedActionField(v === null || v === void 0 ? void 0 : v.id);
                        }, sx: { mr: 1, minWidth: 200, width: 'fit-content' } }), (actionObj === null || actionObj === void 0 ? void 0 : actionObj.action) === DataProcessingAction.SET_VALUE_FROM_FIELD && (_jsx(EnhancedSelect, { label: "Field to use value from", options: selectedFields, value: selectedFields.find((item) => item.id === (actionObj === null || actionObj === void 0 ? void 0 : actionObj.field_to_use)), onChange: (v) => {
                            setActionObject(v === null || v === void 0 ? void 0 : v.id, 'field_to_use');
                        }, sx: { mr: 1, minWidth: 200, width: 'fit-content' } }))] })), selectedActionField &&
                (actionObj === null || actionObj === void 0 ? void 0 : actionObj.action) === DataProcessingAction.SET_FIELD_VALUE && (_jsx(_Fragment, { children: (valueOptions === null || valueOptions === void 0 ? void 0 : valueOptions.length) ? (_jsx(EnhancedSelect, { label: "Value", onChange: (v) => {
                        setActionObject(v, 'value');
                    }, options: valueOptions, value: actionObj === null || actionObj === void 0 ? void 0 : actionObj.value })) : (_jsx(FormControl, { sx: { width: 145 }, children: _jsx(TextField, { value: (actionObj === null || actionObj === void 0 ? void 0 : actionObj.value) || '', onChange: (e) => setActionObject(e.target.value, 'value'), label: "Value" }) })) })), selectedActionField &&
                (actionObj === null || actionObj === void 0 ? void 0 : actionObj.action) === DataProcessingAction.SET_BY_CUSTOM_RULE && (_jsx(Box, { sx: { width: '100%', mt: 1 }, children: _jsx(Box, { className: "w-full h-full overflow-auto bg-sky-50/5 border border-blue-400 border-solid text-sm", children: _jsx(CodeMirror, { theme: codeTheme, height: "100%", value: currentCode, width: "100%", extensions: [javascript({ jsx: true })], onChange: onCodeChange }) }) }))] }));
};
export default ActionPicker;
