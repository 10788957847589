import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography, } from '@mui/material';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { useEffect, useState } from 'react';
import LoadingCircle from '@/components/atoms/LoadingCircle';
import API from '@/services/API';
import ChartWrapper from '@/components/admin/AdminMetricsView/ChartWrapper';
dayjs.extend(quarterOfYear);
dayjs.extend(utc);
dayjs.extend(timezone);
const dateRanges = {
    thisWeek: () => ({
        startDate: dayjs().startOf('week').toDate(),
        endDate: dayjs().endOf('week').toDate(),
    }),
    lastWeek: () => ({
        startDate: dayjs().subtract(1, 'week').startOf('week').toDate(),
        endDate: dayjs().subtract(1, 'week').endOf('week').toDate(),
    }),
    thisMonth: () => ({
        startDate: dayjs().startOf('month').toDate(),
        endDate: dayjs().endOf('month').toDate(),
    }),
    lastMonth: () => ({
        startDate: dayjs().subtract(1, 'month').startOf('month').toDate(),
        endDate: dayjs().subtract(1, 'month').endOf('month').toDate(),
    }),
    thisQuarter: () => ({
        startDate: dayjs().startOf('quarter').toDate(),
        endDate: dayjs().endOf('quarter').toDate(),
    }),
    lastQuarter: () => ({
        startDate: dayjs().subtract(1, 'quarter').startOf('quarter').toDate(),
        endDate: dayjs().subtract(1, 'quarter').endOf('quarter').toDate(),
    }),
    thisYear: () => ({
        startDate: dayjs().startOf('year').toDate(),
        endDate: dayjs().endOf('year').toDate(),
    }),
    lastYear: () => ({
        startDate: dayjs().subtract(1, 'year').startOf('year').toDate(),
        endDate: dayjs().subtract(1, 'year').endOf('year').toDate(),
    }),
    last7days: () => ({
        startDate: dayjs().subtract(7, 'days').toDate(),
        endDate: dayjs().toDate(),
    }),
    last30days: () => ({
        startDate: dayjs().subtract(30, 'days').toDate(),
        endDate: dayjs().toDate(),
    }),
    last60days: () => ({
        startDate: dayjs().subtract(60, 'days').toDate(),
        endDate: dayjs().toDate(),
    }),
    last90days: () => ({
        startDate: dayjs().subtract(90, 'days').toDate(),
        endDate: dayjs().toDate(),
    }),
};
const filters = {
    dateRange: {
        label: 'Date range',
        type: 'select',
        options: [
            { label: 'This week', value: 'thisWeek' },
            { label: 'Last week', value: 'lastWeek' },
            { label: 'This month', value: 'thisMonth' },
            { label: 'Last month', value: 'lastMonth' },
            { label: 'This quarter', value: 'thisQuarter' },
            { label: 'Last quarter', value: 'lastQuarter' },
            { label: 'This year', value: 'thisYear' },
            { label: 'Last year', value: 'lastYear' },
            { label: 'Last 7 days', value: 'last7days' },
            { label: 'Last 30 days', value: 'last30days' },
            { label: 'Last 60 days', value: 'last60days' },
            { label: 'Last 90 days', value: 'last90days' },
        ],
        initialValue: 'last60days',
        sx: { width: 140 },
    },
    startDate: {
        label: 'Start date',
        type: 'date',
        field: 'effectiveDate',
        initialValue: dayjs().subtract(60, 'days').toDate(),
        filterFunc: (val, filterVal) => val >= filterVal,
    },
    endDate: {
        label: 'End date',
        type: 'date',
        field: 'effectiveDate',
        initialValue: dayjs().toDate(),
        filterFunc: (val, filterVal) => val <= filterVal,
    },
};
const Metrics = () => {
    const pageLabel = 'Metrics';
    const [filteredValues, setFilteredValues] = useState({
        startDate: dateRanges.last60days().startDate,
        endDate: dateRanges.last60days().endDate,
        dateRange: 'last60days',
    });
    const [metricsData, setMetricsData] = useState([]);
    const [timeRange, setTimeRange] = useState('day');
    const updateFilteredValues = (key, value) => {
        const newFilteredValues = Object.assign(Object.assign({}, filteredValues), { [key]: value });
        setFilteredValues(newFilteredValues);
        if (key === 'dateRange') {
            const { startDate, endDate } = dateRanges[value]();
            setFilteredValues(Object.assign(Object.assign({}, newFilteredValues), { startDate, endDate }));
        }
    };
    useEffect(() => {
        const url = new URL(window.location.href);
        const params = url.searchParams;
        const newFilteredValues = {};
        Object.keys(filters).forEach((key) => {
            if (params.has(key)) {
                newFilteredValues[key] = params.get(key) || filters[key].initialValue;
            }
        });
        if (newFilteredValues.dateRange) {
            const { startDate, endDate } = dateRanges[newFilteredValues.dateRange]();
            setFilteredValues(Object.assign(Object.assign({}, newFilteredValues), { startDate,
                endDate }));
        }
        else {
            setFilteredValues(Object.assign(Object.assign({}, newFilteredValues), { startDate: dateRanges.last60days().startDate, endDate: dateRanges.last60days().endDate }));
        }
    }, []);
    // Ensure query string is properly formatted
    const query = `start_date=${new Date(filteredValues.startDate).toISOString().substring(0, 10)}&end_date=${new Date(filteredValues.endDate).toISOString()}&time_range=${timeRange}`;
    const filteredFilters = Object.fromEntries(Object.entries(filters).filter(([k]) => !['startDate', 'endDate'].includes(k)));
    const { isLoading: isLoadingMetrics, data: result, isFetched: isFetchedMetrics, } = API.getBasicQuery('metrics', query);
    useEffect(() => {
        if (isFetchedMetrics) {
            setMetricsData(result || []);
        }
    }, [result, isFetchedMetrics]);
    return (_jsxs(Box, { p: 2, sx: { width: '100%', overflowY: 'scroll' }, children: [_jsxs(Box, { display: "flex", justifyContent: "space-between", sx: { mt: 1, p: '0 1%' }, children: [_jsx(Typography, { variant: "h5", children: pageLabel }), Object.entries(filteredFilters).map(([key, filter]) => filter.type === 'select' && (_jsxs(FormControl, { sx: Object.assign({ ml: 1, width: 135 }, filter.sx), children: [_jsx(InputLabel, { children: filter.label }), _jsx(Select, { value: filteredValues[key] || '', label: filter.label, onChange: (e) => updateFilteredValues(key, e.target.value), sx: { '.MuiSelect-select': { py: 0.75, px: 1.5 } }, children: filter.options.map((option) => (_jsx(MenuItem, { value: option.value, children: option.label }, option.value))) })] }, filter.label)))] }), _jsx(Box, { sx: { mt: 1, p: '3% 2%' }, children: _jsxs(Grid, { container: true, spacing: 2, sx: { mt: 1 }, children: [isLoadingMetrics && _jsx(LoadingCircle, {}), metricsData.map((e, key) => (_jsx(Grid, { item: true, xs: 12, md: 6, sx: { display: isLoadingMetrics ? 'none' : 'block' }, children: Array.isArray(e) ? (_jsx(ChartWrapper, { dataSet: e, timeRange: timeRange, setTimeRange: setTimeRange })) : (_jsx(ChartWrapper, { title: e.title, data: e.value, timeRange: timeRange, setTimeRange: setTimeRange })) }, key)))] }) })] }));
};
export default Metrics;
