export const setLocalData = (name: string, data: unknown) => {
  localStorage.setItem(name, JSON.stringify(data));
};

export const removeLocalData = (name: string) => {
  localStorage.removeItem(name);
};

export const getLocalData = <T = any>(name: string) => {
  try {
    return JSON.parse(localStorage.getItem(name) as string) as T;
  } catch {
    return null;
  }
};
