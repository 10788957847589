const convertObjectListToArrayList = (objectList) => {
    const keys = Object.keys(objectList[0]);
    const keyMap = {};
    keys.forEach((key, index) => {
        keyMap[key] = index;
    });
    const arrayList = objectList.map((obj) => {
        return keys.map((key) => obj[key]);
    });
    return { arrayList, keyMap };
};
export const desc_convertObjectListToArrayList = {
    name: 'libs.tools.convertObjectListToArrayList(objectList)',
    description: 'Converts an array of objects into an array list and generates a key mapping.',
    arguments: {
        objectList: '[Array<Object>] Array of objects to be converted',
    },
    returns: '[Object] Contains arrayList (2D array of values) and keyMap (object mapping keys to indices)',
    examples: 'convertObjectListToArrayList([{a: 1, b: 2}, {a: 3, b: 4}])\n// → {arrayList: [[1, 2], [3, 4]], keyMap: {a: 0, b: 1}}\n',
};
export default convertObjectListToArrayList;
