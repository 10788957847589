import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { Box, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormGroup, FormControlLabel, Checkbox, } from '@mui/material';
const RemoveFieldsDialog = ({ open, onClose, onConfirm, fieldsSource, }) => {
    const [checked, setChecked] = useState([]);
    const handleChange = (key, e) => {
        if (e.target.checked) {
            setChecked((prev) => {
                return [...prev, key];
            });
        }
        else {
            setChecked((prev) => {
                return prev.filter((s) => s !== key);
            });
        }
    };
    const handleSubmit = () => {
        const list = fieldsSource.filter((item) => checked.includes(item.key));
        onConfirm(list);
    };
    return (_jsxs(Dialog, { open: open, onClose: onClose, fullWidth: true, children: [_jsx(DialogTitle, { children: "Remove field(s)" }), _jsx(DialogContent, { children: _jsx(Box, { children: _jsx(FormGroup, { children: fieldsSource.map((item) => (_jsx(FormControlLabel, { control: _jsx(Checkbox, {}), label: item.label, value: item.key, onChange: (e) => handleChange(item.key, e) }, item.key))) }) }) }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: onClose, children: "Cancel" }), _jsx(Button, { onClick: handleSubmit, disabled: !checked.length, variant: "contained", children: "Remove" })] })] }));
};
export default RemoveFieldsDialog;
