var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, TextField, Typography } from '@mui/material';
import { LoadingContext } from 'contexts/LoadingContext';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
import useSnackbar from '@/contexts/useSnackbar';
import API from '@/services/API';
import { Roles } from '@/types';
const rolesMap = [
    {
        id: Roles.ACCOUNT_ADMIN,
        label: 'Account Admin',
        value: Roles.ACCOUNT_ADMIN,
    },
    {
        id: Roles.PRODUCER,
        label: 'Producer',
        value: Roles.PRODUCER,
    },
    {
        id: Roles.DATA_SPECIALIST,
        label: 'Data Specialist',
        value: Roles.DATA_SPECIALIST,
    },
];
const WidgetSelector = ({ sharedWidgets, refetch, dashboardSettings, closeAddWidgetDialog, isEditPage, initDashboardLabel, initAccessRoles, dashboardId, }) => {
    var _a, _b, _c;
    const [dashboardName, setDashboardName] = useState(initDashboardLabel !== null && initDashboardLabel !== void 0 ? initDashboardLabel : '');
    const [dashboardWidgetsAdmin, setDashboardWidgetsAdmin] = useState((dashboardSettings === null || dashboardSettings === void 0 ? void 0 : dashboardSettings[Roles.ACCOUNT_ADMIN]) || []);
    const [dashboardWidgetsProducer, setDashboardWidgetsProducer] = useState((dashboardSettings === null || dashboardSettings === void 0 ? void 0 : dashboardSettings[Roles.PRODUCER]) || []);
    const [dashboardWidgetsDataSpecialist, setDashboardWidgetsDataSpecialist] = useState((dashboardSettings === null || dashboardSettings === void 0 ? void 0 : dashboardSettings[Roles.DATA_SPECIALIST]) || []);
    const { setLoadingConfig } = useContext(LoadingContext);
    const { showSnackbar } = useSnackbar();
    const [selectedRoles, setSelectedRoles] = useState(initAccessRoles !== null && initAccessRoles !== void 0 ? initAccessRoles : []);
    const navigate = useNavigate();
    const { refetch: refetchSettings } = API.getBasicQuery('accounts/settings', undefined, false);
    const commonInputStyle = {
        width: '100%',
        marginBottom: '10px',
        '& .MuiInputBase-root': {
            height: '40px !important',
        },
    };
    const saveWidgetSettingPoster = API.getMutation('insights/accountWidgetsSettings', 'PUT');
    const updateWidgetSettingPoster = API.getMutation(`insights/accountWidgetsSettings/${dashboardId}`, 'PUT');
    const saveWidgetSetting = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoadingConfig({
            loading: true,
            message: 'Saving widget setting...',
        });
        const data = {
            adminWidgetsSettings: dashboardWidgetsAdmin,
            producerWidgetsSettings: dashboardWidgetsProducer,
            dataSpecialistWidgetsSettings: dashboardWidgetsDataSpecialist,
            dashboardName,
            accessRoles: selectedRoles.map((role) => { var _a, _b; return (_b = (_a = role.id) === null || _a === void 0 ? void 0 : _a.toString) === null || _b === void 0 ? void 0 : _b.call(_a); }),
        };
        try {
            if (isEditPage) {
                const res = yield updateWidgetSettingPoster.mutateAsync(data);
                navigate(`/insights/${res.name}`);
            }
            else {
                yield saveWidgetSettingPoster.mutateAsync(data);
            }
        }
        catch (error) {
            showSnackbar(`Error save widget setting :${error}`, 'error');
        }
        closeAddWidgetDialog();
        refetchSettings();
        refetch === null || refetch === void 0 ? void 0 : refetch();
        setLoadingConfig({
            loading: false,
        });
    });
    useEffect(() => {
        setDashboardName(initDashboardLabel);
    }, [initDashboardLabel]);
    useEffect(() => {
        setDashboardWidgetsAdmin((dashboardSettings === null || dashboardSettings === void 0 ? void 0 : dashboardSettings[Roles.ACCOUNT_ADMIN]) || []);
        setDashboardWidgetsProducer((dashboardSettings === null || dashboardSettings === void 0 ? void 0 : dashboardSettings[Roles.PRODUCER]) || []);
        setDashboardWidgetsDataSpecialist((dashboardSettings === null || dashboardSettings === void 0 ? void 0 : dashboardSettings[Roles.DATA_SPECIALIST]) || []);
    }, [dashboardSettings]);
    useEffect(() => {
        setSelectedRoles(initAccessRoles !== null && initAccessRoles !== void 0 ? initAccessRoles : []);
    }, [initAccessRoles]);
    const handleSave = () => {
        saveWidgetSetting();
    };
    return (_jsxs(Box, { sx: { padding: 2, minWidth: '400px' }, children: [_jsx(Box, { paddingBlock: 1, children: _jsx(TextField, { label: "Dashboard name", onChange: (e) => setDashboardName(e.target.value), value: dashboardName, fullWidth: true }) }), _jsx(Typography, { variant: "h6", children: "Choose widgets" }), _jsxs(Box, { paddingBlock: 1, paddingInline: 2, children: [_jsx(EnhancedSelect, { label: "Admin", enableSearch: true, value: ((_a = dashboardWidgetsAdmin === null || dashboardWidgetsAdmin === void 0 ? void 0 : dashboardWidgetsAdmin.filter((w) => sharedWidgets === null || sharedWidgets === void 0 ? void 0 : sharedWidgets.some((s) => s.id === w))) === null || _a === void 0 ? void 0 : _a.map((id) => {
                            var _a;
                            return ({
                                id,
                                label: ((_a = sharedWidgets === null || sharedWidgets === void 0 ? void 0 : sharedWidgets.find((w) => w.id === id)) === null || _a === void 0 ? void 0 : _a.name) || id,
                            });
                        })) || [], sx: commonInputStyle, multiple: true, options: (sharedWidgets === null || sharedWidgets === void 0 ? void 0 : sharedWidgets.map((widget) => ({
                            id: widget.id,
                            label: widget.name,
                        }))) || [], onChange: (value) => {
                            setDashboardWidgetsAdmin((value === null || value === void 0 ? void 0 : value.map((v) => v.id)) || []);
                        } }), _jsx(EnhancedSelect, { label: "Producer", enableSearch: true, value: ((_b = dashboardWidgetsProducer === null || dashboardWidgetsProducer === void 0 ? void 0 : dashboardWidgetsProducer.filter((w) => sharedWidgets === null || sharedWidgets === void 0 ? void 0 : sharedWidgets.some((s) => s.id === w))) === null || _b === void 0 ? void 0 : _b.map((id) => {
                            var _a;
                            return ({
                                id,
                                label: ((_a = sharedWidgets === null || sharedWidgets === void 0 ? void 0 : sharedWidgets.find((w) => w.id === id)) === null || _a === void 0 ? void 0 : _a.name) || id,
                            });
                        })) || [], sx: commonInputStyle, multiple: true, options: (sharedWidgets === null || sharedWidgets === void 0 ? void 0 : sharedWidgets.map((widget) => ({
                            id: widget.id,
                            label: widget.name,
                        }))) || [], onChange: (value) => {
                            setDashboardWidgetsProducer((value === null || value === void 0 ? void 0 : value.map((v) => v.id)) || []);
                        } }), _jsx(EnhancedSelect, { label: "Data specialist", enableSearch: true, value: ((_c = dashboardWidgetsDataSpecialist === null || dashboardWidgetsDataSpecialist === void 0 ? void 0 : dashboardWidgetsDataSpecialist.filter((w) => sharedWidgets === null || sharedWidgets === void 0 ? void 0 : sharedWidgets.some((s) => s.id === w))) === null || _c === void 0 ? void 0 : _c.map((id) => {
                            var _a;
                            return ({
                                id,
                                label: ((_a = sharedWidgets === null || sharedWidgets === void 0 ? void 0 : sharedWidgets.find((w) => w.id === id)) === null || _a === void 0 ? void 0 : _a.name) || id,
                            });
                        })) || [], sx: commonInputStyle, multiple: true, options: (sharedWidgets === null || sharedWidgets === void 0 ? void 0 : sharedWidgets.map((widget) => ({
                            id: widget.id,
                            label: widget.name,
                        }))) || [], onChange: (value) => {
                            setDashboardWidgetsDataSpecialist((value === null || value === void 0 ? void 0 : value.map((v) => v.id)) || []);
                        } })] }), _jsx(Typography, { variant: "h6", children: "Access" }), _jsx(Box, { paddingBlock: 1, paddingInline: 2, children: _jsx(EnhancedSelect, { label: "Roles", value: selectedRoles, multiple: true, options: rolesMap, sx: commonInputStyle, onChange: (value) => {
                        setSelectedRoles(value);
                    } }) }), _jsx(Box, { sx: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingBlock: '10px',
                    borderBottom: '1px solid #e0e0e0',
                    position: 'fixed',
                    bottom: '0',
                    width: '100%',
                    background: 'inherit',
                }, children: _jsxs(Box, { children: [_jsx(Button, { variant: "outlined", style: { marginRight: '10px' }, onClick: closeAddWidgetDialog, children: "Close" }), _jsx(Button, { variant: "contained", color: "primary", onClick: handleSave, children: "Save" })] }) })] }));
};
export default WidgetSelector;
