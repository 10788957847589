var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Login } from '@mui/icons-material';
import { Chip } from '@mui/material';
import axios from 'axios';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import API from '@/services/API';
const dataSpec = {
    label: 'Users',
    table: 'admin/users',
    fields: {
        uid: {
            label: 'Uid',
            id: 'uid',
            copyable: true,
            enabled: true,
            visible: true,
        },
        email: {
            label: 'Email',
            enabled: true,
            visible: true,
        },
        first_name: {
            label: 'First name',
            enabled: true,
            visible: true,
        },
        last_name: {
            label: 'Last name',
            enabled: true,
            visible: true,
        },
        type: {
            label: 'Type',
            enabled: true,
            visible: true,
        },
        sub_type: {
            label: 'Sub type',
            enabled: true,
            visible: true,
        },
        account_user_roles: {
            label: 'Log in as user/account',
            enabled: true,
            visible: true,
            formatter: (fieldData, rowData) => {
                return (_jsx(Chip, { label: `${fieldData.account.name} (${fieldData.role.name})`, onClick: () => {
                        localStorage.clear();
                        localStorage.setItem('customLoginUser', JSON.stringify(rowData));
                        localStorage.setItem('selectedAccount', JSON.stringify({
                            accountId: fieldData.account.str_id,
                            accountName: fieldData.account.name,
                            accountMode: fieldData.account.mode,
                            state: fieldData.account.state,
                        }));
                        window.location.pathname = '/';
                    }, clickable: true, icon: _jsx(Login, {}), sx: { m: 0.25 } }, fieldData.id));
            },
        },
    },
};
const actions = [
    {
        id: 'delete_user',
        label: 'Delete user',
        onClick: (row) => __awaiter(void 0, void 0, void 0, function* () {
            const headers = yield API.getHeaders();
            yield axios.delete(`${process.env.REACT_APP_API}/api/admin/users`, {
                headers,
                params: {
                    id: row.id,
                },
            });
        }),
    },
];
const LoginAsView = () => (_jsx(EnhancedDataView, { dataSpec: dataSpec, 
    // @ts-ignore
    actions: actions, actionsEnabled: () => true, hideAdd: true, hideSelectedCount: true, enableMultiSelect: false, enableEdit: false, hideExport: true }));
export default LoginAsView;
