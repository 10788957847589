import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, } from '@mui/material';
import React, { useState } from 'react';
const DataUpdatePreviewModal = ({ open, handleClose, handleDataUpdatePost, loading, previewData = [], fieldsToPreview, fields, }) => {
    const fieldsIds = fieldsToPreview.map((field) => field.id);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const handleDataUpdate = () => {
        handleDataUpdatePost(previewData);
    };
    const getFieldLabel = (key) => {
        // TODO: Remove '-to-change' from key to identify the field to be changed
        const normalizedKey = key.replace('-to-change', '');
        const field = fields.find((f) => f.id === normalizedKey);
        return field ? field.label : key;
    };
    const formatValue = (key, value) => {
        const normalizedKey = key.replace('-to-change', '');
        const field = fields.find((f) => f.id === normalizedKey);
        if (field && field.tableFormatter) {
            return field.tableFormatter(value);
        }
        else if (field && field.formatter) {
            return field.formatter(value);
        }
        return value;
    };
    return (_jsxs(Dialog, { open: open, onClose: handleClose, maxWidth: "md", fullWidth: true, children: [_jsx(DialogTitle, { children: "Data update preview" }), _jsxs(DialogContent, { sx: { pb: 0 }, children: [_jsx(TableContainer, { sx: {
                            maxHeight: '50vh',
                            overflow: 'auto',
                        }, children: _jsxs(Table, { stickyHeader: true, size: "small", sx: { mt: '1px' }, children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { sx: { border: '0.5px solid #ccc' }, children: "Preview fields" }), _jsx(TableCell, { sx: { border: '0.5px solid #ccc' }, children: "Fields to change" })] }) }), _jsx(TableBody, { children: previewData
                                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        .map((data, index) => {
                                        const unchangedFields = {};
                                        const changedFields = {};
                                        const policyDataFields = [];
                                        Object.keys(data).forEach((key) => {
                                            if (key !== 'data_entity_id') {
                                                if (fieldsIds.includes(key)) {
                                                    unchangedFields[key] = data[key];
                                                }
                                                else if (key === 'fieldsFromPolicy') {
                                                    policyDataFields.push(...data[key]);
                                                }
                                                else {
                                                    changedFields[key] = data[key];
                                                }
                                            }
                                        });
                                        return (_jsxs(TableRow, { children: [_jsx(TableCell, { sx: { border: '0.5px solid #ccc' }, children: Object.entries(unchangedFields).map(([key, value]) => (_jsxs("div", { children: [_jsxs("span", { style: { fontWeight: 500 }, children: [getFieldLabel(key), ":"] }), ' ', policyDataFields.includes(key) ? (_jsx(Tooltip, { title: "Sourced from policy data", children: _jsx("span", { children: typeof value === 'object'
                                                                        ? `*${JSON.stringify(value)}`
                                                                        : `*${value}` }) })) : typeof value === 'object' ? (JSON.stringify(value)) : (formatValue(key, value))] }, key))) }), _jsx(TableCell, { sx: { border: '0.5px solid #ccc' }, children: Object.entries(changedFields).map(([key, value]) => (_jsxs("div", { children: [_jsxs("span", { style: { fontWeight: 500 }, children: [getFieldLabel(key), ":"] }), ' ', typeof value === 'object'
                                                                ? JSON.stringify(value)
                                                                : formatValue(key, value)] }, key))) })] }, index));
                                    }) })] }) }), _jsx(TablePagination, { rowsPerPageOptions: [50, 100, 250], component: "div", count: previewData.length, rowsPerPage: rowsPerPage, page: page, onPageChange: handleChangePage, onRowsPerPageChange: handleChangeRowsPerPage, sx: {
                            '& .MuiTablePagination-toolbar': {
                                minHeight: 42,
                                height: 42,
                                pl: 2,
                            },
                        } })] }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: handleClose, color: "primary", children: "Cancel" }), _jsx(LoadingButton, { loading: loading, variant: "contained", onClick: () => handleDataUpdate(), sx: { ml: 1 }, children: "Run update" })] })] }));
};
export default DataUpdatePreviewModal;
