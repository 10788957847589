import { GROUP_BY_VALUES } from 'common/documents/documents.constants';
import { DocumentGroupItem } from 'common/documents/documents.types';
import dayjs from 'dayjs';
import { useMemo } from 'react';

export const useProcessGroupBy = ({
  data,
  groupBy,
}: {
  data: DocumentGroupItem[];
  groupBy: GROUP_BY_VALUES;
}) => {
  return useMemo(() => {
    switch (groupBy) {
      case GROUP_BY_VALUES.QUARTER: {
        // Key: quarter-year, value: total files
        // We use Map instead of object to preserve the order of the keys
        const groupedData = new Map<string, Omit<DocumentGroupItem, 'date'>>();
        data.forEach((item) => {
          const date = dayjs(item.date);
          const year = date.year();
          const month = date.month();
          const quarter = Math.floor(month / 3) + 1;
          const key = `Q${quarter}-${year}`;
          const quarterData = groupedData.get(key);
          groupedData.set(key, {
            file_count: (quarterData?.file_count || 0) + item.file_count,
            total_statement_amount:
              (quarterData?.total_statement_amount || 0) +
              item.total_statement_amount,
            total_bank_amount:
              (quarterData?.total_bank_amount || 0) + item.total_bank_amount,
            total_commission_amount:
              (quarterData?.total_commission_amount || 0) +
              item.total_commission_amount,
          });
        });

        const result: DocumentGroupItem[] = [];
        groupedData.forEach((rest, key) => {
          result.push({
            date: key,
            ...rest,
          });
        });

        return result;
      }

      case GROUP_BY_VALUES.YEAR: {
        const groupedData = new Map<string, Omit<DocumentGroupItem, 'date'>>();
        data.forEach((item) => {
          const date = dayjs(item.date);
          const year = date.year().toString();
          const quarterData = groupedData.get(year);
          groupedData.set(year, {
            file_count: (quarterData?.file_count || 0) + item.file_count,
            total_statement_amount:
              (quarterData?.total_statement_amount || 0) +
              item.total_statement_amount,
            total_bank_amount:
              (quarterData?.total_bank_amount || 0) + item.total_bank_amount,
            total_commission_amount:
              (quarterData?.total_commission_amount || 0) +
              item.total_commission_amount,
          });
        });
        const result: DocumentGroupItem[] = [];
        groupedData.forEach((data, key) => {
          result.push({
            date: key,
            ...data,
          });
        });

        return result;
      }

      default:
        return data;
    }
  }, [data, groupBy]);
};
