var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useContext, useEffect, useState } from 'react';
import { Box, FormControl, Button, Typography, TextField, Grid, Switch, Accordion, AccordionSummary, AccordionDetails, Checkbox, } from '@mui/material';
import CodeMirror from '@uiw/react-codemirror';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { WidgetGroup } from 'common/constants';
import { LoadingContext } from 'contexts/LoadingContext';
import { BarChartOutlined, DonutLargeOutlined, Inventory2Outlined, ReceiptOutlined, RequestQuoteOutlined, Rule, ShowChartOutlined, TableChartOutlined, } from '@mui/icons-material';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
import useSnackbar from '@/contexts/useSnackbar';
import { UIStateContext } from '@/contexts/UIStateProvider';
import BoxWidget from './BoxWidget';
import ChartWidget from './ChartWidget';
import TableWidget from './TableWidget';
import WidgetWrapper from './WidgetWrapper';
import FieldMatcher from '@/components/molecules/FieldMatcher';
import useAccountStore from '@/store/accountStore';
import FieldAggregationManager from '@/components/molecules/FieldAggregation/FieldAggregationManager';
import API from '@/services/API';
import FieldsCalculatorExpressionBuilder from '@/components/molecules/FieldsCalculatorExpressionBuilder';
const dataSources = ['statement', 'report'];
const dataSourceDisplayNames = {
    statement: 'Commissions',
    report: 'Policies',
    reconciliation: 'Reconciliations',
};
const dataSourceIcon = {
    statement: React.createElement(RequestQuoteOutlined),
    report: React.createElement(ReceiptOutlined),
    reconciliation: React.createElement(Rule),
};
const toggleStyle = {
    width: 200,
    display: 'flex',
    justifyContent: 'space-between',
};
const filterFieldsByDataSource = {
    statement: {
        groupFields: [
            {
                name: 'writing_carrier_name',
                type: 'string',
                displayName: 'Writing carrier name',
            },
            {
                name: 'agent_name',
                type: 'string',
                displayName: 'Agent name',
            },
            {
                name: 'contacts',
                type: 'string',
                displayName: 'Agents',
            },
            {
                name: 'customer_name',
                type: 'string',
                displayName: 'Customer name',
            },
            {
                name: 'policy_id',
                type: 'string',
                displayName: 'Policy id',
            },
            {
                name: 'product_type',
                type: 'string',
                displayName: 'Product type',
            },
            {
                name: 'product_name',
                type: 'string',
                displayName: 'Product name',
            },
            {
                name: 'processing_date',
                type: 'date',
                displayName: 'Processing date',
            },
            {
                name: 'compensation_type',
                type: 'string',
                displayName: 'Compensation type',
            },
            {
                name: 'carrier_name',
                type: 'string',
                displayName: 'Carrier name',
            },
            {
                name: 'report.product_type',
                type: 'string',
                displayName: 'Policies.product_type',
                fieldMatcherType: 'text',
            },
            {
                name: 'report.customer_name',
                type: 'string',
                displayName: 'Policies.customer_name',
                fieldMatcherType: 'text',
            },
            {
                name: 'report.geo_state',
                type: 'string',
                displayName: 'Policies.geo_state',
                fieldMatcherType: 'text',
            },
            {
                name: 'report.premium_amount',
                type: 'string',
                displayName: 'Policies.premium_amount',
                fieldMatcherType: 'number',
            },
            {
                name: 'report.writing_carrier_name',
                type: 'string',
                displayName: 'Policies.writing_carrier_name',
                fieldMatcherType: 'text',
            },
        ],
        dataFields: [
            {
                name: 'commission_amount',
                type: 'number',
            },
            {
                name: 'agent_commissions.total',
                type: 'obj',
                lookupKey: 'total',
            },
            {
                name: 'agent_commissions',
                type: 'obj',
            },
            {
                name: 'policy_id',
                type: 'string',
            },
            {
                name: 'premium_amount',
                type: 'number',
            },
            {
                name: 'report',
                type: 'object',
                displayName: 'Policies',
            },
        ],
    },
    report: {
        groupFields: [
            {
                name: 'effective_date',
                type: 'date',
                displayName: 'Effective date',
            },
            {
                name: 'agent_name',
                type: 'string',
                displayName: 'Agent name',
            },
            {
                name: 'customer_name',
                type: 'string',
                displayName: 'Customer name',
            },
            {
                name: 'product_type',
                type: 'string',
                displayName: 'Product type',
            },
            {
                name: 'product_name',
                type: 'string',
                displayName: 'Product name',
            },
            {
                name: 'writing_carrier_name',
                type: 'string',
                displayName: 'Writing carrier name',
            },
            {
                name: 'product_sub_type',
                type: 'string',
                displayName: 'Product sub type',
            },
            {
                name: 'policy_status',
                type: 'string',
                displayName: 'Policy status',
                availableValues: [
                    'Active',
                    'Inactive',
                    'Active Client',
                    'Inactive Client',
                    'In Force',
                    'Prospect/Lead',
                    'duplicate',
                ],
            },
            {
                name: 'state',
                type: 'string',
                displayName: 'State',
                availableValues: ['duplicate', 'active', 'deleted'],
            },
        ],
        dataFields: [
            {
                name: 'commissionable_premium_amount',
                type: 'number',
            },
            {
                name: 'premium_amount',
                type: 'number',
            },
            {
                name: 'commissions_expected',
                type: 'number',
            },
            {
                name: 'customer_paid_premium_amount',
                type: 'number',
            },
        ],
    },
    reconciliation: {
        groupFields: [
            {
                name: 'carrier_name',
                type: 'string',
                displayName: 'Carrier name',
            },
            {
                name: 'agent_name',
                type: 'string',
                displayName: 'Agent name',
            },
            {
                name: 'customer_name',
                type: 'string',
                displayName: 'Customer name',
            },
            {
                name: 'product_type',
                type: 'string',
                displayName: 'Product type',
            },
            {
                name: 'product_name',
                type: 'string',
                displayName: 'Product name',
            },
            {
                name: 'writing_carrier_name',
                type: 'string',
                displayName: 'Writing carrier name',
            },
        ],
        dataFields: [
            {
                name: 'amount_paid',
                type: 'obj',
                lookupKey: 'amount_paid.amount_paid',
            },
            {
                name: 'aggregate_premiums',
                type: 'obj',
                lookupKey: 'aggregatePremiums.aggregatePremiums',
            },
        ],
    },
};
const widgetGroups = ['box', 'chart-donut', 'chart-line', 'chart-bar', 'table'];
const filterByDateOptions = [
    {
        id: 'effective_date',
        label: 'Effective date',
    },
    {
        id: 'commission_processing_date',
        label: 'Commission processing date',
    },
];
const widgetGroupsIcon = {
    box: _jsx(Inventory2Outlined, {}),
    'chart-donut': _jsx(DonutLargeOutlined, {}),
    'chart-line': _jsx(ShowChartOutlined, {}),
    'chart-bar': _jsx(BarChartOutlined, {}),
    table: _jsx(TableChartOutlined, {}),
};
const calculationValues = ['Sum', 'Count', 'Aggregate', 'Average'];
const WidgetCreator = ({ widgetOnEdit, createWidget, setWidgetModel, isEditingMode, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    const { data: _fieldsData } = API.getBasicQuery(`fields`);
    if (_fieldsData) {
        _fieldsData.forEach((field) => {
            Object.keys(filterFieldsByDataSource).forEach((dataSourceKey) => {
                const dataSource = filterFieldsByDataSource[dataSourceKey];
                dataSource.groupFields.forEach((groupField) => {
                    if (groupField.name === field.key) {
                        groupField.fieldMatcherType = field.type;
                    }
                });
                dataSource.dataFields.forEach((dataField) => {
                    if (dataField.name === field.key) {
                        dataField.fieldMatcherType = field.type;
                    }
                });
            });
        });
    }
    const addSelector = () => {
        setDataFieldsAggregators([
            ...dataFieldsAggregators,
            {
                id: dataFieldsAggregators.length + 1,
                field: '',
                aggregation_method: '',
            },
        ]);
    };
    useEffect(() => {
        if (widgetOnEdit) {
            setSelectedDataSource(widgetOnEdit.dataSource || '');
            setSelectedCustomCode(widgetOnEdit.customCode || '');
            setSelectedColumnValue(widgetOnEdit.column || '');
            setColumnLimit(widgetOnEdit.columnLimit || null);
            setSelectedWidgetType(widgetOnEdit.type || '');
            setSelectedGroupByValue(widgetOnEdit.groupBy || '');
            setSimilarityGroupBy(widgetOnEdit.similarityGroupBy || false);
            setSelectedDataFieldValue(widgetOnEdit.dataField || []);
            setDataFieldFilters(widgetOnEdit.dataFieldFilters || []);
            setDataFieldsAggregators(widgetOnEdit.aggregationSelectors || []);
            setDataFieldsExpression(widgetOnEdit.dataFieldsExpression || null);
            setGroupByFilters(widgetOnEdit.groupByFilters || []);
            setSelectedCalculationValue(widgetOnEdit.calculation || '');
            setSelectedResultFormatter(widgetOnEdit.resultFormatter || '');
            setSelectedShared(widgetOnEdit.shared || false);
            setApplyAlevoExcludedAgents(widgetOnEdit.applyAlevoExcludedAgents || false);
            setWidgetName(widgetOnEdit.name || '');
        }
        else {
            setSelectedDataSource('');
            setSelectedCustomCode('');
            setSelectedColumnValue('');
            setColumnLimit(null);
            setSelectedWidgetType('');
            setSelectedGroupByValue('');
            setSimilarityGroupBy(false);
            setSelectedDataFieldValue([]);
            setDataFieldFilters([]);
            setDataFieldsAggregators([]);
            setDataFieldsExpression(null);
            setGroupByFilters([]);
            setSelectedCalculationValue('');
            setSelectedResultFormatter('');
            setSelectedShared(false);
            setApplyAlevoExcludedAgents(false);
            setWidgetName('');
        }
    }, [widgetOnEdit]);
    const removeSelector = (id) => {
        setDataFieldsAggregators(dataFieldsAggregators.filter((selector) => selector.id !== id));
    };
    const updateSelector = (id, updatedField) => {
        setDataFieldsAggregators(dataFieldsAggregators.map((selector) => selector.id === id ? Object.assign(Object.assign({}, selector), updatedField) : selector));
    };
    const [selectedDataSource, setSelectedDataSource] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.dataSource) || '');
    const [enableCustomCode, setEnableCustomCode] = useState(false);
    const [selectedCustomCode, setSelectedCustomCode] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.customCode) || '');
    const [selectedWidgetType, setSelectedWidgetType] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.type) || '');
    const [selectedGroupByValue, setSelectedGroupByValue] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.groupBy) || '');
    const [filterByDate, setFilterByDate] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.filterByDate) || '');
    const [selectedColumnValue, setSelectedColumnValue] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.column) || '');
    const [columnLimit, setColumnLimit] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.columnLimit) || null);
    const [selectedDataFieldValue, setSelectedDataFieldValue] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.dataField) || []);
    const [dataFieldFilters, setDataFieldFilters] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.dataFieldFilters) || []);
    const [dataFieldsAggregators, setDataFieldsAggregators] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.aggregationSelectors) || []);
    const [dataFieldsExpression, setDataFieldsExpression] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.dataFieldsExpression) || null);
    const [groupByFilters, setGroupByFilters] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.groupByFilters) || []);
    const [selectedCalculationValue, setSelectedCalculationValue] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.calculation) || '');
    const [selectedResultFormatter, setSelectedResultFormatter] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.resultFormatter) || '');
    const { showSnackbar } = useSnackbar();
    const [selectedShared, setSelectedShared] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.shared) || false);
    const [similarityGroupBy, setSimilarityGroupBy] = useState(false);
    const [applyAlevoExcludedAgents, setApplyAlevoExcludedAgents] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.applyAlevoExcludedAgents) || false);
    const { selectedAccount } = useAccountStore();
    const [widgetName, setWidgetName] = useState((widgetOnEdit === null || widgetOnEdit === void 0 ? void 0 : widgetOnEdit.name) || '');
    const [previewData, setPreviewData] = useState(null);
    const { setLoadingConfig } = useContext(LoadingContext);
    const { role: [role], } = useContext(UIStateContext);
    const isFintaryAdmin = role === 'admin';
    const isAlevoAccount = (selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountName) === 'Alevo';
    const handleCreateWidget = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!widgetName) {
            return;
        }
        if (!selectedDataSource) {
            return;
        }
        if (!selectedWidgetType) {
            return;
        }
        if (!selectedGroupByValue &&
            dataFieldsAggregators.length == 0 &&
            !selectedCalculationValue &&
            !selectedCustomCode &&
            !selectedDataFieldValue.length &&
            !dataFieldFilters.length &&
            !groupByFilters.length) {
            return;
        }
        setLoadingConfig({
            loading: true,
            message: `${isEditingMode ? 'Editing' : 'Creating'} widget...`,
        });
        const _filterByDate = selectedDataSource === 'report' ? filterByDate : undefined;
        try {
            const result = yield createWidget({
                name: widgetName,
                dataSource: selectedDataSource,
                filterByDate: _filterByDate,
                type: selectedWidgetType,
                groupBy: selectedGroupByValue,
                similarityGroupBy: similarityGroupBy,
                groupByFilters: groupByFilters,
                column: selectedColumnValue,
                columnLimit: columnLimit,
                dataField: selectedDataFieldValue,
                dataFieldFilters: dataFieldFilters,
                applyAlevoExcludedAgents: applyAlevoExcludedAgents,
                calculation: selectedCalculationValue,
                shared: selectedShared,
                resultFormatter: selectedResultFormatter,
                aggregationSelectors: dataFieldsAggregators,
                dataFieldsExpression: dataFieldsExpression,
                customCode: selectedCustomCode,
            });
            setPreviewData(result);
            setWidgetModel({
                id: result.id,
                name: widgetName,
                displayName: result.displayName,
                widgetGroup: selectedWidgetType.toUpperCase(),
                value: result.value,
                type: selectedWidgetType,
                spec: {
                    dataSource: selectedDataSource,
                    filterByDate: _filterByDate,
                    name: widgetName,
                    type: selectedWidgetType,
                    groupBy: selectedGroupByValue,
                    similarityGroupBy: similarityGroupBy,
                    column: selectedColumnValue,
                    columnLimit: columnLimit,
                    groupByFilters: groupByFilters,
                    dataFieldFilters: dataFieldFilters,
                    applyAlevoExcludedAgents: applyAlevoExcludedAgents,
                    dataField: selectedDataFieldValue,
                    calculation: selectedCalculationValue,
                    resultFormatter: selectedResultFormatter,
                    customCode: selectedCustomCode,
                    aggregationSelectors: dataFieldsAggregators,
                    dataFieldsExpression: dataFieldsExpression,
                    shared: selectedShared,
                },
            });
        }
        catch (error) {
            showSnackbar(`Error creating widget: ${error}`, 'error');
        }
        setLoadingConfig({
            loading: false,
        });
    });
    const selectWidget = (data) => {
        switch (data.widgetGroup) {
            case WidgetGroup.BOX:
                return BoxWidget(data);
            case WidgetGroup.TABLE:
                return TableWidget(data);
            case WidgetGroup.CHART:
                return _jsx(ChartWidget, { data: data.value });
            default:
                return null;
        }
    };
    const commonInputStyle = {
        width: '100%',
        '& .MuiInputBase-root': {
            height: '40px !important',
        },
    };
    const renderWidget = (data) => (_jsx(WidgetWrapper, { displayName: data.displayName, children: selectWidget(data) }));
    return (_jsxs(Box, { sx: { padding: 2 }, children: [_jsx(Typography, { variant: "h6", children: isEditingMode ? 'Edit widget' : 'Create a new widget' }), _jsx(FormControl, { fullWidth: true, margin: "normal", children: _jsx(TextField, { sx: { flex: 1 }, value: widgetName, label: "Widget name", onChange: (e) => {
                        setWidgetName(e.target.value);
                    } }) }), _jsx(FormControl, { fullWidth: true, margin: "normal", children: _jsx(EnhancedSelect, { label: "Data source", sx: commonInputStyle, options: dataSources.map((source) => ({
                        id: source,
                        label: (_jsxs("div", { className: "flex items-center gap-2", children: [dataSourceIcon[source], ' ', _jsx("span", { children: dataSourceDisplayNames[source] })] })),
                    })), value: {
                        id: selectedDataSource,
                        label: dataSourceDisplayNames[selectedDataSource] || '',
                    }, onChange: (value) => {
                        setSelectedDataSource(value.id);
                        handleCreateWidget();
                    } }) }), selectedDataSource === 'report' && (_jsx(FormControl, { fullWidth: true, margin: "normal", children: _jsx(EnhancedSelect, { label: "Filter by date", sx: commonInputStyle, options: filterByDateOptions, value: filterByDateOptions.find((item) => item.id === filterByDate), onChange: (value) => {
                        setFilterByDate(value.id);
                    } }) })), _jsx(FormControl, { fullWidth: true, margin: "normal", children: _jsx(EnhancedSelect, { label: "Widget type", sx: commonInputStyle, options: widgetGroups.map((type) => ({
                        id: type,
                        label: (_jsxs("div", { className: "flex items-center gap-2", children: [widgetGroupsIcon[type], " ", _jsx("span", { children: type })] })),
                    })), value: {
                        id: selectedWidgetType,
                        label: selectedWidgetType,
                    }, onChange: (value) => {
                        setSelectedWidgetType(value.id);
                        handleCreateWidget();
                    } }) }), isFintaryAdmin && enableCustomCode && (_jsx(FormControl, { fullWidth: true, margin: "normal", children: _jsxs(Accordion, { children: [_jsx(AccordionSummary, { expandIcon: _jsx(ExpandMoreIcon, {}), "aria-controls": "panel2-content", id: "panel2-header", children: _jsx("label", { children: "Custom code" }) }), _jsx(AccordionDetails, { children: _jsx(CodeMirror, { title: "Custom code", height: "350px", value: selectedCustomCode, width: "100%", onChange: (code) => {
                                    setSelectedCustomCode(code);
                                } }) })] }) })), _jsx(FormControl, { fullWidth: true, margin: "normal", children: _jsxs(Box, { sx: { display: 'flex' }, children: [_jsx(EnhancedSelect, { label: "Group by field", sx: commonInputStyle, options: ((_a = filterFieldsByDataSource[selectedDataSource]) === null || _a === void 0 ? void 0 : _a.groupFields.map((field) => ({
                                id: field.name,
                                label: field.displayName,
                            }))) || [], value: {
                                id: selectedGroupByValue,
                                label: ((_c = (_b = filterFieldsByDataSource[selectedDataSource]) === null || _b === void 0 ? void 0 : _b.groupFields.find((f) => f.name === selectedGroupByValue)) === null || _c === void 0 ? void 0 : _c.displayName) || '',
                            }, onChange: (value) => {
                                setSelectedGroupByValue(value.id);
                            } }), _jsxs(Box, { className: "ml-1 flex flex-col items-center", children: [_jsx(Box, { className: "text-[12px] whitespace-nowrap text-[#00000099] font-[Roboto]", children: "Similarity group by" }), _jsx(Checkbox, { className: "p-0", checked: similarityGroupBy, onChange: () => setSimilarityGroupBy(!similarityGroupBy) })] })] }) }), _jsx(FormControl, { fullWidth: true, margin: "normal", children: _jsxs(Box, { children: [_jsx(EnhancedSelect, { label: "Columns", sx: { marginRight: 1 }, options: ((_d = filterFieldsByDataSource[selectedDataSource]) === null || _d === void 0 ? void 0 : _d.groupFields.map((field) => ({
                                id: field.name,
                                label: field.displayName,
                            }))) || [], value: {
                                id: selectedColumnValue,
                                label: ((_f = (_e = filterFieldsByDataSource[selectedDataSource]) === null || _e === void 0 ? void 0 : _e.groupFields.find((f) => f.name === selectedColumnValue)) === null || _f === void 0 ? void 0 : _f.displayName) || '',
                            }, onChange: (value) => {
                                setSelectedColumnValue(value.id);
                            } }), _jsx(EnhancedSelect, { label: "Column limit", options: [
                                { id: 5, label: 5 },
                                { id: 10, label: 10 },
                                { id: 20, label: 20 },
                                { id: null, label: 'Unspecified' },
                            ], value: {
                                id: columnLimit,
                                label: columnLimit,
                            }, onChange: (value) => {
                                setColumnLimit(value.id);
                            } })] }) }), ((_g = filterFieldsByDataSource[selectedDataSource]) === null || _g === void 0 ? void 0 : _g.groupFields) && (_jsxs(Box, { children: [_jsx("label", { children: "Group by filter" }), _jsx(FieldMatcher, { fields: (_h = filterFieldsByDataSource[selectedDataSource]) === null || _h === void 0 ? void 0 : _h.groupFields.map((field) => {
                            return {
                                id: field.name,
                                label: field.displayName,
                                fieldMatcherType: field.fieldMatcherType,
                            };
                        }), hideUsePolicyData: true, value: groupByFilters, setValue: setGroupByFilters })] })), ((_j = filterFieldsByDataSource[selectedDataSource]) === null || _j === void 0 ? void 0 : _j.dataFields) && (_jsxs(FormControl, { fullWidth: true, margin: "normal", children: [_jsx("label", { children: "Data fields" }), _jsx(FieldAggregationManager, { fields: (_k = filterFieldsByDataSource[selectedDataSource]) === null || _k === void 0 ? void 0 : _k.dataFields, selectors: dataFieldsAggregators, addSelector: addSelector, removeSelector: removeSelector, updateSelector: updateSelector })] })), dataFieldsAggregators.length > 1 && (_jsxs(FormControl, { fullWidth: true, margin: "normal", children: [_jsx("label", { children: "Fields calculation" }), _jsx(FieldsCalculatorExpressionBuilder, { fields: dataFieldsAggregators.map((selector) => selector.field), value: dataFieldsExpression, setValue: setDataFieldsExpression })] })), ((_l = filterFieldsByDataSource[selectedDataSource]) === null || _l === void 0 ? void 0 : _l.dataFields) && (_jsxs(Box, { children: [_jsx("label", { children: "Data filters" }), _jsx(FieldMatcher, { fields: (_m = filterFieldsByDataSource[selectedDataSource]) === null || _m === void 0 ? void 0 : _m.dataFields.map((field) => {
                            return {
                                id: field.name,
                                label: field.name,
                                fieldMatcherType: field.fieldMatcherType,
                            };
                        }), hideUsePolicyData: true, value: dataFieldFilters, setValue: setDataFieldFilters })] })), dataFieldsAggregators.length == 0 && (_jsx(FormControl, { fullWidth: true, margin: "normal", children: _jsx(EnhancedSelect, { label: "Aggregation method", sx: commonInputStyle, options: calculationValues.map((value) => ({
                        id: value,
                        label: value,
                    })), value: {
                        id: selectedCalculationValue,
                        label: selectedCalculationValue,
                    }, onChange: (value) => {
                        setSelectedCalculationValue(value.id);
                        handleCreateWidget();
                    } }) })), _jsx(FormControl, { fullWidth: true, margin: "normal", children: _jsx(EnhancedSelect, { label: "Result formatter", sx: commonInputStyle, options: [
                        { id: '', label: 'None' },
                        { id: 'number', label: 'Number' },
                        { id: 'currency', label: 'Currency' },
                        { id: 'percentage', label: 'Percentage' },
                    ], value: {
                        id: selectedResultFormatter,
                        label: selectedResultFormatter &&
                            selectedResultFormatter.charAt(0).toUpperCase() +
                                selectedResultFormatter.slice(1),
                    }, onChange: (value) => {
                        setSelectedResultFormatter(value.id);
                        handleCreateWidget();
                    } }) }), _jsxs(FormControl, { fullWidth: true, margin: "normal", children: [isAlevoAccount && (_jsxs(Grid, { container: true, spacing: 2, sx: toggleStyle, children: [_jsx(Grid, { item: true, children: _jsx(Typography, { children: "Excluded agents" }) }), _jsx(Grid, { item: true, children: _jsx(Switch, { checked: applyAlevoExcludedAgents, onChange: () => setApplyAlevoExcludedAgents(!applyAlevoExcludedAgents) }) })] })), isFintaryAdmin && (_jsxs(_Fragment, { children: [_jsxs(Grid, { container: true, spacing: 2, sx: toggleStyle, children: [_jsx(Grid, { item: true, children: _jsxs(Typography, { children: ["Global", ' ', _jsx("span", { style: {
                                                        color: 'transparent',
                                                        textShadow: '0 0 0 #e8e8e8',
                                                    }, children: "\uD83D\uDD12" })] }) }), _jsx(Grid, { item: true, children: _jsx(Switch, { checked: selectedShared, onChange: () => setSelectedShared(!selectedShared) }) })] }), _jsxs(Grid, { container: true, spacing: 2, sx: toggleStyle, children: [_jsx(Grid, { item: true, children: _jsxs(Typography, { children: ["Custom code", ' ', _jsx("span", { style: {
                                                        color: 'transparent',
                                                        textShadow: '0 0 0 #e8e8e8',
                                                    }, children: "\uD83D\uDD12" })] }) }), _jsx(Grid, { item: true, children: _jsx(Switch, { checked: enableCustomCode, onChange: () => setEnableCustomCode(!enableCustomCode) }) })] })] }))] }), _jsx("hr", {}), _jsx(Box, { mt: 2, display: 'flex', children: _jsx(Button, { variant: "contained", color: "primary", onClick: handleCreateWidget, children: "Preview" }) }), _jsx(Box, { mt: 4, mb: 4, p: 2, borderColor: "grey.400", height: 380, children: previewData && renderWidget(previewData) })] }));
};
export default WidgetCreator;
