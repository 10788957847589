var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import DocumentProfileView from '@/components/admin/AdminDocumentsView/DocumentProfileView';
import DocumentsView from '@/components/admin/AdminDocumentsView/DocumentsView';
import ProcessorsView from '@/components/admin/AdminDocumentsView/ProcessorsView';
import PromptsView from '@/components/admin/AdminDocumentsView/PromptsView';
const TabPanel = (_a) => {
    var { children, value, index } = _a, other = __rest(_a, ["children", "value", "index"]);
    return (_jsx("div", Object.assign({ role: "tabpanel", hidden: value !== index, id: `tabpanel-${index}` }, other, { children: value === index && (_jsx(Box, { sx: { pt: 2 }, children: _jsx(Typography, { component: "div", children: children }) })) })));
};
const SettingsView = () => {
    const tabs = [
        {
            label: 'Documents',
            path: 'documents?qc=new',
            component: _jsx(DocumentsView, { variant: "tabbed" }),
        },
        {
            label: 'Profiles',
            path: 'profiles',
            component: _jsx(DocumentProfileView, { variant: "tabbed" }),
        },
        {
            label: 'Processors',
            path: 'processors',
            component: _jsx(ProcessorsView, { variant: "tabbed" }),
        },
        {
            label: 'Prompts',
            path: 'prompts',
            component: _jsx(PromptsView, { variant: "tabbed" }),
        },
    ];
    const { tab = 'profiles' } = useParams();
    const tabPaths = tabs.map((tab) => tab.path);
    const currentTabIndex = tabPaths.indexOf(tab);
    const routedTab = currentTabIndex >= 0 ? currentTabIndex : 0;
    const [value, setValue] = useState(routedTab);
    const navigate = useNavigate();
    const handleChange = (event, newValue) => {
        navigate(`/admin/documents/${tabs[newValue].path}`);
        setValue(newValue);
    };
    useEffect(() => {
        const currentTabIndex = tabPaths.indexOf(tab);
        if (currentTabIndex >= 0 && currentTabIndex !== value) {
            setValue(currentTabIndex);
        }
    }, [tab, tabPaths, routedTab, value]);
    return (_jsxs(Box, { sx: { width: 1, overflowY: 'scroll' }, children: [_jsx(Box, { sx: { padding: '15px 25px 10px' }, children: _jsx(Typography, { variant: "h5", children: "Documents" }) }), _jsxs(Box, { sx: { width: '100%' }, children: [_jsx(Box, { sx: { mx: 1, mb: 1 }, children: _jsx(Tabs, { value: value, onChange: handleChange, sx: {
                                minHeight: 32,
                                height: 32,
                                '.MuiTabs-flexContainer': { minHeight: 32 },
                            }, children: tabs.map((tab) => (_jsx(Tab, { label: tab.label, sx: {
                                    minHeight: 24,
                                    height: 24,
                                    py: 0.5,
                                } }, tab.label))) }) }), _jsx(Box, { sx: { mt: { xs: -3, md: -9 } }, children: tabs.map((tab) => (_jsx(TabPanel, { value: value, index: tabs.indexOf(tab), children: tab.component }, tab.label))) })] })] }));
};
export default SettingsView;
