const findTextLineIndex = (json, str, exact = false) => {
    var _a;
    const txt = [];
    (_a = json.Lines) === null || _a === void 0 ? void 0 : _a.find((lineItem, index) => {
        const _idx = lineItem.LinesArray.findIndex((line) => exact ? line.Line === str : line.Line.includes(str));
        if (_idx > -1) {
            txt.push(index, _idx);
        }
        return _idx > -1;
    });
    return txt;
};
export const desc_findTextLineIndex = {
    name: 'libs.tools.findTextLineIndex(json, str, exact)',
    description: 'Finds the indices of the first line that contains or exactly matches the specified text.',
    arguments: {
        json: '[Object] JSON object containing Lines array with text content',
        str: '[String] Text string to search for',
        exact: '[Boolean] Optional. If true, performs exact match instead of partial match',
    },
    returns: '[Array<number>] Array containing [lineIndex, arrayIndex] of first match',
    examples: 'findTextLineIndex(json, "text")\n// → [0, 1]\n\nfindTextLineIndex(json, "text", true)\n// → [0, 1]\n',
};
export default findTextLineIndex;
