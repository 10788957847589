const findTextLineAllIndex = (json, searchText, exact = false) => {
    const allIndices = [];
    for (let i = 0; i < json.Lines.length; i++) {
        const linesArray = json.Lines[i].LinesArray;
        for (let j = 0; j < linesArray.length; j++) {
            const match = exact
                ? linesArray[j].Line === searchText
                : linesArray[j].Line.includes(searchText);
            if (match) {
                allIndices.push([i, j]);
            }
        }
    }
    return allIndices;
};
export const desc_findTextLineAllIndex = {
    name: 'libs.tools.findTextLineAllIndex(json, searchText, exact)',
    description: 'Finds indices of all lines that contain or exactly match the specified text.',
    arguments: {
        json: '[Object] JSON object containing Lines array with text content',
        searchText: '[String] Text to search for',
        exact: '[Boolean] Optional. If true, performs exact match instead of partial match',
    },
    returns: '[Array<Array<number>>] Array of index pairs [lineIndex, arrayIndex] for each match',
    examples: 'findTextLineAllIndex(json, "text")\n// → [[0, 1], [1, 2]]\n\nfindTextLineAllIndex(json, "text", true)\n// → [[0, 1], [1, 2]]\n',
};
export default findTextLineAllIndex;
