const findTextLineByIndex = (json, indexArr) => {
    return json.Lines[indexArr[0]].LinesArray[indexArr[1]].Line;
};
export const desc_findTextLineByIndex = {
    name: 'libs.tools.findTextLineByIndex(json, indexArr)',
    description: 'Retrieves a line from the JSON object at the specified indices.',
    arguments: {
        json: '[Object] JSON object containing Lines array with text content',
        indexArr: '[Array<number>] Array containing [lineIndex, arrayIndex]',
    },
    returns: '[String] Text line at the specified indices',
    examples: 'findTextLineByIndex(json, [0, 1])\n// → "text"\n',
};
export default findTextLineByIndex;
