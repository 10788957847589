import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Close, Close as CloseIcon } from '@mui/icons-material';
import { Box, Button, Checkbox, Chip, Divider, FormControl, FormControlLabel, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, Typography, } from '@mui/material';
import { AccountIds } from 'common/constants';
import Formatter from 'common/Formatter';
import { CustomMethodConditionType, CustomMethodConditionTypeLabel, CustomMethodOperatorOptions, } from 'common/interface';
import { nanoid } from 'nanoid';
import { useMemo, useState } from 'react';
import validator from 'validator';
import { FilterSelect } from '@/common';
import BasicDateRangePicker from '@/common/BasicDateRangePicker';
import API from '@/services/API';
import { useAccountStore } from '@/store';
const applyFunc = (func, arg) => (typeof func === 'function' ? func(arg) : arg);
export const CONDITION_TYPE_OPTIONS = [
    {
        value: CustomMethodConditionType.COMPENSATION_TYPE,
        label: CustomMethodConditionTypeLabel[CustomMethodConditionType.COMPENSATION_TYPE],
    },
    {
        value: CustomMethodConditionType.PAYEE_LEVEL_RATE_DIFFERENCE_THRESHOLD,
        label: CustomMethodConditionTypeLabel[CustomMethodConditionType.PAYEE_LEVEL_RATE_DIFFERENCE_THRESHOLD],
    },
];
const CompProfilesAdd = ({ data, field, setter, dynamicSelects, type = '', }) => {
    var _a, _b;
    const { selectedAccount } = useAccountStore();
    const [query, setQuery] = useState('');
    const [selectedProfiles, setSelectedProfiles] = useState([]);
    const [showInactiveAgents, setShowInactiveAgents] = useState(false);
    const { data: _contacts } = API.getBasicQuery('contacts', `is_dynamic_select=true&show_inactive=${showInactiveAgents}`);
    const contacts = useMemo(() => {
        var _a;
        return ((_a = _contacts === null || _contacts === void 0 ? void 0 : _contacts.data) !== null && _a !== void 0 ? _a : [])
            .map((contact) => ({
            value: contact.id,
            label: Formatter.contact(contact, {
                incl_email: true,
                account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
            }),
        }))
            .sort((a, b) => { var _a; return (_a = a.label) === null || _a === void 0 ? void 0 : _a.localeCompare(b.label); });
    }, [_contacts === null || _contacts === void 0 ? void 0 : _contacts.data, selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId]);
    let profilesWithDates;
    let keyToUse;
    let idToUse;
    if (type === 'set') {
        profilesWithDates =
            (_a = data === null || data === void 0 ? void 0 : data.contacts_agent_commission_schedule_profiles_sets) !== null && _a !== void 0 ? _a : [];
        keyToUse = 'contacts_agent_commission_schedule_profiles_sets';
        idToUse = 'agent_commission_schedule_profile_set_id';
    }
    else {
        profilesWithDates = (_b = data === null || data === void 0 ? void 0 : data.contacts_agent_commission_schedule_profiles) !== null && _b !== void 0 ? _b : [];
        keyToUse = 'contacts_agent_commission_schedule_profiles';
        idToUse = 'agent_commission_schedule_profile_id';
    }
    profilesWithDates.forEach((profileWithDates) => {
        if (!profileWithDates[idToUse].toString().includes('::'))
            profileWithDates[idToUse] =
                profileWithDates[idToUse] + '::' + profileWithDates.str_id;
        if (!profileWithDates.config) {
            profileWithDates.config = [
                {
                    method: profileWithDates.method,
                    rate: profileWithDates.rate,
                    multipler: profileWithDates.multiplier,
                    payee_id: profileWithDates.payee_id,
                },
            ];
        }
    });
    const handleSelectAll = (event) => {
        event.stopPropagation();
        const filteredOptions = filterOptions(dynamicSelects, query);
        setSelectedProfiles(filteredOptions.map((option) => option.id));
    };
    const handleAddSelected = () => {
        var _a;
        const newProfiles = selectedProfiles.map((newId) => ({
            agent_commission_schedule_profile: dynamicSelects.find((item) => item.id === newId),
            [idToUse]: newId + '::' + nanoid(),
            start_date: null,
            end_date: null,
            multiplier: '100',
            method: '',
            rate: '',
        }));
        setter(Object.assign(Object.assign({}, data), { [keyToUse]: [...((_a = data[keyToUse]) !== null && _a !== void 0 ? _a : []), ...newProfiles] }));
        setSelectedProfiles([]);
    };
    const filterOptions = (options = [], query) => {
        const trimmedQuery = query.trim().toLowerCase();
        return options.filter((item) => {
            var _a, _b;
            const itemName = (_b = (_a = item === null || item === void 0 ? void 0 : item.name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) !== null && _b !== void 0 ? _b : '';
            if (trimmedQuery.startsWith('"') && trimmedQuery.endsWith('"')) {
                const exactMatchRegex = new RegExp(`\\b${trimmedQuery.slice(1, -1)}\\b`);
                return exactMatchRegex.test(itemName);
            }
            return itemName.includes(trimmedQuery);
        });
    };
    const renderCustomOption = () => {
        return ((selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId) === AccountIds.TRANSGLOBAL && (_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: showInactiveAgents, onChange: (e) => {
                    setShowInactiveAgents(e.target.checked);
                } }), label: "Show inactive agents", sx: { ml: 1, minWidth: 270 }, onClick: () => {
                setShowInactiveAgents(!showInactiveAgents);
            } })));
    };
    return (_jsxs(Box, { children: [_jsx(Typography, { variant: "subtitle2", children: field.label }), _jsx(Box, { sx: {
                    width: '100%',
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                }, children: _jsx(Box, { sx: {
                        mt: 0.5,
                        p: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        borderStyle: 'solid',
                        borderColor: 'silver',
                        borderWidth: 1,
                        borderRadius: 4,
                        width: '100%',
                        backgroundColor: '#2196f308',
                    }, children: _jsxs(Box, { sx: { display: 'flex', flexWrap: 'wrap' }, children: [profilesWithDates.map((profileWithDates) => {
                                var _a, _b, _c, _d, _e, _f;
                                return (_jsxs(Box, { sx: {
                                        m: 0.5,
                                        p: 1,
                                        borderStyle: 'solid',
                                        borderColor: 'silver',
                                        borderWidth: 1,
                                        borderRadius: 4,
                                        display: 'inline-block',
                                        minWidth: 280,
                                        maxWidth: 380,
                                        boxSizing: 'border-box',
                                        backgroundColor: '#2196f30a',
                                    }, children: [_jsxs(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", position: "relative", sx: { ml: 0.5 }, children: [_jsx(Typography, { variant: "body2", children: (_b = (_a = profileWithDates.agent_commission_schedule_profile) === null || _a === void 0 ? void 0 : _a.name) !== null && _b !== void 0 ? _b : (_c = profileWithDates.agent_commission_schedule_profiles_sets) === null || _c === void 0 ? void 0 : _c.name }), _jsx(IconButton, { onClick: () => {
                                                        setter(Object.assign(Object.assign({}, data), { [keyToUse]: data[keyToUse].filter((item) => item[idToUse] !== profileWithDates[idToUse]) }));
                                                    }, children: _jsx(CloseIcon, {}) })] }), _jsx(Box, { children: _jsx(BasicDateRangePicker, { range: {
                                                    startDate: profileWithDates.start_date,
                                                    startDateLabel: 'Start date',
                                                    endDate: profileWithDates.end_date,
                                                    endDateLabel: 'End date',
                                                }, onChange: ({ startDate, endDate }) => {
                                                    setter(Object.assign(Object.assign({}, data), { [keyToUse]: data[keyToUse].map((item) => item[idToUse] === profileWithDates[idToUse]
                                                            ? Object.assign(Object.assign({}, item), { start_date: startDate, end_date: endDate }) : item) }));
                                                } }) }), _jsx(Box, { children: _jsx(Box, { children: _jsx(TextField, { label: "Multiplier", value: (_d = profileWithDates.multiplier) !== null && _d !== void 0 ? _d : '100', sx: { mt: 1, width: '100%' }, InputProps: {
                                                        endAdornment: (_jsx(InputAdornment, { position: "end", sx: { ml: 0 }, children: "%" })),
                                                    }, onChange: (e) => {
                                                        var _a;
                                                        setter(Object.assign(Object.assign({}, data), { [keyToUse]: (_a = data[keyToUse]) === null || _a === void 0 ? void 0 : _a.map((item) => item[idToUse] === profileWithDates[idToUse]
                                                                ? Object.assign(Object.assign({}, item), { multiplier: e.target.value }) : item) }));
                                                    }, error: profileWithDates.multiplier &&
                                                        !validator.isFloat(profileWithDates.multiplier.trim(), {
                                                            min: 0,
                                                            max: 1000,
                                                        }), helperText: profileWithDates.multiplier &&
                                                        !validator.isFloat(profileWithDates.multiplier.trim(), {
                                                            min: 0,
                                                            max: 1000,
                                                        })
                                                        ? 'Must be a number between 0 and 1000'
                                                        : '' }) }) }), _jsxs(Box, { sx: { mt: 1 }, children: [((_e = profileWithDates === null || profileWithDates === void 0 ? void 0 : profileWithDates.config) === null || _e === void 0 ? void 0 : _e.length) > 0 && _jsx(Divider, {}), (_f = profileWithDates === null || profileWithDates === void 0 ? void 0 : profileWithDates.config) === null || _f === void 0 ? void 0 : _f.map((config, index) => {
                                                    var _a, _b, _c, _d, _e;
                                                    return (_jsxs(Box, { sx: {
                                                            mb: 1,
                                                            display: 'flex',
                                                            boxSizing: 'border-box',
                                                            flexDirection: 'column',
                                                            width: '100%',
                                                            // BackgroundColor: '#2196f308',
                                                        }, children: [_jsxs(Box, { children: [_jsxs(Box, { sx: { display: 'flex', alignItems: 'center', mt: 1 }, children: [_jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { children: "Custom method" }), _jsxs(Select, { value: (_a = config.method) !== null && _a !== void 0 ? _a : '', label: "Custom method", onChange: (e) => {
                                                                                            const items = profileWithDates.config;
                                                                                            items.map((item, idx) => {
                                                                                                if (idx === index) {
                                                                                                    item.method = e.target.value;
                                                                                                }
                                                                                            });
                                                                                            setter(Object.assign(Object.assign({}, data), { [keyToUse]: data[keyToUse].map((item) => item[idToUse] === profileWithDates[idToUse]
                                                                                                    ? Object.assign(Object.assign({}, item), { config: items }) : item) }));
                                                                                        }, children: [_jsx(MenuItem, { value: "", children: "\u00A0" }), _jsx(MenuItem, { value: "add", children: "Add % bonus to house rate" }), _jsx(MenuItem, { value: "set", children: "Set % fixed total rate" }), _jsx(MenuItem, { value: "fixed", children: "Fixed override" }), _jsx(MenuItem, { value: "bonus", children: "Override bonus" })] })] }), _jsx(Box, { sx: { ml: 0.5 }, children: _jsx(IconButton, { onClick: () => {
                                                                                        const items = profileWithDates.config;
                                                                                        setter(Object.assign(Object.assign({}, data), { [keyToUse]: data[keyToUse].map((item) => item[idToUse] === profileWithDates[idToUse]
                                                                                                ? Object.assign(Object.assign({}, item), { config: items.filter((item, idx) => idx !== index) }) : item) }));
                                                                                    }, children: _jsx(Close, {}) }) })] }), config.method && (_jsxs(_Fragment, { children: [_jsx(Box, { children: _jsx(TextField, { label: "Rate", value: (_b = config.rate) !== null && _b !== void 0 ? _b : '', sx: { mt: 1, width: '100%' }, InputProps: {
                                                                                        endAdornment: (_jsx(InputAdornment, { position: "end", sx: { ml: 0 }, children: "%" })),
                                                                                    }, onChange: (e) => {
                                                                                        const items = profileWithDates.config;
                                                                                        items.map((item, idx) => {
                                                                                            if (idx === index) {
                                                                                                item.rate = e.target.value;
                                                                                            }
                                                                                        });
                                                                                        setter(Object.assign(Object.assign({}, data), { [keyToUse]: data[keyToUse].map((item) => item[idToUse] ===
                                                                                                profileWithDates[idToUse]
                                                                                                ? Object.assign(Object.assign({}, item), { config: items }) : item) }));
                                                                                    }, error: profileWithDates.rate &&
                                                                                        !validator.isFloat(profileWithDates.rate.trim(), {
                                                                                            min: 0,
                                                                                            max: 1000,
                                                                                        }), helperText: profileWithDates.rate &&
                                                                                        !validator.isFloat(profileWithDates.rate.trim(), {
                                                                                            min: 0,
                                                                                            max: 1000,
                                                                                        })
                                                                                        ? 'Must be a number between 0 and 1000'
                                                                                        : '' }) }), ['fixed', 'bonus'].includes(config.method) && (_jsx(Box, { children: _jsx(FormControl, { fullWidth: true, margin: "normal", sx: { mt: 1, mb: 0 }, children: _jsx(FilterSelect, { value: (_c = config.payee_id) !== null && _c !== void 0 ? _c : '', options: contacts, label: "Payee", onChange: (e) => {
                                                                                            const items = profileWithDates.config;
                                                                                            items.map((item, idx) => {
                                                                                                var _a;
                                                                                                if (idx === index) {
                                                                                                    item.payee_id = (_a = e === null || e === void 0 ? void 0 : e.data) === null || _a === void 0 ? void 0 : _a.value;
                                                                                                }
                                                                                            });
                                                                                            setter(Object.assign(Object.assign({}, data), { [keyToUse]: data[keyToUse].map((item) => item[idToUse] ===
                                                                                                    profileWithDates[idToUse]
                                                                                                    ? Object.assign(Object.assign({}, item), { config: items }) : item) }));
                                                                                        }, getOptionLabel: (option, options) => {
                                                                                            var _a, _b;
                                                                                            return typeof option === 'number' &&
                                                                                                Array.isArray(options)
                                                                                                ? ((_b = (_a = options.find((o) => o.value === option)) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : '')
                                                                                                : typeof option === 'object'
                                                                                                    ? option.label
                                                                                                    : option;
                                                                                        }, enablePagination: true, itemsPerPage: 100, renderCustomOption: renderCustomOption, sx: { width: '100%' } }) }) }))] }))] }), ((_d = config.conditions) === null || _d === void 0 ? void 0 : _d.length) > 0 && (_jsx(Box, { sx: { mt: 0.25, mx: 1 }, children: _jsx(Typography, { variant: "caption", color: "textSecondary", children: "Custom method conditions" }) })), (_e = config.conditions) === null || _e === void 0 ? void 0 : _e.map((condition, conditionIndex) => {
                                                                var _a, _b, _c, _d, _e;
                                                                return (_jsxs(Box, { sx: {
                                                                        display: 'flex',
                                                                        gap: 1,
                                                                        mt: 0.5,
                                                                        mx: 1,
                                                                        alignItems: 'center',
                                                                    }, children: [_jsxs(FormControl, { sx: { width: '50%' }, children: [_jsx(InputLabel, { children: "Condition type" }), _jsx(Select, { value: (_a = condition.type) !== null && _a !== void 0 ? _a : '', label: "Condition type", onChange: (e) => {
                                                                                        setter(Object.assign(Object.assign({}, data), { [keyToUse]: data[keyToUse].map((item) => item[idToUse] === profileWithDates[idToUse]
                                                                                                ? Object.assign(Object.assign({}, item), { config: item.config.map((cfg, idx) => idx === index
                                                                                                        ? Object.assign(Object.assign({}, cfg), { conditions: cfg.conditions.map((cond, cIdx) => cIdx === conditionIndex
                                                                                                                ? Object.assign(Object.assign({}, cond), { type: e.target
                                                                                                                        .value, operator: '', value: '' }) : cond) }) : cfg) }) : item) }));
                                                                                    }, children: CONDITION_TYPE_OPTIONS.map((option) => {
                                                                                        if (option.value ===
                                                                                            CustomMethodConditionType.PAYEE_LEVEL_RATE_DIFFERENCE_THRESHOLD &&
                                                                                            ['set', 'add'].includes(config.method)) {
                                                                                            return null;
                                                                                        }
                                                                                        return (_jsx(MenuItem, { value: option.value, children: option.label }, option.value));
                                                                                    }) })] }), _jsxs(FormControl, { sx: { width: '50%', mx: 1 }, children: [_jsx(InputLabel, { children: "Operator" }), _jsx(Select, { value: (_b = condition.operator) !== null && _b !== void 0 ? _b : '', label: "Operator", onChange: (e) => {
                                                                                        setter(Object.assign(Object.assign({}, data), { [keyToUse]: data[keyToUse].map((item) => item[idToUse] === profileWithDates[idToUse]
                                                                                                ? Object.assign(Object.assign({}, item), { config: item.config.map((cfg, idx) => idx === index
                                                                                                        ? Object.assign(Object.assign({}, cfg), { conditions: cfg.conditions.map((cond, cIdx) => cIdx === conditionIndex
                                                                                                                ? Object.assign(Object.assign({}, cond), { operator: e.target.value }) : cond) }) : cfg) }) : item) }));
                                                                                    }, children: CustomMethodOperatorOptions.map((option) => (_jsx(MenuItem, { value: option.value, children: option.label }, option.value))) }, `operator-${condition.type}-${conditionIndex}`)] }), _jsx(TextField, { sx: { width: '50%' }, label: (_d = (_c = CONDITION_TYPE_OPTIONS.find((opt) => opt.value === condition.type)) === null || _c === void 0 ? void 0 : _c.label) !== null && _d !== void 0 ? _d : 'Amount', value: (_e = condition.value) !== null && _e !== void 0 ? _e : '', onChange: (e) => {
                                                                                setter(Object.assign(Object.assign({}, data), { [keyToUse]: data[keyToUse].map((item) => item[idToUse] === profileWithDates[idToUse]
                                                                                        ? Object.assign(Object.assign({}, item), { config: item.config.map((cfg, idx) => idx === index
                                                                                                ? Object.assign(Object.assign({}, cfg), { conditions: cfg.conditions.map((cond, cIdx) => cIdx === conditionIndex
                                                                                                        ? Object.assign(Object.assign({}, cond), { value: e.target.value }) : cond) }) : cfg) }) : item) }));
                                                                            } }), _jsx(IconButton, { onClick: () => {
                                                                                setter(Object.assign(Object.assign({}, data), { [keyToUse]: data[keyToUse].map((item) => item[idToUse] === profileWithDates[idToUse]
                                                                                        ? Object.assign(Object.assign({}, item), { config: item.config.map((cfg, idx) => idx === index
                                                                                                ? Object.assign(Object.assign({}, cfg), { conditions: cfg.conditions.filter((_, cIdx) => cIdx !== conditionIndex) }) : cfg) }) : item) }));
                                                                            }, size: "small", children: _jsx(Close, {}) })] }, conditionIndex));
                                                            }), _jsx(Button, { onClick: () => {
                                                                    const updatedData = Object.assign(Object.assign({}, data), { [keyToUse]: data[keyToUse].map((item) => {
                                                                            if (item[idToUse] !== profileWithDates[idToUse]) {
                                                                                return item;
                                                                            }
                                                                            return Object.assign(Object.assign({}, item), { config: item.config.map((config, idx) => {
                                                                                    if (idx !== index) {
                                                                                        return config;
                                                                                    }
                                                                                    return Object.assign(Object.assign({}, config), { conditions: [
                                                                                            ...(config.conditions || []),
                                                                                            {
                                                                                                type: null,
                                                                                                value: null,
                                                                                                operator: null,
                                                                                            },
                                                                                        ] });
                                                                                }) });
                                                                        }) });
                                                                    setter(updatedData);
                                                                }, children: "Add condition" })] }, index));
                                                }), _jsx(Box, { sx: { display: 'flex', gap: 1 }, children: _jsx(Button, { onClick: () => setter(Object.assign(Object.assign({}, data), { [keyToUse]: data[keyToUse].map((item) => item[idToUse] === profileWithDates[idToUse]
                                                                ? Object.assign(Object.assign({}, item), { config: [
                                                                        ...(item.config || []),
                                                                        {
                                                                            method: null,
                                                                            rate: null,
                                                                            payee_id: null,
                                                                            multiplier: null,
                                                                            conditions: [],
                                                                        },
                                                                    ] }) : item) })), children: "Add custom method" }) })] })] }, profileWithDates.str_id));
                            }), _jsxs(FormControl, { sx: { m: 0.5, width: 145 }, children: [_jsx(InputLabel, { id: `${field.id}-label`, children: "Add" }), _jsxs(Select, { labelId: `${field.id}-label`, id: field.id, label: "Add", multiple: true, value: selectedProfiles, onClick: (e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }, onClose: handleAddSelected, onChange: (e) => {
                                            setSelectedProfiles(Array.isArray(e.target.value)
                                                ? e.target.value.filter(Boolean)
                                                : e.target.value);
                                        }, sx: {
                                            '& .MuiInputBase-input.Mui-disabled': {
                                                WebkitTextFillColor: '#333',
                                            },
                                        }, renderValue: (selected) => (_jsx(Box, { sx: { display: 'flex', flexWrap: 'wrap', gap: 0.5 }, children: Array.isArray(selected) &&
                                                selected.map((value) => {
                                                    const item = dynamicSelects.find((option) => option.id === value);
                                                    return (_jsx(Chip, { label: item ? item.name : value }, item ? item.str_id : value));
                                                }) })), children: [_jsxs(Box, { sx: {
                                                    mb: 0.5,
                                                    mx: 1,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                }, onKeyDown: (e) => {
                                                    e.stopPropagation();
                                                }, children: [_jsx(TextField, { sx: { flex: 1, mr: 1 }, label: "Search", value: query, onChange: (e) => {
                                                            setQuery(e.target.value);
                                                        } }), _jsx(Button, { variant: "outlined", onClick: (e) => handleSelectAll(e), sx: { mr: 1 }, children: "Select all" }), _jsx(Button, { variant: "outlined", onClick: handleAddSelected, children: "Add selected" })] }), field.nullable && (_jsx(MenuItem, { value: '', children: "\u00A0" }, "null")), filterOptions(dynamicSelects, query).map((option) => (_jsx(MenuItem, { value: option.id, children: option.name }, applyFunc(field.optionValuer, option))))] })] }, field.id)] }) }) }, `${field.id}-box`)] }));
};
export default CompProfilesAdd;
