var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Chip } from '@mui/material';
import { Link } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import LoadingCircle from '@/components/atoms/LoadingCircle';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
const ApproveReportDialog = ({ open, onClose, dataToApprove, loading, approveReportsHandler, showSnackbar, }) => {
    const [conflictsLoading, setConflictsLoading] = useState(false);
    const rejectReportsPoster = API.getMutation('saved_reports/groups/reject-comp-report', 'POST');
    const overallApproved = dataToApprove.every((report) => {
        return !report.includesPaidCommission && !report.hasConflictingReport;
    });
    return (_jsxs(Dialog, { open: open, onClose: onClose, maxWidth: "md", fullWidth: true, children: [_jsx(DialogTitle, { children: "Approve reports" }), _jsx(DialogContent, { children: loading ? (_jsx(LoadingCircle, {})) : (_jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Report name" }), _jsx(TableCell, { children: "Approval status" }), _jsx(TableCell, { children: "Conflicts" }), _jsx(TableCell, { children: "Actions" })] }) }), _jsx(TableBody, { children: dataToApprove.map((report) => {
                                    const canBeApproved = !report.includesPaidCommission &&
                                        !report.hasConflictingReport;
                                    return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: report.reportName }), _jsx(TableCell, { children: Formatter.statusChip(canBeApproved
                                                    ? 'Ready for approval'
                                                    : 'Not ready for approval', {
                                                    mapping: {
                                                        ['Ready for approval']: 'green',
                                                        ['Not ready for approval']: 'red',
                                                    },
                                                }) }), _jsx(TableCell, { children: canBeApproved ? ('None') : (_jsxs(_Fragment, { children: [report.includesPaidCommission && (_jsx("p", { children: "Includes paid commission item" })), report.hasConflictingReport &&
                                                            report.conflictingReportsData.length > 0 && (_jsxs(_Fragment, { children: [_jsx("p", { children: "Conflicts with existing report(s):" }), _jsx("ul", { children: report.conflictingReportsData.map((conflict) => (_jsx(Chip, { label: conflict.reportName, size: "small", clickable: true, component: Link, to: `/reports/${conflict.reportStrId}`, target: "_blank", sx: { m: 0.1 } }, conflict.reportId))) })] }))] })) }), _jsx(TableCell, { children: report.hasConflictingReport &&
                                                    report.conflictingReportsData.length > 0 ? (_jsx(_Fragment, { children: _jsx(LoadingButton, { variant: "contained", color: "primary", size: "small", loading: conflictsLoading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                            const confirmed = window.confirm('Are you sure you want to delete all conflicting reports?');
                                                            if (confirmed) {
                                                                try {
                                                                    setConflictsLoading(true);
                                                                    const params = report.conflictingReportsData.map((conflict) => conflict.reportStrId);
                                                                    const response = yield rejectReportsPoster.mutateAsync({
                                                                        report_str_ids: params,
                                                                    });
                                                                    if (response) {
                                                                        yield approveReportsHandler(true);
                                                                        setConflictsLoading(false);
                                                                    }
                                                                }
                                                                catch (error) {
                                                                    const errorMessage = error instanceof Error
                                                                        ? error.message
                                                                        : JSON.stringify(error);
                                                                    showSnackbar(errorMessage, 'error');
                                                                    setConflictsLoading(false);
                                                                }
                                                            }
                                                        }), children: "Delete all conflicting reports" }) })) : ('n/a') })] }, report.reportId));
                                }) })] }) })) }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: onClose, color: "primary", children: "Cancel" }), _jsx(LoadingButton, { disabled: !overallApproved, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            yield approveReportsHandler(false);
                            onClose();
                        }), loading: loading, variant: "contained", children: "Approve" })] })] }));
};
export default ApproveReportDialog;
