var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, Chip, FormControl, Grid, InputLabel, MenuItem, Select, } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useContext, useEffect, useState } from 'react';
import { UIStateContext } from '@/contexts/UIStateProvider';
import API from '@/services/API';
import Reconciliations from '@/services/Reconciliations';
import Reports from '@/services/Reports';
import Statements from '@/services/Statements';
import { FieldTypes, Roles } from '@/types';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import DataView from '@/components/DataView';
const Pages = [
    {
        label: 'Reconciliations',
        id: 'reconciliation',
        url: '/reconciliation',
    },
    {
        label: 'Commissions',
        id: 'commissions',
        url: '/commissions',
    },
    {
        label: 'Policies',
        id: 'policies',
        url: '/policies',
    },
    {
        label: 'Insights',
        id: 'insights',
        url: '/insights',
    },
    {
        label: 'Upload documents',
        id: 'add_documents',
    },
    {
        label: 'Agents > Agents',
        id: 'agents',
        url: '/agents/list',
    },
    {
        label: 'Agents > Groups',
        id: 'agents_groups',
        url: '/agents/groups',
    },
    {
        label: 'Agents > Production',
        id: 'agents_production',
        url: '/agents/production',
    },
    // Deprecated
    // {
    //   label: 'Schedules > Agents',
    //   id: 'agent_schedules',
    // },
    {
        label: 'Customers',
        id: 'customers',
    },
    {
        label: 'Schedules > Carriers',
        id: 'carriers_schedules',
        url: '/schedules/carriers',
    },
    {
        label: 'Schedules > Comp profiles',
        id: 'compensation_profiles',
        url: '/schedules/compensation-profiles',
    },
    {
        label: 'Schedules > Comp profile sets',
        id: 'compensation_profile_sets',
        url: '/schedules/comp-profile-sets',
    },
    {
        label: 'Schedules > Incentives',
        id: 'incentives_schedules',
        url: '/schedules/incentive-tiers',
    },
    {
        label: 'Schedules > Comp grids',
        id: 'comp_grids_schedules',
        url: '/schedules/comp-grids/viewer',
    },
    {
        label: 'Settings > Data processing',
        id: 'settings_data_processing',
    },
    {
        label: 'Views',
        id: 'views',
    },
    {
        label: 'Reports > Reports',
        id: 'reports',
        url: '/reports',
    },
    {
        label: 'Reports > Summaries',
        id: 'summaries',
        url: '/reports/summary',
    },
    {
        label: 'Companies > Companies',
        id: 'companies',
        url: '/companies',
    },
    {
        label: 'Companies > Products',
        id: 'products',
        url: '/companies/products',
    },
    {
        label: 'Companies > Options',
        id: 'options',
        url: '/companies/products/options',
    },
    {
        label: 'Documents',
        id: 'documents',
    },
];
const RoleList = [
    {
        label: 'Account admin',
        id: Roles.ACCOUNT_ADMIN,
    },
    {
        label: 'Producer',
        id: Roles.PRODUCER,
    },
    {
        label: 'Data specialist',
        id: Roles.DATA_SPECIALIST,
    },
];
const EnableList = [
    {
        label: 'Yes',
        id: 'yes',
    },
    {
        label: 'No',
        id: 'no',
    },
];
const ViewsFieldsSettings = () => {
    const { role: [role], } = useContext(UIStateContext);
    const statements = new Statements('insurance', role);
    const reconciliations = new Reconciliations('insurance', '');
    const reports = new Reports('insurance', {});
    const [companiesOptions, setCompaniesOptions] = useState([]);
    const [companyValue, setCompanyValue] = useState([]);
    const { data: settingsData, refetch } = API.getBasicQuery('accounts/settings');
    const { data: companiesData } = API.getBasicQuery('companies', 'all=1');
    const companySettingsPoster = API.getMutation('accounts/settings/companies', 'POST');
    useEffect(() => {
        if (settingsData) {
            const companies = settingsData.companies_view;
            if (companies) {
                setCompanyValue(companies);
            }
        }
    }, [settingsData]);
    useEffect(() => {
        var _a;
        setCompaniesOptions((_a = companiesData === null || companiesData === void 0 ? void 0 : companiesData.data) === null || _a === void 0 ? void 0 : _a.filter((item) => item.access === 'global').map((item) => ({
            id: item.str_id,
            label: item.company_name,
        })));
    }, [companiesData]);
    const filtersData = {
        pages: Pages.map((p) => ({ id: p.id, name: p.label })),
        roles: RoleList.map((p) => ({ id: p.id, name: p.label })),
        enable: EnableList.map((p) => ({ id: p.id, name: p.label })),
    };
    const dataSpec = {
        label: 'Views & fields',
        table: 'accounts/settings/views-and-fields',
        filters: {
            pages: {
                label: 'Pages',
            },
            roles: {
                label: 'Roles',
            },
            enable: {
                label: 'Enable',
            },
        },
        fields: {
            custom_fields_id: {
                enabled: false,
                readOnly: true,
                visible: false,
            },
            key: {
                label: 'Page',
                enabled: true,
                type: 'select',
                sx: { width: 200 },
                readOnly: true,
                options: Pages,
            },
            role: {
                label: 'Role',
                enabled: true,
                type: 'select',
                options: RoleList,
                readOnly: true,
            },
            menu_label: {
                label: 'Menu label',
                enabled: true,
            },
            page_label: {
                label: 'Page label',
                enabled: true,
            },
            show_page: {
                label: 'Enable',
                type: 'boolean',
                enabled: true,
                options: EnableList,
            },
            read_only: {
                label: 'Read only',
                type: 'boolean',
                enabled: true,
            },
            fields: {
                label: 'Fields',
                enabled: true,
                type: FieldTypes.DRAGGABLE_SELECT,
                customSelectOptions: {
                    statements: statements,
                    reconciliations: reconciliations,
                    reports: reports,
                },
                disableSort: true,
                tableFormatter: (field, row, dynamicSelects, headers) => {
                    if (field && Array.isArray(field)) {
                        return field
                            .map((f) => {
                            var _a, _b;
                            return ((_b = (_a = headers.customSelectOptions[row.custom_fields_id]) === null || _a === void 0 ? void 0 : _a.fields[f]) === null || _b === void 0 ? void 0 : _b.label) || f;
                        })
                            .map((label, index) => (_jsx(Chip, { sx: { mt: 0.5, mr: 0.25 }, label: label }, `${row.custom_fields_id}-${row.role}-${label}-${index}`)));
                    }
                    return null;
                },
            },
            outstandingMobileFields: {
                label: 'Mobile fields',
                enabled: true,
                type: FieldTypes.DRAGGABLE_SELECT,
                disableSort: true,
                customSelectOptions: {
                    statements: statements,
                    reconciliations: reconciliations,
                    reports: reports,
                },
                tableFormatter: (field, row, dynamicSelects, headers) => {
                    if (field && Array.isArray(field)) {
                        return field
                            .map((f) => {
                            var _a, _b;
                            return ((_b = (_a = headers.customSelectOptions[row.custom_fields_id]) === null || _a === void 0 ? void 0 : _a.fields[f]) === null || _b === void 0 ? void 0 : _b.label) || f;
                        })
                            .map((label, index) => (_jsx(Chip, { sx: { mt: 0.5, mr: 0.25 }, label: label }, `${row.custom_fields_id}-${row.role}-${label}-${index}`)));
                    }
                    return null;
                },
            },
            default_filters: {
                label: 'Default filters',
                type: FieldTypes.CUSTOM,
                disableSort: true,
                customFieldsOptions: {
                    statements: [
                        {
                            label: 'Compensation type - Commission',
                            id: 'compensation_type::Commission',
                        },
                    ],
                },
                enabled: true,
                render: (field, row, setter) => (_jsxs(FormControl, { sx: { mt: 1, mb: 1 }, children: [_jsx(InputLabel, { id: `${field.label}-label`, children: field.label }), _jsxs(Select, { labelId: `${field.label}-label`, label: field.label, value: row.default_filters || 'Add filter', onChange: (event) => {
                                const selectedFilterId = event.target.value;
                                setter(Object.assign(Object.assign({}, row), { default_filters: selectedFilterId }));
                            }, children: [_jsx(MenuItem, { value: "Add filter", children: "Add filter" }), (field.customFieldsOptions[row.custom_fields_id] || []).map((filter) => (_jsx(MenuItem, { value: filter.id, children: filter.label }, filter.id)))] })] })),
                tableFormatter: (field, row, dynamicSelects, headers) => {
                    if (field && Array.isArray(field)) {
                        return field === null || field === void 0 ? void 0 : field.map((fieldElement) => {
                            var _a, _b;
                            const selectedValue = (_a = headers.customFieldsOptions[row.custom_fields_id]) === null || _a === void 0 ? void 0 : _a.filter((filter) => {
                                return filter.id === fieldElement;
                            });
                            const selectedLabel = (_b = selectedValue[0]) === null || _b === void 0 ? void 0 : _b.label;
                            return (_jsx(Chip, { sx: { mt: 0.5, mr: 0.5 }, label: selectedLabel }, fieldElement));
                        });
                    }
                    return null;
                },
            },
            page_options: {
                label: 'Page options',
                enabled: true,
                disableSort: true,
            },
        },
    };
    const dataDescDefLandingPage = {
        label: 'Default page',
        table: 'accounts/settings/default-page',
        editable: true,
        fields: [
            {
                id: 'role_id',
                label: 'Role',
                enabled: true,
                visible: true,
                type: 'select',
                options: RoleList,
            },
            {
                id: 'default_page',
                label: 'Default page',
                enabled: true,
                visible: true,
                type: 'select',
                options: Pages.filter((p) => p.url).map((p) => ({
                    id: p.url,
                    label: p.label,
                })),
            },
        ],
    };
    return (_jsxs(_Fragment, { children: [_jsx(EnhancedDataView, { dataSpec: dataSpec, filters: filtersData, hideSelectedCount: true, hideExport: true, hideAdd: true, enableMultiSelect: false }), _jsx(Box, { sx: { width: '90%', mt: 2, ml: 1 }, children: _jsx(DataView, { dataDesc: dataDescDefLandingPage, hideExport: true }) }), _jsxs(Box, { sx: { width: '90%', mt: 2, ml: 1 }, children: [_jsx(Typography, { variant: "h5", sx: { mb: 2 }, children: "Companies available" }), _jsx(Box, { sx: {
                            display: 'flex',
                        }, children: _jsx(Box, { sx: { flex: 1, ml: 1 }, children: _jsxs(Grid, { container: true, spacing: 2, children: [_jsx(Grid, { item: true, xs: 2, children: _jsx(Typography, { children: "Companies" }) }), _jsxs(Grid, { item: true, xs: 10, sx: {
                                            display: 'flex',
                                            flexDirection: 'column',
                                            maxWidth: '1024px',
                                        }, children: [_jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { id: "id-extract", children: "Companies" }), _jsx(Select, { onChange: (e) => setCompanyValue(e.target.value), id: "id-companies", fullWidth: true, multiple: true, label: "Companies", value: companyValue, children: (companiesOptions === null || companiesOptions === void 0 ? void 0 : companiesOptions.map((item) => (_jsx(MenuItem, { value: item.id, children: item.label }, item.id)))) || [] })] }), _jsx(Button, { sx: { mt: 2, width: 80, alignSelf: 'flex-end' }, variant: "contained", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                                    companySettingsPoster.mutateAsync({
                                                        companies_view: companyValue,
                                                        companyUpdate: true,
                                                        // When updating the company settings the role is not important since we will update the settings for all roles
                                                    });
                                                    refetch();
                                                }), children: "Save" })] })] }) }) })] })] }));
};
export default ViewsFieldsSettings;
