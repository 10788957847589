var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Download } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Chip, FormControl, InputLabel, MenuItem, Select, TablePagination, Tooltip, Typography, } from '@mui/material';
import { AgGridReact } from 'ag-grid-react';
import CommonFormatter from 'common/Formatter';
import { cloneDeep, isEqual } from 'lodash-es';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Navigate, useSearchParams } from 'react-router-dom';
import LoadingCircle from '@/components/atoms/LoadingCircle';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
import SearchBox from '@/components/molecules/SearchBox';
import SearchSettings from '@/components/molecules/SearchSettings';
import CompGridBulkActions from '@/components/schedules/compGrids/CompGridViewerView/CompGridBulkActions';
import ConfirmNewRatesDialog from '@/components/schedules/compGrids/CompGridViewerView/ConfirmNewRatesDialog';
import useSnackbar from '@/contexts/useSnackbar';
import API from '@/services/API';
import { useRoleStore } from '@/store';
import { DateRangesTypes } from '@/types';
const getGridLevelCols = (name, rate_fields, role, editable, adding, editedCells = {}) => {
    return [
        {
            field: `carrier_rate::${name}`,
            headerName: 'Carrier rate',
            type: 'numericColumn',
            cellDataType: 'number',
            width: 80,
            cellStartedEdit: (params) => editable || (params.node.rowPinned === 'top' && adding),
            editable: (params) => editable || (params.node.rowPinned === 'top' && adding),
            wrapHeaderText: true,
            hide: !rate_fields.includes('carrier_rate'),
            cellClassRules: {
                'ag-cell-custom-valid': (params) => editedCells[`${params.data.id}-${params.colDef.field}`] === 'valid',
                'ag-cell-custom-invalid': (params) => editedCells[`${params.data.id}-${params.colDef.field}`] === 'invalid',
            },
        },
        {
            field: `house_rate::${name}`,
            headerName: 'House rate',
            type: 'numericColumn',
            width: 80,
            cellStartedEdit: (params) => editable || (params.node.rowPinned === 'top' && adding),
            editable: (params) => editable || (params.node.rowPinned === 'top' && adding),
            wrapHeaderText: true,
            hide: !rate_fields.includes('house_rate'),
            cellClassRules: {
                'ag-cell-custom-valid': (params) => editedCells[`${params.data.id}-${params.colDef.field}`] === 'valid',
                'ag-cell-custom-invalid': (params) => editedCells[`${params.data.id}-${params.colDef.field}`] === 'invalid',
            },
        },
        {
            field: `rate::${name}`,
            headerName: 'Total rate',
            type: 'numericColumn',
            width: 80,
            cellStartedEdit: (params) => editable || (params.node.rowPinned === 'top' && adding),
            editable: (params) => editable || (params.node.rowPinned === 'top' && adding),
            wrapHeaderText: true,
            hide: !rate_fields.includes('total_rate'),
            cellClassRules: {
                'ag-cell-custom-valid': (params) => editedCells[`${params.data.id}-${params.colDef.field}`] === 'valid',
                'ag-cell-custom-invalid': (params) => editedCells[`${params.data.id}-${params.colDef.field}`] === 'invalid',
            },
        },
    ];
};
const CompGridsView = () => {
    var _a, _b, _c, _d;
    const { data: accountSettings, isFetched: isFetchedAccountSettings } = API.getBasicQuery(`accounts/settings`);
    const viewSettings = (_a = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _a === void 0 ? void 0 : _a.comp_grids_schedules;
    let pageLabel = 'Comp grids';
    let readOnly = false;
    if (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label) {
        pageLabel = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label;
    }
    if (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.read_only) {
        readOnly = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.read_only;
    }
    if (isFetchedAccountSettings && (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.show_page) === false) {
        return (
        // TODO: Remove navigate after figuring out how to handle this in router
        _jsx(Navigate, { to: "/settings" }));
    }
    const initialInputRow = {
        id: undefined,
        comp_grid: null,
        company: null,
        product_type: null,
        product: null,
        compensation_type: null,
        issue_age_start: null,
        issue_age_end: null,
        premium_min: null,
        premium_max: null,
        policy_year_start: null,
        policy_year_end: null,
        str_id: undefined,
    };
    const [inputRow, _setInputRow] = useState(initialInputRow);
    const [newRowNode, setNewRowNode] = useState(null);
    const [adding, setAdding] = useState(false);
    const [dropdownValues, setDropdownValues] = useState({
        comp_grid: [],
        comp_grid_mapping: { 0: 'Click to add' },
        product: [],
        product_mapping: { 0: 'Click to add' },
    });
    const [newRowValues, setNewRowValues] = useState(null);
    const [compGrids, setCompGrids] = useState([]);
    const [selectedCompGrid, setSelectedCompGrid] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams({});
    const [filteredLevels, setFilteredLevels] = useState([]);
    const [editable, setEditable] = useState(false);
    const [editedCells, setEditedCells] = useState({});
    const [editedValues, setEditedValues] = useState({});
    const [editNode, setEditNode] = useState({});
    const [loading, setLoading] = useState(false);
    const [addLoading, setAddLoading] = useState(false);
    const [openBulkActionsDialog, setOpenBulkActionsDialog] = useState(false);
    const [selectedDateRanges, setSelectedDateRanges] = useState([]);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedLevels, setSelectedLevels] = useState([]);
    const [selectedRatesIds, setSelectedRatesIds] = useState([]);
    const [selectedCriteria, setSelectedCriteria] = useState([]);
    const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
    const [selectedDateFilter, setSelectedDateFilter] = useState(searchParams.get('effective_date')
        ? 'singleDate'
        : searchParams.get('range_date')
            ? 'dateRange'
            : 'today');
    const [showDateRanges, setShowDateRanges] = useState(searchParams.get('incl_date_ranges') ? true : false);
    const [effectiveDateFilterValue, setEffectiveDateFilterValue] = useState(searchParams.get('effective_date')
        ? new Date(searchParams.get('effective_date'))
        : undefined);
    const [dateRangeFilterValue, setDateRangeFilterValue] = useState(searchParams.get('range_date')
        ? parseInt(searchParams.get('range_date'))
        : undefined);
    const [confirmNewRates, setConfirmNewRates] = useState(false);
    const [params, setParams] = useState(null);
    const gridRef = useRef(null);
    const query = searchParams.get('q');
    const inclDateRanges = searchParams.get('incl_date_ranges');
    const { userRole } = useRoleStore();
    const { showSnackbar } = useSnackbar();
    const toISOStringSafe = (dateValue) => {
        const date = new Date(dateValue);
        return !isNaN(date.getTime()) ? date.toISOString() : undefined;
    };
    // Uncertain if we can share the queryString with the API
    const queryString = searchParams.toString();
    const { data: _compGrids, isLoading: isLoadingCompGrids, refetch: refetchCompGrids, } = API.getBasicQuery('comp-grids/view', queryString);
    const compGridFilters = _compGrids === null || _compGrids === void 0 ? void 0 : _compGrids.filters;
    const filteredRatesCompGrids = useMemo(() => {
        return _compGrids === null || _compGrids === void 0 ? void 0 : _compGrids.data.map((compGrid) => (Object.assign(Object.assign({}, compGrid), { comp_grid_criteria: compGrid.comp_grid_criteria.filter((criteria) => criteria.comp_grid_rates.length > 0) })));
    }, [_compGrids]);
    useEffect(() => {
        setCompGrids(filteredRatesCompGrids);
    }, [filteredRatesCompGrids]);
    const criteriaCount = _compGrids === null || _compGrids === void 0 ? void 0 : _compGrids.criteria_count;
    const selectedCompGrids = searchParams.getAll('comp_grids').map(Number);
    const { data: dateRangesData } = API.getBasicQuery('date-ranges', `type=${DateRangesTypes.ANY}${(selectedCompGrids === null || selectedCompGrids === void 0 ? void 0 : selectedCompGrids.length) && (selectedCompGrids === null || selectedCompGrids === void 0 ? void 0 : selectedCompGrids.length) !== (compGrids === null || compGrids === void 0 ? void 0 : compGrids.length) ? `&comp_grids=${selectedCompGrids === null || selectedCompGrids === void 0 ? void 0 : selectedCompGrids.join(',')}` : ''}`);
    const ratesCriteriaPatcher = API.getMutation('comp-grids/view', 'PATCH');
    const ratesBulkPatcher = API.getMutation('comp-grids/rates/bulk-edit', 'PATCH');
    const ratesBulkDeleter = API.getMutation('comp-grids/rates/bulk-edit', 'DELETE');
    const copyRatesPoster = API.getMutation('comp-grids/rates/copy-rates', 'POST');
    const criteriaWithRatesPoster = API.getMutation('comp-grids/criteria/criteria-with-rates', 'POST');
    const columnDefsBase = useMemo(() => {
        const nonNegativeValueSetter = (params) => {
            // Ensure the input value is non-negative
            if (params.newValue < 0) {
                showSnackbar('Cannot set a negative value.', 'error');
                return false;
            }
            if (params.data[params.colDef.field] !== params.newValue) {
                params.data[params.colDef.field] = params.newValue;
                return true;
            }
            return false;
        };
        return [
            {
                headerCheckboxSelection: editable,
                checkboxSelection: editable,
                hide: !editable,
                width: 30,
                pinned: 'left',
                cellStyle: {
                    display: 'flex',
                    alignItems: 'none',
                    justifyContent: 'center',
                    marginLeft: 12,
                    border: 'none',
                },
            },
            { field: 'id', hide: true },
            {
                field: 'comp_grid',
                headerName: 'Comp grid',
                pinned: 'left',
                width: 140,
                hide: !adding,
                editable: (params) => params.node.rowPinned === 'top' && adding,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: dropdownValues.comp_grid,
                },
                refData: dropdownValues.comp_grid_mapping,
                cellStyle: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'left',
                    marginLeft: 'inherit',
                    border: 'none',
                },
                onCellValueChanged: (params) => {
                    var _a, _b;
                    setSelectedCompGrid(params.newValue);
                    setNewRowNode(params);
                    // Refresh the cells to reflect comp_grid changes
                    params.data.company = (_a = compGrids.find((grid) => grid.id === params.newValue)) === null || _a === void 0 ? void 0 : _a.company.company_name;
                    params.data.company_id = (_b = compGrids.find((grid) => grid.id === params.newValue)) === null || _b === void 0 ? void 0 : _b.company.id;
                    params.data.product = '';
                    params.data.product_type = '';
                    params.api.refreshCells({
                        rowNodes: [params.node],
                        force: true,
                    });
                },
            },
            {
                field: 'company',
                headerName: 'Carrier',
                pinned: 'left',
                width: 140,
                tooltipValueGetter: (p) => { var _a; return (_a = p === null || p === void 0 ? void 0 : p.data) === null || _a === void 0 ? void 0 : _a.company; },
                cellStyle: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'left',
                    marginLeft: 'inherit',
                    border: 'none',
                },
            },
            { field: 'company_id', hide: true },
            {
                field: 'product',
                headerName: 'Product',
                pinned: 'left',
                width: 180,
                tooltipValueGetter: (p) => { var _a; return (_a = p === null || p === void 0 ? void 0 : p.data) === null || _a === void 0 ? void 0 : _a.product; },
                editable: (params) => params.node.rowPinned === 'top' && adding,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: dropdownValues.product,
                },
                refData: dropdownValues.product_mapping,
                cellStyle: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'left',
                    marginLeft: 'inherit',
                    border: 'none',
                },
                onCellValueChanged: (params) => {
                    var _a, _b;
                    params.data.product_type = (_b = (_a = compGrids
                        .find((grid) => grid.id === selectedCompGrid)) === null || _a === void 0 ? void 0 : _a.comp_grid_products.find((product) => product.id === params.newValue)) === null || _b === void 0 ? void 0 : _b.type;
                    params.api.refreshCells({
                        rowNodes: [params.node],
                        force: true,
                    });
                },
            },
            {
                field: 'product_type',
                headerName: 'Product type',
                pinned: 'left',
                width: 90,
                wrapHeaderText: true,
                cellStyle: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'left',
                    marginLeft: 'inherit',
                    border: 'none',
                },
            },
            {
                field: 'policy_year_start',
                headerName: 'Policy year start',
                pinned: 'left',
                width: 70,
                wrapHeaderText: true,
                editable: (params) => editable || (params.node.rowPinned === 'top' && adding),
                type: 'numberColumn',
                valueSetter: nonNegativeValueSetter,
            },
            {
                field: 'policy_year_end',
                headerName: 'Policy year end',
                pinned: 'left',
                width: 70,
                wrapHeaderText: true,
                editable: (params) => editable || (params.node.rowPinned === 'top' && adding),
                type: 'numberColumn',
                valueSetter: nonNegativeValueSetter,
            },
            {
                field: 'issue_age_start',
                headerName: 'Age start',
                pinned: 'left',
                width: 55,
                wrapHeaderText: true,
                editable: (params) => editable || (params.node.rowPinned === 'top' && adding),
                type: 'numberColumn',
                valueSetter: nonNegativeValueSetter,
            },
            {
                field: 'issue_age_end',
                headerName: 'Age\nend',
                pinned: 'left',
                width: 55,
                wrapHeaderText: true,
                editable: (params) => editable || (params.node.rowPinned === 'top' && adding),
                type: 'numberColumn',
                valueSetter: nonNegativeValueSetter,
            },
            {
                field: 'premium_min',
                headerName: 'Premium min',
                pinned: 'left',
                width: 55,
                wrapHeaderText: true,
                editable: (params) => editable || (params.node.rowPinned === 'top' && adding),
                type: 'numberColumn',
            },
            {
                field: 'premium_max',
                headerName: 'Premium max',
                pinned: 'left',
                width: 55,
                wrapHeaderText: true,
                editable: (params) => editable || (params.node.rowPinned === 'top' && adding),
                type: 'numberColumn',
            },
            {
                field: 'compensation_type',
                headerName: 'Compensation type',
                pinned: 'left',
                width: 120,
                wrapHeaderText: true,
                tooltipValueGetter: (p) => { var _a; return (_a = p === null || p === void 0 ? void 0 : p.data) === null || _a === void 0 ? void 0 : _a.compensation_type; },
                editable: (params) => editable || (params.node.rowPinned === 'top' && adding),
            },
            {
                field: 'rates_date_ranges',
                headerName: 'Date range',
                pinned: 'left',
                width: 220,
                autoHeight: true,
                hide: !showDateRanges,
                cellRenderer: (params) => {
                    const { value } = params;
                    if (!value)
                        return null;
                    return (_jsx(Box, { display: "flex", flexDirection: "column", children: value.map((dateRange, index) => (_jsx(Tooltip, { title: dateRange, children: _jsx(Chip, { sx: { m: 0.25 }, label: dateRange }, `${index}-datechip`) }))) }));
                },
            },
        ];
    }, [
        editable,
        adding,
        dropdownValues.comp_grid,
        dropdownValues.comp_grid_mapping,
        dropdownValues.product,
        dropdownValues.product_mapping,
        showDateRanges,
        showSnackbar,
        compGrids,
        selectedCompGrid,
    ]);
    const searchSettings = [
        {
            id: 'incl_date_ranges',
            type: 'toggle',
            label: 'Show date ranges',
        },
    ];
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const newInclDateRanges = searchParams.get('incl_date_ranges');
        setShowDateRanges(newInclDateRanges ? true : false);
    }, [inclDateRanges]);
    useEffect(() => {
        const params = new URLSearchParams(searchParams);
        const updateParams = (key, value) => {
            if (value) {
                params.set(key, toISOStringSafe(value) || '');
            }
            else {
                params.delete(key);
            }
        };
        if (selectedDateFilter === 'singleDate') {
            setDateRangeFilterValue(undefined);
            params.delete('range_date');
        }
        else if (selectedDateFilter === 'dateRange') {
            setEffectiveDateFilterValue(undefined);
            params.set('range_date', (dateRangeFilterValue === null || dateRangeFilterValue === void 0 ? void 0 : dateRangeFilterValue.toString()) || '');
            params.delete('effective_date');
        }
        else {
            setEffectiveDateFilterValue(undefined);
            setDateRangeFilterValue(undefined);
            params.delete('effective_date');
            params.delete('range_date');
        }
        updateParams('effective_date', effectiveDateFilterValue);
        setSearchParams(params);
    }, [
        selectedDateFilter,
        effectiveDateFilterValue,
        dateRangeFilterValue,
        searchParams,
        setSearchParams,
    ]);
    const filteredCompGrids = useMemo(() => (Array.isArray(compGrids) ? compGrids : []), [compGrids]);
    const compGridLevels = useMemo(() => {
        const uniqueLevels = [
            ...new Set(filteredCompGrids.map((compGrid) => compGrid === null || compGrid === void 0 ? void 0 : compGrid.comp_grid_levels).flat()),
        ];
        // Add comp grid available rate fields to each level for showing/hiding rate columns
        return uniqueLevels.map((level) => {
            const correspondingCompGrid = filteredCompGrids.find((compGrid) => compGrid === null || compGrid === void 0 ? void 0 : compGrid.comp_grid_levels.includes(level));
            return Object.assign(Object.assign({}, level), { rate_fields: (correspondingCompGrid === null || correspondingCompGrid === void 0 ? void 0 : correspondingCompGrid.rate_fields) || [
                    'carrier_rate',
                    'house_rate',
                    'total_rate',
                ] });
        });
    }, [filteredCompGrids]);
    // The following assumes that all levels have the same children
    const levelColsMap = useMemo(() => new Map(compGridLevels === null || compGridLevels === void 0 ? void 0 : compGridLevels.map((compGridLevel) => [
        compGridLevel.name,
        {
            headerName: compGridLevel.name,
            headerClass: 'custom-cell-border',
            children: getGridLevelCols(compGridLevel.name, compGridLevel.rate_fields, userRole, editable, adding, editedCells).map((col, index) => (Object.assign(Object.assign({}, col), { headerClass: 'custom-cell-border', cellClass: [index === 0 ? 'first-child-border' : '']
                    .join(' ')
                    .trim() }))),
        },
    ])), [compGridLevels, editable, editedCells, showDateRanges]);
    const levelCols = useMemo(() => Array.from(levelColsMap.values()).filter((v) => filteredLevels.includes(v.headerName)), [levelColsMap, filteredLevels]);
    const levelNames = useMemo(() => Array.from(levelColsMap.keys()), [levelColsMap]);
    useEffect(() => {
        if (Array.isArray(compGridLevels) && (filteredLevels === null || filteredLevels === void 0 ? void 0 : filteredLevels.length) === 0) {
            setFilteredLevels(levelNames);
        }
        const compGridFilteredValues = searchParams
            .getAll('comp_grids')
            .map(Number);
        if (Array.isArray(compGrids))
            if (compGridFilteredValues.length > 0 &&
                !isEqual(compGridFilteredValues, selectedCompGrids)) {
                setSearchParams((prevParams) => {
                    const newParams = new URLSearchParams(prevParams);
                    newParams.delete('comp_grids');
                    compGridFilteredValues.forEach((value) => newParams.append('comp_grids', value.toString()));
                    return newParams;
                });
            }
        const levelFilteredValues = searchParams.getAll('levels');
        if (Array.isArray(compGridLevels)) {
            if (levelFilteredValues.length > 0 &&
                !isEqual(levelFilteredValues, filteredLevels)) {
                setFilteredLevels(levelFilteredValues);
            }
        }
    }, [
        compGrids,
        isLoadingCompGrids,
        compGridLevels,
        selectedCompGrids,
        searchParams,
        filteredLevels,
        levelNames,
        setSearchParams,
    ]);
    useEffect(() => {
        if (compGrids) {
            const uniqueCompGridsIds = [
                ...new Set(compGrids.map((grid) => grid.id)),
            ];
            const uniqueCompGridsMapping = compGrids.reduce((acc, grid) => {
                acc[grid.id] = grid.name;
                return acc;
            }, { [0]: 'Click to add' });
            const uniqueProductsMapping = compGrids.reduce((acc, grid) => {
                grid.comp_grid_products.forEach((product) => {
                    acc[product.id] = product.name;
                });
                return acc;
            }, { 0: 'Click to add' });
            setDropdownValues((prevValues) => (Object.assign(Object.assign({}, prevValues), { comp_grid: uniqueCompGridsIds, comp_grid_mapping: uniqueCompGridsMapping, product_mapping: uniqueProductsMapping })));
        }
    }, [compGrids]);
    useEffect(() => {
        // When adding a new row, set the carrier and products dropdowns to the selected comp grid data
        if (selectedCompGrid) {
            const filteredGrids = compGrids.filter((grid) => grid.id === selectedCompGrid);
            const uniqueProducts = [
                ...new Set(filteredGrids.flatMap((grid) => grid.comp_grid_products.map((product) => product.id))),
            ];
            setDropdownValues((prevValues) => (Object.assign(Object.assign({}, prevValues), { product: uniqueProducts })));
        }
    }, [selectedCompGrid, compGrids]);
    const columnDefs = [...columnDefsBase, ...levelCols];
    const rowList = (_b = filteredCompGrids
        .map((compGrid) => compGrid.comp_grid_criteria)
        .flat()) === null || _b === void 0 ? void 0 : _b.filter((datum) => {
        var _a;
        if (!query)
            return true;
        const searchFields = [
            datum.comp_grid_product.name,
            datum.comp_grid_product.type,
            (_a = datum.company) === null || _a === void 0 ? void 0 : _a.company_name,
            datum.compensation_type,
        ];
        return searchFields.join(' ').toLowerCase().includes(query.toLowerCase());
    });
    const filteredData = rowList === null || rowList === void 0 ? void 0 : rowList.map((compGridCriterion) => {
        var _a, _b, _c, _d, _e;
        const datum = {
            id: compGridCriterion.id,
            comp_grid: filteredCompGrids
                .filter((compGrid) => compGrid.comp_grid_criteria.some((criteria) => criteria.id === compGridCriterion.id))
                .map((compGrid) => compGrid.id)[0],
            company: (_a = compGridCriterion.company) === null || _a === void 0 ? void 0 : _a.company_name,
            company_id: (_b = compGridCriterion.company) === null || _b === void 0 ? void 0 : _b.id,
            product_type: (_c = compGridCriterion.comp_grid_product) === null || _c === void 0 ? void 0 : _c.type,
            product: (_d = compGridCriterion.comp_grid_product) === null || _d === void 0 ? void 0 : _d.id,
            compensation_type: compGridCriterion.compensation_type,
            issue_age_start: compGridCriterion.issue_age_start
                ? Number(compGridCriterion.issue_age_start)
                : null,
            issue_age_end: compGridCriterion.issue_age_end
                ? Number(compGridCriterion.issue_age_end)
                : null,
            premium_min: compGridCriterion.premium_min
                ? Number(compGridCriterion.premium_min)
                : null,
            premium_max: compGridCriterion.premium_max
                ? Number(compGridCriterion.premium_max)
                : null,
            policy_year_start: compGridCriterion.policy_year_start
                ? Number(compGridCriterion.policy_year_start)
                : null,
            policy_year_end: compGridCriterion.policy_year_end
                ? Number(compGridCriterion.policy_year_end)
                : null,
            str_id: compGridCriterion.str_id,
        };
        (_e = compGridCriterion.comp_grid_rates) === null || _e === void 0 ? void 0 : _e.forEach((rate) => {
            const compGridLevel = rate.comp_grid_level.name;
            datum[`carrier_rate::${compGridLevel}`] = Number(rate.carrier_rate);
            datum[`house_rate::${compGridLevel}`] = Number(rate.house_rate);
            datum[`rate::${compGridLevel}`] = Number(rate.rate);
            datum[`rate_id::${compGridLevel}`] = rate.id;
        });
        datum['rates_date_ranges'] = [];
        if (showDateRanges) {
            const rangeStrings = [];
            const levelRangeStrings = [];
            let count = 0;
            compGridCriterion.comp_grid_rates.forEach((rate) => {
                var _a;
                count++;
                (_a = rate === null || rate === void 0 ? void 0 : rate.date_ranges) === null || _a === void 0 ? void 0 : _a.forEach((dateRange) => {
                    const dateRangeName = dateRange.name;
                    const rangeString = dateRangeName
                        ? `${dateRangeName}: (${CommonFormatter.dateRange(dateRange.start_date, dateRange.end_date)})`
                        : `${CommonFormatter.dateRange(dateRange.start_date, dateRange.end_date)}`;
                    const levelRangeString = dateRangeName
                        ? `${rate.comp_grid_level.name}: ${dateRangeName}: (${CommonFormatter.dateRange(dateRange.start_date, dateRange.end_date)})`
                        : `${rate.comp_grid_level.name}: ${CommonFormatter.dateRange(dateRange.start_date, dateRange.end_date)}`;
                    if (!rangeStrings.includes(rangeString))
                        rangeStrings.push(rangeString);
                    if (!levelRangeStrings.includes(levelRangeString))
                        levelRangeStrings.push(levelRangeString);
                });
            });
            datum['rates_date_ranges'] =
                rangeStrings.length <= 1 && levelRangeStrings.length === count
                    ? rangeStrings
                    : levelRangeStrings;
        }
        return datum;
    });
    // Merge filteredData and editedValues to show edited values
    useEffect(() => {
        var _a, _b, _c, _d, _e, _f;
        const editKeys = Object.keys(editedValues);
        if (filteredData && filteredData.length && editKeys.length) {
            editKeys.forEach((key) => {
                const [rowIndex, _key] = key.split(';;');
                const node = filteredData.find((row) => row.id === parseInt(rowIndex, 10));
                if (node) {
                    node[_key] = editedValues[key];
                }
            });
        }
        (_b = (_a = gridRef.current) === null || _a === void 0 ? void 0 : _a.api) === null || _b === void 0 ? void 0 : _b.refreshCells();
        if (filteredData) {
            const allColumnIds = [];
            (_d = (_c = gridRef.current) === null || _c === void 0 ? void 0 : _c.api) === null || _d === void 0 ? void 0 : _d.getColumns().forEach((column) => {
                const columnId = column.getId();
                if (columnId && columnId.includes('rate::')) {
                    allColumnIds.push(columnId);
                }
            });
            (_f = (_e = gridRef.current) === null || _e === void 0 ? void 0 : _e.api) === null || _f === void 0 ? void 0 : _f.autoSizeColumns(allColumnIds, false);
        }
    }, [editedValues, filteredData]);
    const onExportCsv = () => {
        gridRef.current.api.exportDataAsCsv({
            fileName: 'Fintary-Comp-Grid-Export.csv',
        });
    };
    const onCellValueChanged = useCallback((params) => {
        const { column, data, newValue } = params;
        if (params.rowPinned === 'top')
            setNewRowValues(data);
        if (newValue !== params.oldValue) {
            const _key = column.getId();
            const isValid = !isNaN(newValue);
            setEditedCells((prev) => (Object.assign(Object.assign({}, prev), { [`${data.id};;${_key}`]: isValid ? 'valid' : 'invalid' })));
            setEditedValues((prev) => (Object.assign(Object.assign({}, prev), { [`${data.id};;${_key}`]: newValue })));
            const rowNode = rowList.find((row) => row.id === data.id);
            const [_fieldKey, _levelName] = _key.split('::');
            if (!rowNode) {
                return;
            }
            const rowNodeRates = rowNode.comp_grid_rates.filter((r) => {
                return r.comp_grid_level.name === _levelName;
            });
            let targetNodes = editNode[rowNode.str_id];
            if (!targetNodes) {
                const newNodes = cloneDeep(editNode);
                newNodes[rowNode.str_id] = rowNodeRates.flat();
                setEditNode(newNodes);
                targetNodes = newNodes[rowNode.str_id];
            }
            const copyNodes = cloneDeep(targetNodes);
            const nodeIndex = copyNodes.findIndex((r) => {
                var _a;
                return ((_a = r.comp_grid_level) === null || _a === void 0 ? void 0 : _a.name) === _levelName;
            });
            if (nodeIndex > -1) {
                if (rowNodeRates.length > 0) {
                    copyNodes[nodeIndex] = rowNodeRates;
                }
            }
            else {
                copyNodes.push({
                    rowNode,
                    key: _key,
                    newValue: newValue,
                });
            }
            const newNodes = cloneDeep(editNode);
            newNodes[rowNode.str_id] = copyNodes.flat();
            setEditNode(newNodes);
        }
    }, [editNode, rowList]);
    const handleNewRatesConfirm = () => __awaiter(void 0, void 0, void 0, function* () {
        if (params) {
            saveData(params);
        }
    });
    const onSave = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        if (!editable) {
            setEditable(true);
            setCompGrids(_compGrids === null || _compGrids === void 0 ? void 0 : _compGrids.data);
            return;
        }
        setCompGrids(filteredRatesCompGrids);
        gridRef.current.api.stopEditing();
        // Prepare data to save
        const changeObj = {};
        const _editedValues = cloneDeep(editedValues);
        let _editNode = cloneDeep(editNode);
        // Get the focused cell
        const focusedCell = gridRef.current.api.getFocusedCell();
        if (focusedCell) {
            const { rowIndex, column } = focusedCell;
            const { colDef: { field }, } = column;
            const key = `${rowIndex};;${field}`;
            // Get the cell node
            const node = gridRef.current.api.getDisplayedRowAtIndex(rowIndex);
            if (node && _editedValues[key] === undefined) {
                _editedValues[key] = node.data[field];
                // Update editedNodes
                const [_fieldKey, _levelName] = field.split('::');
                const rowNode = rowList.find((row) => row.id === node.data.id);
                const rowNodeRates = rowNode.comp_grid_rates.filter((r) => {
                    return r.comp_grid_level.name === _levelName;
                });
                const targetNodes = editNode[rowNode.str_id];
                if (targetNodes) {
                    const copyNodes = cloneDeep(targetNodes);
                    const nodeIndex = copyNodes.findIndex((r) => {
                        var _a;
                        return ((_a = r.comp_grid_level) === null || _a === void 0 ? void 0 : _a.name) === _levelName;
                    });
                    if (nodeIndex > -1) {
                        copyNodes[nodeIndex] = rowNodeRates;
                    }
                    else {
                        copyNodes.push(...rowNodeRates);
                    }
                    const newNodes = cloneDeep(editNode);
                    newNodes[rowNode.str_id] = copyNodes.flat();
                    _editNode = newNodes;
                }
                else {
                    const newNodes = cloneDeep(editNode);
                    newNodes[rowNode.str_id] = rowNodeRates.flat();
                    _editNode = newNodes;
                }
            }
        }
        Object.keys(_editedValues).forEach((key) => {
            const rowIndex = key.split(';;')[0];
            let _key = key.split(';;')[1];
            if (!_key.includes('::')) {
                _key += '::criteria';
            }
            const [field, level] = _key.split('::');
            const node = filteredData.find((row) => row.id === parseInt(rowIndex, 10));
            if (node) {
                if (!changeObj[node['str_id']]) {
                    changeObj[node['str_id']] = {
                        [level]: {
                            [field]: _editedValues[key],
                        },
                    };
                }
                else {
                    if (!changeObj[node['str_id']][level]) {
                        changeObj[node['str_id']][level] = {
                            [field]: _editedValues[key],
                        };
                    }
                    else {
                        changeObj[node['str_id']][level][field] = _editedValues[key];
                    }
                }
            }
        });
        // Find rates by str_id
        const changeEntries = Object.entries(changeObj);
        const changedRates = [];
        const changedCriteria = [];
        const newRates = [];
        for (const [strId, item] of changeEntries) {
            const rowItems = _editNode[strId];
            if (!rowItems) {
                continue;
            }
            const tempCriteriaItem = item.criteria;
            if (tempCriteriaItem) {
                const criteria = Object.assign({ str_id: strId }, tempCriteriaItem);
                changedCriteria.push(criteria);
            }
            for (let rate of rowItems) {
                const rateLevelKey = (_a = rate.comp_grid_level) === null || _a === void 0 ? void 0 : _a.name;
                const tempPreItem = item[rateLevelKey];
                if (tempPreItem) {
                    rate = Object.assign(Object.assign({}, rate), tempPreItem);
                    changedRates.push(rate);
                }
                else {
                    // New rate
                    const [, level] = rate.key.split('::'); // TODO: We need to refactor how the columns keys are created to avoid this
                    const newRate = {
                        item: item[level],
                        level_name: level,
                        rate_value: rate.newValue,
                        comp_grid_id: rate.rowNode.comp_grid_id,
                        comp_grid_criterion_id: rate.rowNode.id,
                    };
                    newRates.push(newRate);
                }
            }
        }
        const uniqueNewRates = newRates.filter((rate, index, self) => index ===
            self.findIndex((r) => r.level_name === rate.level_name &&
                r.comp_grid_id === rate.comp_grid_id));
        const newRatesParams = uniqueNewRates.map((rate) => {
            return {
                rates: rate.item,
                level_name: rate.level_name,
                comp_grid_id: rate.comp_grid_id,
                comp_grid_criterion_id: rate.comp_grid_criterion_id,
            };
        });
        const ratesParams = changedRates.map((rate) => {
            return {
                id: rate.id,
                carrier_rate: rate.carrier_rate,
                house_rate: rate.house_rate,
                rate: rate.rate,
                comp_grid_level_id: rate.comp_grid_level_id,
                comp_grid_criterion_id: rate.comp_grid_criterion_id,
                date_ranges: rate.date_ranges,
            };
        });
        const dataParams = {
            rates: ratesParams,
            criteria: changedCriteria,
            new_rates: newRatesParams,
        };
        if (newRatesParams.length > 0) {
            setParams(dataParams);
            setConfirmNewRates(true);
            return;
        }
        if (!dataParams.rates.length && !dataParams.criteria.length) {
            setEditable(false);
            return;
        }
        saveData(dataParams);
    });
    const saveData = (params) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            yield ratesCriteriaPatcher.mutateAsync(params);
        }
        catch (e) {
            console.error(e);
            setLoading(false);
        }
        setLoading(false);
        setConfirmNewRates(false);
        // Toggle editable
        clear();
        // Refetch data
        refetchCompGrids();
    });
    const handleDateRangeChange = (index, newDateRange) => {
        const updatedRates = [...params.new_rates];
        updatedRates[index].date_range = newDateRange;
        setParams(Object.assign(Object.assign({}, params), { new_rates: updatedRates }));
    };
    const clear = () => {
        setCompGrids(filteredRatesCompGrids);
        setEditedCells({});
        setEditedValues({});
        setEditable(false);
        setEditNode({});
    };
    const handleRowSelected = (event) => {
        const selectedNodes = event.api.getSelectedNodes();
        const selectedData = selectedNodes.map((node) => node.data);
        setSelectedRows(selectedData);
    };
    useEffect(() => {
        if (selectedRows.length > 0) {
            const levelsSet = new Set();
            const ratesIdsSet = new Set();
            const _selectedCriteria = [];
            selectedRows.forEach((row, index) => {
                _selectedCriteria[index] = {
                    comp_grid_product: { type: row.product_type, name: row.product },
                    policy_year_start: row.policy_year_start,
                    policy_year_end: row.policy_year_end,
                    issue_age_start: row.issue_age_start,
                    issue_age_end: row.issue_age_end,
                    compensation_type: row.compensation_type,
                };
                Object.keys(row).forEach((key) => {
                    const [field, level] = key.split('::');
                    if (level) {
                        levelsSet.add(level);
                    }
                    if (field === 'rate_id') {
                        ratesIdsSet.add(row[key]);
                    }
                });
            });
            setSelectedLevels(Array.from(levelsSet));
            setSelectedRatesIds(Array.from(ratesIdsSet));
            setSelectedCriteria(_selectedCriteria);
        }
    }, [selectedRows]);
    const bulkEditRates = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const params = {
                ids: selectedRatesIds,
                date_ranges: selectedDateRanges.date_ranges,
            };
            const res = yield ratesBulkPatcher.mutateAsync(params);
            if (res.error) {
                showSnackbar(`An error occurred when updating date range: ${res.error}`, 'error');
            }
            else {
                showSnackbar('Rates updated.', 'success');
            }
        }
        catch (e) {
            console.error(e);
            setLoading(false);
        }
        setSelectedDateRanges([]);
        setLoading(false);
        clear();
        refetchCompGrids();
        setOpenBulkActionsDialog(false);
    });
    const bulkDeleteRates = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const params = {
                ids: selectedRatesIds,
            };
            const res = yield ratesBulkDeleter.mutateAsync(params);
            if (res.error) {
                showSnackbar(`An error occurred when deleting rates: ${res.error}`, 'error');
            }
            else {
                showSnackbar('Rates deleted.', 'success');
            }
        }
        catch (e) {
            console.error(e);
            setLoading(false);
        }
        setSelectedDateRanges([]);
        setLoading(false);
        clear();
        refetchCompGrids();
        setShowConfirmDeleteDialog(false);
    });
    const bulkCopyRates = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const params = {
                ids: selectedRatesIds,
                date_ranges: selectedDateRanges.date_ranges,
            };
            const res = yield copyRatesPoster.mutateAsync(params);
            if (res.errors) {
                showSnackbar(`An error occurred when updating date range: ${res.errors[0].error}`, 'error');
            }
            else {
                showSnackbar('Rates copied.', 'success');
            }
        }
        catch (e) {
            console.error(e);
            setLoading(false);
        }
        setSelectedDateRanges([]);
        setLoading(false);
        clear();
        refetchCompGrids();
        setOpenBulkActionsDialog(false);
    });
    const onCreate = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!adding) {
            setAdding(true);
            return;
        }
        setAddLoading(true);
        if (newRowValues) {
            const response = yield criteriaWithRatesPoster.mutateAsync(newRowValues);
            if (response.data) {
                showSnackbar('New row added successfully', 'success');
                if (newRowNode) {
                    // Clean up the new row
                    Object.keys(initialInputRow).forEach((key) => {
                        newRowNode.data[key] = initialInputRow[key];
                    });
                    Object.keys(newRowNode.data).forEach((key) => {
                        if (!(key in initialInputRow)) {
                            newRowNode.data[key] = null;
                        }
                    });
                    newRowNode.api.refreshCells({
                        rowNodes: [newRowNode.node],
                        force: true,
                    });
                }
                refetchCompGrids();
            }
            else {
                showSnackbar('An error occurred while adding new row', 'error');
            }
        }
        setAdding(false);
        setAddLoading(false);
    });
    // Function to provide a unique ID for each row, this allows to mantain selected rows on re-render
    const getRowId = useMemo(() => (params) => String(params.data.id), []);
    // Custom styles for add new top row
    const getRowHeight = (params) => {
        if (params.node.rowPinned === 'top') {
            return 55;
        }
        return 40;
    };
    const compGridOptions = Array.isArray(compGridFilters)
        ? compGridFilters.map((compGrid) => ({
            id: compGrid.id,
            name: compGrid.name,
        }))
        : [];
    if (!isFetchedAccountSettings)
        return _jsx(LoadingCircle, {});
    return (_jsxs(Box, { sx: { width: '100%', display: 'flex', flexDirection: 'column' }, children: [_jsx("style", { children: `
          .ag-cell-custom-valid {
            background-color: #eaf5fd !important;
            font-weight: bold;
          }
          .ag-cell-custom-invalid {
            border-color: #f60 !important;
            font-weight: bold;
          }
        ` }), _jsx(Box, { sx: { p: 2 }, children: _jsxs(Box, { sx: { display: 'flex', justifyContent: 'space-between' }, children: [_jsx(Typography, { variant: "h5", sx: { whiteSpace: 'nowrap' }, children: pageLabel }), _jsxs(Box, { sx: { display: 'flex', mx: 1 }, children: [_jsx(EnhancedSelect, { enableSearch: true, label: "Comp grids", sx: { width: 'auto', mr: 1 }, options: compGridOptions, value: !selectedCompGrids || (selectedCompGrids === null || selectedCompGrids === void 0 ? void 0 : selectedCompGrids.length) === 0
                                        ? compGridOptions
                                        : compGridOptions.filter((item) => selectedCompGrids === null || selectedCompGrids === void 0 ? void 0 : selectedCompGrids.includes(item.id)), multiple: true, onChange: (v) => {
                                        const ids = (v === null || v === void 0 ? void 0 : v.map((item) => item.id)) || [];
                                        setSearchParams((prev) => {
                                            prev.delete('comp_grids');
                                            if (Array.isArray(compGridOptions) &&
                                                compGridOptions.length !== ids.length) {
                                                ids.forEach((value) => {
                                                    prev.append('comp_grids', value.toString());
                                                });
                                            }
                                            return prev;
                                        });
                                    } }), _jsx(EnhancedSelect, { enableSearch: true, multiple: true, label: "Levels", options: levelNames, value: filteredLevels.filter((item) => Object.values(levelNames).includes(item)), onChange: (values) => {
                                        setFilteredLevels(values);
                                        setSearchParams((prev) => {
                                            prev.delete('levels');
                                            values.forEach((value) => {
                                                if (Array.isArray(levelNames) &&
                                                    levelNames.length !== values.length) {
                                                    prev.append('levels', value);
                                                }
                                            });
                                            return prev;
                                        });
                                    } }), _jsxs(FormControl, { sx: { ml: 1, minWidth: 120 }, children: [_jsx(InputLabel, { children: "Date" }), _jsxs(Select, { value: selectedDateFilter, label: "Date", onChange: (e) => setSelectedDateFilter(e.target.value), sx: {
                                                '.MuiSelect-select': {
                                                    py: 0.75,
                                                    px: 1.5,
                                                    minWidth: 120,
                                                },
                                            }, children: [_jsx(MenuItem, { value: "today", children: "Today" }), _jsx(MenuItem, { value: "singleDate", children: "Date" }), _jsx(MenuItem, { value: "dateRange", children: "Date range" })] })] }), selectedDateFilter === 'singleDate' && (_jsx(BasicDatePicker, { label: "Effective date", value: effectiveDateFilterValue, setValue: (e) => setEffectiveDateFilterValue(e), sx: { ml: 1, minWidth: 152 } })), selectedDateFilter === 'dateRange' && (_jsx(_Fragment, { children: _jsxs(FormControl, { sx: { ml: 1, mr: 1, minWidth: 148 }, children: [_jsx(InputLabel, { sx: { lineHeight: 1 }, children: "Date range" }), _jsx(Select, { value: dateRangeFilterValue !== null && dateRangeFilterValue !== void 0 ? dateRangeFilterValue : '', label: "Date range", onChange: (e) => {
                                                    setDateRangeFilterValue(parseInt(e.target.value));
                                                }, sx: {
                                                    '.MuiSelect-select': { py: 0.75, px: 1.5 },
                                                }, children: dateRangesData === null || dateRangesData === void 0 ? void 0 : dateRangesData.map((dateRange) => (_jsx(MenuItem, { value: dateRange.id, children: dateRange.name
                                                        ? `${dateRange.name}: ${CommonFormatter.dateRange(dateRange.start_date, dateRange.end_date)}`
                                                        : `${CommonFormatter.dateRange(dateRange.start_date, dateRange.end_date)}` }, dateRange.id))) })] }) }))] }), _jsxs(Box, { children: [_jsxs(Box, { sx: { display: 'flex' }, children: [_jsx(SearchBox, { id: "comp_grids" }), _jsx(SearchSettings, { settings: searchSettings }), !readOnly && (_jsxs(_Fragment, { children: [editable && (_jsxs(_Fragment, { children: [_jsx(Button, { sx: { ml: 3 }, variant: "outlined", disabled: selectedRows.length === 0, onClick: () => setOpenBulkActionsDialog(true), children: "Bulk actions" }), _jsx(Button, { sx: { ml: 1 }, onClick: clear, children: "Cancel" })] })), _jsx(LoadingButton, { variant: "contained", sx: { ml: 1 }, onClick: onSave, loading: loading, children: editable ? 'Save' : 'Edit' }), _jsx(LoadingButton, { variant: "contained", sx: { ml: 1 }, onClick: onCreate, loading: addLoading, children: adding ? 'Save' : 'Add' }), _jsx(Button, { startIcon: _jsx(Download, {}), variant: "outlined", sx: { ml: 1 }, onClick: onExportCsv, children: "Export" })] }))] }), editable && (_jsx(Box, { sx: {
                                        display: 'flex',
                                        justifyContent: 'end',
                                        mt: 1,
                                    }, children: _jsx(Typography, { variant: "caption", sx: {
                                            backgroundColor: '#ffffaa',
                                            p: 0.5,
                                            pb: 0.25,
                                            lineHeight: 'unset',
                                            borderRadius: 2,
                                        }, children: "\u2139\uFE0F Click rates to edit" }) }))] })] }) }), _jsx(Box, { sx: { flex: 1 }, className: "ag-theme-material", children: !isLoadingCompGrids ? (_jsxs(Box, { sx: { height: '100%', width: '100%' }, children: [_jsx(AgGridReact, { pinnedTopRowData: adding ? [inputRow] : undefined, headerHeight: 40, columnDefs: columnDefs, rowData: filteredData, suppressHorizontalScroll: false, alwaysShowHorizontalScroll: true, debounceVerticalScrollbar: true, ref: gridRef, onCellValueChanged: onCellValueChanged, singleClickEdit: true, tooltipShowDelay: 500, rowSelection: editable ? 'multiple' : undefined, onRowSelected: handleRowSelected, suppressRowClickSelection: true, getRowId: getRowId, gridOptions: {
                                suppressMovableColumns: true,
                            }, getRowHeight: getRowHeight }), _jsx(TablePagination, { rowsPerPageOptions: [50, 250, 500, 1000], component: "div", count: criteriaCount, rowsPerPage: +((_c = searchParams.get('limit')) !== null && _c !== void 0 ? _c : 50), page: +((_d = searchParams.get('page')) !== null && _d !== void 0 ? _d : 0), onPageChange: (e, newPage) => {
                                setSearchParams((prev) => {
                                    prev.set('page', newPage.toString());
                                    return prev;
                                });
                            }, onRowsPerPageChange: (e) => {
                                setSearchParams((prev) => {
                                    prev.set('limit', e.target.value);
                                    prev.set('page', '0');
                                    return prev;
                                });
                            }, sx: {
                                '&.MuiTablePagination-root': {
                                    position: 'fixed',
                                    bottom: 0,
                                    right: 0,
                                    background: 'white',
                                },
                                '& .MuiTablePagination-toolbar': {
                                    minHeight: 42,
                                    height: 42,
                                    pl: 2,
                                },
                            } })] })) : (_jsx(LoadingCircle, {})) }), _jsx(CompGridBulkActions, { openBulkActionsDialog: openBulkActionsDialog, setOpenBulkActionsDialog: setOpenBulkActionsDialog, selectedCriteria: selectedCriteria, selectedLevels: selectedLevels, selectedDateRanges: selectedDateRanges, setSelectedDateRanges: setSelectedDateRanges, loading: loading, bulkEditRates: bulkEditRates, bulkCopyRates: bulkCopyRates, bulkDeleteRates: bulkDeleteRates, showConfirmDeleteDialog: showConfirmDeleteDialog, setShowConfirmDeleteDialog: setShowConfirmDeleteDialog }), _jsx(ConfirmNewRatesDialog, { open: confirmNewRates, onClose: () => setConfirmNewRates(false), params: params, compGrids: compGrids, selectedDateRanges: selectedDateRanges, handleNewRatesConfirm: handleNewRatesConfirm, handleDateRangeChange: handleDateRangeChange })] }));
};
export default CompGridsView;
