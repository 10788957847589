import { jsx as _jsx } from "react/jsx-runtime";
import { Box, TextField } from '@mui/material';
import DataView from '@/components/DataView';
import { useRoleStore } from '@/store';
import { Roles } from '@/types';
const AccountConfigsSettings = () => {
    const { userRole } = useRoleStore();
    const dataDesc = {
        label: 'Account configurations',
        table: 'accounts/configs',
        editable: userRole === Roles.ACCOUNT_ADMIN,
        fields: [
            {
                id: 'type',
                label: 'Type',
                required: true,
                type: 'select',
                options: [
                    { label: 'Data sync', id: 'dataSync' },
                    { label: 'Per agent payout status', id: 'per_agent_payout_status' },
                    { label: 'Session', id: 'session' },
                ],
            },
            {
                id: 'value',
                label: 'Configuration',
                type: 'custom',
                render: (field, row, setter) => {
                    let defaultValue;
                    if (row.type === 'session') {
                        defaultValue = JSON.stringify({ session: 60 }, null, 2);
                    }
                    const value = typeof row.value === 'string'
                        ? row.value
                        : JSON.stringify(row.value, null, 2);
                    // Set default value
                    if (defaultValue && !row.value) {
                        setter(Object.assign(Object.assign({}, row), { value: defaultValue }));
                    }
                    return (_jsx(TextField, { style: { width: '100%' }, label: "Configuration", value: value || defaultValue, multiline: true, rows: 8, onChange: (e) => {
                            try {
                                const parsedValue = JSON.parse(e.target.value);
                                setter(Object.assign(Object.assign({}, row), { [field.id]: parsedValue }));
                            }
                            catch (_a) {
                                setter(Object.assign(Object.assign({}, row), { [field.id]: e.target.value }));
                            }
                        } }));
                },
            },
            {
                id: 'notes',
                label: 'Notes',
                type: 'text',
            },
        ],
    };
    return (_jsx(Box, { children: _jsx(DataView, { dataDesc: dataDesc }) }));
};
export default AccountConfigsSettings;
