import { useEffect, useRef, useState } from 'react';

import { useAccountStore } from '@/store';
import API from '@/services/API';

const SESSION_CHECK_INTERVAL = 30000; // Check every 30 seconds
const WARNING_THRESHOLD = 60000; // 1 minute warning

export const useSessionMonitor = () => {
  const { selectedAccount } = useAccountStore();
  const [showCountdown, setShowCountdown] = useState(false);
  const [sessionLeft, setSessionLeft] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout | undefined>(undefined);

  useEffect(() => {
    if (!selectedAccount?.accountId) return;

    const checkSession = async () => {
      try {
        const response = await API.get('session/check');
        if (response?.session === null) {
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
          }
          return;
        }
        if (response?.sessionLeft === 0) {
          handleLogout();
          return;
        }
        setSessionLeft(response.sessionLeft);

        // Show warning when less than 1 minute left
        if (
          response?.sessionLeft > 0 &&
          response?.sessionLeft * 60 * 1000 <= WARNING_THRESHOLD
        ) {
          setShowCountdown(true);
        } else {
          setShowCountdown(false);
        }
      } catch (error) {
        console.error('Error checking session:', error);
      }
    };

    intervalRef.current = setInterval(checkSession, SESSION_CHECK_INTERVAL);

    // Update last activity on user interaction
    const updateActivity = () => setShowCountdown(false);
    window.addEventListener('mousemove', updateActivity);
    window.addEventListener('keydown', updateActivity);
    window.addEventListener('click', updateActivity);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      window.removeEventListener('mousemove', updateActivity);
      window.removeEventListener('keydown', updateActivity);
      window.removeEventListener('click', updateActivity);
    };
  }, [selectedAccount?.accountId]);

  const handleLogout = () => {
    // Clear sensitive data
    window.dispatchEvent(new Event('sessionExpired'));
  };
  return { showCountdown, sessionLeft };
};
