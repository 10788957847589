var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';
import dayjs from 'dayjs';
const PolicyCard = (_a) => {
    var { policy } = _a, props = __rest(_a, ["policy"]);
    const groupedStyle = {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
        opacity: 0.65,
    };
    return (_jsx(Card, Object.assign({ component: 'li' }, props, { sx: Object.assign({ listStyleType: 'none' }, (policy.state === 'grouped' ? groupedStyle : {})), children: _jsxs(CardContent, { style: { paddingTop: 1, paddingBottom: 1 }, children: [_jsxs(Typography, { variant: "subtitle1", sx: { fontWeight: 'bold' }, children: ["Policy number: ", policy.policy_id] }), _jsxs(Typography, { variant: "body2", color: "text.secondary", children: ["Customer name: ", policy.customer_name] }), _jsxs(Typography, { variant: "body2", color: "text.secondary", children: ["Agent name: ", policy.agent_name] }), _jsxs(Typography, { variant: "body2", color: "text.secondary", children: ["Effective date:", ' ', policy.effective_date
                            ? dayjs.utc(policy.effective_date).format('MM/DD/YYYY')
                            : 'N/A'] })] }) }), policy.id));
};
export default PolicyCard;
