import { convertArrayOfMapsToArrayOfArrays } from 'common/helpers';
import removeColsWithName, { desc_removeColsWithName, } from './removeColsWithName';
import mapToArray, { desc_mapToArray } from './mapToArray';
import spliceExcelArray, { desc_spliceExcelArray } from './spliceExcelArray';
import pickData, { desc_pickData } from './pickData';
import findTextLine, { desc_findTextLine } from './findTextLine';
import findTextLineWithContext, { desc_findTextLineWithContext, } from './findTextLineWithContext';
import findAllTextLine, { desc_findAllTextLine } from './findAllTextLine';
import findAllTextLineWithContext, { desc_findAllTextLineWithContext, } from './findAllTextLineWithContext';
import convertToNumber, { desc_convertToNumber } from './convertToNumber';
import getPercent, { desc_getPercent } from './getPercent';
import splitFormatDate, { desc_splitFormatDate } from './splitFormatDate';
import autoMapping, { desc_autoMapping } from './autoMapping';
import convertArrayToMap, { desc_convertArrayToMap } from './convertArrayToMap';
import findTextLineIndex, { desc_findTextLineIndex } from './findTextLineIndex';
import findTextLineByIndex, { desc_findTextLineByIndex, } from './findTextLineByIndex';
import findTextLineAllIndex, { desc_findTextLineAllIndex, } from './findTextLineAllIndex';
import findAllTextLineByIndex, { desc_findAllTextLineByIndex, } from './findAllTextLineByIndex';
import findAllDate, { desc_findAllDate } from './findAllDate';
import dateProcessor, { desc_dateProcessor } from './dateProcessor';
import convertObjectListToArrayList, { desc_convertObjectListToArrayList, } from './convertObjectListToArrayList';
import parallelArray, { desc_parallelArray } from './parallelArray';
import fillDown, { desc_fillDown } from './fillDown';
import parseDate, { desc_parseDate } from './parseDate';
import convertMapToArray, { desc_convertMapToArray } from './convertMapToArray';
import findTargetIn2DArray, { desc_findTargetIn2DArray, } from './findTargetIn2DArray';
import isValidDateFormat, { desc_isValidDateFormat } from './isValidDateFormat';
/**
 * Tool funtion list
 */
export const tool = {
    removeColsWithName,
    mapToArray,
    spliceExcelArray,
    parseDate,
    pickData,
    findTextLine,
    findTextLineWithContext,
    findAllTextLine,
    findAllTextLineWithContext,
    findTextLineIndex,
    findTextLineAllIndex,
    findTextLineByIndex,
    findAllTextLineByIndex,
    findAllDate,
    dateProcessor,
    convertToNumber,
    getPercent,
    convertMapToArray,
    splitFormatDate,
    autoMapping,
    convertArrayToMap,
    convertArrayOfMapsToArrayOfArrays,
    convertObjectListToArrayList,
    parallelArray,
    fillDown,
    findTargetIn2DArray,
    isValidDateFormat,
};
export const toolDesc = {
    Array: {
        mapToArray: desc_mapToArray,
        convertArrayToMap: desc_convertArrayToMap,
        convertMapToArray: desc_convertMapToArray,
        convertObjectListToArrayList: desc_convertObjectListToArrayList,
        spliceExcelArray: desc_spliceExcelArray,
        findTargetIn2DArray: desc_findTargetIn2DArray,
        parallelArray: desc_parallelArray,
        fillDown: desc_fillDown,
    },
    Date: {
        parseDate: desc_parseDate,
        isValidDateFormat: desc_isValidDateFormat,
        splitFormatDate: desc_splitFormatDate,
        dateProcessor: desc_dateProcessor,
    },
    Number: {
        convertToNumber: desc_convertToNumber,
        getPercent: desc_getPercent,
    },
    Lines: {
        findTextLine: desc_findTextLine,
        findTextLineWithContext: desc_findTextLineWithContext,
        findAllTextLine: desc_findAllTextLine,
        findAllTextLineWithContext: desc_findAllTextLineWithContext,
        findTextLineIndex: desc_findTextLineIndex,
        findTextLineAllIndex: desc_findTextLineAllIndex,
        findTextLineByIndex: desc_findTextLineByIndex,
        findAllTextLineByIndex: desc_findAllTextLineByIndex,
        findAllDate: desc_findAllDate,
    },
    Utility: {
        pickData: desc_pickData,
        autoMapping: desc_autoMapping,
        removeColsWithName: desc_removeColsWithName,
    },
};
