import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Select, Skeleton, TextField, Typography, } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { Close as CloseIcon } from '@mui/icons-material';
import { AgentCommissionsStatusesLabels } from 'common/globalTypes';
import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';
import MultiSelectV2 from '@/components/molecules/MultiSelectV2';
const PayoutStatusEdit = ({ data, field, setter, dynamicSelects = [], }) => {
    const idToUse = useMemo(() => {
        return ['comp_calc', 'comp_calc_status'].includes(field.id)
            ? 'id'
            : 'str_id';
    }, [field.id]);
    const agentDataToUse = useMemo(() => {
        let dataToUse = {};
        const { agent_commissions_status2: agentCommissionsStatus, comp_calc_status: compCalcStatus, } = data || {};
        switch (field.id) {
            case 'agent_commissions_status2':
                dataToUse = agentCommissionsStatus || {};
                break;
            case 'comp_calc_status':
                dataToUse = compCalcStatus || {};
                break;
            default:
                dataToUse = {};
        }
        return dataToUse;
    }, [data, field.id]);
    const [selectedAgents, setSelectedAgents] = useState([]);
    const [query, setQuery] = React.useState('');
    const { selectedAccount } = useAccountStore();
    const availableAgentIds = useMemo(() => {
        const ids = Object.keys(agentDataToUse !== null && agentDataToUse !== void 0 ? agentDataToUse : {});
        return idToUse === 'id' ? ids.map(Number) : ids;
    }, [agentDataToUse, idToUse]);
    const availableAgents = useMemo(() => {
        return availableAgentIds.length > 0
            ? dynamicSelects.filter((agent) => availableAgentIds.includes(agent[idToUse]))
            : dynamicSelects;
    }, [availableAgentIds, dynamicSelects, idToUse]);
    const updateAgentCommissionsStatus = (agentId, value) => {
        setter((prevData) => {
            const newAgentCommissionsStatus = Object.assign({}, prevData[field.id]);
            newAgentCommissionsStatus[agentId] = value;
            return Object.assign(Object.assign({}, prevData), { [field.id]: newAgentCommissionsStatus });
        });
    };
    const removeAgentCommissionsStatus = (agentId) => {
        setter((prevData) => {
            const newAgentCommissionsStatus = Object.assign({}, prevData[field.id]);
            delete newAgentCommissionsStatus[agentId];
            return Object.assign(Object.assign({}, prevData), { [field.id]: newAgentCommissionsStatus });
        });
    };
    const handleAddSelected = () => {
        selectedAgents.forEach((agentId) => {
            if (!agentDataToUse[agentId]) {
                updateAgentCommissionsStatus(agentId, '');
            }
        });
        setSelectedAgents([]);
    };
    const filterOptions = (options, query) => {
        if (!query)
            return options;
        const lowerQuery = query === null || query === void 0 ? void 0 : query.toLowerCase();
        return options.filter((option) => {
            var _a, _b, _c, _d;
            return ((_b = (_a = option.first_name) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === null || _b === void 0 ? void 0 : _b.includes(lowerQuery)) ||
                ((_d = (_c = option.last_name) === null || _c === void 0 ? void 0 : _c.toLowerCase()) === null || _d === void 0 ? void 0 : _d.includes(lowerQuery));
        });
    };
    return (_jsxs(Box, { children: [_jsx(Typography, { variant: "subtitle2", children: field.label }), _jsx(Box, { sx: {
                    width: '100%',
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                }, children: _jsxs(Box, { sx: {
                        mt: 0.5,
                        p: 0.5,
                        display: 'flex',
                        flexDirection: 'column',
                        borderStyle: 'solid',
                        borderColor: 'silver',
                        borderWidth: 1,
                        borderRadius: 4,
                        width: '100%',
                        backgroundColor: '#2196f308',
                    }, children: [_jsxs(Box, { sx: { my: 0.5, display: 'flex', flexWrap: 'wrap' }, children: [agentDataToUse &&
                                    Object.entries(agentDataToUse).map(([k, v]) => {
                                        var _a;
                                        const contact = (_a = availableAgents === null || availableAgents === void 0 ? void 0 : availableAgents.find((e) => (e === null || e === void 0 ? void 0 : e[idToUse]) === (idToUse === 'id' ? +k : k))) !== null && _a !== void 0 ? _a : { id: undefined };
                                        return contact.id ? (_jsxs(Box, { sx: {
                                                m: 0.5,
                                                p: 1,
                                                borderStyle: 'solid',
                                                borderColor: 'silver',
                                                borderWidth: 1,
                                                borderRadius: 4,
                                                display: 'inline-block',
                                                width: 180,
                                                backgroundColor: '#2196f30a',
                                            }, children: [_jsxs(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", position: "relative", sx: { ml: 0.5 }, children: [_jsx(Typography, { variant: "body2", children: Formatter.contact(contact, {
                                                                account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                                                            }) }), _jsx(IconButton, { onClick: () => {
                                                                removeAgentCommissionsStatus(contact[idToUse]);
                                                            }, children: _jsx(CloseIcon, {}) })] }), _jsx(Box, { sx: { mt: 1 }, children: _jsx(MultiSelectV2, { options: Object.values(AgentCommissionsStatusesLabels) || [], value: {
                                                            value: (v === null || v === void 0 ? void 0 : v.toString()) || '',
                                                            label: (v === null || v === void 0 ? void 0 : v.toString()) || '',
                                                            data: (v === null || v === void 0 ? void 0 : v.toString()) || '',
                                                        }, label: "Payout status", formatter: (o) => o, onChange: (value) => {
                                                            updateAgentCommissionsStatus(contact[idToUse], value === null || value === void 0 ? void 0 : value.value);
                                                        } }) })] }, `${contact.id}`)) : (_jsx(Skeleton, {}, k));
                                    }), _jsx(Box, { display: "flex", justifyContent: "space-between", position: "relative", children: _jsxs(FormControl, { sx: { m: 0.5, width: 145 }, children: [_jsx(InputLabel, { id: `${field.id}-label`, children: "Add" }), _jsxs(Select, { labelId: `${field.id}-label`, id: field.id, label: "Add", multiple: true, value: selectedAgents, onClick: (e) => {
                                                    e.stopPropagation();
                                                    e.preventDefault();
                                                }, onClose: handleAddSelected, onChange: (e) => {
                                                    setSelectedAgents(Array.isArray(e.target.value)
                                                        ? e.target.value.filter(Boolean)
                                                        : [e.target.value]);
                                                }, sx: {
                                                    '& .MuiInputBase-input.Mui-disabled': {
                                                        WebkitTextFillColor: '#333',
                                                    },
                                                }, renderValue: (selected) => (_jsx(Box, { sx: { display: 'flex', flexWrap: 'wrap', gap: 0.5 }, children: Array.isArray(selected) &&
                                                        selected.map((value) => {
                                                            const item = availableAgents.find((option) => option.id === value);
                                                            return (_jsx(Chip, { label: item ? item.name : value }, item ? item[idToUse] : value));
                                                        }) })), children: [_jsx(Box, { sx: {
                                                            mb: 0.5,
                                                            mx: 1,
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'space-between',
                                                        }, onKeyDown: (e) => {
                                                            e.stopPropagation();
                                                        }, children: _jsx(TextField, { sx: { flex: 1, mr: 1 }, label: "Search", value: query, onChange: (e) => {
                                                                setQuery(e.target.value);
                                                            } }) }), _jsx(Button, { sx: { ml: 1 }, variant: "outlined", onClick: handleAddSelected, children: "Add selected" }), field.nullable && (_jsx(MenuItem, { value: '', children: "\u00A0" }, "null")), filterOptions(availableAgents, query).map((option) => (_jsx(MenuItem, { value: option[idToUse], children: Formatter.contact(option, {
                                                            account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                                                        }) }, option.id)))] })] }, field.id) })] }), Object.keys(data).filter((k) => ![
                            'agent_commissions_status2_only_update_existing',
                            'agent_commissions_status2',
                        ].includes(k)).length === 0 && (_jsx(FormGroup, { sx: { ml: 1 }, children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: data.agent_commissions_status2_only_update_existing, onChange: (e) => setter((prevData) => {
                                        return Object.assign(Object.assign({}, prevData), { agent_commissions_status2_only_update_existing: e.target.checked });
                                    }) }), label: "Only update existing payout status (otherwise will add)" }) }))] }) }, `${field.id}-box`)] }));
};
export default PayoutStatusEdit;
