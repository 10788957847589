var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Chat, DeleteOutlineOutlined, Edit } from '@mui/icons-material';
import { Box, Chip } from '@mui/material';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { CommentDrawer } from '@/common';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import ActionModal from './ActionModal';
import { PriorityList } from './enum';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import { NotesEntityTypes } from '@/types';
const DocumentProfileView = ({ variant = '' }) => {
    const [current, setCurrent] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showComment, setShowComment] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const statusList = useMemo(() => {
        return [
            {
                value: 'draft',
                label: 'Draft',
                color: '',
            },
            {
                value: 'in_review',
                label: 'In review',
                color: 'blue',
            },
            {
                value: 'need_update',
                label: 'Need update',
                color: 'yellow',
            },
            {
                value: 'approved',
                label: 'Approved',
                color: 'green',
            },
        ];
    }, []);
    const queryClient = useQueryClient();
    const { data: processors = [] } = API.getBasicQuery('processors');
    const { data: companies = [] } = API.getBasicQuery('companies/all');
    const { data: owners = [] } = API.getBasicQuery('users/get_fintary_admins');
    const { data: mappings = [] } = API.getBasicQuery('mappings');
    const { data } = API.getBasicQuery('admin/users');
    const accounts = data === null || data === void 0 ? void 0 : data.data;
    const deleter = API.getMutation('document_profiles', 'DELETE');
    const dataDesc = useMemo(() => ({
        label: 'Document profiles',
        table: 'document_profiles',
        fields: {
            name: {
                label: 'Name',
                enabled: true,
            },
            company_name: {
                label: 'Carrier/Paying entity',
                enabled: true,
            },
            document_str_ids: {
                label: 'Documents',
                enabled: true,
                tableFormatter: (strIds, row) => {
                    return (_jsx(Box, { children: _jsx(Chip, { component: Link, to: `/admin/documents?profile=${row.str_id}`, label: strIds.length, clickable: true }) }));
                },
            },
            owner: {
                label: 'Owner',
                enabled: true,
                formatter: (s) => {
                    const target = owners === null || owners === void 0 ? void 0 : owners.find((c) => c.uid === s);
                    return Formatter.contact(target);
                },
            },
            account_id: {
                label: 'Requester',
                enabled: true,
                formatter: (s) => {
                    const target = accounts === null || accounts === void 0 ? void 0 : accounts.find((c) => { var _a; return ((_a = c.account_user_roles[0]) === null || _a === void 0 ? void 0 : _a.account_id) === s; });
                    return Formatter.contact(target);
                },
            },
            priority: {
                label: 'Priority',
                enabled: true,
                formatter: (s, _row) => {
                    const tag = PriorityList.find((item) => item.value === s);
                    if (!tag)
                        return '';
                    return Formatter.statusChip(tag === null || tag === void 0 ? void 0 : tag.label, {
                        mapping: {
                            [tag === null || tag === void 0 ? void 0 : tag.label]: tag === null || tag === void 0 ? void 0 : tag.color,
                        },
                    });
                },
            },
            status: {
                label: 'Status',
                enabled: true,
                formatter: (s) => {
                    const tag = statusList.find((item) => item.value === s);
                    if (!tag)
                        return '';
                    return Formatter.statusChip(tag === null || tag === void 0 ? void 0 : tag.label, {
                        mapping: {
                            [tag === null || tag === void 0 ? void 0 : tag.label]: tag === null || tag === void 0 ? void 0 : tag.color,
                        },
                    });
                },
            },
            processor_str_ids: {
                label: 'Processors',
                enabled: true,
                tableFormatter: (strIds, row) => {
                    return (_jsx(Box, { children: strIds.length ? (_jsx(Chip, { component: Link, to: `/processors?profile=${row.str_id}`, label: strIds.length, clickable: true })) : ('') }));
                },
            },
            mapping: {
                label: 'Mapping',
                enabled: true,
                formatter: Formatter.getLinkChipFormatter('name', 'str_id', '/mappings?id='),
            },
            notes: {
                label: 'Notes',
                enabled: true,
                formatter: (text) => text.split('\n').map((line, index) => (_jsxs(Fragment, { children: [line, _jsx("br", {})] }, index))),
            },
            created_at: {
                label: 'Created at',
                enabled: true,
                formatter: (s) => Formatter.date(s, {
                    format: 'MM/DD/YYYY hh:mm:A',
                }),
                readOnly: true,
            },
        },
        queryChips: {
            all: {
                id: 'all',
                label: 'All',
                query: {},
            },
            global: {
                id: 'global',
                label: 'Fintary',
                query: {
                    group_by: 'carrier_name',
                    access: 'global',
                },
            },
            draft: {
                id: 'draft',
                label: 'Draft',
                query: {
                    status: 'draft',
                },
            },
            in_review: {
                id: 'in_review',
                label: 'In review',
                query: {
                    status: 'in_review',
                },
            },
            need_update: {
                id: 'need_update',
                label: 'Need update',
                query: {
                    status: 'need_update',
                },
            },
            approved: {
                id: 'approved',
                label: 'Approved',
                query: {
                    status: 'approved',
                },
            },
        },
        actions: [
            {
                label: 'Edit',
                type: 'iconButton',
                icon: _jsx(Edit, {}),
                onClick: (row) => {
                    setCurrent(row);
                    setShowAddModal(true);
                },
            },
            {
                label: 'Delete',
                type: 'iconButton',
                icon: _jsx(DeleteOutlineOutlined, {}),
                onClick: (row) => __awaiter(void 0, void 0, void 0, function* () {
                    setCurrent(row);
                    const res = yield deleter.mutateAsync({ ids: [row.id] });
                    if (res.error) {
                        alert(res.error);
                    }
                    else {
                        setRefresh((prev) => prev + 1);
                    }
                }),
            },
            {
                label: 'Chat',
                type: 'iconButton',
                icon: _jsx(Chat, {}),
                onClick: (row) => {
                    setCurrent(row);
                    setShowComment(true);
                },
            },
        ],
    }), [companies, owners, accounts]);
    const closeModal = (evt, reason) => {
        if (reason && reason === 'backdropClick')
            return;
        setShowAddModal(false);
        setRefresh((prev) => prev + 1);
        setCurrent(null);
    };
    useEffect(() => {
        if (refresh === 0)
            return;
        queryClient.invalidateQueries();
    }, [refresh]);
    return (_jsxs(_Fragment, { children: [_jsx(EnhancedDataView, { dataSpec: dataDesc, hideAdd: true, hideSelectedCount: true, enableMultiSelect: false, enableEdit: false, actionsEnabled: () => true, 
                // @ts-ignore
                actions: dataDesc.actions, variant: variant, hideExport: true }), showAddModal && (_jsx(ActionModal, { open: showAddModal, handleCancel: closeModal, rowData: current, companies: companies, owners: owners, processors: processors, mappings: mappings })), showComment && (_jsx(CommentDrawer, { open: showComment, setOpen: setShowComment, rowData: current, type: NotesEntityTypes.PROCESSOR }))] }));
};
export default DocumentProfileView;
