var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { SavedReportStatuses, SavedReportStatusesLabels, } from 'common/globalTypes';
import Formatter from '@/services/Formatter';
const PayReportDialog = ({ open, onClose, dataToPay, payReportsHandler, }) => {
    const [loading, setLoading] = useState(false);
    return (_jsxs(Dialog, { open: open, onClose: onClose, maxWidth: "sm", fullWidth: true, children: [_jsx(DialogTitle, { children: "Set reports as paid" }), _jsx(DialogContent, { children: _jsx(TableContainer, { children: _jsxs(Table, { children: [_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, { children: "Report name" }), _jsx(TableCell, { children: "Current status" }), _jsx(TableCell, { children: "Agent name" })] }) }), _jsx(TableBody, { children: dataToPay.map((report) => (_jsxs(TableRow, { children: [_jsx(TableCell, { children: report.reportName }), _jsx(TableCell, { children: Formatter.statusChip(SavedReportStatusesLabels[report.reportStatus], {
                                                mapping: {
                                                    [SavedReportStatusesLabels[SavedReportStatuses.APPROVED]]: 'blue',
                                                },
                                            }) }), _jsx(TableCell, { children: report.agentName })] }, report.reportStrId))) })] }) }) }), _jsxs(DialogActions, { children: [_jsx(Button, { onClick: onClose, color: "primary", children: "Cancel" }), _jsx(LoadingButton, { onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            setLoading(true);
                            yield payReportsHandler(dataToPay.map((report) => report.reportStrId));
                            setLoading(false);
                            onClose();
                        }), loading: loading, variant: "contained", children: "Mark as paid" })] })] }));
};
export default PayReportDialog;
