import { DocumentTypeLabels } from 'common/constants/documents';
import { useCompanies } from '@/api/companies';
import { SyncStatusList } from '@/components/admin/AdminDocumentsView/DocumentsView/DocumentsView';
export const useFilters = ({ isAdmin } = {}) => {
    const { data: { data: companies } = {} } = useCompanies({ isAdmin });
    const filtersData = {
        type: Object.entries(DocumentTypeLabels).map(([id, name]) => ({
            id,
            name,
        })),
        companies: (companies === null || companies === void 0 ? void 0 : companies.map((company) => ({
            id: company.str_id,
            name: company.company_name,
        }))) || [],
        sync_status: SyncStatusList.map((syncStatus) => ({
            id: syncStatus.id,
            name: syncStatus.label,
        })),
    };
    const filters = {
        type: {
            label: 'Type',
        },
        companies: {
            label: 'Companies',
        },
        sync_status: {
            label: 'Sync status',
        },
    };
    return {
        filters: filters,
        filtersData: filtersData,
    };
};
