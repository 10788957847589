import getIndices from './getIndices';
const parallelArray = (array, colPattern, colNames, tag) => {
    const patternColumnIndex = getIndices(array, [colPattern])[0];
    const columnsToProcess = getIndices(array, colNames);
    const updatedArray = array.map((row) => [...row]);
    // Store the mapping of row indices and character indices
    const patternMappings = [];
    for (let rowIndex = 1; rowIndex < updatedArray.length; rowIndex++) {
        // Remove all whitespace characters from the pattern column value
        const patternValue = updatedArray[rowIndex][patternColumnIndex].replace(/\s+/g, '');
        // Check if the pattern value contains the specified tag
        if (patternValue.includes(tag)) {
            // Split the pattern value by the tag
            const splitParts = patternValue.split(tag);
            // Process each part of the split result
            splitParts.forEach((part, partIndex) => {
                if (part) {
                    updatedArray[rowIndex][patternColumnIndex] = part;
                    patternMappings.push([rowIndex, partIndex]);
                }
            });
        }
    }
    // Process the columns based on the pattern mappings
    columnsToProcess.forEach((columnIndex) => {
        patternMappings.forEach(([rowIndex, partIndex]) => {
            // Clean up the column value by removing unwanted spaces around $ and %
            const cleanedValue = updatedArray[rowIndex][columnIndex]
                .trim()
                .replace(/\$\s+/g, '$')
                .replace(/\s+%/g, '%');
            // Split the cleaned value by spaces
            const valueParts = cleanedValue.split(' ');
            // Update the cell with the appropriate part based on the partIndex
            updatedArray[rowIndex][columnIndex] =
                valueParts[partIndex] || valueParts[0];
        });
    });
    return updatedArray;
};
export const desc_parallelArray = {
    name: 'libs.tools.parallelArray(array, colPattern, colNames, tag)',
    description: 'Splits and aligns values in specified columns based on a pattern column and tag.',
    arguments: {
        array: '[Array<Array<string>>] 2D array of data',
        colPattern: '[String] Column name containing the pattern to match',
        colNames: '[Array<string>] Column names to process',
        tag: '[String] Tag used to split values',
    },
    returns: '[Array<Array<string>>] Processed array with aligned values',
    examples: 'parallelArray([\n  ["a", "b", "c"],\n  ["100 total", "1 2", "1 2"],\n  ["total 100", "1 2", "1 2"]\n], "a", ["b", "c"], "total")\n// → [\n//   ["a", "b", "c"],\n//   ["100", "1", "1"],\n//   ["100", "2", "2"]\n// ]\n',
};
export default parallelArray;
