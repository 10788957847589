var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Button, FormControl, InputLabel, MenuItem, Select, ToggleButton, ToggleButtonGroup, Tooltip, Typography, } from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { dateOrDefault } from 'common/helpers';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import BasicDateRangePicker from '@/common/BasicDateRangePicker';
import useSnackbar from '@/contexts/useSnackbar';
import DataUpdateGroupViewer from '@/components/ToolsPage/DataUpdateTools/DataUpdateGroupViewer';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
import DataUpdatePreviewDialog from '@/components/ToolsPage/DataUpdateTools/DataUpdatePreviewModal';
var DataFilterOptions;
(function (DataFilterOptions) {
    DataFilterOptions["ALL"] = "all";
    DataFilterOptions["RECONCILED"] = "reconciled";
})(DataFilterOptions || (DataFilterOptions = {}));
const RunDataUpdate = ({ fields }) => {
    const memoizedFields = useMemo(() => fields, [fields]);
    const { showSnackbar } = useSnackbar();
    const [searchDoc, setSearchDoc] = useState('');
    const [showGlobal, setShowGlobal] = useState(false);
    const [globalSelected, setGlobalSelected] = useState(false);
    const [loading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedDocs, setSelectedDocs] = useState([]);
    const [selectedDataUpdateGroup, setSelectedDataUpdateGroup] = useState('');
    const [fieldsToPreview, setFieldsToPreview] = useState([]);
    const [previewData, setPreviewData] = useState([]);
    const [openPreview, setOpenPreview] = useState(false);
    const [selectedDataFilter, setSelectedDataFilter] = useState(DataFilterOptions.RECONCILED);
    const fieldsToPreviewRef = useRef([]);
    const handleClose = () => {
        setOpenPreview(false);
    };
    const { data: _documents } = API.getBasicQuery('documents', 'is_dynamic_select=true&limit=300');
    const documents = useMemo(() => { var _a; return (_a = _documents === null || _documents === void 0 ? void 0 : _documents.data) !== null && _a !== void 0 ? _a : []; }, [_documents === null || _documents === void 0 ? void 0 : _documents.data]);
    const { data: _dataUpdateConfig } = API.getBasicQuery(`data-update/config?global=${showGlobal}`);
    const dataUpdateInfo = useMemo(() => _dataUpdateConfig !== null && _dataUpdateConfig !== void 0 ? _dataUpdateConfig : [], [_dataUpdateConfig]);
    const dataUpdateGroups = Array.from(new Set(dataUpdateInfo.map((data) => data.access === 'global' ? `${data.group} - global` : data.group)));
    const runCompensationTypePreviewPoster = API.getMutation('admin/data-update/preview', 'POST');
    const runCompensationTypePoster = API.getMutation('admin/data-update', 'POST');
    const handleGroupChange = (event) => {
        const group = event.target.value;
        setSelectedDataUpdateGroup(group);
        setGlobalSelected(group.includes(' - global'));
        fieldsToPreviewRef.current = [];
    };
    const filteredDataUpdateInfo = useMemo(() => {
        if (selectedDataUpdateGroup) {
            const normalizedGroup = selectedDataUpdateGroup.replace(' - global', '');
            return dataUpdateInfo.filter((data) => data.group === normalizedGroup);
        }
        return [];
    }, [selectedDataUpdateGroup, dataUpdateInfo]);
    const fieldsList = useMemo(() => {
        var _a;
        if (filteredDataUpdateInfo.length > 0) {
            return memoizedFields[(_a = filteredDataUpdateInfo[0]) === null || _a === void 0 ? void 0 : _a.data_entity] || [];
        }
        return [];
    }, [filteredDataUpdateInfo, memoizedFields]);
    const handleDataUpdatePost = (dataToUpdate) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setLoading(true);
        try {
            const params = {
                dataToUpdate: dataToUpdate.map((data) => {
                    const dataEntity = { data_entity_id: data.data_entity_id };
                    Object.keys(data).forEach((key) => {
                        if (key.includes('-to-change')) {
                            dataEntity[key] = data[key];
                        }
                    });
                    return dataEntity;
                }),
                dataEntity: (_a = filteredDataUpdateInfo[0]) === null || _a === void 0 ? void 0 : _a.data_entity,
            };
            const response = yield runCompensationTypePoster.mutateAsync(params);
            if (response.error) {
                showSnackbar(response.error, 'error');
            }
            else {
                showSnackbar(`${response.data.length} records updated.`, 'success');
            }
        }
        catch (error) {
            const errorMessage = error instanceof Error ? error.message : JSON.stringify(error);
            showSnackbar(errorMessage, 'error');
        }
        setLoading(false);
    });
    const handleDataUpdatePreviewPost = (isPreview) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            const params = {
                data_update_group: selectedDataUpdateGroup.replace(' - global', ''),
                global: globalSelected,
                processing_date_start: dateOrDefault(startDate, undefined),
                processing_date_end: dateOrDefault(endDate, undefined),
                document_str_ids: selectedDocs,
                only_reconcilied_data: selectedDataFilter === DataFilterOptions.RECONCILED,
                preview: isPreview,
                fieldsToPreview: fieldsToPreview.map((field) => field.id),
            };
            const response = yield runCompensationTypePreviewPoster.mutateAsync(params);
            if (response.error) {
                showSnackbar(response.error, 'error');
            }
            else {
                if (isPreview) {
                    if (Array.isArray(response.data)) {
                        setPreviewData(response.data);
                    }
                    setOpenPreview(true);
                    showSnackbar(`Preview generated for ${response.data.length} records.`, 'success');
                }
                else {
                    showSnackbar(`${response.data.length} records updated.`, 'success');
                }
            }
        }
        catch (error) {
            const errorMessage = error instanceof Error ? error.message : JSON.stringify(error);
            showSnackbar(errorMessage, 'error');
        }
        setLoading(false);
    });
    const selectedDocsId = new Set(selectedDocs);
    const filteredDocs = useMemo(() => {
        return documents === null || documents === void 0 ? void 0 : documents.filter((d) => { var _a; return (_a = d.filename) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(searchDoc === null || searchDoc === void 0 ? void 0 : searchDoc.toLowerCase()); });
    }, [documents, searchDoc]);
    const criteriaDefaultFields = useMemo(() => {
        const _fields = new Set();
        filteredDataUpdateInfo.forEach((item) => {
            const { data_update_criteria, data_update_actions } = item;
            data_update_criteria.forEach((criterion) => {
                var _a;
                (_a = criterion.data_update_criteria) === null || _a === void 0 ? void 0 : _a.forEach((c) => {
                    _fields.add(c.field);
                });
            });
            data_update_actions.forEach((action) => {
                _fields.add(action.data_update_actions.field);
            });
        });
        return _fields;
    }, [filteredDataUpdateInfo]);
    useEffect(() => {
        const defaultFields = (fieldsList === null || fieldsList === void 0 ? void 0 : fieldsList.filter((field) => criteriaDefaultFields.has(field.id))) || [];
        if (fieldsToPreviewRef.current.length === 0) {
            setFieldsToPreview(defaultFields);
        }
        else {
            setFieldsToPreview(fieldsToPreviewRef.current);
        }
    }, [fieldsList, criteriaDefaultFields]);
    useEffect(() => {
        fieldsToPreviewRef.current = fieldsToPreview;
    }, [fieldsToPreview]);
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: { mt: 1, mb: 1, ml: 1 }, children: [_jsx(Typography, { variant: "body2", sx: { mb: 1 }, children: "Select data update group to run" }), _jsxs(FormControl, { sx: { width: 250, mt: 1 }, children: [_jsx(InputLabel, { id: "group-select-label", children: "Group" }), _jsx(Select, { labelId: "group-select-label", label: "Group", value: selectedDataUpdateGroup, onChange: handleGroupChange, children: dataUpdateGroups.map((group) => (_jsx(MenuItem, { value: group, children: group }, group))) })] }), _jsx(Button, { variant: "outlined", onClick: () => {
                            setShowGlobal(!showGlobal);
                        }, sx: { ml: 1, mt: 1 }, children: showGlobal ? 'Show account groups' : 'Show global groups' }), _jsx(DataUpdateGroupViewer, { dataUpdateData: filteredDataUpdateInfo, fields: memoizedFields })] }), _jsxs(Box, { sx: { mt: 1, mb: 1, ml: 1 }, children: [_jsx(Typography, { variant: "body2", sx: { mb: 1 }, children: "Commission filters" }), _jsx(BasicDateRangePicker, { range: {
                            startDate: startDate,
                            startDateLabel: 'Processing date start',
                            endDate: endDate,
                            endDateLabel: 'Processing date end',
                        }, onChange: (range) => {
                            setStartDate(range.startDate);
                            setEndDate(range.endDate);
                        }, justify: "left", width: 210 }), _jsx(Box, { children: _jsx(EnhancedSelect, { label: "Document filter", enableSearch: true, multiple: true, valueKey: "str_id", labelKey: "filename", options: filteredDocs, sx: { width: 200, mt: 1.5 }, value: filteredDocs.filter((item) => selectedDocsId.has(item.str_id)), searchKeyword: searchDoc, onSearch: setSearchDoc, renderLabel: (input) => {
                                const doc = filteredDocs.find((d) => d.str_id === (input === null || input === void 0 ? void 0 : input.key));
                                const text = doc
                                    ? `${doc === null || doc === void 0 ? void 0 : doc.filename} (${Formatter.date(doc === null || doc === void 0 ? void 0 : doc.created_at, { format: 'YYYY/MM/DD hh:mmA' })})`
                                    : '';
                                return (_jsx(Tooltip, { title: text, children: _jsx(Typography, { sx: Object.assign(Object.assign({}, input === null || input === void 0 ? void 0 : input.sx), { overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }), children: text }) }));
                            }, onChange: (v) => {
                                setSelectedDocs(v.map((item) => item.str_id));
                            } }) }), _jsxs(ToggleButtonGroup, { sx: { mt: 1 }, value: selectedDataFilter, exclusive: true, onChange: (event, reportPrefix) => {
                            setSelectedDataFilter(reportPrefix);
                        }, color: "primary", children: [_jsx(ToggleButton, { value: DataFilterOptions.RECONCILED, children: "Reconcilied data" }), _jsx(ToggleButton, { value: DataFilterOptions.ALL, children: "All data" })] })] }), _jsx(Box, { sx: { mt: 2 }, children: selectedDataUpdateGroup && fieldsList && (_jsxs(_Fragment, { children: [_jsx(EnhancedSelect, { label: "Fields to preview", enableSearch: true, options: fieldsList, value: fieldsToPreview, onChange: (v) => {
                                setFieldsToPreview(v);
                            }, multiple: true, sx: { ml: 1, minWidth: 200, width: 'fit-content' } }), _jsx(LoadingButton, { loading: loading, variant: "contained", disabled: fieldsToPreview.length === 0, onClick: () => handleDataUpdatePreviewPost(true), sx: { ml: 1 }, children: "Preview update" }), _jsx(DataUpdatePreviewDialog, { handleClose: handleClose, open: openPreview, handleDataUpdatePost: handleDataUpdatePost, loading: loading, previewData: previewData, fieldsToPreview: fieldsToPreview, fields: fieldsList })] })) })] }));
};
export default RunDataUpdate;
