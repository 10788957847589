class Validator {
}
// Age must be between 0-200, and be whole numbers or half numbers
Validator.age = (val, opts = {}) => {
    const { required } = opts;
    if (!required && (val === undefined || val === ''))
        return true;
    if (Number.isNaN(+val))
        return false;
    if (val < 0 || val > 200)
        return false;
    if (val % 0.5 !== 0)
        return false;
    return true;
};
Validator.commissionRate = (val, opts = {}) => {
    const { required } = opts;
    if (!required && (val === undefined || val === ''))
        return true;
    if (Number.isNaN(+val) || val < 0 || val > 5000)
        return false;
    return true;
};
export default Validator;
