var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Divider } from '@mui/material';
import { cloneDeep } from 'lodash';
import { ProcessorSelectorStatuses } from 'common/globalTypes';
import { tool } from 'common/tools';
import CommissionPreview from './CommissionPreview';
import ProcessForm from './ProcessForm';
import ProcessMappingForm from './ProcessMappingForm';
import { ProcessMethodE, ProcessResultE, ProcessFormKey, } from './process';
import useCommonData from './hoc/useCommonData';
import API from '@/services/API';
import { convertFieldListToMapping } from '@/services/helpers';
import { normalizeCurrency } from '@/services/DataTransformation/normalizer';
import useCommissionStore from './stores/useCommissionStore';
const CommissionMapper = ({ file, spreadsheet, fileType, selectedSheet, setSelectedSheet, errors, setErrors, extraction, mpData, setProcessForm, processForm, rowData, setProcessFormatData, processFormatData, fileData, addActionCount, }) => {
    const [processedData, setProcessedData] = useState({
        data: [],
        fields: [],
        version: null,
    });
    const [rangeData, setRangeData] = useState([
        {
            index: 0,
            count: 0,
            fields: [],
            data: [],
        },
    ]);
    const [missingRequiredFields, setMissingRequiredFields] = useState([]);
    const [selectedDataRange, setSelectedDataRange] = useState({
        index: 0,
        count: 0,
        fields: [],
        data: [],
    });
    const rowMapping = useCommissionStore((state) => state.rowMapping);
    const setRowMapping = useCommissionStore((state) => state.setRowMapping);
    // Used to store the current processor to trigger get method
    const [curProcessor, setCurProcessor] = useState();
    const [suggestedProcessors, setSuggestedProcessors] = useState([]);
    const { data: processorData } = API.getBasicQuery('processors');
    const { data: curProcessorData, isLoading: isCurPorcessorLoading } = API.getBasicQuery(`processors/${curProcessor === null || curProcessor === void 0 ? void 0 : curProcessor.id}`, '', !!(curProcessor === null || curProcessor === void 0 ? void 0 : curProcessor.id));
    const processorSelectorPoster = API.getMutation('processors/selector', 'POST');
    const { fields, isExcel, requiredRows, statements, allFieldKeys } = useCommonData(fileType, file);
    /**
     * Share data between the commission mapper and the commission preview
     */
    const [shareData, setShareData] = useState({
        isExcel: false,
        sheets: [],
        fields: {},
        fileType: '',
        requiredRows: [],
        errors: {},
        allFieldKeys: [],
        fileData: {},
    });
    const sheets = useMemo(() => {
        if (spreadsheet) {
            return spreadsheet.getSheets();
        }
        return [];
    }, [spreadsheet]);
    useEffect(() => {
        setShareData((prev) => {
            const newData = Object.assign(Object.assign({}, prev), { isExcel,
                sheets,
                fields,
                allFieldKeys, fileType: fileType || '', requiredRows,
                errors,
                fileData });
            return newData;
        });
    }, [
        fileType,
        fields,
        allFieldKeys,
        requiredRows,
        sheets,
        isExcel,
        errors,
        fileData,
    ]);
    // The key is the field name, the value is the index of the column in the spreadsheet
    const handleProcessFormChange = useCallback((key, value) => {
        var _a;
        if (key === ProcessFormKey.method && value === ProcessMethodE.Mapping) {
            setProcessForm((prev) => (Object.assign(Object.assign({}, prev), { [key]: value, processor: '' })));
            if (spreadsheet && selectedSheet) {
                try {
                    const curMetaData = spreadsheet.getJson(selectedSheet);
                    if (Array.isArray(curMetaData)) {
                        const sheetData = statements.getDataInfo(curMetaData);
                        setRangeData(sheetData.rangeData);
                        if (sheetData.rangeData.length > 0) {
                            const currentData = sheetData.rangeData[0];
                            setSelectedDataRange(currentData);
                            setProcessedData({
                                data: currentData === null || currentData === void 0 ? void 0 : currentData.data,
                                fields: currentData === null || currentData === void 0 ? void 0 : currentData.fields,
                                version: ProcessResultE.MappingSpreadsheet,
                            });
                        }
                    }
                }
                catch (error) {
                    console.error('Error initializing mapping data:', error);
                }
            }
            return;
        }
        if (key === ProcessFormKey.mapping) {
            if (value !== 0) {
                const mapping = (_a = mpData.mappings) === null || _a === void 0 ? void 0 : _a.find((m) => m.str_id === value);
                if (mapping) {
                    setRowMapping(mapping.mapping, true);
                }
            }
            else {
                const _mappings = cloneDeep(processFormatData.mappingOptions);
                setRowMapping(_mappings, true);
            }
        }
        else if (key === ProcessFormKey.selectedSheet) {
            setSelectedSheet(value);
        }
        setProcessForm((prev) => {
            const newData = Object.assign(Object.assign({}, prev), { [key]: value });
            return newData;
        });
    }, [
        mpData.mappings,
        processFormatData.mappingOptions,
        spreadsheet,
        selectedSheet,
    ]);
    // Load the originnal data based on the method
    useEffect(() => {
        if (sheets.length && spreadsheet) {
            const _sheet = selectedSheet || sheets[0];
            setSelectedSheet(_sheet);
        }
    }, [sheets, spreadsheet, selectedSheet]);
    /**
     * Select process method
     */
    useEffect(() => {
        var _a, _b;
        let preProcessor;
        const calculateCommissionTotal = (data, fields) => {
            const commissionIndex = fields.findIndex((field) => field.toLowerCase() === 'commission_amount');
            if (commissionIndex === -1 || !(data === null || data === void 0 ? void 0 : data.length))
                return 'Invalid';
            const total = data.reduce((sum, row) => {
                const amount = normalizeCurrency(row[commissionIndex]);
                return amount ? sum + amount : sum;
            }, 0);
            return total === 0 ? 'Invalid' : total;
        };
        const processExtraction = (processors) => {
            return processors.map((processor) => {
                var _a, _b;
                try {
                    const processorFn = eval(`${processor.processor}`);
                    const libs = {
                        document: rowData,
                        tools: tool,
                    };
                    let result;
                    let successFound = false;
                    if (spreadsheet && sheets && sheets.length > 0 && isExcel) {
                        for (let i = 0; i < sheets.length; i++) {
                            const sheet = sheets[i];
                            try {
                                const source = spreadsheet.getJson(sheet);
                                const sheetResult = processorFn(source, libs);
                                if ((_a = sheetResult === null || sheetResult === void 0 ? void 0 : sheetResult.data) === null || _a === void 0 ? void 0 : _a.length) {
                                    result = sheetResult;
                                    successFound = true;
                                    setSelectedSheet(sheet);
                                    break;
                                }
                            }
                            catch (sheetError) {
                                console.log(`Processing sheet ${sheet} failed:`, sheetError);
                            }
                        }
                        if (!successFound && !result) {
                            throw new Error('No valid result found in any sheet');
                        }
                    }
                    else {
                        result = processorFn(extraction, libs);
                    }
                    if (!((_b = result === null || result === void 0 ? void 0 : result.data) === null || _b === void 0 ? void 0 : _b.length)) {
                        throw new Error('No row data');
                    }
                    const errorCount = result.data.filter((row) => Object.values(row).some((val) => val == null || val === '')).length;
                    const baseResponse = {
                        processor_id: processor.str_id,
                        processor_name: processor.name,
                        totalRows: result.data.length,
                        commissionTotal: calculateCommissionTotal(result.data, result.fields),
                        source: 'selector',
                    };
                    if (baseResponse.totalRows <= 1 &&
                        baseResponse.commissionTotal === 'Invalid') {
                        throw new Error('Invalid data');
                    }
                    if (baseResponse.commissionTotal !== 'Invalid') {
                        baseResponse.commissionTotal = Number(baseResponse.commissionTotal).toFixed(2);
                    }
                    return errorCount > 0
                        ? Object.assign(Object.assign({}, baseResponse), { status: ProcessorSelectorStatuses.PARTIAL, errorCount }) : Object.assign(Object.assign({}, baseResponse), { status: ProcessorSelectorStatuses.SUCCESS });
                }
                catch (error) {
                    return {
                        processor_id: processor.str_id,
                        processor_name: processor.name,
                        status: ProcessorSelectorStatuses.ERROR,
                        error,
                    };
                }
            });
        };
        const STATUS_PRIORITY = {
            [ProcessorSelectorStatuses.SUCCESS]: 3,
            [ProcessorSelectorStatuses.PARTIAL]: 2,
            [ProcessorSelectorStatuses.ERROR]: 1,
        };
        const sortProcessorResult = (results) => {
            return results.sort((a, b) => {
                const statusDiff = STATUS_PRIORITY[b.status] - STATUS_PRIORITY[a.status];
                if (statusDiff !== 0)
                    return statusDiff;
                const bankTotal = +(rowData.bank_total_amount || 0);
                const aMatch = bankTotal == a.commissionTotal;
                const bMatch = bankTotal == b.commissionTotal;
                if (aMatch !== bMatch)
                    return aMatch ? -1 : 1;
                return (b.totalRows || 0) - (a.totalRows || 0);
            });
        };
        if (processorData) {
            if (rowData.processor) {
                preProcessor = processorData.find((p) => p.str_id === rowData.processor);
            }
            if (sheets && spreadsheet) {
                const selectProcessor = () => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        const processors = yield processorSelectorPoster.mutateAsync({
                            rowData,
                        });
                        let allResults = [];
                        if (processors === null || processors === void 0 ? void 0 : processors.length) {
                            const selectorResults = processExtraction(processors);
                            allResults = [...selectorResults];
                        }
                        if (preProcessor &&
                            !allResults.find((r) => r.processor_id === rowData.processor)) {
                            const tableResult = processExtraction([preProcessor])[0];
                            tableResult.source = 'table';
                            allResults.push(tableResult);
                        }
                        setSuggestedProcessors(allResults);
                        const validProcessors = allResults.filter((proc) => proc.status !== ProcessorSelectorStatuses.ERROR);
                        if (validProcessors.length > 0) {
                            const sortedResults = sortProcessorResult(validProcessors);
                            handleProcessFormChange(ProcessFormKey.method, ProcessMethodE.Processor);
                            handleProcessFormChange(ProcessFormKey.processor, sortedResults[0].processor_id);
                        }
                        else {
                            switchToMappingMode();
                        }
                    }
                    catch (_a) {
                        switchToMappingMode();
                    }
                });
                const switchToMappingMode = () => {
                    handleProcessFormChange(ProcessFormKey.method, ProcessMethodE.Mapping);
                    handleProcessFormChange(ProcessFormKey.processor, '');
                    if (spreadsheet && selectedSheet) {
                        try {
                            const curMetaData = spreadsheet.getJson(selectedSheet);
                            if (Array.isArray(curMetaData)) {
                                const sheetData = statements.getDataInfo(curMetaData);
                                setRangeData(sheetData.rangeData);
                                if (sheetData.rangeData.length > 0) {
                                    const currentData = sheetData.rangeData[0];
                                    setSelectedDataRange(currentData);
                                    setProcessedData({
                                        data: currentData === null || currentData === void 0 ? void 0 : currentData.data,
                                        fields: currentData === null || currentData === void 0 ? void 0 : currentData.fields,
                                        version: ProcessResultE.MappingSpreadsheet,
                                    });
                                }
                            }
                        }
                        catch (error) {
                            console.error('Error in processor selection:', error);
                        }
                    }
                };
                selectProcessor();
            }
            else if ((processForm.method === ProcessMethodE.Processor ||
                !processForm.method) &&
                extraction) {
                const selectFn = () => __awaiter(void 0, void 0, void 0, function* () {
                    try {
                        const processors = yield processorSelectorPoster.mutateAsync({
                            rowData,
                        });
                        let allResults = [];
                        if (processors === null || processors === void 0 ? void 0 : processors.length) {
                            const selectorResults = processExtraction(processors);
                            allResults = [...selectorResults];
                        }
                        if (preProcessor &&
                            !allResults.find((r) => r.processor_id === rowData.processor)) {
                            const tableResult = processExtraction([preProcessor])[0];
                            tableResult.source = 'table';
                            allResults.push(tableResult);
                        }
                        if (allResults.length) {
                            setSuggestedProcessors(allResults);
                            handleProcessFormChange(ProcessFormKey.method, ProcessMethodE.Processor);
                            handleProcessFormChange(ProcessFormKey.processor, allResults[0].processor_id);
                        }
                        else if (!processForm.method) {
                            handleProcessFormChange(ProcessFormKey.method, ProcessMethodE.Mapping);
                            handleProcessFormChange(ProcessFormKey.processor, '');
                        }
                    }
                    catch (error) {
                        console.error('Error in processor selection:', error);
                        handleProcessFormChange(ProcessFormKey.method, ProcessMethodE.Mapping);
                    }
                });
                selectFn();
            }
            else if (processForm.method === ProcessMethodE.Gemini &&
                ((_a = shareData.fileData) === null || _a === void 0 ? void 0 : _a.type) === ProcessMethodE.Gemini) {
                const { keys, values } = tool.convertMapToArray((_b = shareData.fileData) === null || _b === void 0 ? void 0 : _b.data);
                const sheetData = statements.getDataInfo([keys, ...values]);
                setRangeData(sheetData.rangeData);
                setSelectedDataRange(sheetData.rangeData[0]);
                setProcessedData({
                    data: values,
                    fields: keys,
                    version: ProcessResultE.Gemini,
                });
            }
        }
    }, [
        selectedSheet,
        spreadsheet,
        mpData.processors,
        rowData,
        shareData.fileData,
        extraction,
        processorData,
    ]);
    useEffect(() => {
        if (processForm.method === ProcessMethodE.Mapping) {
            const missingFields = shareData.requiredRows.filter((k) => !Object.keys(rowMapping).includes(k));
            setMissingRequiredFields(missingFields);
        }
    }, [rowMapping, shareData.requiredRows, processForm.method]);
    /**
     * If using the processor, then process the data
     */
    useEffect(() => {
        const doAction = () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            if (processForm.method === ProcessMethodE.Processor &&
                processForm.processor) {
                const targetProcessor = (_b = (_a = mpData.processors) === null || _a === void 0 ? void 0 : _a.filter((p) => p.str_id === processForm.processor)) === null || _b === void 0 ? void 0 : _b[0];
                if (targetProcessor) {
                    setCurProcessor(targetProcessor);
                }
            }
        });
        doAction();
        return () => {
            setProcessedData({
                data: [],
                fields: [],
                version: null,
            });
        };
    }, [processForm.processor, processForm.method, mpData.processors]);
    useEffect(() => {
        if (!isCurPorcessorLoading && curProcessorData) {
            try {
                let source = extraction;
                if ((curProcessorData === null || curProcessorData === void 0 ? void 0 : curProcessorData.suggest_for) &&
                    sheets.includes(curProcessorData === null || curProcessorData === void 0 ? void 0 : curProcessorData.suggest_for)) {
                    setSelectedSheet(curProcessorData === null || curProcessorData === void 0 ? void 0 : curProcessorData.suggest_for);
                }
                if (isExcel && spreadsheet && selectedSheet) {
                    source = spreadsheet.getJson(selectedSheet);
                }
                if (source && curProcessorData) {
                    try {
                        const processorFunc = eval(`${curProcessorData.processor}`);
                        const libs = {
                            document: rowData,
                            tools: tool,
                        };
                        const res = processorFunc(source, libs);
                        setProcessedData(res);
                        const sheetData = statements.getDataInfo([res.fields, ...res.data]);
                        setRangeData(sheetData.rangeData);
                        setSelectedDataRange(sheetData.rangeData[0]);
                        const missingFields = shareData.requiredRows.filter((k) => { var _a; return !((_a = res === null || res === void 0 ? void 0 : res.fields) === null || _a === void 0 ? void 0 : _a.includes(k)); });
                        setMissingRequiredFields(missingFields);
                    }
                    catch (e) {
                        setErrors(Object.assign(Object.assign({}, errors), { dataRows: `Error while processing document: ${e}` }));
                    }
                }
            }
            catch (err) {
                console.log(err);
            }
        }
    }, [curProcessorData, isCurPorcessorLoading, extraction, selectedSheet]);
    useEffect(() => {
        var _a, _b;
        if ((_a = processedData === null || processedData === void 0 ? void 0 : processedData.fields) === null || _a === void 0 ? void 0 : _a.length) {
            const tempMapping = convertFieldListToMapping((_b = processedData === null || processedData === void 0 ? void 0 : processedData.fields) !== null && _b !== void 0 ? _b : []);
            const fieldsInDB = Object.keys(shareData.fields);
            const fieldsInDBMappings = fieldsInDB.reduce((acc, cur) => {
                const opt = shareData.fields[cur].matches;
                const tempMappingKeys = Object.keys(tempMapping);
                const fieldInDBMapping = tempMappingKeys.find((k) => [cur, ...opt].includes(k.toLowerCase()));
                if (fieldInDBMapping) {
                    acc[cur] = {
                        colIndex: tempMapping[fieldInDBMapping],
                        colHeader: fieldInDBMapping,
                    };
                }
                return acc;
            }, {});
            setRowMapping(fieldsInDBMappings);
            setErrors(Object.assign(Object.assign({}, errors), { dataRows: '' }));
        }
    }, [processedData, shareData.fields]);
    return (_jsx(_Fragment, { children: _jsxs(Box, { sx: {
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                overflow: 'hidden',
            }, children: [_jsx(ProcessForm, { mpData: mpData, shareData: shareData, processForm: processForm, suggestedProcessors: suggestedProcessors, rowData: rowData, handleProcessFormChange: handleProcessFormChange, addActionCount: addActionCount }), _jsx(Divider, { sx: { mt: 1, mb: 1 }, variant: "middle", textAlign: "center" }), _jsxs(Box, { sx: { display: 'flex', flex: 1 }, children: [_jsx(ProcessMappingForm, { selectedDataRange: selectedDataRange, setSelectedDataRange: setSelectedDataRange, rangeData: rangeData, shareData: shareData, processForm: processForm, processedData: processedData, addActionCount: addActionCount, setProcessedData: setProcessedData }), _jsx(CommissionPreview, { processedData: processedData, setProcessedData: setProcessedData, shareData: shareData, setProcessFormatData: setProcessFormatData, setErrors: setErrors, missingRequiredFields: missingRequiredFields, handleProcessFormChange: handleProcessFormChange, addActionCount: addActionCount, rowData: rowData })] })] }) }));
};
export default CommissionMapper;
