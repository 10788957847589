const findAllTextLine = (json, str, exact = false) => {
    var _a;
    return (((_a = json.Lines) === null || _a === void 0 ? void 0 : _a.flatMap((lineItem) => lineItem.LinesArray.filter((line) => exact ? line.Line === str : line.Line.includes(str)).map((line) => line.Line))) || []);
};
export const desc_findAllTextLine = {
    name: 'libs.tools.findAllTextLine(json, str, exact)',
    description: 'Finds all lines in a JSON object that contain or exactly match the specified text.',
    arguments: {
        json: '[Object] JSON object containing Lines array with text content to search',
        str: '[String] Text string to search for',
        exact: '[Boolean] Optional. If true, performs exact match instead of partial match',
    },
    returns: '[Array<string>] Array of found text lines from the content',
    examples: '// Partial match\nfindAllTextLine(json, "text")\n// → ["text1", "text2"]\n\n// Exact match\nfindAllTextLine(json, "text", true)\n// → ["text"]\n',
};
export default findAllTextLine;
