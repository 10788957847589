import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Close as CloseIcon } from '@mui/icons-material';
import { Box, Button, Checkbox, FormControl, FormControlLabel, IconButton, InputAdornment, TextField, ToggleButton, ToggleButtonGroup, Typography, } from '@mui/material';
import { AccountIds } from 'common/constants';
import { nanoid } from 'nanoid';
import { useEffect, useMemo, useState } from 'react';
import { ContactStatuses } from 'common/globalTypes';
import { FilterSelect } from '@/common';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';
import BasicDateRangePicker from '@/common/BasicDateRangePicker';
const defaultReferral = {
    config: {
        rate: '',
        deductFromUpline: false,
        useLifeRatesForAnnuity: true,
        annuity_rate: '',
    },
    end_date: null,
    notes: '',
    remarks: '',
    start_date: null,
    type: '',
};
const ContactsReferralsAdd = ({ data, field, setter, }) => {
    const contactReferrals = useMemo(() => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.contact_referrals) !== null && _a !== void 0 ? _a : []; }, [data]);
    const [showInactiveAgents, setShowInactiveAgents] = useState(false);
    const { selectedAccount } = useAccountStore();
    const { data: _contacts, isLoading: isLoadingContacts, refetch: refetchContacts, } = API.getBasicQuery('contacts', `is_dynamic_select=true&show_inactive=${showInactiveAgents}`);
    useEffect(() => {
        const hasInactiveAgents = contactReferrals.some((referral) => {
            var _a, _b;
            return ((_a = referral.referrer) === null || _a === void 0 ? void 0 : _a.status) === ContactStatuses.INACTIVE ||
                ((_b = referral.payer) === null || _b === void 0 ? void 0 : _b.status) === ContactStatuses.INACTIVE;
        });
        if (hasInactiveAgents) {
            setShowInactiveAgents(true);
        }
    }, [contactReferrals]);
    useEffect(() => {
        refetchContacts();
    }, [refetchContacts, showInactiveAgents]);
    const contacts = useMemo(() => {
        var _a;
        return ((_a = _contacts === null || _contacts === void 0 ? void 0 : _contacts.data) !== null && _a !== void 0 ? _a : [])
            .map((contact) => ({
            value: contact.id,
            label: Formatter.contact(contact, {
                incl_email: true,
                incl_nickname: true,
                account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
            }),
        }))
            .sort((a, b) => { var _a; return (_a = a.label) === null || _a === void 0 ? void 0 : _a.localeCompare(b.label); });
    }, [_contacts === null || _contacts === void 0 ? void 0 : _contacts.data, selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId]);
    const renderCustomOption = () => {
        return ((selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId) === AccountIds.TRANSGLOBAL && (_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: showInactiveAgents, onChange: (e) => {
                    setShowInactiveAgents(e.target.checked);
                } }), label: "Show inactive agents", sx: { ml: 1, minWidth: 270 }, onClick: () => {
                setShowInactiveAgents(!showInactiveAgents);
            } })));
    };
    return (_jsxs(Box, { sx: { width: '100%' }, children: [_jsx(Typography, { variant: "subtitle2", children: field.label }), _jsx(Box, { sx: {
                    width: '100%',
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center',
                }, children: _jsx(Box, { sx: {
                        mt: 0.5,
                        px: 1,
                        pt: 0.5,
                        pb: 0,
                        display: 'flex',
                        flexDirection: 'column',
                        borderStyle: 'solid',
                        borderColor: 'silver',
                        borderWidth: 1,
                        borderRadius: 4,
                        width: '100%',
                        backgroundColor: '#2196f308',
                    }, children: _jsxs(Box, { sx: { my: 0.5, display: 'flex', flexWrap: 'wrap' }, children: [contactReferrals.map((referral, index) => {
                                var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
                                return (_jsxs(Box, { sx: {
                                        m: 0.5,
                                        p: 1,
                                        borderStyle: 'solid',
                                        borderColor: 'silver',
                                        borderWidth: 1,
                                        borderRadius: 4,
                                        display: 'inline-block',
                                        width: '100%',
                                        backgroundColor: '#2196f30a',
                                    }, children: [_jsx(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", position: "relative", sx: { ml: 0.5 }, children: _jsx(Typography, { variant: "body2" }) }), _jsxs(Box, { children: [_jsxs(Box, { sx: {
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        alignItems: 'start',
                                                    }, children: [_jsxs(Box, { children: [_jsx(ToggleButtonGroup, { color: "primary", value: referral.type, exclusive: true, onChange: (e, type) => {
                                                                        setter(Object.assign(Object.assign({}, data), { contact_referrals: data.contact_referrals.map((item) => item.id === referral.id
                                                                                ? Object.assign(Object.assign({}, item), { type }) : item) }));
                                                                    }, sx: { my: 0.5 }, children: [
                                                                        { value: 'agent', label: 'Agent' },
                                                                        { value: 'region', label: 'Region' },
                                                                        { value: 'recruiting', label: 'Recruiting' },
                                                                        { value: 'other', label: 'Other' },
                                                                    ].map((option) => (_jsx(ToggleButton, { value: option.value, sx: {
                                                                            borderRadius: 24,
                                                                            borderColor: '#b9bec2',
                                                                        }, children: option.label }, option.value))) }), _jsx(BasicDateRangePicker, { range: {
                                                                        startDate: referral.start_date,
                                                                        startDateLabel: 'Start date',
                                                                        endDate: referral.end_date,
                                                                        endDateLabel: 'End date',
                                                                    }, onChange: ({ startDate, endDate }) => {
                                                                        setter(Object.assign(Object.assign({}, data), { contact_referrals: data.contact_referrals.map((item) => item.id === referral.id
                                                                                ? Object.assign(Object.assign({}, item), { start_date: startDate, end_date: endDate }) : item) }));
                                                                    }, mt: 0.5, my: 0.5 })] }), _jsx(IconButton, { onClick: () => {
                                                                setter(Object.assign(Object.assign({}, data), { contact_referrals: contactReferrals.filter((cm) => cm.id !== referral.id) }));
                                                            }, children: _jsx(CloseIcon, {}) })] }), _jsx(Box, { sx: { display: 'flex', width: '100%' }, children: _jsx(FilterSelect, { label: "Payee (recruiting agent)", placeholder: "Select agent", options: contacts, value: (_a = referral.referrer_contact_id) !== null && _a !== void 0 ? _a : '', onChange: (e) => {
                                                            setter(Object.assign(Object.assign({}, data), { contact_referrals: data.contact_referrals.map((item) => {
                                                                    var _a;
                                                                    return item.id === referral.id
                                                                        ? Object.assign(Object.assign({}, item), { referrer_contact_id: (_a = e === null || e === void 0 ? void 0 : e.data) === null || _a === void 0 ? void 0 : _a.value }) : item;
                                                                }) }));
                                                        }, getOptionLabel: (option, options) => {
                                                            var _a, _b;
                                                            return typeof option === 'number' && Array.isArray(options)
                                                                ? ((_b = (_a = options.find((o) => o.value === option)) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : '')
                                                                : typeof option === 'object'
                                                                    ? option.label
                                                                    : option;
                                                        }, sx: { my: 0.5, width: '100%' }, loading: isLoadingContacts, renderCustomOption: renderCustomOption, enablePagination: true, itemsPerPage: 100 }) }), _jsxs(Box, { sx: { display: 'flex', width: '100%' }, children: [_jsx(FilterSelect, { label: "Payer", placeholder: "Select agent", options: contacts, value: referral.payer_contact_id, onChange: (e) => {
                                                                setter(Object.assign(Object.assign({}, data), { contact_referrals: data.contact_referrals.map((item) => {
                                                                        var _a;
                                                                        return item.id === referral.id
                                                                            ? Object.assign(Object.assign({}, item), { payer_contact_id: (_a = e === null || e === void 0 ? void 0 : e.data) === null || _a === void 0 ? void 0 : _a.value }) : item;
                                                                    }) }));
                                                            }, getOptionLabel: (option, options) => {
                                                                var _a, _b;
                                                                return typeof option === 'number' && Array.isArray(options)
                                                                    ? ((_b = (_a = options.find((o) => o.value === option)) === null || _a === void 0 ? void 0 : _a.label) !== null && _b !== void 0 ? _b : '')
                                                                    : typeof option === 'object'
                                                                        ? option.label
                                                                        : option;
                                                            }, sx: { my: 0.5, width: '100%' }, loading: isLoadingContacts, renderCustomOption: renderCustomOption, enablePagination: true, itemsPerPage: 100, disabled: (_b = referral.config) === null || _b === void 0 ? void 0 : _b.deductFromUpline }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: (_c = referral.config) === null || _c === void 0 ? void 0 : _c.deductFromUpline, onChange: (e) => {
                                                                    setter(Object.assign(Object.assign({}, data), { contact_referrals: data.contact_referrals.map((item) => item.id === referral.id
                                                                            ? Object.assign(Object.assign({}, item), { config: Object.assign(Object.assign({}, item.config), { deductFromUpline: e.target.checked }), payer_contact_id: null }) : item) }));
                                                                } }), label: "Deduct from agent upline", sx: { ml: 1, minWidth: 270 } })] }), _jsxs(Box, { sx: { display: 'flex', width: '100%' }, children: [_jsx(TextField, { label: "Rate", variant: "outlined", value: (_e = (_d = referral.config) === null || _d === void 0 ? void 0 : _d.rate) !== null && _e !== void 0 ? _e : '', onChange: (e) => {
                                                                setter(Object.assign(Object.assign({}, data), { contact_referrals: data.contact_referrals.map((item) => item.id === referral.id
                                                                        ? Object.assign(Object.assign({}, item), { config: Object.assign(Object.assign({}, item.config), { rate: e.target.value }) }) : item) }));
                                                            }, fullWidth: true, multiline: true, error: ((_f = referral.config) === null || _f === void 0 ? void 0 : _f.rate) &&
                                                                Number.isNaN(+((_g = referral.config) === null || _g === void 0 ? void 0 : _g.rate)), sx: { my: 0.5 }, InputProps: {
                                                                endAdornment: (_jsx(InputAdornment, { position: "end", sx: { ml: 0 }, children: "%" })),
                                                            } }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: (_h = referral.config) === null || _h === void 0 ? void 0 : _h.useLifeRatesForAnnuity, onChange: (e) => {
                                                                    setter(Object.assign(Object.assign({}, data), { contact_referrals: data.contact_referrals.map((item) => item.id === referral.id
                                                                            ? Object.assign(Object.assign({}, item), { config: Object.assign(Object.assign({}, item.config), { useLifeRatesForAnnuity: e.target.checked }) }) : item) }));
                                                                } }), label: "Use life referral rate for annuity", sx: { ml: 1, minWidth: 270 } })] }), !((_j = referral.config) === null || _j === void 0 ? void 0 : _j.useLifeRatesForAnnuity) && (_jsxs(Box, { sx: { display: 'flex', width: '100%' }, children: [_jsx(TextField, { label: "Annuity rate", variant: "outlined", value: (_l = (_k = referral.config) === null || _k === void 0 ? void 0 : _k.annuity_rate) !== null && _l !== void 0 ? _l : '', onChange: (e) => {
                                                                setter(Object.assign(Object.assign({}, data), { contact_referrals: data.contact_referrals.map((item) => item.id === referral.id
                                                                        ? Object.assign(Object.assign({}, item), { config: Object.assign(Object.assign({}, item.config), { annuity_rate: e.target.value }) }) : item) }));
                                                            }, fullWidth: true, multiline: true, error: ((_m = referral.config) === null || _m === void 0 ? void 0 : _m.rate) &&
                                                                Number.isNaN(+((_o = referral.config) === null || _o === void 0 ? void 0 : _o.annuity_rate)), sx: { my: 0.5 }, InputProps: {
                                                                endAdornment: (_jsx(InputAdornment, { position: "end", sx: { ml: 0 }, children: "%" })),
                                                            } }), _jsx(Box, { sx: { width: 410 } })] })), _jsx(TextField, { label: "Notes", variant: "outlined", value: (_p = referral.notes) !== null && _p !== void 0 ? _p : '', onChange: (e) => {
                                                        setter(Object.assign(Object.assign({}, data), { contact_referrals: data.contact_referrals.map((item) => item.id === referral.id
                                                                ? Object.assign(Object.assign({}, item), { notes: e.target.value }) : item) }));
                                                    }, fullWidth: true, multiline: true, sx: { my: 0.5 } }), _jsx(TextField, { label: "Remarks", variant: "outlined", value: (_q = referral.remarks) !== null && _q !== void 0 ? _q : '', onChange: (e) => {
                                                        setter(Object.assign(Object.assign({}, data), { contact_referrals: data.contact_referrals.map((item) => item.id === referral.id
                                                                ? Object.assign(Object.assign({}, item), { remarks: e.target.value }) : item) }));
                                                    }, fullWidth: true, multiline: true, sx: { my: 0.5 } })] })] }, `${referral.id}-${index}`));
                            }), _jsx(FormControl, { sx: { m: 0.5, width: 30 }, children: _jsx(Button, { onClick: (e) => {
                                        var _a;
                                        e.stopPropagation();
                                        e.preventDefault();
                                        setter(Object.assign(Object.assign({}, data), { contact_referrals: [
                                                ...((_a = data.contact_referrals) !== null && _a !== void 0 ? _a : []),
                                                Object.assign(Object.assign({}, defaultReferral), { id: nanoid() }),
                                            ] }));
                                    }, children: "Add" }) }, field.id)] }) }) }, `${field.id}-box`)] }));
};
export default ContactsReferralsAdd;
