import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AppBar, Avatar, Box, Link, Toolbar, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { useAccountStore } from '@/store';
import { ToggleMenuButton } from './ToggleMenuButton';
import { Notification } from './Notification';
import { UserMenu } from './UserMenu';
import { AuthMenu } from './AuthMenu';
import { App } from '@/constants/app';
const Bar = ({ onHelpClick, onSignOutClick, onSignUpClick, onSignInClick, performingAction, user, }) => {
    var _a;
    const { selectedAccount, logoUrl } = useAccountStore();
    return (_jsxs(AppBar, { color: "primary", position: "fixed", sx: { zIndex: 1300 }, children: [_jsxs(Toolbar, { disableGutters: true, sx: { mx: 1 }, children: [user && _jsx(ToggleMenuButton, {}), _jsx(Link, { color: "inherit", component: RouterLink, to: "/", underline: "none", sx: { display: 'flex', alignItems: 'center' }, children: _jsxs(Box, { sx: { display: 'flex', alignItems: 'center', my: 1 }, children: [logoUrl && _jsx(Avatar, { alt: "Logo", src: logoUrl, sx: { ml: 1 } }), _jsx(Typography, { color: "inherit", variant: "h5", sx: { textWrap: 'nowrap', ml: 1 }, children: (_a = selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountName) !== null && _a !== void 0 ? _a : App.Title })] }) }), _jsxs(Box, { sx: {
                            position: 'absolute',
                            right: 0,
                            display: 'flex',
                        }, children: [_jsx(Box, { sx: {
                                    width: 16,
                                    backgroundImage: 'linear-gradient(to right, rgba(0,0,0,0), #2196f3)',
                                } }), _jsx(Box, { sx: { backgroundColor: '#2196f3' }, children: user ? (_jsxs(_Fragment, { children: [_jsx(Notification, {}), _jsx(UserMenu, { onHelpClick: onHelpClick, onSignOutClick: onSignOutClick, user: user })] })) : (_jsx(AuthMenu, { onSignUpClick: onSignUpClick, onSignInClick: onSignInClick, performingAction: performingAction })) })] })] }), process.env.REACT_APP_ENVIRONMENT === 'development' && (_jsxs(_Fragment, { children: [_jsx(Box, { sx: { position: 'absolute', top: 0, right: 4 }, children: "\uD83E\uDDEA" }), _jsx(Box, { sx: { position: 'absolute', top: 0, left: 4 }, children: "\uD83E\uDDEA" })] }))] }));
};
export default Bar;
