var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Autocomplete, autocompleteClasses, Box, CircularProgress, TextField, } from '@mui/material';
import { debounce } from 'lodash';
import React, { useMemo, useRef, useState } from 'react';
import { CustomerType } from 'common/customer/customer.constants';
import { useSearchCustomerName } from '@/components/customers/customer-list/apis';
export const CustomerField = ({ value, onChange, }) => {
    const [search, setSearch] = useState('');
    const { isLoading, isFetching, data } = useSearchCustomerName({
        q: search,
    });
    const group = 'Customer entities';
    const _value = useMemo(() => {
        return typeof value === 'string'
            ? {
                id: -1,
                first_name: value,
                group: '',
                str_id: '',
                type: CustomerType.individual,
            }
            : Object.assign(Object.assign({}, value), { group });
    }, [value]);
    const options = useMemo(() => {
        const opts = (data === null || data === void 0 ? void 0 : data.map((item) => (Object.assign(Object.assign({}, item), { group })))) || [];
        if (_value.id !== -1 && !(data === null || data === void 0 ? void 0 : data.length))
            opts.push(_value);
        return opts;
    }, [_value, data]);
    const onChangeSearch = useRef(debounce(setSearch, 500));
    return (_jsx(Autocomplete, { value: _value, isOptionEqualToValue: (option, value) => option.id === value.id, renderOption: (props, option, state, ownerState) => {
            const { key } = props, optionProps = __rest(props, ["key"]);
            const renderText = () => {
                if (option.id === -1)
                    return `Custom: "${ownerState.getOptionLabel(option)}"`;
                return ownerState.getOptionLabel(option);
            };
            return (_jsx(Box, Object.assign({ sx: {
                    borderRadius: '8px',
                    margin: '5px',
                    [`&.${autocompleteClasses.option}`]: {
                        padding: 1,
                        paddingLeft: option.group === group ? 4 : 2,
                    },
                }, component: "li" }, optionProps, { children: renderText() }), key));
        }, options: options, onChange: (evt, newValue) => {
            const _new = Object.assign({}, newValue);
            delete _new.group;
            if (newValue)
                onChange === null || onChange === void 0 ? void 0 : onChange(_new);
        }, groupBy: (v) => v.group, onInputChange: (evt, value) => {
            onChangeSearch.current(value);
        }, filterOptions: (options, params) => {
            if (params.inputValue) {
                options.push({
                    id: -1,
                    first_name: params.inputValue,
                    last_name: '',
                });
            }
            return [...options];
        }, getOptionLabel: (v) => {
            const { first_name, last_name } = v;
            return `${first_name} ${last_name || ''}`;
        }, sx: { width: '100%' }, renderInput: (params) => (_jsx(TextField, Object.assign({}, params, { label: "Customer name", InputProps: Object.assign(Object.assign({}, params.InputProps), { endAdornment: (_jsxs(React.Fragment, { children: [isLoading || isFetching ? (_jsx(CircularProgress, { color: "inherit", size: 20 })) : null, params.InputProps.endAdornment] })) }) }))) }));
};
