const findTextLine = (json, str, exact = false) => {
    var _a;
    let txt = '';
    (_a = json.Lines) === null || _a === void 0 ? void 0 : _a.find((lineItem) => {
        const item = lineItem.LinesArray.find((line) => exact ? line.Line === str : line.Line.includes(str));
        if (item) {
            txt = item.Line;
        }
        return !!item;
    });
    return txt;
};
export const desc_findTextLine = {
    name: 'libs.tools.findTextLine(json, str, exact)',
    description: 'Finds the first line in a JSON object that contains or exactly matches the specified text.',
    arguments: {
        json: '[Object] JSON object containing Lines array with text content',
        str: '[String] Text string to search for',
        exact: '[Boolean] Optional. If true, performs exact match instead of partial match',
    },
    returns: '[String] First matching text line, or empty string if not found',
    examples: 'findTextLine(json, "text")\n// → "text"\n\nfindTextLine(json, "text", true)\n// → "text"\n',
};
export default findTextLine;
