import { useState } from 'react';
import { captureException } from '@sentry/react';

import API from '@/services/API';

export const useDataSync = (userInfo?: AuthResponse) => {
  const [isGoogleAuthenticated, setIsGoogleAuthenticated] = useState(false);
  const [googleLoginUrl, setGoogleLoginUrl] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [verifiedUser, setVerifiedUser] = useState<Record<string, any> | null>(
    null
  );

  const initializeGoogleAuth = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API}/api/admin/data-sync/verify-user`,
        {
          method: 'POST',
          headers: await API.getHeaders(),
        }
      );

      const data = await res.json();
      setIsGoogleAuthenticated(data.valid);
      setVerifiedUser(data.user);

      if (!data.valid) {
        const loginRes = await fetch(
          `${process.env.REACT_APP_API}/api/admin/data-sync/auth-url`,
          {
            method: 'POST',
            headers: await API.getHeaders(),
          }
        );
        const loginData = await loginRes.json();
        setGoogleLoginUrl(loginData.loginUrl);
      }
    } catch (err) {
      setError('Failed to initialize Google authentication');
      captureException(err);
      console.error(err);
    }
  };

  const handleSave = async (googleDriveFolder: string) => {
    try {
      await fetch(`${process.env.REACT_APP_API}/api/admin/data-sync/configs`, {
        method: 'PUT',
        headers: await API.getHeaders(),
        body: JSON.stringify({
          email: userInfo?.userEmail,
          googleDriveFolder,
        }),
      });
    } catch (err) {
      captureException(err);
      console.error('Error saving folder:', err);
    }
  };

  const handleLogout = async () => {
    try {
      await fetch(`${process.env.REACT_APP_API}/api/admin/data-sync/logout`, {
        method: 'POST',
        headers: await API.getHeaders(),
        body: JSON.stringify({ email: verifiedUser?.email }),
      });
      setIsGoogleAuthenticated(false);
      setGoogleLoginUrl(null);
      setVerifiedUser(null);
      await initializeGoogleAuth();
    } catch (err) {
      captureException(err);
      console.error('Error logging out:', err);
    }
  };

  return {
    isGoogleAuthenticated,
    googleLoginUrl,
    error,
    initializeGoogleAuth,
    handleSave,
    verifiedUser,
    handleLogout,
  };
};
