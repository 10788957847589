import { GROUP_BY_VALUES } from 'common/documents/documents.constants';
import dayjs from 'dayjs';

export const formatDateName = (value: string, groupBy: GROUP_BY_VALUES) => {
  switch (groupBy) {
    case GROUP_BY_VALUES.QUARTER: {
      return value.replace('-', ' ');
    }
    case GROUP_BY_VALUES.YEAR: {
      return value;
    }
    default: {
      return dayjs(value as string).format('MMMM YYYY');
    }
  }
};

export const getStartAndEndDates = (
  value: string,
  groupBy: GROUP_BY_VALUES
) => {
  switch (groupBy) {
    case GROUP_BY_VALUES.QUARTER: {
      const quarter = parseInt(value.split('-')[0].replace('Q', ''));
      const year = parseInt(value.split('-')[1]);
      const startMonth = (quarter - 1) * 3 + 1;
      const endMonth = startMonth + 2;

      return {
        startDate: dayjs(new Date(year, startMonth - 1))
          .startOf('month')
          .format('YYYY-MM-DD'),
        endDate: dayjs(new Date(year, endMonth - 1))
          .endOf('month')
          .format('YYYY-MM-DD'),
      };
    }
    case GROUP_BY_VALUES.YEAR: {
      const date = dayjs(`${value}-01-01`);
      return {
        startDate: date.startOf('year').format('YYYY-MM-DD'),
        endDate: date.endOf('year').format('YYYY-MM-DD'),
      };
    }
    default: {
      const month = dayjs(value);
      return {
        startDate: month.startOf('month').format('YYYY-MM-DD'),
        endDate: month.endOf('month').format('YYYY-MM-DD'),
      };
    }
  }
};
