var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { DeleteOutline } from '@mui/icons-material';
import { Alert, Chip, IconButton } from '@mui/material';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { BasicDialog } from '@/common';
import DataView from '@/components/DataView';
import { LoadingContext } from '@/contexts/LoadingContext';
import API from '@/services/API';
import { headerSx, cellSx, } from '@/components/HoverActionButtonContainer/styles';
import { HoverActionButtonContainer } from '@/components/HoverActionButtonContainer';
import useSnackbar from '@/contexts/useSnackbar';
const ExtractionsView = () => {
    const [refresh, setRefresh] = useState(0);
    const [showDelConfirm, setShowDelConfirm] = useState(false);
    const [currentRow, setCurrentRow] = useState(null);
    const { setLoadingConfig } = useContext(LoadingContext);
    const { showSnackbar } = useSnackbar();
    const extractionsDelter = API.getMutation('extractions', 'DELETE');
    const dataDesc = {
        label: 'Extractions',
        table: 'extractions',
        fields: [
            { id: 'str_id', label: 'Id', copyable: true },
            {
                id: 'created_at',
                numeric: false,
                label: 'Created at',
                formatter: (s) => new Date(s).toLocaleString(),
            },
            {
                id: 'method',
                numeric: false,
                label: 'Method',
            },
            {
                id: 'documents',
                numeric: false,
                label: 'Document name',
                tableFormatter: (s, row) => {
                    var _a, _b;
                    return (_jsx(Chip, { label: (_a = row.documents) === null || _a === void 0 ? void 0 : _a.filename, clickable: true, component: Link, to: `/documents?id=${(_b = row.documents) === null || _b === void 0 ? void 0 : _b.str_id}`, target: "_blank" }));
                },
                formatter: (s, row) => {
                    var _a;
                    return (_a = row.documents) === null || _a === void 0 ? void 0 : _a.filename;
                },
            },
            {
                id: 'result',
                label: 'Result',
                formatter: (s, row) => {
                    return `${s} (${row === null || row === void 0 ? void 0 : row.status})`;
                },
            },
            // {
            //   id: 'output',
            //   numeric: false,
            //   label: 'Extracted Data Key',
            //   formatter: (s) => {
            //     // show max 600 chars
            //     return (
            //       <CustomWidthTooltip
            //         title={JSON.stringify(
            //           JSON.parse(s)?.Tables || JSON.parse(s)?.table || ''
            //         )}
            //       >
            //         <span>{Object.keys(JSON.parse(s)).toString()}</span>
            //       </CustomWidthTooltip>
            //     );
            //   },
            // },
            {
                id: 'id',
                numeric: false,
                label: 'Actions',
                sx: cellSx,
                headerSx,
                formatter: (id, row) => {
                    return (_jsx(HoverActionButtonContainer, { children: _jsx(IconButton, { className: "ml-1", onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                                setCurrentRow(row);
                                setShowDelConfirm(true);
                            }), children: _jsx(DeleteOutline, {}) }) }));
                },
            },
        ],
    };
    const DelConfirmComp = ({ row }) => {
        return (_jsx(BasicDialog, { title: "Extraction Data Delete", open: showDelConfirm, onClose: (val) => {
                if (val) {
                    setLoadingConfig({
                        loading: true,
                        message: 'Deleting...',
                    });
                    extractionsDelter
                        .mutateAsync({ ids: [row.id] })
                        .then(() => {
                        showSnackbar('Extraction data deleted successfully', 'success');
                        setRefresh(refresh + 1);
                        setLoadingConfig({
                            loading: false,
                            message: '',
                            delayToClose: 1000,
                        });
                        setCurrentRow(null);
                    })
                        .catch((err) => {
                        showSnackbar(err.message || err, 'error');
                        setLoadingConfig({
                            loading: false,
                            message: '',
                        });
                    });
                }
                else {
                    setShowDelConfirm(false);
                    setCurrentRow(null);
                }
            }, bodyComponent: _jsxs(Alert, { severity: "warning", children: ["Are you sure you want to delete the extraction: ", row.str_id, "?"] }) }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(DataView, { dataDesc: dataDesc, viewOnly: true, refresh: refresh, hideExport: true }), showDelConfirm && currentRow && _jsx(DelConfirmComp, { row: currentRow })] }));
};
ExtractionsView.propTypes = {
    user: PropTypes.object,
};
export default ExtractionsView;
