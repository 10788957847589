/**
 * Select the required table data according to the mapping data
 * @param mapObj Object with key-value pair of column name and display name
 * @param arr parsed data array
 * @returns array of table headers and table data
 */
const pickData = (mapObj, arr, exact = false) => {
    const [headerConf, ...mainData] = arr;
    const tableHeaders = [];
    const tableData = [];
    Object.entries(mapObj).forEach(([k, v], i) => {
        const key = k.replace(/\r\n/g, ' ').trim();
        const titleIndex = headerConf.findIndex((title) => exact ? title === key : title.includes(key));
        if (titleIndex > -1) {
            tableHeaders.push(v);
            mainData.forEach((row, idx) => {
                const temp = row[titleIndex];
                if (tableData[idx]) {
                    tableData[idx].push(temp);
                }
                else {
                    tableData[idx] = [temp];
                }
            });
        }
    });
    return {
        tableHeaders,
        tableData,
    };
};
export const desc_pickData = {
    name: 'libs.tools.pickData(mapObj, arr, exact)',
    description: 'Extracts table data based on a mapping between column names and display names.',
    arguments: {
        mapObj: '[Object] Mapping of original column names to display names',
        arr: '[Array<Array>] 2D array where first row contains headers',
        exact: '[Boolean] Optional. If true, performs exact match instead of partial match',
    },
    returns: '[Object] Object containing tableHeaders array and tableData array',
    examples: 'pickData(\n  { "Original Name": "Display Name" },\n  [\n    ["Original Name", "Other Col"],\n    ["Value1", "Other1"]\n  ]\n)\n// → {\n//   tableHeaders: ["Display Name"],\n//   tableData: [["Value1"]]\n// }\n',
};
export default pickData;
