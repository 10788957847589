import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingButton } from '@mui/lab';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Link, Typography, } from '@mui/material';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useAcceptTos } from '@/components/terms-of-service/apis';
import tosContent from '@/components/terms-of-service/components/termsOfService-2025-01-05';
import useSnackbar from '@/contexts/useSnackbar';
import { auth } from '@/firebase';
import { useUserInfo } from '@/hooks/useUserInfo';
import { useAccountStore } from '@/store';
const TermsOfService = ({ onSignOut }) => {
    const [open, setOpen] = useState(false);
    const [showTos, setShowTos] = useState(false);
    const [checked, setChecked] = useState(false);
    const { data: userInfo, refetch } = useUserInfo();
    const [firebaseUser, setFirbaseUser] = useState(auth.currentUser);
    const mutation = useAcceptTos();
    const { showSnackbar } = useSnackbar();
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
    const [contentEl, setContentEl] = useState(null);
    const { data: { fintaryAdmin } = {} } = useUserInfo();
    const { userState } = useAccountStore();
    const handleLogout = () => {
        onSignOut();
    };
    const handleAccept = () => {
        mutation.mutate(undefined, {
            onError: () => {
                showSnackbar('Failed to accept terms of service', 'error');
            },
            onSuccess: () => {
                refetch();
            },
        });
    };
    useEffect(() => {
        const unSubscribe = auth.onAuthStateChanged((user) => {
            setFirbaseUser(user);
        });
        return unSubscribe;
    }, []);
    useEffect(() => {
        if (firebaseUser &&
            userInfo &&
            !userInfo.tos_accepted_at &&
            !fintaryAdmin &&
            (userState === null || userState === void 0 ? void 0 : userState.userOverallState) === 'active') {
            setOpen(true);
        }
        else {
            setOpen(false);
        }
    }, [fintaryAdmin, firebaseUser, userInfo, userState === null || userState === void 0 ? void 0 : userState.userOverallState]);
    const onRef = useCallback((node) => {
        if (node) {
            setContentEl(node);
        }
    }, []);
    useLayoutEffect(() => {
        const handleScroll = () => {
            if (contentEl) {
                const { scrollTop, scrollHeight, clientHeight } = contentEl;
                if (scrollTop + clientHeight >= scrollHeight) {
                    setIsScrolledToBottom(true);
                }
            }
        };
        if (contentEl) {
            contentEl.addEventListener('scroll', handleScroll);
        }
        handleScroll();
        return () => {
            if (contentEl) {
                contentEl.removeEventListener('scroll', handleScroll);
            }
        };
    }, [contentEl]);
    return (_jsxs(Dialog, { open: open, sx: showTos ? { maxHeight: '60vh', mt: '15%' } : undefined, children: [_jsx(DialogTitle, { children: "Terms of service" }), _jsx(DialogContent, { ref: onRef, children: showTos ? (_jsx(Typography, { variant: "body2", children: tosContent })) : (_jsxs(Box, { children: ["We've updated our", ' ', _jsx(Link, { onClick: () => setShowTos(true), sx: { cursor: 'pointer' }, children: "terms of service" }), ".", _jsx("br", {}), "Please review and accept to continue using Fintary."] })) }), _jsxs(DialogActions, { children: [_jsx(FormControlLabel, { sx: { flexGrow: 1 }, control: _jsx(Checkbox, { checked: checked, onChange: (evt) => setChecked(evt.target.checked), disabled: !isScrolledToBottom, name: "acceptTerms", color: "primary" }), label: "I've read and accept the terms of service" }), _jsx(Button, { sx: { mr: 1 }, disabled: mutation.isPending, onClick: handleLogout, children: "Logout" }), _jsx(LoadingButton, { loading: mutation.isPending, variant: "contained", color: "primary", onClick: handleAccept, disabled: !checked, children: "Accept" })] })] }));
};
export default TermsOfService;
