var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Close, Close as CloseIcon } from '@mui/icons-material';
import { Box, Button, FormControl, IconButton, InputLabel, MenuItem, Select, Skeleton, TextField, Typography, } from '@mui/material';
import { CustomMethodConditionType, CustomMethodOperatorOptions, } from 'common/interface';
import React, { useEffect, useMemo } from 'react';
import { CONDITION_TYPE_OPTIONS } from '@/components/contacts/ContactsView/CompProfilesAdd';
import Formatter from '@/services/Formatter';
import { useAccountStore } from '@/store';
import { AgentSelect } from './AgentSelect';
const AgentCommissionsEdit = ({ data, field, setter, dynamicSelects, readOnly = false, disableConditions = true, percentageFormat = 'decimal', }) => {
    const idToUse = useMemo(() => {
        return ['comp_calc', 'comp_calc_status'].includes(field.id)
            ? 'id'
            : 'str_id';
    }, [field.id]);
    const units = useMemo(() => {
        return [
            'agent_payout_rate',
            'agent_payout_rate_override',
            'agent_commission_payout_rate',
        ].includes(field.id)
            ? 'rate'
            : 'amount';
    }, [field.id]);
    const agentDataToUse = useMemo(() => {
        let dataToUse = {};
        const { agent_commissions: agentCommissions, agent_commissions_v2: agentCommissionsV2, agent_payout_rate: agentPayoutRate, agent_payout_rate_override: agentPayoutRateOverride, agent_commission_payout_rate: agentCommissionPayoutRate, comp_calc: compCalc, } = data || {};
        switch (field.id) {
            case 'agent_commissions':
                dataToUse = agentCommissions || {};
                break;
            case 'agent_commissions_v2':
                dataToUse = agentCommissionsV2 || {};
                break;
            case 'agent_payout_rate':
                dataToUse = agentPayoutRate || {};
                break;
            case 'agent_payout_rate_override':
                dataToUse = agentPayoutRateOverride || {};
                break;
            case 'agent_commission_payout_rate':
                dataToUse = agentCommissionPayoutRate || {};
                break;
            case 'comp_calc':
                dataToUse = compCalc || {};
                break;
            default:
                dataToUse = {};
        }
        return dataToUse;
    }, [data, field.id]);
    const { selectedAccount } = useAccountStore();
    const [renderedValues, setRenderedValues] = React.useState({});
    const [dataSetted, setDataSetted] = React.useState(false);
    const [showConditions] = React.useState(!disableConditions);
    const updateAgentData = (agentId, value) => {
        setter((prevData) => {
            const newAgentCommissions = Object.assign({}, prevData[field.id]);
            newAgentCommissions[agentId] = value;
            const { total: _total } = newAgentCommissions, commissionsWithoutTotal = __rest(newAgentCommissions, ["total"]);
            if (units === 'amount') {
                const newTotal = Object.values(commissionsWithoutTotal).reduce((sum, current) => sum + (Number.isNaN(+current) ? 0 : +current), 0);
                newAgentCommissions['total'] = newTotal;
            }
            return Object.assign(Object.assign({}, prevData), { [field.id]: newAgentCommissions });
        });
    };
    const removeAgentData = (agentId) => {
        setter((prevData) => {
            var _a;
            const newAgentCommissions = Object.assign({}, prevData[field.id]);
            delete newAgentCommissions[agentId];
            (_a = newAgentCommissions.config) === null || _a === void 0 ? true : delete _a[agentId];
            const { total: _total } = newAgentCommissions, commissionsWithoutTotal = __rest(newAgentCommissions, ["total"]);
            if (units === 'amount') {
                const newTotal = Object.values(commissionsWithoutTotal).reduce((sum, current) => sum + current, 0);
                newAgentCommissions['total'] = newTotal;
            }
            return Object.assign(Object.assign({}, prevData), { [field.id]: newAgentCommissions });
        });
    };
    // Update the handler for adding conditions
    const handleAddCondition = (agentId) => {
        setter((prevData) => {
            const newData = Object.assign({}, prevData);
            const fieldData = newData[field.id] || {};
            // Initialize or update the config structure
            if (!fieldData.config) {
                fieldData.config = {};
            }
            if (!fieldData.config[agentId]) {
                fieldData.config[agentId] = { conditions: [] };
            }
            // Add new empty condition
            fieldData.config[agentId].conditions.push({});
            newData[field.id] = fieldData;
            return newData;
        });
    };
    const updateCondition = (agentId, index, fieldId, value) => {
        setter((prevData) => {
            var _a, _b, _c;
            const newData = Object.assign({}, prevData);
            const fieldData = newData[field.id];
            if (!((_c = (_b = (_a = fieldData.config) === null || _a === void 0 ? void 0 : _a[agentId]) === null || _b === void 0 ? void 0 : _b.conditions) === null || _c === void 0 ? void 0 : _c[index])) {
                return prevData;
            }
            fieldData.config[agentId].conditions[index] = Object.assign(Object.assign({}, fieldData.config[agentId].conditions[index]), { [fieldId]: value });
            return newData;
        });
    };
    const handleRemoveCondition = (agentId, index) => {
        setter((prevData) => {
            var _a, _b;
            const newData = Object.assign({}, prevData);
            const fieldData = newData[field.id] || {};
            if ((_b = (_a = fieldData.config) === null || _a === void 0 ? void 0 : _a[agentId]) === null || _b === void 0 ? void 0 : _b.conditions) {
                fieldData.config[agentId].conditions.splice(index, 1);
            }
            newData[field.id] = fieldData;
            return newData;
        });
    };
    useEffect(() => {
        if (agentDataToUse &&
            Object.keys(agentDataToUse).length > 0 &&
            !dataSetted) {
            const newRenderedValues = {};
            Object.entries(agentDataToUse)
                .filter(([k, v]) => k !== 'total' && k !== 'config')
                .forEach(([k, v]) => {
                newRenderedValues[k] =
                    units === 'amount'
                        ? Formatter.currency(v)
                        : percentageFormat === 'percentage'
                            ? Formatter.percentageNotDecimal(v)
                            : Formatter.percentage(v);
            });
            setRenderedValues(newRenderedValues);
            setDataSetted(true);
        }
    }, [agentDataToUse, units, percentageFormat, dataSetted]);
    return (_jsxs(Box, { children: [_jsx(Typography, { sx: { mb: 1 }, variant: "subtitle1", children: field.label }), !readOnly && (_jsx(Box, { display: "flex", justifyContent: "space-between", position: "relative", children: _jsx(AgentSelect, { removeAgentData: removeAgentData, agentDataToUse: agentDataToUse, updateAgentData: updateAgentData, dynamicSelects: dynamicSelects, idToUse: idToUse }) })), _jsx(Box, { sx: {
                    mt: 0.5,
                    p: 0.5,
                    display: Object.keys(agentDataToUse).filter((item) => item !== 'config').length
                        ? 'flex'
                        : 'none',
                    flexDirection: 'column',
                    borderStyle: 'solid',
                    borderColor: 'silver',
                    borderWidth: 1,
                    borderRadius: 4,
                    width: '100%',
                    backgroundColor: '#2196f308',
                }, children: _jsxs(Box, { sx: { my: 0.5, display: 'flex', flexWrap: 'wrap' }, children: [agentDataToUse &&
                            Object.entries(agentDataToUse)
                                .filter(([k, v]) => k !== 'total' && k !== 'config')
                                .map(([k, v]) => {
                                var _a, _b, _c, _d;
                                const contact = (_a = dynamicSelects === null || dynamicSelects === void 0 ? void 0 : dynamicSelects.find((e) => (e === null || e === void 0 ? void 0 : e[idToUse]) === (idToUse === 'id' ? +k : k))) !== null && _a !== void 0 ? _a : { id: undefined };
                                return contact.id ? (_jsxs(Box, { sx: {
                                        m: 0.5,
                                        p: 1,
                                        borderStyle: 'solid',
                                        borderColor: 'silver',
                                        borderWidth: 1,
                                        borderRadius: 4,
                                        display: 'inline-block',
                                        minWidth: 180,
                                        backgroundColor: '#2196f30a',
                                    }, children: [_jsxs(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", position: "relative", sx: { ml: 0.5 }, children: [_jsx(Typography, { variant: "body2", children: Formatter.contact(contact, {
                                                        account_id: selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
                                                    }) }), !readOnly && (_jsx(IconButton, { onClick: () => {
                                                        removeAgentData(contact[idToUse]);
                                                    }, children: _jsx(CloseIcon, {}) }))] }), _jsx(Box, { children: _jsx(TextField, { label: `Commission ${units}`, variant: "outlined", value: renderedValues[k], onChange: (e) => {
                                                    let newValue = e.target.value;
                                                    if (newValue.includes('%')) {
                                                        newValue = newValue.replace('%', '');
                                                    }
                                                    if (newValue.includes('$')) {
                                                        newValue = newValue.replace('$', '');
                                                    }
                                                    setRenderedValues((prevRendered) => (Object.assign(Object.assign({}, prevRendered), { [k]: units === 'amount'
                                                            ? newValue
                                                            : percentageFormat === 'percentage'
                                                                ? Formatter.percentageNotDecimal(newValue)
                                                                : Formatter.percentage(newValue) })));
                                                    updateAgentData(contact[idToUse], newValue);
                                                }, onBlur: (e) => {
                                                    setter((prevData) => {
                                                        const newData = Object.assign({}, prevData);
                                                        const fieldData = Object.assign({}, newData[field.id]);
                                                        const { config } = fieldData, rest = __rest(fieldData, ["config"]);
                                                        // Convert all non-config values to numbers
                                                        Object.entries(rest).forEach(([k, v]) => {
                                                            const amount = v;
                                                            fieldData[k] = Number.isNaN(+amount)
                                                                ? 0
                                                                : +amount;
                                                        });
                                                        // Preserve the config
                                                        if (config) {
                                                            fieldData.config = config;
                                                        }
                                                        newData[field.id] = fieldData;
                                                        return newData;
                                                    });
                                                }, sx: { my: 0.5 }, disabled: readOnly }) }), showConditions && (_jsxs(Box, { sx: { mt: 0.25, mx: 1 }, children: [(_d = (_c = (_b = agentDataToUse === null || agentDataToUse === void 0 ? void 0 : agentDataToUse.config) === null || _b === void 0 ? void 0 : _b[contact[idToUse]]) === null || _c === void 0 ? void 0 : _c.conditions) === null || _d === void 0 ? void 0 : _d.map((condition, index) => {
                                                    var _a, _b, _c, _d, _e;
                                                    return (_jsxs(Box, { sx: {
                                                            display: 'flex',
                                                            gap: 1,
                                                            mt: 0.5,
                                                            mx: 1,
                                                            alignItems: 'center',
                                                        }, children: [_jsxs(FormControl, { sx: { width: '50%' }, children: [_jsx(InputLabel, { children: "Condition type" }), _jsx(Select, { value: (_a = condition.type) !== null && _a !== void 0 ? _a : '', label: "Condition type", onChange: (e) => updateCondition(contact[idToUse], index, 'type', e.target.value), children: CONDITION_TYPE_OPTIONS.filter((condition) => condition.value ===
                                                                            CustomMethodConditionType.COMPENSATION_TYPE).map((option) => (_jsx(MenuItem, { value: option.value, children: option.label }, option.value))) })] }), _jsxs(FormControl, { sx: { width: '50%', mx: 1 }, children: [_jsx(InputLabel, { children: "Operator" }), _jsx(Select, { value: (_b = condition.operator) !== null && _b !== void 0 ? _b : '', label: "Operator", onChange: (e) => updateCondition(contact[idToUse], index, 'operator', e.target.value), children: CustomMethodOperatorOptions.map((option) => (_jsx(MenuItem, { value: option.value, children: option.label }, option.value))) }, `operator-${condition.type}-${index}`)] }), _jsx(TextField, { sx: { width: '50%' }, label: (_d = (_c = CONDITION_TYPE_OPTIONS.find((opt) => opt.value === condition.type)) === null || _c === void 0 ? void 0 : _c.label) !== null && _d !== void 0 ? _d : 'Amount', value: (_e = condition.value) !== null && _e !== void 0 ? _e : '', onChange: (e) => updateCondition(contact[idToUse], index, 'value', e.target.value) }), _jsx(IconButton, { size: "small", onClick: () => handleRemoveCondition(contact[idToUse], index), children: _jsx(Close, {}) })] }, index));
                                                }), _jsx(Button, { onClick: () => handleAddCondition(contact[idToUse]), disabled: readOnly, children: "Add condition" })] }))] }, `${contact.id}`)) : (_jsx(Skeleton, {}, k));
                            }), units === 'amount' && (_jsx(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", position: "relative", sx: { mx: 0.5 }, children: _jsx(TextField, { label: "Total", variant: "outlined", value: Formatter.currency((agentDataToUse === null || agentDataToUse === void 0 ? void 0 : agentDataToUse.total) ? agentDataToUse.total : 0), disabled: true, sx: { my: 0.5 } }) }))] }) })] }));
};
export default AgentCommissionsEdit;
