import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { Box, Button, Checkbox, Chip, Collapse, FormControl, FormControlLabel, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, } from '@mui/material';
import { Link } from 'react-router-dom';
import { KeyboardArrowDown as KeyboardArrowDownIcon, KeyboardArrowUp as KeyboardArrowUpIcon, Close as CloseIcon, EditOutlined, EditOffOutlined, } from '@mui/icons-material';
import { nanoid } from 'nanoid';
import currency from 'currency.js';
import { TransactionStatuses, TransactionStatusesLabels, TransactionStatusesOptions, } from 'common/globalTypes';
import { transactionTypes } from 'common/constants';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import Formatter from '@/services/Formatter';
import { Roles } from '@/types';
const defaultTransaction = {
    amount: '',
    status: TransactionStatuses.DRAFT,
    date: null,
    notes: '',
    accounting_transaction_details: [],
};
const defaultTransactionDetails = {
    amount: '',
    status: TransactionStatuses.DRAFT,
    date: null,
    notes: '',
};
const calculateTotalAmount = (details) => {
    return currency(details.reduce((sum, detail) => sum.add(currency(detail.amount || '0')), currency(0))).toString();
};
const updateTransaction = (transactions, transactionId, updateFn) => {
    return transactions.map((transaction) => transaction.id === transactionId ? updateFn(transaction) : transaction);
};
const updateTransactionDetail = (details, detailId, updateFn) => {
    return details.map((detail) => detail.id === detailId ? updateFn(detail) : detail);
};
const ContactsTransactionsAdd = ({ data, field, setter, userRole, }) => {
    var _a;
    const [open, setOpen] = useState({});
    const [showSettled, setShowSettled] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const isProducer = userRole && userRole === Roles.PRODUCER;
    const handleToggle = (id) => {
        setOpen((prev) => (Object.assign(Object.assign({}, prev), { [id]: !prev[id] })));
    };
    const contactTransactions = ((_a = data === null || data === void 0 ? void 0 : data.accounting_transactions) !== null && _a !== void 0 ? _a : []).sort((a, b) => {
        if (a.type && !b.type) {
            return -1;
        }
        if (!a.type && b.type) {
            return 1;
        }
        return 0;
    });
    return (_jsxs(Box, { sx: { width: '100%' }, children: [_jsx(Typography, { variant: "subtitle2", children: field.label }), _jsxs(Box, { sx: {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    ml: 1,
                    mt: 1,
                }, children: [_jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: showSettled }), label: "Show settlements", onClick: () => setShowSettled(!showSettled) }), !isProducer && (_jsx(IconButton, { onClick: () => setIsEditMode(!isEditMode), children: isEditMode ? _jsx(EditOffOutlined, {}) : _jsx(EditOutlined, {}) }))] }), _jsx(TableContainer, { children: _jsxs(Table, { children: [contactTransactions.length > 0 && (_jsx(TableHead, { children: _jsxs(TableRow, { children: [_jsx(TableCell, {}), _jsx(TableCell, { children: "Date" }), _jsx(TableCell, { children: "Total amount" }), _jsx(TableCell, { children: "Status" }), _jsx(TableCell, { children: "Notes" }), _jsx(TableCell, { children: "Reference" }), isEditMode && _jsx(TableCell, { sx: { width: 0 } })] }) })), _jsxs(TableBody, { children: [contactTransactions.map((contactTransaction) => {
                                    var _a, _b, _c, _d;
                                    const shouldShowElement = contactTransaction.type !==
                                        transactionTypes.COMP_REPORT_PAYMENT ||
                                        (contactTransaction.type ===
                                            transactionTypes.COMP_REPORT_PAYMENT &&
                                            showSettled);
                                    if (!shouldShowElement) {
                                        return null;
                                    }
                                    return (_jsx(React.Fragment, { children: isEditMode ? (_jsxs(_Fragment, { children: [_jsxs(TableRow, { children: [_jsx(TableCell, { children: _jsx(IconButton, { size: "small", onClick: () => handleToggle(contactTransaction.id), children: open[contactTransaction.id] ? (_jsx(KeyboardArrowUpIcon, {})) : (_jsx(KeyboardArrowDownIcon, {})) }) }), _jsx(TableCell, { children: _jsx(BasicDatePicker, { label: "Date", value: contactTransaction.date, setValue: (e) => {
                                                                    setter(Object.assign(Object.assign({}, data), { accounting_transactions: updateTransaction(data.accounting_transactions, contactTransaction.id, (item) => (Object.assign(Object.assign({}, item), { date: e }))) }));
                                                                } }) }), _jsx(TableCell, { children: _jsx(TextField, { label: "Total amount", variant: "outlined", value: calculateTotalAmount(contactTransaction.accounting_transaction_details ||
                                                                    []), disabled: true }) }), _jsx(TableCell, { children: _jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { children: "Status" }), _jsx(Select, { value: (_a = contactTransaction.status) !== null && _a !== void 0 ? _a : '', label: "Status", disabled: contactTransaction.type ===
                                                                            transactionTypes.COMP_REPORT_PAYMENT, onChange: (e) => {
                                                                            setter(Object.assign(Object.assign({}, data), { accounting_transactions: updateTransaction(data.accounting_transactions, contactTransaction.id, (item) => {
                                                                                    var _a;
                                                                                    return (Object.assign(Object.assign({}, item), { status: e.target.value, accounting_transaction_details: ((_a = item.accounting_transaction_details) !== null && _a !== void 0 ? _a : []).map((td) => (Object.assign(Object.assign({}, td), { status: e.target.value }))) }));
                                                                                }) }));
                                                                        }, children: TransactionStatusesOptions.map((option) => (_jsx(MenuItem, { value: option.value, children: option.label }, option.value))) })] }) }), _jsx(TableCell, { children: _jsx(TextField, { label: "Notes", variant: "outlined", value: (_b = contactTransaction.notes) !== null && _b !== void 0 ? _b : '', onChange: (e) => {
                                                                    setter(Object.assign(Object.assign({}, data), { accounting_transactions: updateTransaction(data.accounting_transactions, contactTransaction.id, (item) => (Object.assign(Object.assign({}, item), { notes: e.target.value }))) }));
                                                                }, multiline: true }) }), _jsx(TableCell, { children: contactTransaction.saved_report_id &&
                                                                contactTransaction.saved_report ? (_jsx(Chip, { label: contactTransaction.saved_report.name, clickable: true, component: Link, to: `/reports/${contactTransaction.saved_report.str_id}`, target: "_blank" })) : contactTransaction.type ===
                                                                transactionTypes.COMP_REPORT_PAYMENT ? ('Comp report payment') : null }), _jsx(TableCell, { children: _jsx(IconButton, { onClick: () => {
                                                                    setter(Object.assign(Object.assign({}, data), { accounting_transactions: contactTransactions.filter((cl) => cl.id !== contactTransaction.id) }));
                                                                }, children: _jsx(CloseIcon, {}) }) })] }), open[contactTransaction.id] && (_jsx(TableRow, { children: _jsx(TableCell, { style: { paddingBottom: 0, paddingTop: 0 }, colSpan: 6, children: _jsx(Collapse, { in: open[contactTransaction.id], timeout: "auto", unmountOnExit: true, children: _jsx(Box, { sx: {
                                                                    width: '100%',
                                                                    ml: 2,
                                                                    mt: 1,
                                                                    mb: 2,
                                                                    border: '1px solid rgba(224, 224, 224, 1)',
                                                                    borderRadius: '16px',
                                                                    overflow: 'hidden',
                                                                }, children: _jsxs(Table, { size: "small", children: [_jsx(TableHead, { children: contactTransaction.accounting_transaction_details &&
                                                                                contactTransaction
                                                                                    .accounting_transaction_details.length >
                                                                                    0 && (_jsxs(TableRow, { sx: { backgroundColor: '#f8fcfe' }, children: [_jsx(TableCell, { children: "Date" }), _jsx(TableCell, { children: "Amount" }), _jsx(TableCell, { children: "Status" }), _jsx(TableCell, { children: "Notes" }), _jsx(TableCell, { children: "Reference" }), isEditMode && (_jsx(TableCell, { sx: { width: 0 } }))] })) }), _jsxs(TableBody, { children: [(_c = contactTransaction.accounting_transaction_details) === null || _c === void 0 ? void 0 : _c.map((transactionDetail) => {
                                                                                    var _a, _b, _c;
                                                                                    return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: _jsx(BasicDatePicker, { label: "Date", value: transactionDetail.date, setValue: (e) => {
                                                                                                        setter(Object.assign(Object.assign({}, data), { accounting_transactions: updateTransaction(data.accounting_transactions, contactTransaction.id, (item) => {
                                                                                                                var _a, _b;
                                                                                                                return (Object.assign(Object.assign({}, item), { accounting_transaction_details: updateTransactionDetail((_a = item.accounting_transaction_details) !== null && _a !== void 0 ? _a : [], transactionDetail.id, (detailItem) => (Object.assign(Object.assign({}, detailItem), { date: e }))), amount: calculateTotalAmount((_b = item.accounting_transaction_details) !== null && _b !== void 0 ? _b : []) }));
                                                                                                            }) }));
                                                                                                    } }) }), _jsx(TableCell, { children: _jsx(TextField, { label: "Amount", variant: "outlined", value: (_a = transactionDetail.amount) !== null && _a !== void 0 ? _a : '', onChange: (e) => {
                                                                                                        const newAmount = e.target.value;
                                                                                                        const updatedTransactions = updateTransaction(data.accounting_transactions, contactTransaction.id, (item) => {
                                                                                                            var _a, _b;
                                                                                                            return (Object.assign(Object.assign({}, item), { accounting_transaction_details: updateTransactionDetail((_a = item.accounting_transaction_details) !== null && _a !== void 0 ? _a : [], transactionDetail.id, (detailItem) => (Object.assign(Object.assign({}, detailItem), { amount: newAmount }))), amount: calculateTotalAmount((_b = item.accounting_transaction_details) !== null && _b !== void 0 ? _b : []) }));
                                                                                                        });
                                                                                                        setter(Object.assign(Object.assign({}, data), { accounting_transactions: updatedTransactions }));
                                                                                                    } }) }), _jsx(TableCell, { children: _jsxs(FormControl, { fullWidth: true, children: [_jsx(InputLabel, { children: "Status" }), _jsx(Select, { disabled: contactTransaction.type ===
                                                                                                                transactionTypes.COMP_REPORT_PAYMENT, value: (_b = transactionDetail.status) !== null && _b !== void 0 ? _b : '', label: "Status", onChange: (e) => {
                                                                                                                const newStatus = e.target.value;
                                                                                                                const updatedTransactions = updateTransaction(data.accounting_transactions, contactTransaction.id, (item) => {
                                                                                                                    var _a, _b;
                                                                                                                    return (Object.assign(Object.assign({}, item), { accounting_transaction_details: updateTransactionDetail((_a = item.accounting_transaction_details) !== null && _a !== void 0 ? _a : [], transactionDetail.id, (detailItem) => (Object.assign(Object.assign({}, detailItem), { status: newStatus }))), amount: calculateTotalAmount((_b = item.accounting_transaction_details) !== null && _b !== void 0 ? _b : []) }));
                                                                                                                });
                                                                                                                setter(Object.assign(Object.assign({}, data), { accounting_transactions: updatedTransactions }));
                                                                                                            }, children: TransactionStatusesOptions.map((option) => (_jsx(MenuItem, { value: option.value, children: option.label }, option.value))) })] }) }), _jsx(TableCell, { children: _jsx(TextField, { label: "Notes", variant: "outlined", value: (_c = transactionDetail.notes) !== null && _c !== void 0 ? _c : '', onChange: (e) => {
                                                                                                        setter(Object.assign(Object.assign({}, data), { accounting_transactions: updateTransaction(data.accounting_transactions, contactTransaction.id, (item) => {
                                                                                                                var _a;
                                                                                                                return (Object.assign(Object.assign({}, item), { accounting_transaction_details: updateTransactionDetail((_a = item.accounting_transaction_details) !== null && _a !== void 0 ? _a : [], transactionDetail.id, (detailItem) => (Object.assign(Object.assign({}, detailItem), { notes: e.target.value }))) }));
                                                                                                            }) }));
                                                                                                    }, multiline: true, sx: { minWidth: 110 } }) }), _jsx(TableCell, { children: transactionDetail.statement_id &&
                                                                                                    transactionDetail.statement_data ? (_jsx(Chip, { label: "Linked commission", clickable: true, component: Link, to: `/commissions?q=${transactionDetail.statement_data.str_id}`, target: "_blank", sx: { m: 0.25 } })) : transactionDetail.saved_report_id &&
                                                                                                    transactionDetail.saved_report ? (_jsx(Chip, { label: transactionDetail.saved_report
                                                                                                        .name, clickable: true, component: Link, to: `/reports/${transactionDetail.saved_report.str_id}`, target: "_blank" })) : null }), _jsx(TableCell, { children: _jsx(IconButton, { onClick: () => {
                                                                                                        setter(Object.assign(Object.assign({}, data), { accounting_transactions: updateTransaction(data.accounting_transactions, contactTransaction.id, (item) => {
                                                                                                                var _a, _b;
                                                                                                                return (Object.assign(Object.assign({}, item), { accounting_transaction_details: (_a = item.accounting_transaction_details) === null || _a === void 0 ? void 0 : _a.filter((detailItem) => detailItem.id !==
                                                                                                                        transactionDetail.id), amount: calculateTotalAmount((_b = item.accounting_transaction_details) !== null && _b !== void 0 ? _b : []) }));
                                                                                                            }) }));
                                                                                                    }, children: _jsx(CloseIcon, {}) }) })] }, transactionDetail.id));
                                                                                }), _jsx(TableRow, { children: _jsx(TableCell, { colSpan: 4, children: _jsx(Button, { onClick: (e) => {
                                                                                                e.stopPropagation();
                                                                                                e.preventDefault();
                                                                                                setter(Object.assign(Object.assign({}, data), { accounting_transactions: updateTransaction(data.accounting_transactions, contactTransaction.id, (item) => {
                                                                                                        var _a, _b;
                                                                                                        return (Object.assign(Object.assign({}, item), { accounting_transaction_details: [
                                                                                                                ...((_a = item.accounting_transaction_details) !== null && _a !== void 0 ? _a : []),
                                                                                                                Object.assign({ id: nanoid() }, defaultTransactionDetails),
                                                                                                            ], amount: calculateTotalAmount([
                                                                                                                ...((_b = item.accounting_transaction_details) !== null && _b !== void 0 ? _b : []),
                                                                                                                Object.assign({ id: nanoid() }, defaultTransactionDetails),
                                                                                                            ]) }));
                                                                                                    }) }));
                                                                                            }, children: "Add detail" }) }) })] })] }) }) }) }) }))] })) : (
                                        //  View mode
                                        _jsxs(_Fragment, { children: [_jsxs(TableRow, { children: [_jsx(TableCell, { sx: { width: 35 }, children: _jsx(IconButton, { size: "small", onClick: () => handleToggle(contactTransaction.id), children: open[contactTransaction.id] ? (_jsx(KeyboardArrowUpIcon, {})) : (_jsx(KeyboardArrowDownIcon, {})) }) }), _jsx(TableCell, { children: _jsx(Typography, { variant: "body2", children: contactTransaction.date
                                                                    ? new Date(contactTransaction.date).toLocaleDateString('en-US')
                                                                    : '' }) }), _jsx(TableCell, { children: _jsxs(Typography, { variant: "body2", children: ["$", calculateTotalAmount(contactTransaction.accounting_transaction_details ||
                                                                        [])] }) }), _jsx(TableCell, { children: Formatter.statusChip(TransactionStatusesLabels[contactTransaction.status], {
                                                                mapping: {
                                                                    [TransactionStatusesLabels[TransactionStatuses.APPROVED]]: 'blue',
                                                                    [TransactionStatusesLabels[TransactionStatuses.PAID]]: 'green',
                                                                    [TransactionStatusesLabels[TransactionStatuses.SETTLEMENT]]: 'green',
                                                                    [TransactionStatusesLabels[TransactionStatuses.DRAFT]]: '',
                                                                },
                                                            }) }), _jsx(TableCell, { children: _jsx(Typography, { variant: "body2", children: contactTransaction.notes || '' }) }), _jsx(TableCell, { children: contactTransaction.saved_report_id &&
                                                                contactTransaction.saved_report ? (_jsx(Chip, { label: contactTransaction.saved_report.name, clickable: true, component: Link, to: `/reports/${contactTransaction.saved_report.str_id}`, target: "_blank" })) : contactTransaction.type ===
                                                                transactionTypes.COMP_REPORT_PAYMENT ? ('Comp report payment') : null })] }), open[contactTransaction.id] && (_jsx(TableRow, { children: _jsx(TableCell, { sx: { pb: 0, pt: 0 }, colSpan: 6, children: _jsx(Collapse, { in: open[contactTransaction.id], timeout: "auto", unmountOnExit: true, children: _jsx(Box, { sx: {
                                                                    width: '100%',
                                                                    ml: 2,
                                                                    mt: 1,
                                                                    mb: 2,
                                                                    border: '1px solid rgba(224, 224, 224, 1)',
                                                                    borderRadius: '16px',
                                                                    overflow: 'hidden',
                                                                }, children: _jsxs(Table, { size: "small", children: [_jsx(TableHead, { children: contactTransaction.accounting_transaction_details &&
                                                                                contactTransaction
                                                                                    .accounting_transaction_details.length >
                                                                                    0 && (_jsxs(TableRow, { sx: { backgroundColor: '#f8fcfe' }, children: [_jsx(TableCell, { children: "Date" }), _jsx(TableCell, { children: "Amount" }), _jsx(TableCell, { children: "Status" }), _jsx(TableCell, { children: "Notes" }), _jsx(TableCell, { children: "Reference" }), isEditMode && (_jsx(TableCell, { sx: { width: 0 } }))] })) }), _jsx(TableBody, { children: (_d = contactTransaction.accounting_transaction_details) === null || _d === void 0 ? void 0 : _d.map((transactionDetail) => {
                                                                                var _a;
                                                                                return (_jsxs(TableRow, { children: [_jsx(TableCell, { children: _jsx(Typography, { variant: "body2", children: transactionDetail.date
                                                                                                    ? new Date(transactionDetail.date).toLocaleDateString('en-US')
                                                                                                    : '' }) }), _jsx(TableCell, { children: _jsxs(Typography, { variant: "body2", children: ["$", (_a = transactionDetail.amount) !== null && _a !== void 0 ? _a : ''] }) }), _jsx(TableCell, { children: Formatter.statusChip(TransactionStatusesLabels[transactionDetail.status], {
                                                                                                mapping: {
                                                                                                    [TransactionStatusesLabels[TransactionStatuses.APPROVED]]: 'blue',
                                                                                                    [TransactionStatusesLabels[TransactionStatuses.PAID]]: 'green',
                                                                                                    [TransactionStatusesLabels[TransactionStatuses
                                                                                                        .SETTLEMENT]]: 'green',
                                                                                                    [TransactionStatusesLabels[TransactionStatuses.DRAFT]]: '',
                                                                                                },
                                                                                            }) }), _jsx(TableCell, { children: _jsx(Typography, { variant: "body2", children: transactionDetail.notes || '' }) }), _jsx(TableCell, { children: transactionDetail.statement_id &&
                                                                                                transactionDetail.statement_data ? (_jsx(Chip, { label: "Linked commission", clickable: true, component: Link, to: `/commissions?q=${transactionDetail.statement_data.str_id}`, target: "_blank", sx: { m: 0.25 } })) : transactionDetail.saved_report_id &&
                                                                                                transactionDetail.saved_report ? (_jsx(Chip, { label: transactionDetail.saved_report
                                                                                                    .name, clickable: true, component: Link, to: `/reports/${transactionDetail.saved_report.str_id}`, target: "_blank" })) : null })] }, transactionDetail.id));
                                                                            }) })] }) }) }) }) }))] })) }, contactTransaction.id));
                                }), isEditMode && (_jsx(TableRow, { children: _jsx(TableCell, { colSpan: 6, children: _jsx(Button, { onClick: (e) => {
                                                var _a;
                                                e.stopPropagation();
                                                e.preventDefault();
                                                setter(Object.assign(Object.assign({}, data), { accounting_transactions: [
                                                        ...((_a = data.accounting_transactions) !== null && _a !== void 0 ? _a : []),
                                                        Object.assign({ id: nanoid() }, defaultTransaction),
                                                    ] }));
                                            }, children: "Add transaction" }) }) }))] })] }) })] }));
};
export default ContactsTransactionsAdd;
