import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { GroupAdd, Launch } from '@mui/icons-material';
import { Box, IconButton, TextField, Typography } from '@mui/material';
import { CustomerGender, CustomerType, } from 'common/customer/customer.constants';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { SyncEndAdornment } from '@/common/SyncEndAdornment';
import { SyncedEntity } from '@/common/SyncedEntity';
import DataPreviewTable from '@/components/molecules/DataPreviewTable';
import { useSyncedFieldsNew } from '@/contexts/useSyncedFields';
import Formatter from '@/services/Formatter';
import { FieldTypes } from '@/types';
import { formatDate } from '@/utils/datetime';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import { SyncWithPolicies } from '@/components/customers/sync-with-policies';
import useEnhancedTableToolbarStore from '@/components/molecules/EnhancedTableToolbar/store';
export const Customers = () => {
    const isBulkEdit = useEnhancedTableToolbarStore((state) => state.isBulkEdit);
    const [openCreateCustomers, setOpenCreateCustomers] = useState(false);
    const { workerSyncedFields, isSyncedField } = useSyncedFieldsNew();
    const dataDesc = useMemo(() => {
        const genderOptions = [
            { id: CustomerGender.male, label: 'Male' },
            { id: CustomerGender.female, label: 'Female' },
        ];
        const typeOptions = [
            { id: CustomerType.individual, label: 'Individual' },
            { id: CustomerType.group, label: 'Group' },
        ];
        const fields = {
            type: {
                label: 'Type',
                enabled: true,
                type: FieldTypes.SELECT,
                options: typeOptions,
                formatter: (v) => (_jsx(Box, { sx: { textTransform: 'capitalize' }, children: v })),
            },
            first_name: {
                label: 'First name',
                enabled: true,
            },
            middle_name: {
                label: 'Middle name',
                enabled: false,
            },
            last_name: {
                label: 'Last name',
                enabled: true,
            },
            nickname: {
                label: 'Nickname',
                enabled: true,
            },
            dob: {
                label: 'Birthday',
                enabled: true,
                type: FieldTypes.DATE,
                formatter: formatDate,
            },
            gender: {
                label: 'Gender',
                enabled: false,
                type: FieldTypes.SELECT,
                options: genderOptions,
                formatter: (v) => (_jsx(Box, { sx: { textTransform: 'capitalize' }, children: v })),
            },
            company_name: {
                label: 'Company name',
                enabled: true,
            },
            website: {
                label: 'Website',
                enabled: true,
            },
            email: {
                label: 'Email',
                enabled: true,
            },
            phone: {
                label: 'Phone',
                enabled: true,
            },
            address: {
                label: 'Address',
                enabled: true,
                type: FieldTypes.CUSTOM,
                formatter: (v) => {
                    if (!v)
                        return '';
                    const values = [v.street, v.city, v.geo_state, v.zipcode].filter((v) => !!v);
                    return _jsx(Box, { children: values.join(', ') });
                },
                render: (_, newData, setNewData) => {
                    const { street = '', city = '', geo_state = '', zipcode = '', } = newData.address || {};
                    const onChange = (v, field) => {
                        setNewData(Object.assign(Object.assign({}, newData), { address: Object.assign(Object.assign({}, (newData.address || {})), { [field]: v }) }));
                    };
                    return (_jsxs(Box, { sx: { display: 'flex', gap: 1 }, children: [_jsx(TextField, { value: street, onChange: (v) => onChange(v.target.value, 'street'), label: "Street" }), _jsx(TextField, { value: city, onChange: (v) => onChange(v.target.value, 'city'), label: "City" }), _jsx(TextField, { value: geo_state, onChange: (v) => onChange(v.target.value, 'geo_state'), label: "State" }), _jsx(TextField, { value: zipcode, onChange: (v) => onChange(v.target.value, 'zipcode'), label: "Zip code" })] }));
                },
            },
            group_id: {
                label: 'Group ID',
                enabled: true,
            },
            start_date: {
                label: 'Start date',
                enabled: true,
                type: 'date',
                formatter: formatDate,
            },
            end_date: {
                label: 'End date',
                enabled: true,
                type: FieldTypes.DATE,
                formatter: formatDate,
            },
            report_data: {
                label: 'Report data',
                enabled: true,
                type: FieldTypes.CUSTOM,
                tableFormatter: (field) => field.length || '',
                readOnly: true,
                render: (field, row) => (_jsx(DataPreviewTable, { label: "Policies", data: row.report_data, fields: [
                        {
                            label: 'ID',
                            key: 'str_id',
                            formatter: (val) => (_jsxs(Box, { sx: { whiteSpace: 'nowrap' }, children: [_jsx(Typography, { variant: "body2", component: "span", children: `${val.substring(0, 10)}...` }), _jsx(IconButton, { component: Link, to: `/policies?id=${val}`, target: "_blank", children: _jsx(Launch, {}) })] })),
                        },
                        { label: 'Policy number', key: 'policy_id' },
                        { label: 'Master company', key: 'writing_carrier_name' },
                        { label: 'Product type', key: 'product_type' },
                        { label: 'Product name', key: 'product_name' },
                        { label: 'Policy status', key: 'policy_status' },
                        {
                            label: 'Effective date',
                            key: 'effective_date',
                            formatter: Formatter.date,
                        },
                        { label: 'Policy term (months)', key: 'policy_term_months' },
                    ], opts: { expanded: true } })),
            },
        };
        return {
            label: 'Customers',
            table: 'customers',
            editable: true,
            fields: fields,
            bulkAddFields: [
                {
                    id: 'geo_state',
                },
            ],
            queryChips: {},
            queryChipsType: 'select',
        };
    }, []);
    const extraActions = [
        {
            type: 'button',
            label: 'Sync with policies',
            onClick: () => setOpenCreateCustomers(true),
            icon: _jsx(GroupAdd, {}),
        },
    ];
    if (!isBulkEdit) {
        for (const key in dataDesc.fields) {
            const field = dataDesc.fields[key];
            const fieldId = key;
            field.readOnly =
                field.readOnly ||
                    ((data) => {
                        var _a;
                        const syncedFields = (_a = workerSyncedFields === null || workerSyncedFields === void 0 ? void 0 : workerSyncedFields[data === null || data === void 0 ? void 0 : data.sync_worker]) === null || _a === void 0 ? void 0 : _a.customers;
                        if ((syncedFields || []).includes(fieldId)) {
                            return isSyncedField(data, syncedFields, fieldId, data.config);
                        }
                        return false;
                    });
            field.endAdornment = (data, field, setNewData) => {
                var _a;
                return (_jsx(SyncEndAdornment, { syncedFields: (_a = workerSyncedFields === null || workerSyncedFields === void 0 ? void 0 : workerSyncedFields[data === null || data === void 0 ? void 0 : data.sync_worker]) === null || _a === void 0 ? void 0 : _a.customers, syncId: data === null || data === void 0 ? void 0 : data.sync_id, fieldId: fieldId, data: data, fieldType: field.type, onChange: (newOverrideFields) => {
                        setNewData(Object.assign(Object.assign({}, data), { config: Object.assign(Object.assign({}, (data.config || {})), { overrideFields: newOverrideFields }) }));
                    } }));
            };
        }
    }
    const actions = [
        {
            type: 'icon',
            label: 'Sync',
            icon: _jsx(SyncedEntity, { isSynced: true, disabled: true }),
            enabled: (row) => !!row.sync_id,
            onClick: () => { },
        },
    ];
    return (_jsxs(_Fragment, { children: [_jsx(EnhancedDataView, { extraActions: extraActions, bulkAdd: true, dataSpec: dataDesc, actions: actions, actionsEnabled: ((row) => {
                    return !!row.sync_id;
                }) }), openCreateCustomers && (_jsx(SyncWithPolicies, { open: openCreateCustomers, onClose: () => setOpenCreateCustomers(false) }))] }));
};
