const mapToArray = (obj) => {
    return Object.values(obj).map((item) => typeof item === 'object' ? Object.values(item) : item);
};
export const desc_mapToArray = {
    name: 'libs.tools.mapToArray(obj)',
    description: 'Converts a nested object to a flat array of values.',
    arguments: {
        obj: '[Object] Object to convert to array',
    },
    returns: '[Array] Flattened array of object values',
    examples: 'mapToArray({ a: { x: 1, y: 2 }, b: 3 })\n// → [[1, 2], 3]\n',
};
export default mapToArray;
