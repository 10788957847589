var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LoadingButton } from '@mui/lab';
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, ToggleButton, ToggleButtonGroup, useMediaQuery, } from '@mui/material';
import { useState } from 'react';
import { CompReportViewTypes } from 'common/globalTypes';
import { savedReportsGroupsTemplates } from '@/types';
import Formatter from '@/services/Formatter';
const CommissionPayoutExportConfig = ({ open, setOpen, setExportOptions, savedReport, selectedAccount, selectedData, }) => {
    const [loading, setLoading] = useState(false);
    const [exportType, setExportType] = useState('xlsx');
    const [amountDueThreshold, setAmountDueThreshold] = useState('0');
    const [individualReports, setIndividualReports] = useState(false);
    const [disableCheckbox, setDisableCheckbox] = useState(false);
    const [grouping, setGrouping] = useState('none');
    const [view, setView] = useState(CompReportViewTypes.PRODUCER_VIEW);
    const isMobile = useMediaQuery('(max-width:600px)');
    return (_jsxs(Dialog, { open: open, onClose: () => setOpen(false), children: [_jsx(DialogTitle, { children: "Generate comp payout report" }), _jsx(DialogContent, { sx: { width: 450 }, children: _jsxs(Box, { sx: {
                        mt: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                    }, children: [_jsx(Box, { children: _jsxs(FormControl, { sx: { width: '25%' }, children: [_jsx(InputLabel, { id: "select-export-type", children: "Export as" }), _jsxs(Select, { labelId: "select-export-type", value: exportType, label: "Export as", onChange: (event) => {
                                            if (event.target.value === 'csv') {
                                                setDisableCheckbox(true);
                                            }
                                            else {
                                                setDisableCheckbox(false);
                                            }
                                            setExportType(event.target.value);
                                        }, children: [_jsx(MenuItem, { value: 'xlsx', children: "Excel" }), _jsx(MenuItem, { value: 'pdf', children: "PDF" }), _jsx(MenuItem, { value: 'csv', children: "CSV" })] })] }) }), _jsx(Box, { sx: { mt: 1 }, children: _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: individualReports, onChange: (event) => setIndividualReports(event.target.checked), disabled: disableCheckbox }), label: "Generate individual reports" }) }), _jsx(Box, { children: _jsxs(ToggleButtonGroup, { sx: { height: '34px' }, exclusive: true, orientation: isMobile ? 'vertical' : 'horizontal', color: "primary", onChange: (event, newView) => setView(newView), value: view, children: [_jsx(ToggleButton, { value: CompReportViewTypes.ADMIN_VIEW, children: "Admin view" }), _jsx(ToggleButton, { value: CompReportViewTypes.PRODUCER_VIEW, children: "Producer view" })] }) }), _jsx(Box, { sx: { mt: 1.5 }, children: _jsx(TextField, { label: "Amount due threshold", value: amountDueThreshold, onChange: (event) => {
                                    setAmountDueThreshold(event.target.value);
                                }, type: "number" }) }), _jsx(Box, { sx: { mt: 1 }, children: _jsxs(ToggleButtonGroup, { sx: { height: '30px', mb: 1 }, exclusive: true, color: "primary", onChange: (event, newGrouping) => {
                                    setGrouping(newGrouping);
                                }, value: grouping, children: [_jsx(ToggleButton, { sx: {
                                            borderRadius: '15px',
                                        }, value: "policyNumber", children: "Group by policy" }), _jsx(ToggleButton, { sx: {
                                            borderRadius: '15px',
                                        }, value: "none", children: "Commission data" })] }) })] }) }), _jsxs(DialogActions, { sx: { pt: 0 }, children: [_jsx(Button, { onClick: () => setOpen(false), children: "Cancel" }), _jsx(LoadingButton, { variant: "contained", loading: loading, onClick: () => __awaiter(void 0, void 0, void 0, function* () {
                            var _a, _b, _c;
                            setLoading(true);
                            setExportOptions({
                                export_type: exportType,
                                fileName: savedReport &&
                                    Array.isArray(savedReport) &&
                                    savedReport.length > 0
                                    ? ((_a = savedReport[0]) === null || _a === void 0 ? void 0 : _a.template) ===
                                        savedReportsGroupsTemplates.COMMISSION_PAYOUT
                                        ? `${selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountName} - ${Formatter.date((_b = savedReport[0]) === null || _b === void 0 ? void 0 : _b.created_at)}`.replace(/&/g, '')
                                        : (_c = savedReport[0]) === null || _c === void 0 ? void 0 : _c.name.replace(/&/g, '')
                                    : '',
                                amount_due_threshold: amountDueThreshold,
                                individual_reports: individualReports,
                                view: view,
                                selected_data: selectedData.map((data) => ({
                                    id: data.id,
                                    str_id: data.str_id,
                                })),
                                grouping: grouping,
                            });
                            setLoading(false);
                            setOpen(false);
                        }), children: "Export" })] })] }));
};
export default CommissionPayoutExportConfig;
