var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Delete, Edit } from '@mui/icons-material';
import { Box, Button, Dialog, DialogContent, IconButton } from '@mui/material';
import { useState } from 'react';
import { BasicDialog } from '@/common';
import DataView from '@/components/DataView';
import API from '@/services/API';
import ActionModal from '@/components/documents/MappingsView/ActionModal';
import { cellSx, headerSx, } from '@/components/HoverActionButtonContainer/styles';
import { HoverActionButtonContainer } from '@/components/HoverActionButtonContainer';
const MappingsView = () => {
    var _a, _b;
    const [showMappings, setShowMappings] = useState('');
    const [refresh, setRefresh] = useState(0);
    const [current, setCurrent] = useState(null);
    const [showDel, setShowDel] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    const mappingDel = API.getMutation('mappings', 'DELETE');
    const { data: companies } = API.getBasicQuery('companies');
    const deleteMapping = (id) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield mappingDel.mutateAsync({
                ids: [id],
            });
            setRefresh(refresh + 1);
            setCurrent(null);
        }
        catch (error) {
            console.log(error);
        }
    });
    const onClose = (v) => {
        if (v) {
            deleteMapping(current.id);
        }
        else {
            setCurrent(null);
        }
        setShowDel(false);
    };
    const dataDesc = {
        label: 'Mappings',
        table: 'mappings',
        editable: false,
        fields: [
            {
                id: 'created_at',
                label: 'Created at',
                formatter: (s) => new Date(s).toLocaleString(),
                readOnly: true,
            },
            {
                id: 'name',
                label: 'Name',
            },
            {
                id: 'type',
                label: 'Type',
                type: 'select',
                options: ['statement', 'report'],
                readOnly: true,
            },
            {
                id: 'carrier',
                label: 'Carrier',
                formatter: (s) => (typeof s === 'object' ? s === null || s === void 0 ? void 0 : s.company_name : s),
                normalizer: (s) => (typeof s === 'string' ? JSON.parse(s) : s),
                readOnly: true,
            },
            {
                id: 'mapping',
                label: 'Mapping',
                // Type: 'text-multiline',
                formatter: (s) => {
                    return (_jsxs(Box, { children: [typeof s === 'object' ? JSON.stringify(s) : s, typeof s === 'object' && (_jsx(Button, { onClick: () => setShowMappings(JSON.stringify(s, null, 2)), sx: { ml: 1 }, children: "View formatted json" }))] }));
                },
                normalizer: (s) => (typeof s === 'string' ? JSON.parse(s) : s),
                readOnly: true,
            },
            {
                id: 'access',
                label: 'Access',
                type: 'select',
                options: ['global', 'account'],
                access: 'admin',
            },
            {
                id: 'id',
                label: 'Actions',
                sx: cellSx,
                headerSx,
                formatter: (v, row) => {
                    return (_jsxs(HoverActionButtonContainer, { children: [_jsx(IconButton, { className: "ml-1", onClick: () => {
                                    setCurrent(row);
                                    setShowEdit(true);
                                }, children: _jsx(Edit, {}) }), _jsx(IconButton, { className: "ml-1", onClick: () => {
                                    setCurrent(row);
                                    setShowDel(true);
                                }, children: _jsx(Delete, {}) })] }));
                },
            },
        ],
    };
    return (_jsxs(_Fragment, { children: [_jsx(Dialog, { open: !!showMappings, onClose: () => setShowMappings(''), children: _jsx(DialogContent, { children: _jsx(Box, { component: 'pre', children: showMappings }) }) }), _jsx(DataView, { dataDesc: dataDesc, viewOnly: true, refresh: refresh }), showEdit && current && (_jsx(ActionModal, { open: showEdit, setOpen: setShowEdit, rowData: current, companies: (_a = companies === null || companies === void 0 ? void 0 : companies.data) !== null && _a !== void 0 ? _a : [] })), showDel && current && (_jsx(BasicDialog, { title: "Delete mapping", positiveLabel: "Delete", open: showDel, onClose: onClose, bodyComponent: _jsxs(Box, { children: [_jsx("h4", { children: "Are you sure you want to delete the mapping?" }), _jsxs("p", { className: "text-sm", children: [_jsx("span", { className: "text-gray-500", children: "Name:" }), _jsx("span", { className: "pl-2", children: current.name })] }), current.carrier && (_jsxs("p", { className: "text-sm", children: [_jsx("span", { className: "text-gray-500", children: "Carrier:" }), _jsx("span", { className: "pl-2", children: (_b = current.carrier) === null || _b === void 0 ? void 0 : _b.company_name })] }))] }) }))] }));
};
export default MappingsView;
