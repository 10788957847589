import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { GroupViewOptions } from 'common/documents/documents.constants';
import { Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useMemo, useRef } from 'react';
import { EnhancedSelect } from '@/components/molecules/EnhancedSelect';
import { useCompanies } from '@/api/companies';
import BasicDateRangePicker from '@/common/BasicDateRangePicker';
import { formatDBDate } from '@/utils/datetime';
import { useFilterParams } from '../hooks/useFilterParams';
export const FilterForm = () => {
    const refForm = useRef(null);
    const [_, setSearchParams] = useSearchParams();
    const { start_date, end_date, companies, group_by } = useFilterParams();
    const onChange = (data) => {
        setSearchParams((prev) => {
            for (const key in data) {
                prev.set(key, data[key]);
            }
            if (data.companies) {
                if (data.companies.length === (companiesData === null || companiesData === void 0 ? void 0 : companiesData.length))
                    prev.delete('companies');
                else if (!data.companies.length)
                    prev.set('companies', 'undefined');
                else
                    prev.set('companies', data.companies.join(','));
            }
            return prev;
        });
    };
    const { data: { data: companiesData } = {} } = useCompanies();
    const selectedCompanies = useMemo(() => {
        if (companies === undefined)
            return companiesData;
        return companiesData === null || companiesData === void 0 ? void 0 : companiesData.filter((item) => companies.includes(item.str_id));
    }, [companies, companiesData]);
    const selectedGroupBy = useMemo(() => {
        for (const item of GroupViewOptions) {
            if (item.subList) {
                for (const subItem of item.subList) {
                    if (subItem.id === group_by) {
                        return subItem;
                    }
                }
            }
            if (item.id === group_by) {
                return item;
            }
        }
    }, [group_by]);
    return (_jsxs(Box, { sx: {
            ml: 'auto',
            display: 'flex',
            alignItems: 'center',
            gap: 1,
        }, ref: refForm, children: [_jsx(BasicDateRangePicker, { range: {
                    startDate: start_date || null,
                    endDate: end_date || null,
                }, onChange: (range) => {
                    onChange({
                        start_date: formatDBDate(range.startDate),
                        end_date: formatDBDate(range.endDate),
                    });
                } }), _jsx(EnhancedSelect, { sx: { mr: 0 }, multiple: true, enableSearch: true, options: companiesData || [], label: "Companies", value: selectedCompanies, labelKey: "company_name", valueKey: "str_id", onChange: (v) => {
                    onChange({
                        companies: v.map((item) => item.str_id),
                    });
                } }), _jsx(EnhancedSelect, { sortLabel: false, options: GroupViewOptions, label: "Group by", value: selectedGroupBy, containerWidth: 200, onChange: (v) => {
                    onChange({
                        group_by: v.id,
                    });
                } })] }));
};
