/**
 * Splice Excel array
 */
const spliceExcelArray = (dataList, companyList, tableDataTarget) => {
    const result = {};
    let currentCompany = null;
    let recording = false;
    let tempData = [];
    let expectedColumnCount = 0;
    dataList.forEach((row) => {
        const isCompanyRow = companyList.includes(row[0]);
        if (isCompanyRow) {
            if (currentCompany && tempData.length > 0) {
                result[currentCompany] = tempData;
            }
            currentCompany = row[0];
            tempData = [];
            recording = false;
            expectedColumnCount = 0;
        }
        else if (tableDataTarget.some((target) => row.includes(target))) {
            recording = true;
            tempData.push(row);
            expectedColumnCount = row.length;
        }
        else if (recording && row.length === expectedColumnCount) {
            tempData.push(row);
        }
    });
    if (currentCompany && tempData.length > 0) {
        result[currentCompany] = tempData;
    }
    return result;
};
export const desc_spliceExcelArray = {
    name: 'libs.tools.spliceExcelArray(dataList, companyList, tableDataTarget)',
    description: 'Splits Excel data into separate tables by company and specified target rows.',
    arguments: {
        dataList: '[Array<Array<string>>] Source 2D array of Excel data',
        companyList: '[Array<string>] List of company identifiers to split by',
        tableDataTarget: '[Array<string>] List of target strings to identify table headers',
    },
    returns: '[Object] Object with company names as keys and their respective data arrays as values',
    examples: 'spliceExcelArray(\n  [["Company A"], ["Headers", "Col1"], ["Data", "1"], ["Company B"]],\n  ["Company A", "Company B"],\n  ["Headers"]\n)\n// → {\n//   "Company A": [["Headers", "Col1"], ["Data", "1"]]\n// }\n',
};
export default spliceExcelArray;
