const splitFormatDate = (dateString, format, defaultYear = '2024') => {
    let year, month, day;
    switch (format) {
        case 'YYYYMMDD':
            year = dateString.substring(0, 4);
            month = dateString.substring(4, 6);
            day = dateString.substring(6, 8);
            break;
        case 'MMDDYYYY':
            month = dateString.substring(0, 2);
            day = dateString.substring(2, 4);
            year = dateString.substring(4, 8);
            break;
        case 'MMDDYY':
            month = dateString.substring(0, 2);
            day = dateString.substring(2, 4);
            year = '20' + dateString.substring(4, 6);
            break;
        case 'MM/YY':
            month = dateString.substring(0, 2);
            year = '20' + dateString.substring(3, 5);
            day = '01';
            break;
        case 'MM-DD':
            month = dateString.substring(0, 2);
            day = dateString.substring(3, 5);
            year = defaultYear;
            break;
        default:
            throw new Error('Unsupported date format');
    }
    if (!day) {
        day = '01';
    }
    month = month.padStart(2, '0');
    day = day.padStart(2, '0');
    return `${month}/${day}/${year}`;
};
export const desc_splitFormatDate = {
    name: 'libs.tools.splitFormatDate(dateString, format, defaultYear)',
    description: 'Converts date strings from various formats to a standardized MM/DD/YYYY format.',
    arguments: {
        dateString: '[String] Date string to format',
        format: '[String] Input format (YYYYMMDD|MMDDYYYY|MMDDYY|MM/YY|MM-DD)',
        defaultYear: '[String] Optional. Default year to use for formats without year',
    },
    returns: '[String] Date string in MM/DD/YYYY format',
    examples: 'splitFormatDate("20210101", "YYYYMMDD")\n// → "01/01/2021"\n\nsplitFormatDate("01/21", "MM/YY")\n// → "01/01/2021"\n\nsplitFormatDate("01-01", "MM-DD", "2024")\n// → "01/01/2024"\n',
};
export default splitFormatDate;
