var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Add, Download, FilterAltOffOutlined } from '@mui/icons-material';
import { Box, Button, Chip, Divider, IconButton, Link, MenuItem, Select, Tooltip, Typography, useMediaQuery, } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useQuery } from '@tanstack/react-query';
import { numberOrDefault } from 'common/helpers';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { isEqual } from 'lodash-es';
import log from 'loglevel';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { ResponseAction } from 'common/constants';
import ViewWeekOutlinedIcon from '@mui/icons-material/ViewWeekOutlined';
import BasicDateRangePicker from '@/common/BasicDateRangePicker';
import DataForm from '@/components/DataForm';
import LoadingCircle from '@/components/atoms/LoadingCircle';
import BasicDatePicker from '@/components/molecules/BasicDatePicker';
import EnhancedTable from '@/components/molecules/EnhancedTable';
import MoreDateFilters from '@/components/molecules/MoreDateFilters';
import MoreMenu from '@/components/molecules/MoreMenu';
import MultiSelect from '@/components/molecules/MultiSelect';
import SaveReport from '@/components/molecules/SaveReport';
import SearchBox from '@/components/molecules/SearchBox';
import SearchSettings from '@/components/molecules/SearchSettings';
import SplitButton from '@/components/molecules/SplitButton';
import { auth } from '@/firebase';
import API from '@/services/API';
import Formatter from '@/services/Formatter';
import { exportToCsv, formatSearchDateParams } from '@/services/helpers';
import { isNill } from '@/services/tools';
import { useMenuStore } from '@/store';
import useAccountStore from '@/store/accountStore';
import { DocumentPreviewKeys } from '@/types';
import useSnackbar from '@/contexts/useSnackbar';
import { EnhancedSelect } from '../molecules/EnhancedSelect';
import DataBulkAdd from '../DataBulkAdd';
dayjs.extend(utc);
const EnhancedDataView = ({ dataSpec, bulkAdd = false, setSelectedData = (a) => { }, options = { mode: 'default' }, prefilter = undefined, suggested = undefined, filters = null, hideAdd = false, hideExport = false, hideSelectedCount = false, enableSaves = false, exportOptions = [], defaultData = {}, reportId = null, showTotals = false, readOnly = false, rowKey = '', actionsEnabled = () => false, actions = [], outstandingMobileFields = [], enableMultiSelect = true, customHeaderActions = false, enableEdit = true, enableResetFilters = true, nonSelectableOnMobile = false, onBulkSync = undefined, bulkActions = [], extraActions = [], variant = '', extraFormActions = [], onQueryKeyChange = (_queryKey) => { }, notUpdateFields = [], }) => {
    var _a, _b, _c, _d, _f, _g, _h, _j, _k, _l, _m, _o, _p;
    const [addBtnLabel, setAddBtnLabel] = useState(null);
    const [dataDescSelect, setDataDescSelect] = useState(null);
    const [hideAddSelect, setHideAddSelect] = useState(hideAdd);
    const { menuOpen } = useMenuStore();
    const isMobile = useMediaQuery('(max-width:600px)');
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const prevSearchParams = useRef(searchParams.toString());
    const mode = (_a = searchParams.get('m')) !== null && _a !== void 0 ? _a : 'list';
    const rowsPerPage = numberOrDefault(searchParams.get('limit'), 50);
    let page = numberOrDefault(searchParams.get('page'), 0);
    const startDate = (_b = searchParams.get('start_date')) !== null && _b !== void 0 ? _b : null;
    const endDate = (_c = searchParams.get('end_date')) !== null && _c !== void 0 ? _c : null;
    const [newData, setNewData] = useState(defaultData || {});
    const [oldData, setOldData] = useState(defaultData || {});
    const [existingFieldOptions, setExistingFieldOptions] = useState({});
    const [availableFilterValues, setAvailableFilterValues] = useState({});
    const [orderBy, setOrderBy] = useState((_d = dataSpec.defaultOrderBy) !== null && _d !== void 0 ? _d : 'created_at');
    const [order, setOrder] = useState((_f = dataSpec.defaultSort) !== null && _f !== void 0 ? _f : 'desc');
    const [filterSuggested, setFilterSuggested] = useState(true);
    const [isDownloading, setIsDownloading] = useState(false);
    const [savingReport, setSavingReport] = useState(false);
    const [initialFilterValues, setInitialFilterValues] = useState();
    const [fields, setFields] = useState(Object.values(dataSpec === null || dataSpec === void 0 ? void 0 : dataSpec.fields)
        .filter((f) => f.label)
        .filter((f) => { var _a; return !((_a = f.label) === null || _a === void 0 ? void 0 : _a.includes('🔒')); })
        .filter((f) => !f.defaultTableHidden || f.type !== 'dynamic-select') // Dynamic-select can't be filtered out or hooks rendering rules will fail
        .map((f) => f.label));
    const [snapshotData, setSnapshotData] = useState({});
    const [getDataUrl, setGetDataUrl] = useState('');
    const { data: accountInfo } = API.getBasicQuery('accounts');
    const { selectedAccount } = useAccountStore();
    const { showSnackbar } = useSnackbar();
    const qcParam = searchParams.get('qc');
    const idParam = searchParams.get('id');
    const includeZeroCommissionParam = searchParams.get('incl_zero_commissions') === 'true';
    const reconciliationThreshold = (_g = accountInfo === null || accountInfo === void 0 ? void 0 : accountInfo.reconciliation_threshold) !== null && _g !== void 0 ? _g : 1;
    const poster = API.getMutation(dataSpec.table, 'POST');
    const patcher = API.getMutation(dataSpec.table, 'PATCH');
    const bulkPatcher = API.getMutation(`${dataSpec.table}/bulk_edit`, 'PATCH');
    const deleter = API.getMutation(dataSpec.table, 'DELETE');
    const posterBulkAdd = API.getMutation(`${dataSpec.table}/bulk_add`, 'POST');
    // === handle scroll filter ===
    const refFilterContainer = useRef();
    const refFilterLeftIcon = useRef();
    const refFilterRightIcon = useRef();
    const [isFilterScrollable, setIsFilterScrollable] = useState(false);
    const scrollFilter = (direction) => {
        var _a;
        const distance = 400;
        (_a = refFilterContainer.current) === null || _a === void 0 ? void 0 : _a.scrollTo({
            left: refFilterContainer.current.scrollLeft +
                (direction === 'right' ? distance : -distance),
            behavior: 'smooth',
        });
    };
    useEffect(() => {
        const elFilter = refFilterContainer.current;
        const elFilterLeftIcon = refFilterLeftIcon.current;
        const elFilterRightIcon = refFilterRightIcon.current;
        const onScroll = () => {
            if (elFilter === null ||
                elFilterLeftIcon === null ||
                elFilterRightIcon === null)
                return;
            const isScrollable = elFilter.scrollWidth > elFilter.clientWidth;
            // Reset
            elFilterLeftIcon.style.display = '';
            elFilterRightIcon.style.display = '';
            if (elFilter.scrollLeft === 0) {
                elFilterLeftIcon.style.display = 'none';
            }
            if (Math.ceil(elFilter.scrollLeft) >=
                elFilter.scrollWidth - elFilter.clientWidth) {
                elFilterRightIcon.style.display = 'none';
            }
            setIsFilterScrollable(isScrollable);
        };
        if (elFilter)
            elFilter.addEventListener('scroll', onScroll);
        const observer = new MutationObserver(() => {
            onScroll();
        });
        observer.observe(elFilter, { subtree: true, childList: true });
        return () => {
            if (elFilter) {
                elFilter.removeEventListener('scroll', onScroll);
                observer.disconnect();
            }
        };
    }, []);
    // === end handle scroll filter ===
    // TODO (frank.santillan): Fix commission_filters. Probably better for us to just move onto the next version of configuring these, so will just comment this out for now.
    // useEffect(() => {
    //   if (accountSettings?.commissions_filters?.length > 0) {
    //     accountSettings.commissions_filters.forEach((_filter) => {
    //       const filter = _filter.filter((item) => item);
    //       if (!filter) {
    //         console.warn('Invalid filter', filter);
    //         return;
    //       }
    //       const filterNameValue = filter.split('::');
    //       setSearchParams((prev) => {
    //         prev.set(filterNameValue[0], filterNameValue[1]);
    //         return prev;
    //       });
    //     });
    //   }
    // }, [accountSettings]);
    useEffect(() => {
        const currentSearchParams = new URLSearchParams(searchParams.toString());
        const oldSearchParams = new URLSearchParams(prevSearchParams.current);
        const inPreview = currentSearchParams.get('m') === DocumentPreviewKeys.PREVIEW;
        if (inPreview) {
            return;
        }
        if (currentSearchParams.get('m') !== 'edit') {
            currentSearchParams.delete('page');
            oldSearchParams.delete('page');
            if (currentSearchParams.toString() !== oldSearchParams.toString()) {
                handleChangePage('', 0);
            }
            prevSearchParams.current = currentSearchParams.toString();
        }
    }, [searchParams]);
    // Add cleanup function for form data
    useEffect(() => {
        return () => {
            // Clean up form data when component unmounts
            setNewData({});
            setOldData({});
        };
    }, []);
    const queryKey = useMemo(() => [
        selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
        dataSpec.table,
        page,
        rowsPerPage,
        orderBy,
        order,
        Array.from(searchParams.entries()).toString(),
    ], [
        selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountId,
        dataSpec.table,
        page,
        rowsPerPage,
        orderBy,
        order,
        searchParams,
    ]);
    const updateSearchParams = (kvMap) => setSearchParams((prev) => {
        Object.entries(kvMap).forEach(([k, v]) => {
            if ([undefined, null].includes(v)) {
                prev.delete(k);
            }
            else {
                prev.set(k, v);
            }
        });
        return prev;
    });
    const { isLoading, isError, data: queryData, refetch, } = useQuery({
        queryKey,
        queryFn: () => __awaiter(void 0, void 0, void 0, function* () {
            var _a, _b;
            let queryParams = `?page=${page}&limit=${rowsPerPage}&orderBy=${orderBy}&sort=${order}`;
            if (reportId) {
                queryParams += `&comp_report_id=${reportId}`;
            }
            let additionalQueryParams = `&${prevSearchParams.current}`;
            if (dataSpec.queryChips && qcParam) {
                const chipQuery = (_b = (_a = dataSpec.queryChips[qcParam]) === null || _a === void 0 ? void 0 : _a.query) !== null && _b !== void 0 ? _b : {};
                Object.entries(chipQuery).forEach(([k, v]) => {
                    if (v instanceof Array) {
                        v.forEach((e) => {
                            additionalQueryParams += `&${k}=${encodeURIComponent(e)}`;
                        });
                    }
                    else {
                        additionalQueryParams += `&${k}=${encodeURIComponent(v)}`;
                    }
                });
            }
            const url = `${process.env.REACT_APP_API}/api/${dataSpec.table}${queryParams}${additionalQueryParams}`;
            setGetDataUrl(url);
            const res = yield fetch(url, {
                method: 'GET',
                headers: yield API.getHeaders(),
            });
            const data = yield res.json();
            if (res.status === 401 && data.action === ResponseAction.LOG_OUT) {
                window.dispatchEvent(new Event('sessionExpired'));
                return;
            }
            if (data.success === false) {
                throw new Error(data.message);
            }
            return data;
        }),
        enabled: !!(auth === null || auth === void 0 ? void 0 : auth.currentUser),
    });
    useEffect(() => {
        onQueryKeyChange(queryKey);
    }, [queryKey, onQueryKeyChange]);
    useEffect(() => {
        if (isError) {
            showSnackbar('Error retrieving data, please try again later', 'error');
        }
    }, [isError, showSnackbar]);
    // UseEffect(() => {
    //   setInitialFilterValues(null);
    // }, [queryKey]);
    const data = useMemo(() => {
        if (Array.isArray(queryData)) {
            return queryData;
        }
        if (Array.isArray(queryData === null || queryData === void 0 ? void 0 : queryData.data)) {
            return queryData.data;
        }
        return [];
    }, [queryData]);
    const count = useMemo(() => { var _a; return (_a = queryData === null || queryData === void 0 ? void 0 : queryData.count) !== null && _a !== void 0 ? _a : 0; }, [queryData === null || queryData === void 0 ? void 0 : queryData.count]);
    const fieldOptions = useMemo(() => { var _a; return (_a = (filters || (queryData === null || queryData === void 0 ? void 0 : queryData.fieldOptions))) !== null && _a !== void 0 ? _a : {}; }, [filters, queryData === null || queryData === void 0 ? void 0 : queryData.fieldOptions]);
    const totals = useMemo(() => { var _a; return (_a = queryData === null || queryData === void 0 ? void 0 : queryData.totals) !== null && _a !== void 0 ? _a : {}; }, [queryData === null || queryData === void 0 ? void 0 : queryData.totals]);
    useEffect(() => {
        if (mode === 'edit' &&
            idParam &&
            Array.isArray(data) &&
            data.length === 1) {
            setNewData(data[0]);
            setOldData(JSON.parse(JSON.stringify(data[0])));
        }
    }, [data, mode, idParam]);
    const setFieldsStorage = (newFields) => {
        setFields(newFields);
        if (Array.isArray(newFields) && newFields.length > 0) {
            localStorage.setItem(`ui${location.pathname}`, newFields.join(','));
        }
    };
    useEffect(() => {
        if (isLoading || isEqual(fieldOptions, existingFieldOptions)) {
            return;
        }
        setExistingFieldOptions(fieldOptions);
        const availableVals = {};
        const filteredVals = {};
        Object.entries(fieldOptions).forEach(([k, v]) => {
            if (k.endsWith('_date_start')) {
                availableVals[k] = {
                    label: 'Start date',
                    type: 'date',
                    field: k.replace('_start', ''),
                    value: v,
                };
                filteredVals[k] = v;
            }
            else if (k.endsWith('_date_end')) {
                availableVals[k] = {
                    label: 'End date',
                    type: 'date',
                    field: k.replace('_end', ''),
                    value: v,
                };
                filteredVals[k] = v;
            }
            else if (Array.isArray(v) && v.length) {
                availableVals[k] = { label: k, type: 'multiSelect', options: v };
                filteredVals[k] = Array.isArray(v) ? v : [];
            }
            else if (k.startsWith('payment_date')) {
                // Do nothing
            }
            else {
                console.warn('Unexpected field option', k, v);
            }
        });
        setAvailableFilterValues(availableVals);
        // SetFilteredValues(filteredVals);
        // if (!initialFilterValues) {
        setInitialFilterValues(filteredVals);
        // }
    }, [dataSpec.table, existingFieldOptions, fieldOptions, isLoading]);
    /**
     * Download CSV
     */
    const downloadCsvFn = useCallback((...args_1) => __awaiter(void 0, [...args_1], void 0, function* (options = {}) {
        var _a, _b, _c;
        const idToken = yield ((_a = auth.currentUser) === null || _a === void 0 ? void 0 : _a.getIdToken(true));
        let additionalQueryParams = '';
        if (dataSpec.queryChips && qcParam) {
            const chipQuery = (_c = (_b = dataSpec.queryChips[qcParam]) === null || _b === void 0 ? void 0 : _b.query) !== null && _c !== void 0 ? _c : {};
            Object.entries(chipQuery).forEach(([k, v]) => {
                if (v instanceof Array) {
                    v.forEach((e) => {
                        additionalQueryParams += `&${k}=${encodeURIComponent(e)}`;
                    });
                }
                else {
                    additionalQueryParams += `&${k}=${encodeURIComponent(v)}`;
                }
            });
        }
        const tempQuery = {};
        Object.entries(availableFilterValues).forEach(([k, v]) => {
            if (v.type === 'multiSelect' &&
                searchParams.getAll(k).length > 0 &&
                // V.options.length !== filteredValues[k].length
                v.options.length !== searchParams.getAll(k).length) {
                tempQuery[k] = searchParams.getAll(k);
            }
            if (v.type === 'date' && searchParams.get(k)) {
                tempQuery[k] = new Date(encodeURIComponent(new Date(searchParams.get(k)).toISOString().substring(0, 10)));
            }
        });
        // Special case for ReconciliationView special casing reconciled status
        // TODO: These should be read directly from Reconciliations.js. And the reconciled statuses should be converted to enums.
        if (dataSpec.table === 'reconciliation_data') {
            tempQuery.reconciled =
                dataSpec.queryChips[qcParam !== null && qcParam !== void 0 ? qcParam : 'all'].query.reconciled;
        }
        if (Object.entries(options).length > 0) {
            Object.entries(options).forEach(([k, v]) => {
                tempQuery[k] = v;
            });
        }
        // TODO: Handle dupes
        // if (fieldOptions.payment_date_first && fieldOptions.payment_date_last) {
        //   tempQuery.payment_date_first = new Date(
        //     encodeURIComponent(
        //       new Date(fieldOptions.payment_date_first)
        //         .toISOString()
        //         .substring(0, 10)
        //     )
        //   );
        //   tempQuery.payment_date_last = new Date(
        //     encodeURIComponent(
        //       new Date(fieldOptions.payment_date_last)
        //         .toISOString()
        //         .substring(0, 10)
        //     )
        //   );
        // }
        try {
            yield exportToCsv(Object.assign({ orderBy, sort: order, q: additionalQueryParams, extraParams: searchParams }, tempQuery), { idToken, endpoint: dataSpec.table });
        }
        catch (err) {
            showSnackbar((err === null || err === void 0 ? void 0 : err.message) || 'Export failed', 'error');
        }
    }), [
        availableFilterValues,
        fieldOptions,
        orderBy,
        order,
        dataSpec.table,
        searchParams.toString(),
    ]);
    const handleDownload = (options) => __awaiter(void 0, void 0, void 0, function* () {
        setIsDownloading(true);
        yield downloadCsvFn(options);
        setIsDownloading(false);
    });
    const handleChangePage = (event, newPage) => __awaiter(void 0, void 0, void 0, function* () {
        setSearchParams((prev) => {
            if (newPage && +newPage > 0) {
                page = newPage;
                prev.set('page', newPage);
            }
            else {
                prev.delete('page');
            }
            return prev;
        });
    });
    const handleChangeRowsPerPage = (e) => {
        setSearchParams((prev) => {
            prev.delete('page');
            prev.set('limit', e.target.value);
            return prev;
        });
    };
    const dataDesc = {
        label: dataSpec.label,
        table: dataSpec.table,
        copyable: (_h = dataSpec.copyable) !== null && _h !== void 0 ? _h : false,
        editable: true,
        fields: Object.entries(dataSpec.fields)
            .filter(([k, v]) => v.enabled)
            .reduce((acc, [k, v]) => [...acc, Object.assign(Object.assign({}, v), { id: k })], []),
    };
    const deleteRows = (ids) => __awaiter(void 0, void 0, void 0, function* () {
        log.debug('Deleting ids: ', ids);
        yield deleter.mutateAsync({ ids }).catch((err) => {
            showSnackbar((err === null || err === void 0 ? void 0 : err.message) || 'Delete failed', 'error');
        });
        setTimeout(refetch, 300);
    });
    useEffect(() => {
        // Reset states initially
        setAddBtnLabel(null);
        setDataDescSelect(null);
        setHideAddSelect(hideAdd);
        const qc = searchParams.get('qc');
        const mode = searchParams.get('m');
        if (!qc)
            return;
        const chip = Object.values(dataSpec.queryChips).find((chip) => chip.id === qc);
        if (!chip)
            return;
        const updateDataDescFields = (fields) => {
            const enabledFields = Object.entries(fields)
                .filter(([_, field]) => field.enabled)
                .map(([key, field]) => (Object.assign(Object.assign({}, field), { id: key })));
            const updatedDataDesc = Object.assign(Object.assign({}, dataDesc), { fields: enabledFields });
            setDataDescSelect(updatedDataDesc);
        };
        // Handle chip configurations
        if (chip.showAddBtn)
            setHideAddSelect(!chip.showAddBtn);
        if (chip.addBtnLabel)
            setAddBtnLabel(chip.addBtnLabel);
        // Handle 'add' mode
        if (chip.addFields && mode === 'add') {
            const updatedNewData = Object.entries(chip.addFields)
                .filter(([_, field]) => field.value !== undefined)
                .reduce((acc, [key, field]) => {
                acc[key] =
                    typeof field.value === 'function' ? field.value() : field.value;
                return acc;
            }, {});
            setNewData(updatedNewData);
            updateDataDescFields(chip.addFields);
        }
        // Handle 'edit' mode
        if (chip.editFields && mode === 'edit') {
            updateDataDescFields(chip.editFields);
        }
    }, [searchParams, setDataDescSelect, dataSpec === null || dataSpec === void 0 ? void 0 : dataSpec.queryChips, setHideAddSelect]);
    useEffect(() => {
        var _a, _b, _c;
        const savedFields = (_a = localStorage
            .getItem(`ui${location.pathname}`)) === null || _a === void 0 ? void 0 : _a.split(',');
        if (Array.isArray(savedFields) && savedFields.length > 0) {
            setFields(savedFields);
        }
        else if ((_c = Object.keys((_b = dataSpec === null || dataSpec === void 0 ? void 0 : dataSpec.fields) !== null && _b !== void 0 ? _b : {})) === null || _c === void 0 ? void 0 : _c.length) {
            setFields(Object.values(dataSpec === null || dataSpec === void 0 ? void 0 : dataSpec.fields)
                .filter((f) => f.label)
                .filter((f) => { var _a; return !((_a = f.label) === null || _a === void 0 ? void 0 : _a.includes('🔒')); })
                .map((f) => f.label));
        }
    }, [dataSpec === null || dataSpec === void 0 ? void 0 : dataSpec.fields]);
    const headers = useMemo(() => {
        var _a, _b;
        const newHeaders = Object.entries(dataSpec.fields)
            .map(([k, v]) => (Object.assign(Object.assign({}, v), { id: k })))
            .filter((i) => i.enabled)
            .filter((i) => (options.mode === 'reconciler' ? i.reconciler : true))
            .filter((i) => fields.includes(i.label));
        const contacts = searchParams.getAll('contacts');
        if (contacts.length === 1) {
            const exportOption = exportOptions.find((option) => option.id === 'export-producer-view');
            if (exportOption) {
                exportOption.options.disabled = false;
            }
        }
        else {
            const exportOption = exportOptions.find((option) => option.id === 'export-producer-view');
            if (exportOption) {
                exportOption.options.disabled = true;
            }
        }
        // TODO: Find a better way of handling this
        // Figure out first and last day of payments, rather than the filters set in this view
        // queryData?.data?.forEach((row) => {
        //   if (row.payments) {
        //     const payments = Object.keys(row.payments);
        //     const firstPayment = payments[0];
        //     const lastPayment = payments[payments.length - 1];
        //     if (firstPayment) {
        //       const firstPaymentDate = dayjsUTC(firstPayment);
        //       if (
        //         !filteredValues?.effective_date_start ||
        //         firstPaymentDate.isBefore(filteredValues?.effective_date_start)
        //       ) {
        //         setFilteredValues({
        //           ...filteredValues,
        //           effective_date_start: firstPaymentDate.toDate(),
        //         });
        //       }
        //     }
        //     if (lastPayment) {
        //       const lastPaymentDate = dayjsUTC(lastPayment);
        //       if (
        //         !filteredValues?.effective_date_end ||
        //         lastPaymentDate.isAfter(filteredValues?.effective_date_end)
        //       ) {
        //         setFilteredValues({
        //           ...filteredValues,
        //           effective_date_end: lastPaymentDate.toDate(),
        //         });
        //       }
        //     }
        //   }
        // });
        // TODO: This is very specific for commissions. Generalize somehow outside of this component.
        if ((_b = (_a = dataSpec === null || dataSpec === void 0 ? void 0 : dataSpec.fields) === null || _a === void 0 ? void 0 : _a.commission_amount_monthly) === null || _b === void 0 ? void 0 : _b.enabled) {
            const startEffective = dayjs.utc(searchParams.get('effective_date_start'));
            const startPayment = dayjs.utc(fieldOptions.payment_date_first);
            const endEffective = dayjs.utc(searchParams.get('effective_date_end'));
            const endPayment = dayjs.utc(fieldOptions.payment_date_last);
            const start = startEffective.isBefore(startPayment)
                ? startEffective.startOf('month')
                : startPayment.startOf('month');
            const end = endEffective.isAfter(endPayment)
                ? endEffective.startOf('month')
                : endPayment.startOf('month');
            let maxMonths = 60; // 5 years max
            for (let i = end; i >= start; i = i.subtract(1, 'month')) {
                newHeaders.push({
                    id: 'commission_amount_monthly',
                    id2: i.format('MM/DD/YYYY'),
                    label: i.format('MMM YYYY'),
                    // Formatter: Formatter.currency,
                    numeric: true,
                    getter: (row) => {
                        var _a, _b, _c, _d, _f;
                        const month = i.format('MM/DD/YYYY');
                        let commissionReceived = (_b = (_a = row.commission_amount_monthly) === null || _a === void 0 ? void 0 : _a[month]) === null || _b === void 0 ? void 0 : _b.commission_amount_monthly;
                        let commissionExpected = (_c = row.commission_expected_monthly) === null || _c === void 0 ? void 0 : _c[month];
                        let commissionBalance = (_d = row.commission_balance_monthly) === null || _d === void 0 ? void 0 : _d[month];
                        // Aggregate child values into parent
                        // TODO: Unlink when showing child policies, will ahve to do with normal headers too
                        if (((_f = row === null || row === void 0 ? void 0 : row.children_reconciliation_data) === null || _f === void 0 ? void 0 : _f.length) > 0) {
                            let childrenCommissionReceived;
                            let childrenCommissionExpected;
                            let childrenCommissionBalance;
                            row.children_reconciliation_data.forEach((child) => {
                                var _a, _b, _c, _d, _f, _g, _h, _j;
                                if ((_b = (_a = child.commission_amount_monthly) === null || _a === void 0 ? void 0 : _a[month]) === null || _b === void 0 ? void 0 : _b.commission_amount_monthly)
                                    childrenCommissionReceived =
                                        +((_d = (_c = child.commission_amount_monthly) === null || _c === void 0 ? void 0 : _c[month]) === null || _d === void 0 ? void 0 : _d.commission_amount_monthly) +
                                            (childrenCommissionReceived !== null && childrenCommissionReceived !== void 0 ? childrenCommissionReceived : 0);
                                if ((_f = child.commission_expected_monthly) === null || _f === void 0 ? void 0 : _f[month])
                                    childrenCommissionExpected =
                                        +((_g = child.commission_expected_monthly) === null || _g === void 0 ? void 0 : _g[month]) +
                                            (childrenCommissionExpected !== null && childrenCommissionExpected !== void 0 ? childrenCommissionExpected : 0);
                                if ((_h = child.commission_balance_monthly) === null || _h === void 0 ? void 0 : _h[month])
                                    childrenCommissionBalance =
                                        ((_j = child.commission_balance_monthly) === null || _j === void 0 ? void 0 : _j[month]) +
                                            (childrenCommissionBalance !== null && childrenCommissionBalance !== void 0 ? childrenCommissionBalance : 0);
                            });
                            if (childrenCommissionReceived)
                                commissionReceived =
                                    childrenCommissionReceived + (commissionReceived !== null && commissionReceived !== void 0 ? commissionReceived : 0);
                            if (childrenCommissionExpected)
                                commissionExpected =
                                    childrenCommissionExpected + (commissionExpected !== null && commissionExpected !== void 0 ? commissionExpected : 0);
                            if (childrenCommissionBalance)
                                commissionBalance =
                                    childrenCommissionBalance + (commissionBalance !== null && commissionBalance !== void 0 ? commissionBalance : 0);
                        }
                        let result = Formatter.currency(commissionReceived);
                        let commissionDiff;
                        if (!isNill(commissionExpected) || !isNill(commissionReceived)) {
                            // const effCommissionReceived = commissionReceived ?? 0;
                            // const balance = commissionBalance;
                            commissionDiff = (_jsxs("div", { children: ["Due for this month: ", Formatter.currency(commissionExpected), commissionBalance < 0 &&
                                        Math.abs(commissionBalance) > reconciliationThreshold && (_jsxs(_Fragment, { children: [_jsx("br", {}), _jsxs("span", { children: ["Excess: ", Formatter.currency(commissionBalance)] })] }))] }));
                        }
                        if (commissionBalance < 0 &&
                            Math.abs(commissionBalance) > reconciliationThreshold &&
                            commissionReceived) {
                            result = (_jsx(Tooltip, { title: commissionDiff, children: _jsxs("div", { className: "whitespace-nowrap text-black text-right", children: [Formatter.currency(commissionReceived), "*", _jsx("br", {}), _jsxs(Typography, { variant: "caption", className: "invisible", children: ["Bal: ", Formatter.currency(commissionBalance !== null && commissionBalance !== void 0 ? commissionBalance : 0), ' '] })] }) }));
                        }
                        else if (commissionBalance > 0 &&
                            Math.abs(commissionBalance) > reconciliationThreshold) {
                            result = (_jsx(Tooltip, { title: commissionDiff, children: _jsxs("div", { className: "whitespace-nowrap text-black text-right", children: [Formatter.currency(commissionReceived !== null && commissionReceived !== void 0 ? commissionReceived : 0), _jsx("br", {}), _jsxs(Typography, { variant: "caption", className: "text-red-600", children: ["Bal: ", Formatter.currency(commissionBalance !== null && commissionBalance !== void 0 ? commissionBalance : 0), ' '] })] }) }));
                        }
                        return (_jsx("div", { className: "whitespace-nowrap text-black text-right", children: result }));
                    },
                });
                maxMonths -= 1;
                if (maxMonths <= 0)
                    break;
            }
        }
        return newHeaders;
    }, [
        dataSpec.fields,
        searchParams.toString(),
        fieldOptions === null || fieldOptions === void 0 ? void 0 : fieldOptions.payment_date_first,
        fieldOptions === null || fieldOptions === void 0 ? void 0 : fieldOptions.payment_date_last,
        options.mode,
        fields,
    ]);
    const searchSettings = [];
    // TODO: Move these filters into dataSpec
    if (['report_data', 'reconciliation_data', 'statement_data'].includes(dataSpec.table)) {
        searchSettings.unshift({
            id: 'incl_dupes',
            type: 'toggle',
            label: 'Show duplicates',
        });
        searchSettings.unshift({
            id: 'incl_linked',
            type: 'toggle',
            label: `Show linked ${dataSpec.table === 'report_data' ? 'policies' : 'commissions'}`,
        });
    }
    if (dataSpec.table === 'statement_data') {
        searchSettings.unshift({
            id: 'incl_zero_commissions',
            type: 'toggle',
            label: 'Show payouts of $0',
        });
        searchSettings.unshift({
            id: 'hide_no_payout_calc_commissions',
            type: 'toggle',
            label: 'Hide commissions without payout calculated',
        });
    }
    const dataAll = data;
    let dataFiltered = dataAll;
    const displayBasicRangeDate = (availableFilterValues) => {
        const filterDatesMap = Object.entries(availableFilterValues).filter(([, value]) => value.type === 'date');
        return filterDatesMap
            .filter(([key]) => key.includes('start'))
            .map(([startKey, startValue]) => {
            const endDateKey = startKey.replace('start', 'end');
            const endDateValue = availableFilterValues[endDateKey];
            if (!endDateValue)
                return null;
            return (_jsx(BasicDateRangePicker, { range: {
                    startDate: searchParams.get(startKey) ||
                        (initialFilterValues === null || initialFilterValues === void 0 ? void 0 : initialFilterValues[startKey]) ||
                        null,
                    startDateLabel: startValue.label,
                    toolTipStartDate: (_jsx(Tooltip, { title: ['report_data', 'reconciliation_data'].includes(dataSpec.table)
                            ? 'Effective date'
                            : dataSpec.table === 'statement_data'
                                ? 'Payment date'
                                : '', placement: "top", children: _jsx("div", {}) }, startValue.label)),
                    endDate: searchParams.get(endDateKey) ||
                        (initialFilterValues === null || initialFilterValues === void 0 ? void 0 : initialFilterValues[endDateKey]) ||
                        null,
                    endDateLabel: endDateValue.label,
                    toolTipEndDate: (_jsx(Tooltip, { title: ['report_data', 'reconciliation_data'].includes(dataSpec.table)
                            ? 'Effective date'
                            : dataSpec.table === 'statement_data'
                                ? 'Payment date'
                                : '', placement: "top", children: _jsx("div", {}) }, endDateValue.label)),
                }, onChange: ({ startDate, endDate }) => {
                    setSearchParams((prev) => {
                        if (startDate === 'Invalid Date') {
                            prev.delete(startKey);
                        }
                        else {
                            prev.set(startKey, 
                            // @ts-ignore
                            startDate
                                ? dayjs.isDayjs(startDate)
                                    ? startDate.toString()
                                    : formatSearchDateParams(startDate)
                                : null);
                        }
                        if (endDate === 'Invalid Date') {
                            prev.delete(endDateKey);
                        }
                        else {
                            prev.set(endDateKey, 
                            // @ts-ignore
                            endDate
                                ? dayjs.isDayjs(endDate)
                                    ? endDate.toString()
                                    : formatSearchDateParams(endDate)
                                : null);
                        }
                        return prev;
                    });
                } }, startKey));
        })
            .filter(Boolean);
    };
    // TODO: Reconcile prefilter vs suggested, xor for now
    if (prefilter instanceof Function) {
        dataFiltered = dataAll.filter(prefilter);
    }
    else if (suggested && filterSuggested) {
        dataFiltered = dataAll.filter((e) => suggested.includes(e.policy_id));
    }
    useEffect(() => {
        if (headers.length > 0 && dataFiltered.length > 0)
            setSnapshotData({
                headers: headers,
            });
    }, [dataFiltered, headers]);
    const getValues = (paramKey) => {
        var _a;
        const paramValues = searchParams.getAll(paramKey) || [];
        // Unselect all
        if (paramValues.length === 1 && paramValues[0] === 'undefined') {
            return [];
        }
        return paramValues.length
            ? paramValues
                .map((val) => {
                var _a;
                return (_a = availableFilterValues[paramKey]) === null || _a === void 0 ? void 0 : _a.options.find((option) => String(option.id) === val);
            })
                .filter((item) => !!item)
            : (_a = availableFilterValues[paramKey]) === null || _a === void 0 ? void 0 : _a.options;
    };
    const renderAddButtons = () => {
        if (hideAddSelect)
            return null;
        const options = [
            {
                id: 'add',
                label: addBtnLabel || 'Add',
                onClick: () => {
                    updateSearchParams({ m: 'add', id: null });
                },
            },
        ];
        if (bulkAdd) {
            options.push({
                id: 'bulkAdd',
                label: 'Bulk add',
                onClick: () => updateSearchParams({ m: 'bulkAdd' }),
            });
        }
        return (_jsx(SplitButton, { startIcon: _jsx(Add, {}), options: options, disabled: ['add', 'edit'].includes(mode), variant: "contained" }));
    };
    if (!dataSpec)
        return null;
    return (_jsxs(Box, { sx: {
            width: options.mode === 'reconciler'
                ? 'calc((100vw - 200px)/2 - 120px)'
                : `calc(100vw - ${menuOpen ? '200px' : '0px'})`,
        }, children: [options.mode === 'reconciler' ? (_jsx(Box, { children: _jsxs(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", sx: { height: 56 }, children: [_jsx(Typography, { variant: "h5", children: dataSpec.labelSimple }), _jsx(SearchBox, { id: dataSpec.table })] }) })) : (_jsxs(Box, { sx: { pt: 2 }, children: [_jsxs(Box, { display: "flex", justifyContent: "space-between", alignItems: "center", flexWrap: isMobile ? 'wrap' : 'nowrap', sx: { mb: 1, px: 2 }, children: [_jsx(Typography, { variant: "h5", children: !['tabbed'].includes(variant) && dataSpec.label }), _jsxs(Box, { sx: { display: 'flex' }, children: [idParam &&
                                        (idParam.split(',').length === 1 ? (_jsx(Chip, { label: idParam, sx: { mr: 1 }, onDelete: () => {
                                                setOldData({});
                                                setNewData({});
                                                updateSearchParams({ id: null, m: null });
                                            }, color: "primary" })) : (_jsx(Chip, { label: `${idParam.split(',')[0]} and ${idParam.split(',').length - 1} others`, sx: { mr: 1 }, onDelete: () => {
                                                updateSearchParams({ id: null, m: null });
                                            }, color: "primary" }))), _jsx(SearchBox, { id: dataSpec.table }), (searchSettings === null || searchSettings === void 0 ? void 0 : searchSettings.length) > 0 && (_jsx(SearchSettings, { settings: searchSettings }))] })] }), _jsxs(Box, { sx: {
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: isMobile ? 'column' : 'row',
                        }, children: [_jsxs(Box, { sx: {
                                    display: 'flex',
                                    flexGrow: 1,
                                    overflow: 'hidden',
                                    position: 'relative',
                                    alignItems: 'center',
                                    alignSelf: 'stretch',
                                    '&:hover': {
                                        '& .navigate-icon': {
                                            display: isFilterScrollable ? 'inline-flex' : 'none',
                                        },
                                    },
                                }, children: [_jsx(IconButton, { ref: refFilterLeftIcon, className: "navigate-icon", onClick: () => {
                                            scrollFilter('left');
                                        }, sx: {
                                            display: 'none',
                                            position: 'absolute',
                                            left: 8,
                                            zIndex: 3,
                                            background: '#e3e3e3',
                                            boxShadow: '-6px 0px 7px -3px rgba(0, 0, 0, 0.1)',
                                            '&:hover': {
                                                background: '#e3e3e3',
                                            },
                                        }, children: _jsx(ChevronLeftIcon, {}) }), _jsxs(Box, { ref: refFilterContainer, className: "hiddenScrollbar", sx: {
                                            flexGrow: 1,
                                            display: 'flex',
                                            alignItems: 'center',
                                            position: 'relative',
                                            alignSelf: 'stretch',
                                            px: 2,
                                            overflow: 'auto',
                                            '&:hover': {
                                                '& .navigate-icon': {
                                                    display: isFilterScrollable ? 'inline-flex' : 'none',
                                                },
                                            },
                                        }, children: [(dataSpec === null || dataSpec === void 0 ? void 0 : dataSpec.queryChips) &&
                                                Object.keys(dataSpec === null || dataSpec === void 0 ? void 0 : dataSpec.queryChips).length > 0 && (_jsxs(Box, { sx: {
                                                    whiteSpace: 'wrap',
                                                    display: 'flex',
                                                    width: isMobile ? '100%' : 'auto',
                                                    justifyContent: 'start',
                                                    alignItems: 'center',
                                                    mb: isMobile ? 1.5 : 0,
                                                }, children: [Object.entries((_j = dataSpec === null || dataSpec === void 0 ? void 0 : dataSpec.queryChips) !== null && _j !== void 0 ? _j : {})
                                                        .filter(([k, v]) => !v.more)
                                                        .map(([k, chip]) => (_jsx(Chip, { label: chip.label, onClick: () => {
                                                            setSearchParams((prev) => {
                                                                if (chip.id === 'all') {
                                                                    prev.delete('qc');
                                                                }
                                                                else {
                                                                    prev.set('qc', chip.id);
                                                                }
                                                                prev.delete('m');
                                                                return prev;
                                                            });
                                                        }, sx: { mr: 0.5, cursor: 'pointer' }, color: searchParams.get('qc') === chip.id ||
                                                            (!searchParams.get('qc') && chip.id === 'all')
                                                            ? 'primary'
                                                            : 'default', variant: searchParams.get('qc') === chip.id ||
                                                            (!searchParams.get('qc') && chip.id === 'all')
                                                            ? 'filled'
                                                            : 'outlined' }, chip.id))), Object.values((_k = dataSpec === null || dataSpec === void 0 ? void 0 : dataSpec.queryChips) !== null && _k !== void 0 ? _k : {}).filter((chip) => chip.more).length > 0 && (_jsx(MoreMenu, { actions: (_m = Object.values((_l = dataSpec === null || dataSpec === void 0 ? void 0 : dataSpec.queryChips) !== null && _l !== void 0 ? _l : {})
                                                            .filter((chip) => chip.more)) === null || _m === void 0 ? void 0 : _m.map((chip) => ({
                                                            label: chip.label,
                                                            onClick: () => {
                                                                setSearchParams((prev) => {
                                                                    if (chip.id === 'all') {
                                                                        prev.delete('qc');
                                                                    }
                                                                    else {
                                                                        prev.set('qc', chip.id);
                                                                    }
                                                                    return prev;
                                                                });
                                                            },
                                                        })), data: null, setActionLoading: () => { }, sx: { mr: 1 } }))] })), _jsxs(Box, { sx: {
                                                    mt: 1,
                                                    mr: 1,
                                                    mb: isMobile ? 0 : 1,
                                                    display: 'flex',
                                                    flexWrap: isMobile ? 'wrap' : 'nowrap',
                                                    alignItems: 'center',
                                                }, children: [displayBasicRangeDate(availableFilterValues), Array.isArray(dataSpec === null || dataSpec === void 0 ? void 0 : dataSpec.dateFilters) &&
                                                        (dataSpec === null || dataSpec === void 0 ? void 0 : dataSpec.dateFilters.length) > 0 &&
                                                        Object.keys(availableFilterValues).length > 0 && (_jsx(MoreDateFilters, { title: "Additional date filters", filters: (_o = dataSpec === null || dataSpec === void 0 ? void 0 : dataSpec.dateFilters) !== null && _o !== void 0 ? _o : [], values: searchParams, onSetValue: (k, e) => {
                                                            setSearchParams((prev) => {
                                                                if (e === null || e === undefined || e === '')
                                                                    prev.delete(k);
                                                                else
                                                                    prev.set(k, e);
                                                                return prev;
                                                            });
                                                        } }))] }), _jsxs(Box, { sx: {
                                                    mt: isMobile ? 1 : 0,
                                                    display: 'flex',
                                                    flexWrap: isMobile ? 'wrap' : 'nowrap',
                                                    alignItems: 'center',
                                                    flexShrink: 0,
                                                    gap: 1,
                                                }, children: [Object.entries(availableFilterValues)
                                                        .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
                                                        .filter(([k, v]) => v.type === 'multiSelect')
                                                        .map(([k, v]) => {
                                                        var _a, _b, _c, _d, _f, _g, _h, _j, _k, _l;
                                                        const selectedValue = getValues(k);
                                                        const selectedValue2 = searchParams.getAll(k).length
                                                            ? searchParams.getAll(k)
                                                            : (_a = availableFilterValues[k]) === null || _a === void 0 ? void 0 : _a.options;
                                                        // For handling objects as options the option must have an 'id' and a 'name' property
                                                        return ((_b = availableFilterValues[k]) === null || _b === void 0 ? void 0 : _b.options.every((option) => typeof option === 'object')) ? (_jsx(EnhancedSelect, { enableActiveColor: selectedValue.length > 0 &&
                                                                selectedValue.length <
                                                                    ((_c = availableFilterValues[k]) === null || _c === void 0 ? void 0 : _c.options.length), sx: { minWidth: 130, width: 'fit-content' }, label: ((_d = dataSpec.filters[k]) === null || _d === void 0 ? void 0 : _d.label) || k, enableSearch: true, multiple: true, options: (_f = availableFilterValues[k]) === null || _f === void 0 ? void 0 : _f.options, value: selectedValue, listContainerSx: (_g = dataSpec.filters[k]) === null || _g === void 0 ? void 0 : _g.listContainerSx, onChange: (values) => {
                                                                var _a;
                                                                const ids = values.map((item) => item.id);
                                                                const options = (_a = availableFilterValues[k]) === null || _a === void 0 ? void 0 : _a.options;
                                                                // Select all
                                                                if (!values.length) {
                                                                    setSearchParams((prev) => {
                                                                        prev.set(k, 'undefined');
                                                                        return prev;
                                                                    });
                                                                }
                                                                else {
                                                                    setSearchParams((prev) => {
                                                                        prev.delete(k);
                                                                        // If select all values, we remove params on the url instead
                                                                        if (ids.length !== options.length) {
                                                                            ids.forEach((v) => {
                                                                                prev.append(k, v);
                                                                            });
                                                                        }
                                                                        return prev;
                                                                    });
                                                                }
                                                            } }, k)) : (_jsx(EnhancedSelect, { sx: { minWidth: 130, width: 'fit-content' }, label: ((_h = dataSpec.filters[k]) === null || _h === void 0 ? void 0 : _h.label) || k, enableSearch: true, multiple: true, enableActiveColor: selectedValue2.length > 0 &&
                                                                selectedValue2.length <
                                                                    ((_j = availableFilterValues[k]) === null || _j === void 0 ? void 0 : _j.options.length), options: (_k = availableFilterValues[k]) === null || _k === void 0 ? void 0 : _k.options, listContainerSx: (_l = dataSpec.filters[k]) === null || _l === void 0 ? void 0 : _l.listContainerSx, value: selectedValue2, onChange: (values) => {
                                                                setSearchParams((prev) => {
                                                                    prev.delete(k);
                                                                    // If select all values, we remove params on the url instead
                                                                    if (values.length !== options.length) {
                                                                        values.forEach((v) => {
                                                                            prev.append(k, v);
                                                                        });
                                                                    }
                                                                    return prev;
                                                                });
                                                            } }, k));
                                                    }), Object.keys(availableFilterValues).length > 0 &&
                                                        enableResetFilters &&
                                                        searchParams.size > 0 && (_jsx(Box, { sx: { display: 'flex', alignItems: 'center' }, children: _jsx(Tooltip, { title: "Clear filters", children: _jsx(IconButton, { onClick: () => {
                                                                    setSearchParams({});
                                                                }, disabled: ['add', 'edit'].includes(mode), sx: { mr: 1 }, children: _jsx(FilterAltOffOutlined, {}) }) }) }))] })] }), _jsx(IconButton, { className: "navigate-icon", onClick: () => {
                                            scrollFilter('right');
                                        }, ref: refFilterRightIcon, sx: {
                                            display: 'none',
                                            position: 'absolute',
                                            right: 8,
                                            zIndex: 3,
                                            background: '#e3e3e3',
                                            boxShadow: '-6px 0px 7px -3px rgba(0, 0, 0, 0.1)',
                                            '&:hover': {
                                                background: '#e3e3e3',
                                            },
                                        }, children: _jsx(ChevronRightIcon, {}) })] }), _jsxs(Box, { sx: {
                                    boxShadow: isFilterScrollable
                                        ? '-6px 4px 7px -3px rgba(0, 0, 0, 0.1)'
                                        : 'none',
                                    pr: 1,
                                    py: 1,
                                    display: 'flex',
                                    flexShrink: 0,
                                    flexWrap: isMobile ? 'wrap' : 'nowrap',
                                    gap: 1,
                                    alignItems: 'center',
                                    background: 'white',
                                    zIndex: 2,
                                }, children: [_jsx(EnhancedSelect, { label: "Fields", multiple: true, sortLabel: false, options: Object.values(dataSpec === null || dataSpec === void 0 ? void 0 : dataSpec.fields)
                                            .filter((f) => f.label)
                                            .map((f) => f.label), value: fields, onChange: (values) => setFieldsStorage(values), sx: { mx: 0.5, maxWidth: 140 }, popoverProps: {
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'right',
                                            },
                                            transformOrigin: {
                                                vertical: 'top',
                                                horizontal: 'right',
                                            },
                                        }, renderField: ({ onClick }) => {
                                            return (_jsx(Tooltip, { title: "Show/hide fields", children: _jsx(IconButton, { color: "primary", onClick: onClick, children: _jsx(ViewWeekOutlinedIcon, {}) }) }));
                                        } }), extraActions.map((action) => (_jsxs(Box, { children: [action.type === 'button' && (_jsx(Button, { onClick: action.onClick, sx: { mr: 1 }, startIcon: action.icon, variant: action.variant || 'outlined', disabled: action.disabled || false, disableElevation: true, children: action.label }, action.label)), action.type === 'select' && (_jsx(Select, { value: action.value, onChange: action.onChange, sx: { mr: 1 }, children: action.options.map((option) => (_jsx(MenuItem, { value: option.value, children: option.label }, option.value))) }, action.label)), action.type === 'date' && (_jsx(BasicDatePicker, { label: action.label, value: action.value, setValue: action.onChange, sx: { mr: 1 } }, action.label)), action.type === 'dateRange' && (_jsx(BasicDateRangePicker, { range: {
                                                    startDate,
                                                    endDate,
                                                }, onChange: (range) => {
                                                    setSearchParams((prev) => {
                                                        const { startDate, endDate } = range;
                                                        if (startDate) {
                                                            prev.set('start_date', dayjs.isDayjs(startDate)
                                                                ? startDate.toString()
                                                                : formatSearchDateParams(startDate));
                                                        }
                                                        else {
                                                            prev.delete('start_date');
                                                        }
                                                        if (endDate) {
                                                            prev.set('end_date', dayjs.isDayjs(endDate)
                                                                ? endDate.toString()
                                                                : formatSearchDateParams(endDate));
                                                        }
                                                        else {
                                                            prev.delete('end_date');
                                                        }
                                                        return prev;
                                                    });
                                                } }, action.label)), action.type === 'multiSelect' && (_jsx(MultiSelect, { label: action.label, values: action.options, selectedValues: action.value, setSelectedValues: action.onChange, sx: { mr: 1 } }, action.label)), action.element && action.element] }, action.label))), enableSaves && (_jsx(SaveReport, { reportId: reportId, queryData: snapshotData, getDataUrl: getDataUrl, table: dataSpec.table, setSavingReport: setSavingReport })), renderAddButtons(), !hideExport && (_jsx(SplitButton, { startIcon: _jsx(Download, {}), useLoadingBtn: true, loading: isDownloading, options: exportOptions.length > 0
                                            ? exportOptions.map((e) => (Object.assign(Object.assign({}, e), { onClick: () => handleDownload(e.options) })))
                                            : [
                                                {
                                                    id: 'export',
                                                    label: 'Export',
                                                    onClick: handleDownload,
                                                },
                                            ] }))] })] })] })), (suggested === null || suggested === void 0 ? void 0 : suggested.length) > 0 && (_jsx(Box, { sx: { textAlign: 'center' }, children: filterSuggested ? (_jsxs(Typography, { variant: "body2", children: ["\u2728 Showing suggested matches (", _jsx(Link, { onClick: () => setFilterSuggested(false), sx: { cursor: 'pointer' }, children: "see all" }), ")"] })) : (_jsxs(Typography, { variant: "body2", children: ["Showing all matches (", _jsx(Link, { onClick: () => setFilterSuggested(true), sx: { cursor: 'pointer' }, children: "show suggested" }), ")"] })) })), !isLoading && mode === 'bulkAdd' && (_jsx(Box, { sx: {
                    overflowY: 'scroll',
                    height: 'calc(100vh - 168px)',
                    display: 'flex',
                    justifyContent: 'center',
                    p: 2,
                }, children: _jsx(DataBulkAdd, { fields: [
                        ...(dataDescSelect || dataDesc).fields
                            .flat()
                            .filter((field) => [
                            undefined,
                            'select',
                            'dynamic-select',
                            'boolean',
                            'date',
                        ].includes(field.type) &&
                            field.access !== 'admin' &&
                            !field.readOnly &&
                            !field.bulkAddUnsupported),
                        ...dataSpec.bulkAddFields,
                    ], onCancel: () => {
                        updateSearchParams({ m: null });
                    }, onSave: (jsonEntities) => __awaiter(void 0, void 0, void 0, function* () {
                        const res = yield posterBulkAdd.mutateAsync(jsonEntities);
                        if (res.total) {
                            showSnackbar(`Added ${res.total} records`, 'success');
                            updateSearchParams({ m: null });
                            setTimeout(refetch, 200);
                        }
                        else {
                            showSnackbar('Error bulk adding data', 'error');
                        }
                    }) }) })), !isLoading && ['add', 'edit'].includes(mode) && (_jsx(Box, { sx: {
                    overflowY: 'scroll',
                    height: 'calc(100vh - 168px)',
                    display: 'flex',
                    justifyContent: 'center',
                    p: 2,
                }, children: _jsx(DataForm, { dataDesc: dataDescSelect || dataDesc, fields: Object.values((dataDescSelect || dataDesc).fields).filter((field) => (field.condition ? field.condition(newData) : true)), newData: newData, readOnly: readOnly, oldData: oldData, setNewData: setNewData, onCancel: () => {
                        setNewData({});
                        updateSearchParams({ m: null, id: null });
                    }, onSave: () => __awaiter(void 0, void 0, void 0, function* () {
                        // In general, don't send read-only fields. But some are necessary for identifying records to update.
                        // For general forms, id and str_id, and for Views and fields and Agent settings, key, role is needed.
                        const normalizedNewData = Object.fromEntries(Object.entries(newData)
                            .filter(([key]) => {
                            var _a, _b, _c;
                            return ['id', 'str_id', 'role', 'key'].includes(key) ||
                                (typeof ((_a = dataDesc.fields.find((field) => field.id === key)) === null || _a === void 0 ? void 0 : _a.readOnly) === 'function'
                                    ? !((_b = dataDesc.fields
                                        .find((field) => field.id === key)) === null || _b === void 0 ? void 0 : _b.readOnly(newData))
                                    : !((_c = dataDesc.fields.find((field) => field.id === key)) === null || _c === void 0 ? void 0 : _c.readOnly));
                        })
                            .map(([key, value]) => {
                            var _a;
                            return [
                                key,
                                ((_a = dataDesc.fields.find((field) => field.id === key)) === null || _a === void 0 ? void 0 : _a.normalizer)
                                    ? dataDesc.fields
                                        .find((field) => field.id === key)
                                        .normalizer(value, [])
                                    : value,
                            ];
                        }));
                        if (includeZeroCommissionParam)
                            normalizedNewData.incl_zero_commissions =
                                includeZeroCommissionParam;
                        let res;
                        if (notUpdateFields.length > 0) {
                            notUpdateFields.forEach((field) => {
                                delete normalizedNewData[field];
                            });
                        }
                        try {
                            if (normalizedNewData.id) {
                                if (reportId)
                                    normalizedNewData.comp_report_id = reportId;
                                res = yield patcher.mutateAsync(normalizedNewData);
                            }
                            else {
                                res = yield poster.mutateAsync(normalizedNewData);
                            }
                            setNewData({});
                            updateSearchParams({ m: null, id: null });
                        }
                        catch (e) {
                            console.error('Error encountered while saving data');
                            return {
                                error: e.message || 'Error encountered while saving data',
                            };
                        }
                        finally {
                            setTimeout(refetch, 300);
                        }
                        return res;
                    }), onDelete: () => __awaiter(void 0, void 0, void 0, function* () {
                        yield deleter.mutate({ ids: [newData.id] });
                        setNewData({});
                        updateSearchParams({ m: null, id: null });
                        setTimeout(refetch, 300);
                    }), validateData: dataDesc.validateData, extraActions: extraFormActions, currentData: newData }) })), _jsx(Divider, {}), (isLoading || savingReport) && (_jsx(Box, { sx: { textAlign: 'center', mt: 6 }, children: _jsx(LoadingCircle, {}) })), !isLoading &&
                !['edit', 'bulkAdd', 'add'].includes(mode) &&
                dataFiltered.length > 0 &&
                headers.length > 0 && (_jsx(EnhancedTable, { dense: true, headers: headers, stickyColumns: dataSpec.stickyColumns, rows: dataFiltered, readOnly: readOnly, actions: actions, outstandingFieldsInMobileView: (outstandingMobileFields === null || outstandingMobileFields === void 0 ? void 0 : outstandingMobileFields.length) > 0
                    ? outstandingMobileFields
                    : ((_p = dataSpec.outstandingFieldsInMobileView) !== null && _p !== void 0 ? _p : []), actionsEnabled: actionsEnabled, customHeaderActions: customHeaderActions, onDelete: enableMultiSelect ? deleteRows : false, nonSelectableOnMobile: nonSelectableOnMobile, rowKey: rowKey, onBulkSync: onBulkSync, bulkActions: bulkActions, onEdit: enableEdit
                    ? options.mode !== 'reconciler'
                        ? (row) => {
                            updateSearchParams({
                                m: 'edit',
                                id: row.str_id,
                            });
                            setNewData(row);
                            setOldData(JSON.parse(JSON.stringify(row)));
                        }
                        : undefined
                    : false, onBulkEdit: (selected, updateData) => __awaiter(void 0, void 0, void 0, function* () {
                    bulkPatcher.mutateAsync(Object.assign({ ids: selected }, updateData));
                    setTimeout(refetch, 300);
                }), 
                // TODO: Should be controlled selection...hack for now
                setSelectedData: setSelectedData, stickyHeader: true, paginated: true, controlledOrdering: {
                    order,
                    orderBy,
                    setOrder: (e) => {
                        handleChangePage(e, 0);
                        setOrder(e);
                    },
                    setOrderBy: (e) => {
                        handleChangePage(e, 0);
                        setOrderBy(e);
                    },
                }, controlledPagination: {
                    count, // +(filterVals.showDupes ? dataCounts.countDupes : 0),
                    page,
                    onPageChange: handleChangePage,
                    rowsPerPage,
                    onRowsPerPageChange: handleChangeRowsPerPage,
                }, options: { hideSelectedCount, radio: options.radio }, showTotals: showTotals, totals: totals, refetch: refetch })), !isLoading && dataFiltered.length === 0 && (_jsx(Box, { sx: { textAlign: 'center', mt: 6, width: '100%' }, children: isError ? (_jsxs(Box, { children: [_jsx(Typography, { variant: "h5", children: "Error retrieving data, please try again later" }), _jsx(Button, { variant: "outlined", onClick: (_e) => refetch(), sx: { mt: 5 }, children: "Retry" })] })) : (_jsx(Typography, { variant: "h5", children: "No results" })) }))] }));
};
export default EnhancedDataView;
