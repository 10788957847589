var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { AdminPanelSettingsOutlined, ArrowForwardIosSharp, CloudUploadOutlined, } from '@mui/icons-material';
import { Box, Button, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, Toolbar, Tooltip, useMediaQuery, } from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import { styled, useTheme } from '@mui/material/styles';
import { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import BaseModal from '@/components/UploadModal';
import { UIStateContext } from '@/contexts/UIStateProvider';
import API from '@/services/API';
import { hasAccess } from '@/services/helpers';
import { useAccountStore, useMenuStore, useRoleStore } from '@/store';
import { Roles } from '@/types';
import { getRouteConfig } from '@/components/AppDrawer/routeConfig';
import useSnackbar from '@/contexts/useSnackbar';
import { LoginAsUser } from './LoginAsUser';
import { useUserInfo } from '@/hooks/useUserInfo';
const FintaryAdminOnlyIcon = ({ access }) => {
    return Array.isArray(access) &&
        (access === null || access === void 0 ? void 0 : access.length) === 1 &&
        (access === null || access === void 0 ? void 0 : access.includes(Roles.FINTARY_ADMIN)) ? (_jsx("span", { style: {
            color: 'transparent',
            textShadow: '0 0 0 #e8e8e8',
        }, children: "\uD83D\uDD12" })) : null;
};
const AppDrawer = () => {
    var _a, _b;
    const navigate = useNavigate();
    const location = useLocation();
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [menuItems, setMenuItems] = useState([[]]);
    const { menuOpen, setMenuOpen } = useMenuStore();
    const isDesktop = useMediaQuery('(min-width:600px)');
    const drawerWidth = 200;
    const theme = useTheme();
    const { userRole } = useRoleStore();
    const { selectedAccount } = useAccountStore();
    const { showSnackbar } = useSnackbar();
    const mode = selectedAccount === null || selectedAccount === void 0 ? void 0 : selectedAccount.accountMode;
    const { data: accountSettings, isFetched: accountSettingsReady } = API.getBasicQuery(`accounts/settings`);
    const classificationPoster = API.getMutation('documents/document-classification', 'POST');
    const { data: { fintaryAdmin } = {} } = useUserInfo();
    const { role: [role, setRole], appDrawerExpanded: [expanded, setExpanded], } = useContext(UIStateContext);
    const documentViewSettings = (_a = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _a === void 0 ? void 0 : _a.add_documents;
    const showAddDocument = (_b = documentViewSettings === null || documentViewSettings === void 0 ? void 0 : documentViewSettings.show_page) !== null && _b !== void 0 ? _b : (userRole &&
        [Roles.ACCOUNT_ADMIN, Roles.DATA_SPECIALIST].includes(userRole));
    useEffect(() => {
        const routeConfig = getRouteConfig(mode, selectedAccount, accountSettings);
        setMenuItems(routeConfig);
    }, [mode, role, userRole, accountSettings, selectedAccount]);
    useEffect(() => {
        setMenuOpen(isDesktop);
    }, [isDesktop, setMenuOpen]);
    useEffect(() => {
        if (['demo@fintary.com'].includes((fintaryAdmin === null || fintaryAdmin === void 0 ? void 0 : fintaryAdmin.email) || ''))
            setRole('demo');
        else if (fintaryAdmin)
            setRole('admin');
    }, [fintaryAdmin, setRole]);
    const handleClose = (result) => {
        setShowUploadModal(false);
        if (result) {
            showSnackbar(result);
        }
    };
    const Accordion = styled((props) => (_jsx(MuiAccordion, Object.assign({ disableGutters: true, elevation: 0, square: true }, props))))(({ theme: t }) => ({
        border: 0,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    }));
    const AccordionSummary = styled((props) => (_jsx(MuiAccordionSummary, Object.assign({ expandIcon: isDesktop ? _jsx(ArrowForwardIosSharp, { sx: { fontSize: '0.9rem' } }) : null, sx: {
            pl: 0.5,
            borderRadius: '0 24px 24px 0',
            '&:hover': { backgroundColor: 'whitesmoke' },
        } }, props))))(({ theme: t }) => ({
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginTop: 0,
            marginBottom: 0,
        },
    }));
    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(0),
        borderTop: 0,
    }));
    const filteredMenuItems = menuItems
        .filter((section) => hasAccess(section.access, userRole, role === 'admin'))
        .map((section) => section
        .filter((item) => hasAccess(item === null || item === void 0 ? void 0 : item.access, userRole, role === 'admin'))
        .map((item) => (Object.assign(Object.assign({}, item), { target: Array.isArray(item.target)
            ? item.target.filter((t) => hasAccess(t === null || t === void 0 ? void 0 : t.access, userRole, role === 'admin'))
            : item.target }))))
        .filter((section) => section.length > 0);
    const initializeCompanyClassifier = () => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield classificationPoster.mutateAsync({
                file: {
                    name: 'init.txt',
                    type: 'txt',
                    content: '',
                },
            });
        }
        catch (error) {
            console.error('Classifier initialized failed', error);
        }
    });
    const handleUploadClick = () => __awaiter(void 0, void 0, void 0, function* () {
        setShowUploadModal(true);
        initializeCompanyClassifier();
    });
    return (_jsxs(_Fragment, { children: [_jsxs(Drawer, { variant: isDesktop ? 'persistent' : 'temporary', open: menuOpen, sx: { width: menuOpen ? drawerWidth : 0 }, onClose: isDesktop ? undefined : () => setMenuOpen(false), children: [_jsx(Toolbar, {}), _jsx(Box, { sx: {
                            flexGrow: 1,
                            width: drawerWidth,
                            overflow: 'auto',
                            overflowX: 'hidden',
                        }, children: accountSettingsReady &&
                            filteredMenuItems.map((section, i) => (_jsxs("div", { children: [_jsx(Divider, {}), _jsx(List, { children: section.map(({ label, icon, target, access, endIcon, disabled, tooltip, }) => Array.isArray(target) ? (_jsxs(Accordion, { expanded: expanded[label], children: [_jsx(AccordionSummary, { onClick: () => {
                                                        if (!expanded[label]) {
                                                            navigate(target[0].target);
                                                        }
                                                        setExpanded(Object.assign(Object.assign({}, expanded), { [label]: !expanded[label] }));
                                                    }, sx: { pr: 1, minHeight: 40 }, children: _jsxs(ListItem, { button: true, disabled: disabled, sx: {
                                                            pl: 1,
                                                            pr: 0,
                                                            py: 0.5,
                                                            '&:hover': { backgroundColor: 'unset' },
                                                        }, children: [_jsx(ListItemIcon, { style: {
                                                                    minWidth: 40,
                                                                    color: 'black',
                                                                }, children: icon }), _jsx(ListItemText, { primary: label }), _jsx(FintaryAdminOnlyIcon, { access: access })] }) }), _jsx(AccordionDetails, { children: target.map((t) => {
                                                        var _a;
                                                        return (_jsx(Tooltip, { title: (_a = t.tooltip) !== null && _a !== void 0 ? _a : '', placement: "right", children: _jsxs(ListItem, { onClick: () => {
                                                                    if (!isDesktop)
                                                                        setMenuOpen(false);
                                                                }, component: Link, to: t.target, sx: Object.assign(Object.assign({ pl: 2.5, py: 0.25, opacity: 0.8 }, (location.pathname ===
                                                                    t.target.split('?')[0]
                                                                    ? {
                                                                        backgroundColor: '#2096f322',
                                                                        borderRadius: '0 24px 24px 0',
                                                                    }
                                                                    : {})), { color: 'unset' }), disabled: t.disabled, children: [_jsx(ListItemIcon, { style: {
                                                                            color: location.pathname ===
                                                                                t.target.split('?')[0]
                                                                                ? theme.palette.primary.main
                                                                                : 'unset',
                                                                            minWidth: 40,
                                                                        }, children: t.icon }), _jsx(ListItemText, { primary: t.label, primaryTypographyProps: location.pathname === t.target
                                                                            ? {
                                                                                color: theme.palette.primary.main,
                                                                                fontWeight: 600,
                                                                                fontSize: 15,
                                                                            }
                                                                            : { fontSize: 15 } }), _jsx(FintaryAdminOnlyIcon, { access: t.access })] }, t.label) }, t.label));
                                                    }) })] }, label)) : (_jsx(Tooltip, { title: tooltip !== null && tooltip !== void 0 ? tooltip : '', placement: "right", enterDelay: 1000, children: _jsxs(ListItem, { onClick: () => {
                                                    if (!isDesktop)
                                                        setMenuOpen(false);
                                                }, component: Link, to: target, sx: location.pathname === target
                                                    ? {
                                                        color: 'unset',
                                                        backgroundColor: '#2096f322',
                                                        borderRadius: '0 24px 24px 0',
                                                        px: 1.5,
                                                        py: 0.5,
                                                    }
                                                    : {
                                                        color: 'unset',
                                                        px: 1.5,
                                                        py: 0.5,
                                                        '&:hover': {
                                                            borderRadius: '0 24px 24px 0',
                                                        },
                                                    }, secondaryAction: access === 'admin' && (_jsx(IconButton, { edge: "end", disabled: true, sx: { opacity: 0.5, mr: -2 }, children: endIcon !== null && endIcon !== void 0 ? endIcon : _jsx(AdminPanelSettingsOutlined, {}) })), disabled: disabled, children: [_jsx(ListItemIcon, { style: {
                                                            color: location.pathname === target
                                                                ? theme.palette.primary.main
                                                                : 'unset',
                                                            minWidth: 40,
                                                        }, children: icon }), _jsx(ListItemText, { primary: label, primaryTypographyProps: location.pathname === target
                                                            ? {
                                                                color: theme.palette.primary.main,
                                                                fontWeight: 600,
                                                            }
                                                            : {}, sx: location.pathname === target
                                                            ? {
                                                                color: 'white',
                                                                fontWeight: 600,
                                                                '&:hover': {
                                                                    color: 'black',
                                                                },
                                                            }
                                                            : {} }), _jsx(FintaryAdminOnlyIcon, { access: access })] }, target) }, target))) })] }, i))) }), _jsxs(Box, { sx: {
                            width: drawerWidth,
                            zIndex: 1,
                        }, children: [_jsx(LoginAsUser, { showAddDocument: showAddDocument }), showAddDocument && (_jsx(Box, { sx: {
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }, children: _jsx(Tooltip, { title: "You can also email statements to documents@fintary.com for processing", children: _jsx(Button, { variant: "contained", startIcon: _jsx(CloudUploadOutlined, {}), onClick: handleUploadClick, sx: {
                                            width: 180,
                                            mt: 1,
                                            mb: 1.5,
                                            boxShadow: 0,
                                            backgroundColor: '#e5f1fd',
                                            color: '#2196f3',
                                            fontWeight: 'bold',
                                            '&:hover': {
                                                backgroundColor: '#e5f1fd',
                                                color: '#2196f3',
                                                boxShadow: 0,
                                            },
                                        }, children: "Upload data" }) }) }))] })] }), _jsx(BaseModal, { open: showUploadModal, handleClose: handleClose })] }));
};
export default AppDrawer;
