const findTextLineWithContext = (json, str, range = [0, 0], exact = false) => {
    var _a;
    const txt = [];
    (_a = json.Lines) === null || _a === void 0 ? void 0 : _a.some((lineItem) => {
        const matchIdx = lineItem.LinesArray.findIndex((line) => exact ? line.Line === str : line.Line.includes(str));
        if (matchIdx > -1) {
            const startIdx = Math.max(0, matchIdx + range[0]);
            const endIdx = Math.min(matchIdx + range[1], lineItem.LinesArray.length - 1);
            for (let i = startIdx; i <= endIdx; i++) {
                txt.push(lineItem.LinesArray[i].Line);
            }
        }
        return matchIdx > -1;
    });
    return txt;
};
export const desc_findTextLineWithContext = {
    name: 'libs.tools.findTextLineWithContext(json, str, range, exact)',
    description: 'Finds the first line containing specified text and returns it with surrounding context lines.',
    arguments: {
        json: '[Object] JSON object containing Lines array with text content',
        str: '[String] Text string to search for',
        range: '[Array<number>] Optional. Two-element array specifying lines before and after [-n, n]',
        exact: '[Boolean] Optional. If true, performs exact match instead of partial match',
    },
    returns: '[Array<string>] Array of text lines including context',
    examples: 'findTextLineWithContext(json, "text", [-1, 1])\n// → ["before", "text", "after"]\n\nfindTextLineWithContext(json, "text", [-1, 1], true)\n// → ["before", "text", "after"]\n',
};
export default findTextLineWithContext;
