import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Typography } from '@mui/material';
import ReactECharts from 'echarts-for-react';
const ChartWidget = ({ data }) => {
    try {
        return (_jsx(ReactECharts, { option: data, style: { height: '100%', minHeight: '250px' } }, JSON.stringify(data)));
    }
    catch (error) {
        console.error('Error rendering ChartWidget:', error);
        return (_jsx(Box, { children: _jsx(Typography, { variant: "h5", children: "Error rendering ChartWidget" }) }));
    }
};
export default ChartWidget;
