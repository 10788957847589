import { jsx as _jsx } from "react/jsx-runtime";
import * as math from 'mathjs';
import DataView from '@/components//DataView';
import Formatter from '@/services/Formatter';
import DataTransformation from '@/services/DataTransformation';
import API from '@/services/API';
const Normalizer = DataTransformation;
const dataDesc = {
    label: 'Commission receivable schedules',
    table: 'schedules/carriers',
    editable: true,
    copyable: true,
    fields: [
        { id: 'name', label: 'Name' },
        // [
        {
            id: 'carrier_company_id',
            label: 'Carrier',
            type: 'dynamic-select',
            table: 'companies',
            field: 'id',
            formatter: (val, collectionVals = []) => {
                var _a, _b;
                if (val && Array.isArray(collectionVals) && collectionVals.length > 0) {
                    return (((_b = (_a = collectionVals === null || collectionVals === void 0 ? void 0 : collectionVals.filter((company) => company.id === val)) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.company_name) || 'Not found');
                }
                return val; // Not formatting when collectionVals is not available
            },
            optionFormatter: (option) => option.company_name,
            optionValuer: (option) => option.id,
        },
        // {
        //   id: 'paying_entity_company_id',
        //   label: 'Paying entity',
        //   type: 'dynamic-select',
        //   table: 'companies',
        //   field: 'id',
        //   nullable: true,
        //   formatter: (val, collectionVals = []) => {
        //     if (
        //       val &&
        //       Array.isArray(collectionVals) &&
        //       collectionVals.length > 0
        //     ) {
        //       return (
        //         collectionVals?.filter((company) => company.id === val)?.[0]
        //           ?.company_name || 'Not found'
        //       );
        //     }
        //     return val; // not formatting when collectionVals is not available
        //   },
        //   optionFormatter: (option) => option.company_name,
        //   optionValuer: (option) => option.id,
        // },
        // ],
        [
            { id: 'product_type', label: 'Product type' },
            { id: 'product_name', label: 'Product name' },
        ],
        [
            {
                id: 'issue_age_start',
                label: 'Issue age min',
                normalizer: Normalizer.normalizeInt,
            },
            {
                id: 'issue_age_end',
                label: 'Issue age max',
                normalizer: Normalizer.normalizeInt,
            },
            {
                id: 'premium_min',
                label: 'Premium min',
                normalizer: Normalizer.normalizeCurrency,
                formatter: Formatter.currency,
            },
            {
                id: 'premium_max',
                label: 'Premium max',
                normalizer: Normalizer.normalizeCurrency,
                formatter: Formatter.currency,
            },
        ],
        { id: 'agent_grid_level', label: 'Agent grid level' },
        [
            {
                startDateId: 'start_date',
                startDateLabel: 'Start date',
                endDateId: 'end_date',
                endDateLabel: 'End date',
                type: 'date_range',
                formatter: Formatter.date,
            },
        ],
        {
            id: 'commission_levels_by_year_heading',
            type: 'heading',
            label: 'Commission levels by year',
        },
        {
            id: 'commission_schedule',
            label: 'Commission rate schedule',
            type: 'rate-schedule-annual',
            formatter: (val) => {
                var _a, _b;
                return `Year ${val.year}: ${val.rate}% (${(_b = (_a = val === null || val === void 0 ? void 0 : val.breakdown) === null || _a === void 0 ? void 0 : _a.join(', ')) !== null && _b !== void 0 ? _b : '-'})${(val === null || val === void 0 ? void 0 : val.max_commission) ? `, Max: ${val === null || val === void 0 ? void 0 : val.max_commission}` : ''}`;
            },
            validator: (val) => {
                return (Array.isArray(val) &&
                    val.every((item) => {
                        var _a;
                        return Number.isInteger(Number.parseInt(item.year, 10)) &&
                            !Number.isNaN(Number.parseFloat(item.rate)) &&
                            (+item.rate === 0 ||
                                (+item.rate > 0 &&
                                    Array.isArray(item.breakdown) &&
                                    item.breakdown.length > 0 &&
                                    ((_a = item.breakdown) === null || _a === void 0 ? void 0 : _a.every((breakdownItem) => {
                                        try {
                                            math.fraction(breakdownItem); // Try to convert to fraction
                                            return true;
                                        }
                                        catch (_a) {
                                            return false;
                                        }
                                    })) &&
                                    math.number(math.fraction(item.breakdown.reduce((acc, cur) => math.add(math.fraction(acc), math.fraction(cur)), '0'))) === 100));
                    } // All values in this array must be floats or fractions that add up to 1
                    ));
            },
            normalizer: (val) => {
                return val.map((year) => (Object.assign(Object.assign({}, year), { breakdown: year.breakdown.map((item) => item.toString()) })));
            },
        },
        {
            id: 'delay',
            label: 'Delay',
            tip: 'Number of months between policy effective date and first expected commission payment',
            type: 'number',
            validator: (val) => val === null ||
                val === '' ||
                (Number.isInteger(Number.parseInt(val, 10)) && +val >= 0 && +val <= 12),
            normalizer: (val) => (val === '' ? null : +val),
        },
        { id: 'notes', label: 'Notes' },
        { id: 'divider', type: 'divider' },
        {
            id: 'created_at',
            label: 'Created',
            formatter: (val) => new Date(val).toLocaleString(),
            readOnly: true,
        },
    ],
};
const CommissionSchedulesPage = () => {
    var _a, _b;
    const { data: accountSettings } = API.getBasicQuery(`accounts/settings`);
    const viewSettings = (_a = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _a === void 0 ? void 0 : _a.carriers_schedules;
    const viewOnly = (_b = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.read_only) !== null && _b !== void 0 ? _b : false;
    if (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label) {
        dataDesc.label = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label;
    }
    return _jsx(DataView, { dataDesc: dataDesc, hideExport: true, viewOnly: viewOnly });
};
export default CommissionSchedulesPage;
