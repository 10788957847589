import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useState } from 'react';
import { TextField, Button, Grid, Chip, IconButton, Typography, } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import CloseIcon from '@mui/icons-material/Close';
const FieldsCalculatorExpressionBuilder = ({ fields, value, setValue }) => {
    const [inputValue, setInputValue] = useState(value || '');
    const handleFieldClick = (field) => {
        setInputValue((prev) => prev + field);
    };
    const handleOperatorClick = (operator) => {
        setInputValue((prev) => prev + operator);
    };
    const handleConfirm = () => {
        setValue(inputValue);
    };
    return (_jsxs(_Fragment, { children: [_jsx(TextField, { sx: { flex: 1 }, value: inputValue, label: "Calculation expression", onChange: (e) => setInputValue(e.target.value), margin: "normal", fullWidth: true }), _jsx(Grid, { container: true, spacing: 1, children: fields.map((field, index) => (_jsx(Grid, { item: true, children: _jsx(Chip, { label: field, onClick: () => handleFieldClick(field), variant: "outlined", sx: { cursor: 'pointer' } }) }, index))) }), _jsxs(Grid, { container: true, spacing: 1, sx: { mt: 0.2 }, children: [_jsx(Grid, { item: true, children: _jsx(IconButton, { onClick: () => handleOperatorClick('+'), color: "primary", children: _jsx(AddIcon, {}) }) }), _jsx(Grid, { item: true, children: _jsx(IconButton, { onClick: () => handleOperatorClick('-'), color: "primary", children: _jsx(RemoveIcon, {}) }) }), _jsx(Grid, { item: true, children: _jsx(IconButton, { onClick: () => handleOperatorClick('*'), color: "primary", children: _jsx(CloseIcon, {}) }) }), _jsx(Grid, { item: true, children: _jsx(IconButton, { onClick: () => handleOperatorClick('/'), sx: { height: '34px', width: '34px' }, color: "primary", children: _jsx(Typography, { variant: "h6", component: "span", sx: {
                                    display: 'inline-block',
                                    verticalAlign: 'middle',
                                }, children: "/" }) }) }), _jsx(Grid, { item: true, children: _jsx(Button, { variant: "contained", color: "primary", onClick: handleConfirm, children: "Confirm" }) })] })] }));
};
export default FieldsCalculatorExpressionBuilder;
