import getIndices from './getIndices';
const fillDown = (array, colNames) => {
    const indicesToProcess = getIndices(array, colNames);
    const newArray = JSON.parse(JSON.stringify(array));
    indicesToProcess.forEach((idx) => {
        let tempVal = '';
        for (let i = 1; i < newArray.length; i++) {
            if (newArray[i][idx]) {
                tempVal = newArray[i][idx];
            }
            else {
                newArray[i][idx] = tempVal;
            }
        }
    });
    return newArray;
};
export const desc_fillDown = {
    name: 'libs.tools.fillDown(array, colNames)',
    description: 'Fills empty cells in specified columns with values from the row above.',
    arguments: {
        array: '[Array<Array>] 2D array of data to process',
        colNames: '[Array<string>] Array of column names to fill down',
    },
    returns: '[Array<Array>] New array with empty cells filled with values from above',
    examples: 'fillDown([\n  ["a", "b", "c"],\n  [1, 2, 3],\n  [null, 5, 6]\n], ["a"])\n// → [\n//   ["a", "b", "c"],\n//   [1, 2, 3],\n//   [1, 5, 6]\n// ]\n',
};
export default fillDown;
