import { GROUP_BY_VALUES } from 'common/documents/documents.constants';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useFilterParams = () => {
  const [searchParams] = useSearchParams();
  const params = useMemo(() => {
    return {
      start_date: searchParams.get('start_date') || '',
      end_date: searchParams.get('end_date') || '',
      companies: searchParams.get('companies')?.split(','),
      group_by: searchParams.get('group_by') || GROUP_BY_VALUES.UPLOAD_DATE,
    };
  }, [searchParams]);

  return params;
};
