const findAllDate = (json) => {
    var _a;
    const datePattern = [
        /\b(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)[a-z]*\s\d{4}\b/g, // "Oct 2024"
        // eslint-disable-next-line no-useless-escape
        /\b\d{1,2}[\/\-]\d{1,2}[\/\-]\d{2,4}\b/g, // "1/1/2024", "1/1/24", "01/01/2024", "01-01-24", "01-01-2024"
        // eslint-disable-next-line no-useless-escape
        /\b\d{2,4}[-\/]\d{1,2}[-\/]\d{1,2}\b/g, // "2024/1/1", "24/1/1", "2024/01/01", "24-01-01", "2024-01-01"
        /\b\d{1,2}-[A-Z]{3}-\d{2,4}\b/g, // "11-NOV-24", "15-JAN-2024"
        /\b\d{1,2}\s(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)[a-z]*,?\s\d{4}\b/g, // "01 Jan, 2024", "01 Janunary, 2024"
        /\b(?:Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)[a-z]*\s\d{1,2},\s\d{4}\b/g, // "January 01, 2024"
    ];
    const foundDates = [];
    (_a = json.Lines) === null || _a === void 0 ? void 0 : _a.forEach((lineItem) => {
        lineItem.LinesArray.forEach((line) => {
            datePattern.forEach((pattern) => {
                const matches = line.Line.matchAll(pattern);
                if (matches) {
                    foundDates.push(...Array.from(matches).map((match) => match[0]));
                }
            });
        });
    });
    return foundDates;
};
export const desc_findAllDate = {
    name: 'libs.tools.findAllDate(json)',
    description: 'Finds all date values in a JSON object that match common date formats in the text content.',
    arguments: {
        json: '[Object] JSON object containing Lines array with text content to search',
    },
    returns: '[Array<string>] Array of found date strings from the content',
    examples: 'findAllDate({\n  Lines: [{\n    LinesArray: [{\n      Line: "Document from Oct 2024 and 01/02/2024"\n    }]\n  }]\n})\n// → ["Oct 2024", "01/02/2024"]\n',
};
export default findAllDate;
