var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Alert, FormControlLabel, Checkbox, Tooltip } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useContext, useEffect, useState, useRef } from 'react';
import { Navigate } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { SyncOutlined } from '@mui/icons-material';
import { BasicDialog, FileDialogPreview } from '@/common';
import { PDF_HTML_IMG_TYPES } from '@/common/preview/model';
import UploadOverrideFile from '@/components/documents/DocumentsView/DocumentOverrideFile';
import ExtractMethod from '@/components/documents/DocumentsView/ExtractMethod';
import UpdateProcessData from '@/components/documents/DocumentsView/UpdateProcessData';
import EnhancedDataView from '@/components/organisms/EnhancedDataView';
import { LoadingContext } from '@/contexts/LoadingContext';
import { UIStateContext } from '@/contexts/UIStateProvider';
import useDownloadStorageFile from '@/contexts/useDownloadStorageFile';
import usePreviewParams from '@/contexts/usePreviewParams';
import API from '@/services/API';
import { useSetOriginFile } from '@/store/excelStore';
import ActionDialog from './ActionDialog';
import useSnackbar from '@/contexts/useSnackbar';
import { useDocumentViewDesc } from './hooks/useDocumentViewDesc';
import { useFilters } from './hooks/useFilters';
import { useExtraActions } from './hooks/useExtraActions';
import { useSyncedFieldsNew } from '@/contexts/useSyncedFields';
import { SyncEndAdornment } from '@/common/SyncEndAdornment';
import { SyncedEntity } from '@/common/SyncedEntity';
const DocumentsView = () => {
    var _a, _b;
    const { data: accountSettings, isFetched: isFetchedAccountSettings } = API.getBasicQuery(`accounts/settings`);
    const [selectedData, setSelectedData] = useState([]);
    const { showPreview, setShowPreview, previewId } = usePreviewParams();
    const { role: [], } = useContext(UIStateContext);
    const syncStatement = API.getMutation('data_processing/sync/benefit-point/statements', 'POST');
    const viewSettings = (_a = accountSettings === null || accountSettings === void 0 ? void 0 : accountSettings.pages_settings) === null || _a === void 0 ? void 0 : _a.documents;
    const { data: documentProfiles } = API.getBasicQuery('document_profiles');
    if (isFetchedAccountSettings && (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.show_page) === false) {
        return (
        // TODO: Remove navigate after figuring out how to handle this in router
        _jsx(Navigate, { to: "/settings" }));
    }
    const { showSnackbar } = useSnackbar();
    const [open, setOpen] = useState(false);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [showExtract, setShowExtract] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [processLoading, setProcessLoading] = useState('');
    const [activeRow, setActiveRow] = useState();
    const [showEdit, setShowEdit] = useState(null);
    const [sync, setSync] = useState({ documentId: '', show: false, count: 0 });
    const [rowData, setRowData] = useState({});
    const { setLoadingConfig } = useContext(LoadingContext);
    const [deleteRecords, setDeleteRecords] = useState(false);
    const setUploadedFile = useSetOriginFile();
    const { downloadFile } = useDownloadStorageFile();
    const { filtersData } = useFilters();
    const { extraActions } = useExtraActions();
    const documentsDelete = API.getMutation('documents', 'DELETE');
    const { data: activeRowData, isLoading: isActiveRowLoading } = API.getBasicQuery('extractions', `document_id=${activeRow === null || activeRow === void 0 ? void 0 : activeRow.id}`, !!(activeRow === null || activeRow === void 0 ? void 0 : activeRow.id));
    const queryClient = useQueryClient();
    const activeRowRef = useRef(false);
    const { workerSyncedFields, isSyncedField } = useSyncedFieldsNew();
    const { dataDesc } = useDocumentViewDesc({
        setProcessLoading,
        setActiveRow,
        setRowData,
        setShowUploadModal,
        setSync,
        setShowEdit,
        setShowConfirm,
        activeRowRef,
    });
    for (const key in dataDesc.fields) {
        const field = dataDesc.fields[key];
        field.readOnly =
            field.readOnly ||
                ((data) => {
                    var _a;
                    const syncedFields = (_a = workerSyncedFields === null || workerSyncedFields === void 0 ? void 0 : workerSyncedFields[data === null || data === void 0 ? void 0 : data.sync_worker]) === null || _a === void 0 ? void 0 : _a.documents;
                    if ((syncedFields || []).includes(field.id)) {
                        return isSyncedField(data, syncedFields, field.id, data.config);
                    }
                    return false;
                });
        field.endAdornment = (data, field, setNewData) => {
            var _a;
            return (_jsx(SyncEndAdornment, { syncedFields: (_a = workerSyncedFields === null || workerSyncedFields === void 0 ? void 0 : workerSyncedFields[data === null || data === void 0 ? void 0 : data.sync_worker]) === null || _a === void 0 ? void 0 : _a.documents, syncId: data === null || data === void 0 ? void 0 : data.sync_id, fieldId: field === null || field === void 0 ? void 0 : field.id, data: data, fieldType: field.type, onChange: (newOverrideFields) => {
                    setNewData(Object.assign(Object.assign({}, data), { config: Object.assign(Object.assign({}, (data.config || {})), { overrideFields: newOverrideFields }) }));
                } }));
        };
    }
    const BulkSync = ({ key, isWorkingToolbar, setIsWorkingToolbar, refetch, }) => {
        const syncStatements = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                setIsWorkingToolbar(true);
                for (const document of selectedData) {
                    yield syncToBenefit({ documentId: document.str_id });
                }
                showSnackbar('Bulk sync statements back to BenefitPoint successfully, please view details in the logs under the "Settings" -> "Data processing" section', 'success');
                refetch === null || refetch === void 0 ? void 0 : refetch();
            }
            finally {
                setIsWorkingToolbar(false);
            }
        });
        return (_jsx(LoadingButton, { loading: isWorkingToolbar, children: _jsx(Tooltip, { title: "Bulk sync statements to BenefitPoint", onClick: () => syncStatements(), children: _jsx(SyncOutlined, {}) }) }, key));
    };
    // Hard coded for now, will be dynamic later
    const syncToBenefit = (data) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoadingConfig({
                loading: true,
                message: 'Syncing...',
            });
            const ret = yield syncStatement.mutateAsync(data);
            if (ret.success === false) {
                showSnackbar(`Sync failed: ${ret.message || ret.statusText}`, 'error');
            }
            else {
                const { actualCreated, create, invalid, grouped } = ret;
                let tips = [];
                if (grouped === actualCreated && create.length != grouped) {
                    tips = [
                        `${actualCreated} commission entries posted.`,
                        '',
                        grouped !== create.length
                            ? `${create.length} commissions entries merged into ${actualCreated}.`
                            : '',
                        `Only one commission entry allowed per policy.`,
                    ];
                }
                else {
                    tips.push(`${actualCreated} statement ${actualCreated > 1 ? 'entries' : 'entry'} created`);
                }
                // if not equal, show the tips in a yellow background
                showSnackbar(_jsxs(Alert, { severity: grouped !== actualCreated || (invalid === null || invalid === void 0 ? void 0 : invalid.length) > 0
                        ? 'warning'
                        : 'success', children: ["Synced statmentID: ", ret.statementId, _jsx("br", {}), tips.map((tip, index) => (_jsx("div", { children: tip ? tip : _jsx("br", {}) }, index))), (invalid === null || invalid === void 0 ? void 0 : invalid.length) > 0 && _jsx("br", {}), (invalid === null || invalid === void 0 ? void 0 : invalid.length) > 0
                            ? `${invalid.length} statement ${invalid.length > 1 ? 'reports' : 'report'} invalid`
                            : null] }));
            }
        }
        catch (error) {
            showSnackbar(error.message || error, 'error');
        }
        finally {
            setLoadingConfig({
                loading: false,
                message: '',
            });
        }
    });
    const deleteData = (row) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoadingConfig({
                loading: true,
                message: 'Deleting...',
            });
            yield documentsDelete.mutateAsync({
                id: row.id,
                str_id: row.str_id,
                type: row.type,
                deleteRecords,
            });
            queryClient.invalidateQueries();
            setDeleteRecords(false);
            setLoadingConfig({
                loading: false,
                message: '',
            });
            showSnackbar(`Deleted ${row.filename}`, 'success');
        }
        catch (error) {
            setLoadingConfig({
                loading: false,
                message: '',
            });
            showSnackbar(error.message || error, 'error');
        }
    });
    /**
     * Extraxt data from file, if file is image or pdf, show an select option to choose which method to extract data (1. Google Document AI, 2. ExtractTable)
     * @param {object} row documents table row
     */
    useEffect(() => {
        if (isActiveRowLoading || !activeRow || showExtract || open) {
            return;
        }
        if (activeRow) {
            setProcessLoading(activeRow.id);
        }
        const extractData = (_row) => __awaiter(void 0, void 0, void 0, function* () {
            const filePreviewType = (activeRow === null || activeRow === void 0 ? void 0 : activeRow.override_file_path)
                ? 'override'
                : 'original';
            try {
                const file = yield downloadFile({
                    endpoint_str_id: activeRow === null || activeRow === void 0 ? void 0 : activeRow.str_id,
                    file_preview_type: filePreviewType,
                    endpoint: 'documents',
                });
                if (!file) {
                    throw new Error('Failed to download file');
                }
                setRowData(() => {
                    return Object.assign(Object.assign({}, activeRow), { extractions: activeRowData });
                });
                setUploadedFile(file);
                setProcessLoading('');
                if (PDF_HTML_IMG_TYPES.includes(file.type)) {
                    setShowExtract(true);
                }
                else {
                    setOpen(true);
                }
                activeRowRef.current = false;
                setActiveRow(undefined);
            }
            catch (error) {
                activeRowRef.current = false;
                setActiveRow(undefined);
                setProcessLoading('');
                showSnackbar((error === null || error === void 0 ? void 0 : error.message) || error, 'error');
            }
        });
        extractData(activeRowData);
    }, [
        activeRowData,
        isActiveRowLoading,
        activeRow,
        processLoading,
        downloadFile,
        setUploadedFile,
        showSnackbar,
    ]);
    if (viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label) {
        dataDesc.label = viewSettings === null || viewSettings === void 0 ? void 0 : viewSettings.page_label;
    }
    const onConfirmMethod = (method) => {
        setRowData((prev) => (Object.assign(Object.assign({}, prev), { method })));
        setShowExtract(false);
        setOpen(true);
    };
    const onSetShowEdit = (isShow, action = 'cancel') => {
        setShowEdit(isShow);
        if (action === 'save') {
            queryClient.invalidateQueries();
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(EnhancedDataView, { dataSpec: dataDesc, hideAdd: true, enableMultiSelect: true, enableEdit: false, actionsEnabled: () => true, actions: [
                    ...dataDesc.actions,
                    {
                        type: 'icon',
                        label: 'Sync',
                        icon: _jsx(SyncedEntity, { isSynced: true, disabled: true }),
                        enabled: (row) => !!row.sync_id && row.sync_worker,
                        onClick: (row, e) => __awaiter(void 0, void 0, void 0, function* () {
                            e === null || e === void 0 ? void 0 : e.stopPropagation();
                            e === null || e === void 0 ? void 0 : e.preventDefault();
                        }),
                    },
                ], extraActions: extraActions, bulkActions: [BulkSync], filters: filtersData, customHeaderActions: true, setSelectedData: setSelectedData }), open && (_jsx(UpdateProcessData, { open: open, rowData: rowData, setRowData: setRowData, handleClose: (arg) => {
                    queryClient.invalidateQueries();
                    setOpen(arg);
                } })), showUploadModal && (_jsx(UploadOverrideFile, { open: showUploadModal, setOpen: setShowUploadModal, uploadedRow: rowData })), showExtract && (_jsx(ExtractMethod, { showExtract: showExtract, onClose: () => setShowExtract(false), onConfirm: onConfirmMethod, uploadedRow: rowData })), showPreview && (_jsx(FileDialogPreview, { showPreview: showPreview, setShowPreview: setShowPreview, fileId: previewId })), showConfirm && (_jsx(BasicDialog, { open: showConfirm, title: "Delete document", bodyComponent: _jsxs(_Fragment, { children: [_jsxs(Alert, { severity: "warning", children: ["Are you sure you want to delete ", rowData.filename, "?"] }), _jsx(FormControlLabel, { control: _jsx(Checkbox, { checked: deleteRecords, onChange: (e) => setDeleteRecords(e.target.checked) }), label: `Also delete imported records (${(_b = rowData.statement_data) === null || _b === void 0 ? void 0 : _b.total_count})`, sx: { ml: 1, mt: 1 } })] }), onClose: (isOk) => {
                    if (isOk) {
                        deleteData(rowData);
                    }
                    setShowConfirm(false);
                }, sxContent: { pb: 1 } })), showEdit && (_jsx(ActionDialog, { open: showEdit, setOpen: onSetShowEdit, rowData: rowData, documentProfiles: documentProfiles === null || documentProfiles === void 0 ? void 0 : documentProfiles.data })), _jsx(BasicDialog, { open: sync.show, title: "Sync data", bodyComponent: _jsxs(Alert, { severity: "warning", children: ["Are you sure you want to sync ", sync.count, " statement entries to BenefitPoint?"] }), onClose: (isOk) => {
                    if (isOk) {
                        syncToBenefit({ documentId: sync.documentId });
                    }
                    setSync(Object.assign(Object.assign({}, sync), { show: false }));
                }, positiveLabel: "Sync" })] }));
};
export default DocumentsView;
