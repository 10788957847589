var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { MaterialReactTable, MRT_TablePagination } from 'material-react-table';
import { useMemo, useState } from 'react';
import { Box } from '@mui/material';
import { useGroupData } from '../apis';
import { useProcessGroupBy } from '../hooks/useProcessGroupBy';
import { useColumns } from '../hooks/useColumns';
import { TopToolbar } from './TopToolbar';
import { useFilterParams } from '../hooks/useFilterParams';
import { formatDateName } from '../utils';
export const DocumentsGroupView = () => {
    var _a;
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 50,
    });
    const _b = useFilterParams(), { group_by } = _b, rest = __rest(_b, ["group_by"]);
    const { data = [], isLoading } = useGroupData(Object.assign(Object.assign({ group_by }, rest), { companies: ((_a = rest.companies) === null || _a === void 0 ? void 0 : _a.join(',')) || '' }));
    const groupedData = useProcessGroupBy({
        data,
        groupBy: group_by,
    });
    const totalData = useMemo(() => {
        const totalData = {
            file_count: 0,
            total_bank_amount: 0,
            total_commission_amount: 0,
            total_statement_amount: 0,
        };
        data.forEach((item) => {
            totalData.total_bank_amount += item.total_bank_amount;
            totalData.total_commission_amount += item.total_commission_amount;
            totalData.total_statement_amount += item.total_statement_amount;
            totalData.file_count += item.file_count;
        });
        return totalData;
    }, [data]);
    const { columns } = useColumns({
        groupBy: group_by,
        totalData,
    });
    const dataWithFormattedDate = useMemo(() => {
        return groupedData.map((item) => {
            return Object.assign(Object.assign({}, item), { display_name: formatDateName(item.date, group_by) });
        });
    }, [group_by, groupedData]);
    return (_jsx(_Fragment, { children: _jsx(MaterialReactTable, { enableStickyHeader: true, enableStickyFooter: true, getRowId: (v) => (v.date || v.company_str_id), columns: columns, data: dataWithFormattedDate, enableGlobalFilter: false, initialState: {
                density: 'compact',
                sorting: [
                    {
                        id: 'company_name',
                        desc: false,
                    },
                ],
            }, state: {
                isLoading,
                pagination,
            }, muiTablePaperProps: {
                elevation: 0,
                sx: {
                    pt: 2,
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                },
            }, muiTableContainerProps: {
                sx: { flex: 1 },
            }, muiPaginationProps: {
                rowsPerPageOptions: [50, 250, 500, 1000],
            }, enableTableFooter: true, onPaginationChange: setPagination, renderBottomToolbar: ({ table }) => {
                return (_jsx(Box, { sx: {
                        background: 'white',
                        right: 0,
                        bottom: 0,
                        '& .MuiTablePagination-root': {
                            paddingTop: 0.5,
                            paddingBottom: 0.5,
                        },
                    }, children: _jsx(MRT_TablePagination, { table: table }) }));
            }, renderTopToolbar: ({ table }) => {
                return _jsx(TopToolbar, { table: table });
            } }, group_by) }));
};
