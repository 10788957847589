import convertToNumber from './convertToNumber';
const getPercent = (value1, value2) => {
    const number1 = convertToNumber(value1);
    const number2 = convertToNumber(value2);
    if (number2 === 0)
        return '';
    const percent = ((number1 / number2) * 100).toFixed(2);
    return !Number.isFinite(percent) && !Number.isNaN(percent)
        ? `${percent}%`
        : '';
};
export const desc_getPercent = {
    name: 'libs.tools.getPercent(value1, value2)',
    description: 'Calculates the percentage of first number relative to second number.',
    arguments: {
        value1: '[String|Number] First number (numerator)',
        value2: '[String|Number] Second number (denominator)',
    },
    returns: '[String] Formatted percentage with two decimal places, or empty string if invalid',
    examples: 'getPercent("123", "456")\n// → "26.97%"\n',
};
export default getPercent;
