export var GROUP_BY_VALUES;
(function (GROUP_BY_VALUES) {
    GROUP_BY_VALUES["MONTH"] = "month";
    GROUP_BY_VALUES["QUARTER"] = "quarter";
    GROUP_BY_VALUES["YEAR"] = "year";
    GROUP_BY_VALUES["COMPANY"] = "company";
    GROUP_BY_VALUES["UPLOAD_DATE"] = "created_at";
    GROUP_BY_VALUES["DEPOSIT_DATE"] = "deposit_date";
    GROUP_BY_VALUES["PROCESSING_DATE"] = "processing_date";
    GROUP_BY_VALUES["PAYMENT_DATE"] = "payment_date";
})(GROUP_BY_VALUES || (GROUP_BY_VALUES = {}));
export const GroupViewOptions = [
    {
        id: GROUP_BY_VALUES.MONTH,
        name: 'Month',
        subList: [
            { id: GROUP_BY_VALUES.UPLOAD_DATE, name: 'Upload date' },
            { id: GROUP_BY_VALUES.DEPOSIT_DATE, name: 'Deposit date' },
            { id: GROUP_BY_VALUES.PROCESSING_DATE, name: 'Processing date' },
            { id: GROUP_BY_VALUES.PAYMENT_DATE, name: 'Payment date' },
        ],
    },
    { id: GROUP_BY_VALUES.QUARTER, name: 'Quarter' },
    { id: GROUP_BY_VALUES.YEAR, name: 'Year' },
    { id: GROUP_BY_VALUES.COMPANY, name: 'Company' },
];
export const GROUP_BY_KEY = 'group_by';
