export const dashboardWidgetOptions = {
    policiesInForce: 'policiesInForce',
    premiumsInForce: 'premiumsInForce',
    premiumsIssuePay: 'premiumsIssuePay',
    commissionsReceived: 'commissionsReceived',
    commissionsPaid: 'commissionsPaid',
    numPoliciesWithCommissions: 'numPoliciesWithCommissions',
    policiesWithReversedPayments: 'policiesWithReversedPayments',
    commissionsByCarrierMap: 'commissionsByCarrierMap',
    commissionsByWritingCarrierMap: 'commissionsByWritingCarrierMap',
    premiumsByCarrierMap: 'premiumsByCarrierMap',
    agentCommissions: 'agentCommissions',
    commissionsByTimeMap: 'commissionsByTimeMap',
    commissionsAccByTimeMap: 'commissionsAccByTimeMap',
    commissionsOutByTimeMap: 'commissionsOutByTimeMap',
    commissionsOutAccByTimeMap: 'commissionsOutAccByTimeMap',
    commissionsNetByTimeMap: 'commissionsNetByTimeMap',
    commissionsNetAccByTimeMap: 'commissionsNetAccByTimeMap',
    policiesByTimeMap: 'policiesByTimeMap',
    effectiveCommissionRateByCarrierArray: 'effectiveCommissionRateByCarrierArray',
    effectiveCommissionRateByWritingCarrierArray: 'effectiveCommissionRateByWritingCarrierArray',
};
export const CALC_METHODS = {
    compGrid: 'Pay commission to grid level',
    compGridLevel: 'Share override to grid level',
    payoutRate: 'Pay a set rate',
    payHouseRate: 'Pay house rate',
    payOverrideUpToTotalRate: 'Pay override up to total rate',
    keepRate: 'Keep a set rate',
    overrideSplit: 'Split percentage',
    carrierGridSplitlRemainder: 'Pay to a grid level then split percentage',
    payoutRateIncentiveTiers: 'Pay based on incentive tier',
    referral: 'Referral',
};
export var WidgetGroup;
(function (WidgetGroup) {
    WidgetGroup["BOX"] = "BOX";
    WidgetGroup["CHART"] = "CHART";
    WidgetGroup["TABLE"] = "TABLE";
})(WidgetGroup || (WidgetGroup = {}));
export var AccountIds;
(function (AccountIds) {
    AccountIds["BROKERS_ALLIANCE"] = "8LKborwusmH-8C79qmpfx";
    AccountIds["EDISON_RISK"] = "tY4K6TGT8NH6yMREQf2XQ";
    AccountIds["FINTARY_GLOBAL"] = "8cb1c18c05062d1134db";
    AccountIds["QUILITY"] = "_xNo3yr3mHsZZHeubkE7h";
    AccountIds["RISK_TAG"] = "W4kSrayZvmh26pGfYVrGE";
    AccountIds["TRAILSTONE"] = "f_mX8g_LfFzV1SeD-U4ki";
    AccountIds["TRANSGLOBAL"] = "XN9U5UtGrD5aovyEwNlHj";
    AccountIds["WORLD_CHANGERS"] = "LTVy72chvj8LvP_ztKSax";
})(AccountIds || (AccountIds = {}));
export const ALEVO_EXCLUDED_AGENTS = [
    'DrRNXzr0itzLiqYRJyVkk',
    'Jcp0FdGNQR6Gk6UnoYFAB',
    'SwHJMuUejInJGRGlHXatD',
    'zbmNbWcwgM1gNEvLH_7n0',
];
export var WorkerNames;
(function (WorkerNames) {
    WorkerNames["AgencyIntegratorWorker"] = "AgencyIntegratorWorker";
    WorkerNames["NowCertsWorker"] = "NowCertsWorker";
    WorkerNames["OneHQWorker"] = "OneHQWorker";
    WorkerNames["SmartOfficeWorker"] = "SmartOfficeWorker";
    WorkerNames["BenefitPointWorker"] = "BenefitPointWorker";
    WorkerNames["TransGlobalWorker"] = "TransGlobalWorker";
    WorkerNames["MyAdvisorGridsWorker"] = "MyAdvisorGridsWorker";
    WorkerNames["AwsS3Worker"] = "AwsS3Worker";
    WorkerNames["DocumentProcessingWorker"] = "DocumentProcessingWorker";
    // sync data using fintary openapi
    WorkerNames["OpenAPI"] = "OpenAPI";
})(WorkerNames || (WorkerNames = {}));
export const RATES_LABELS = {
    carrier_rate: 'Carrier rate',
    house_rate: 'House rate',
    rate: 'Total rate',
};
export const transactionTypes = {
    COMP_REPORT_PAYMENT: 'comp_report_payment',
};
export var ResponseAction;
(function (ResponseAction) {
    ResponseAction["LOG_OUT"] = "logout";
})(ResponseAction || (ResponseAction = {}));
